import { GroupBase, StylesConfig } from 'react-select';
import { IOption } from './SearchBarSelect';

export const searchBarSelectStyles: StylesConfig<IOption, false, GroupBase<IOption>> = {
  container: (s) => ({ ...s, boxShadow: 'none' }),
  control: (s) => ({
    ...s,
    border: 'none !important',
    boxShadow: 'none',
    backgroundColor: 'var(--white)',
    height: 31,
    minHeight: 31,
    borderRadius: 0,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    transition: 'none',
  }),
  menu: (s) => ({
    ...s,
    zIndex: 100,
    minWidth: 150,
    marginTop: 2,
    marginBottom: 2,
    backgroundColor: 'var(--white)',
  }),
  indicatorsContainer: (s) => ({ ...s, color: 'var(--black)' }),
  valueContainer: (s) => ({ ...s, paddingRight: 0 }),
  singleValue: (s) => ({
    ...s,
    color: 'var(--secondary-text-color)',
    fontWeight: 600,
    fontSize: 'var(--font-size-13)',
  }),
};

export const getValue = (data) => {
  if (!data || (Array.isArray(data) && !data.length)) {
    return;
  }

  if (Array.isArray(data) && data.length) {
    const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
    return temp.filter((item) => item);
  }

  return data.value !== 'any' ? data.value : null;
};
import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './crypto-wallet-table.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { ClockCounterClockwise, Wallet } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { usePermission } from 'utils/usePermission';
import { copyToBuffer } from 'utils/copyToBuffer';
import DeleteRestorePopup from 'pages/CustomerService/CryptoPayments/CryptoPaymentsPopups/DeleteRestorePopup';
import { CRYPTO_WALLETS_TYPES, WALLET_ACTION_TYPE } from 'constants/payments.const';
import { ICryptoWalletTableProps } from 'models/components/Table/CryptoWalletTable/CryptoWalletTable.ts';
import { getShortUuid } from 'utils/getShortUuid';
import { Icon } from 'components/ui/Icon';

const cx = classNames.bind(styles);

const CryptoWalletTable: FC<ICryptoWalletTableProps> = ({
  data,
  perPage,
  showLoader,
  columns,
  onSaveColumnOrder,
  modifyDataHandler,
  onRowSelect,
}) => {
  const { permissionGiven: PermissionDeleteCryptoWallet } = usePermission(
    'admin.crypto_wallets.delete',
  );
  const { permissionGiven: PermissionRestoreDeletedCryptoWallet } = usePermission(
    'admin.crypto_wallets.restore',
  );

  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      id,
      wallet,
      crypto_type,
      crypto_network,
      user,
      description,
      created_at,
      deleted_at,
      general,
      fintegrity,
    } = item;

    return {
      crypto_type: (
        <div className={cx('wallet__type')}>
          {!deleted_at ? (
            <Link to={`/customer-crypto/${id}`} className={cx('logo')}>
              <div className={cx('wallet__item')}>
                <Icon name={crypto_type?.toLowerCase()} size={18} />
                <div className={cx('wallet__text')}>
                  <span>{CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]}</span>
                  {fintegrity === 1 && <span>Fintegrity</span>}
                </div>
              </div>
            </Link>
          ) : (
            <div className={cx('wallet__item')}>
              {/* <Icon name={crypto_type?.toLowerCase()} size={18} /> */}
              <div className={cx('wallet__text')}>
                <span>
                  {CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]}{' '}
                  {crypto_type === 'USDT' && crypto_network && `(${crypto_network})`}
                </span>
                {fintegrity === 1 && <span>Fintegrity</span>}
              </div>
            </div>
          )}
        </div>
      ),

      wallet: (
        <div className={cx('wallet__desc')}>
          <span className={cx('wallet__name')}>
            <Icon name="wallet" size={18} color="var(--main-color)" />
            <span onClick={() => copyToBuffer(wallet, true)}>{wallet}</span>
          </span>
          <span>{description}</span>
        </div>
      ),

      shared_wallet: (
        <div>
          <span className={cx('wallet__shared')}>{general === 1 ? 'Yes' : 'No'}</span>
        </div>
      ),
      user_profile: (
        <div className={cx('wallet_table_td')}>
          <Link
            target={'_blank'}
            to={`/client/${item?.user_profile?.user_id ?? 0}`}
            className={cx('wallet_table_td-link')}
          >
            <span>
              {item && item.user_profile && item.user_profile.first_name
                ? item.user_profile.first_name + ' '
                : ''}
              {item && item.user_profile && item.user_profile.last_name
                ? item.user_profile.last_name
                : ''}
            </span>
          </Link>
          {item.user_profile !== null && item?.user_profile?.user_uuid && (
            <span
              className={cx('uuid')}
              onClick={() => copyToBuffer(item?.user_profile?.user_uuid, true)}
            >
              {getShortUuid(item?.user_profile?.user_uuid)}
            </span>
          )}
        </div>
      ),
      action: (
        <div className={cx('wallet__actions')}>
          {!deleted_at ? (
            <>
              <div className={cx('wallet__actions-delete')}>
                {PermissionDeleteCryptoWallet ? (
                  <DeleteRestorePopup
                    wallet={item}
                    actionType={WALLET_ACTION_TYPE.DELETE}
                    triggerBtn={<Icon name="trash" size={20} color="var(--red)" />}
                    onCloseModal={() => console.log('a')}
                    updateComponent={modifyDataHandler}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <div className={cx('wallet__actions-restore')}>
              {PermissionRestoreDeletedCryptoWallet ? (
                <DeleteRestorePopup
                  wallet={item}
                  actionType={WALLET_ACTION_TYPE.RESTORE}
                  triggerBtn={<Icon name="clockCounter" size={20} color="var(--grey-dark-100)" />}
                  onCloseModal={() => console.log('a')}
                  updateComponent={modifyDataHandler}
                />
              ) : null}
            </div>
          )}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={onSaveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('wallet-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? 'var(--cyan)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default CryptoWalletTable;

import React, { useState } from 'react';
import Button from 'components/ui/Button';
import {
  closeJokerModal,
  selectModalIsConstructiveMode,
  selectModalProps,
} from 'store/slice/modalSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { deleteAlert, updateAlertStatus } from 'store/slice/tickerSlice';
import './DeleteMessageModal.scss';
import Popup from 'reactjs-popup';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import { Container } from 'react-bootstrap';

export const SYSTEM_MESSAGE_DELETE_MODAL_KEY = 'SYSTEM_MESSAGE_DELETE_MODAL_KEY';

export const DeleteMessageModal = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isConstructiveMode = useAppSelector(selectModalIsConstructiveMode);
  const modalProps = useAppSelector(selectModalProps);

  const isArchive = modalProps?.archive;

  const handleCloseModal = (close) => {
    close?.();
    dispatch(closeJokerModal());
  };

  const handleConfirm = async (close) => {
    setIsLoading(true);

    if (isArchive) {
      await dispatch(updateAlertStatus({ id: modalProps?.message?.id, status: false }));
    } else {
      await dispatch(deleteAlert(modalProps?.message));
    }
    close?.();
    setIsLoading(false);
    dispatch(closeJokerModal());
  };

  return (
    <Popup
      modal
      closeOnEscape
      repositionOnResize
      lockScroll
      closeOnDocumentClick
      open={!!modalProps && !isConstructiveMode}
      onClose={handleCloseModal}
    >
      {(close) => (
        <PopupTemplate
          isBigPopup={false}
          trigger={<button> Trigger</button>}
          dismissModal={close}
          headerTitle={isArchive ? 'Archive message' : 'Delete message'}
          rightContent={
            <Container>
              <div className="system-message-delete-modal">
                <div className="system-message-delete-title">
                  <div>{`Are you sure, you want to ${
                    isArchive ? 'archive' : 'delete'
                  } the message?`}</div>
                  {isArchive ? (
                    <div>
                      The message will be moved to the 'Past' tab. You'll be able to republish it
                      later.
                    </div>
                  ) : (
                    <div>Once deleted, it will no longer be possible to restore it.</div>
                  )}
                </div>
                <div className="modal-actions">
                  <Button
                    buttonText="Cancel"
                    buttonType="outline"
                    type="button"
                    onClick={() => handleCloseModal(close)}
                  />
                  <Button
                    disabled={isLoading}
                    buttonText={isArchive ? 'Archive' : 'Delete'}
                    onClick={() => handleConfirm(close)}
                  />
                </div>
              </div>
            </Container>
          }
        />
      )}
    </Popup>
  );
  //     <ModalTemplate
  //         id={SYSTEM_MESSAGE_DELETE_MODAL_KEY}
  //         title={isArchive ? 'Archive message' : 'Delete message'}
  //         onClose={handleCloseModal}
  //         size='md'
  //     >
  //         <div className='system-message-delete-modal'>
  //             <div className="system-message-delete-title">
  //                 <div>{`Are you sure, you want to ${isArchive ? 'archive' : 'delete'} the message?`}</div>
  //                 {isArchive ? (
  //                     <div>The message will be moved to the 'Past' tab. You'll be able to republish it later.</div>
  //                 ) : (
  //                     <div>Once deleted, it will no longer be possible to restore it.</div>
  //                 )}
  //             </div>
  //             <div className="modal-actions">
  //                 <Button
  //                     buttonText='Cancel'
  //                     buttonType="outline"
  //                     type="button"
  //                     onClick={handleCloseModal}
  //                 />
  //                 <Button
  //                     className={isArchive ? '' : 'delete-message'}
  //                     buttonType="delete"
  //                     size="big"
  //                     disabled={isLoading}
  //                     buttonText={isArchive ? 'Archive' : 'Delete'}
  //                     onClick={handleConfirm}
  //                 />
  //             </div>
  //         </div>
  //     </ModalTemplate>
  // )
};

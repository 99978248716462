import classNames from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React from 'react';
import { updateScheduleEvent } from 'api/partners';
import { makeTableData } from 'helpers/table.helper';
import DeletePartnerSchedulesPopup from 'pages/Management/Partners/Partner/PartnerShedules/DeletePertnerSchedulesPopup';
import EditPartnerSchedulesPopup from 'pages/Management/Partners/Partner/PartnerShedules/EditPartnerSchedulesPopup';
import { notify } from 'utils/notify';
import { usePermission } from 'utils/usePermission.tsx';
import Table from '../Table';
import styles from './style.module.scss';
import { Icon } from 'components/ui/Icon';
import { Switch } from 'components/ui/Switch';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const cx = classNames.bind(styles);

const PartnerSchedulesTable = ({
  data,
  countryList,
  langList,
  operatorsList,
  partnersList,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  onSort,
  columns,
  saveColumnOrder,
}) => {
  const { permissionGiven: PermissionUpdate } = usePermission('admin.partner-schedule.update');
  const { permissionGiven: PermissionDelete } = usePermission('admin.partner-schedule.delete');

  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data, countryList, langList, operatorsList, partnersList],
  );

  const updateEvent = (id, item) => {
    const sendData = {
      ...item,
      hours_from: getTimeSplit(item.from, 'hours'),
      minutes_from: getTimeSplit(item.from, 'minutes'),
      hours_to: getTimeSplit(item.to, 'hours'),
      minutes_to: getTimeSplit(item.to, 'minutes'),
      active: item.active === 1 ? 0 : 1,
    };

    updateScheduleEvent(id, sendData)
      .then((res) => {
        if (res.data.data) {
          notify({
            type: 'success',
            message: 'Event activation changed',
            timeOut: 3000,
          });
          modifyDataHandler();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  const getTime = (time) => {
    const time_from_arr = time?.split(':');
    return time_from_arr && time_from_arr[0] + ':' + time_from_arr[1];
  };

  const getTimeSplit = (time, key) => {
    const time_from_arr = time?.split(':');

    return key === 'hours' ? time_from_arr[0] : time_from_arr[1];
  };

  const prepearedTableData = tableData.map((item, ind, pos) => {
    const { active, day, from, to, total_leads_limit, countries, id } = item;
    const time_from = getTime(from);
    const time_to = getTime(to);

    return {
      active: item.id ? (
        <div className={cx('filters-toggle')}>
          <label className="search-clients__toggle">
            {PermissionUpdate ? (
              <Switch isChecked={active === 1} onChange={() => updateEvent(id, item)} />
            ) : null}
          </label>
        </div>
      ) : null,
      day: (
        <div className={cx('rule__country')}>
          <span>{days[ind]}</span>
        </div>
      ),
      working_hours: (
        <div className={cx('rule__priority')}>
          <span>
            {time_from} - {time_to}
          </span>
        </div>
      ),
      total_leads_limit: (
        <div className={cx('rule__language')}>
          <span>{total_leads_limit}</span>
        </div>
      ),
      total_limit: (
        <div className={cx('rule__partner')}>
          {countries?.length ? <span>{countries?.length} Countries </span> : ''}
          {countries?.length ? (
            <div>
              {countries?.map((item) => {
                return (
                  <div key={item.id}>
                    <span>
                      {item.name}-{item.limit}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <span className={cx('rule__operator-empty')}>&mdash;</span>
          )}
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {PermissionUpdate ? (
              <EditPartnerSchedulesPopup
                countryList={countryList}
                sourceArray={[]}
                langList={langList}
                operatorsList={operatorsList}
                partnersList={partnersList}
                ruleData={item}
                day={ind + 1}
                actionType={SALES_RULES_ACTION_TYPE.EDIT}
                triggerBtn={<Icon name="notePencil" size={20} color="var(--main-color)" />}
                // onCloseModal={() => console.log('a')}
                updateComponent={modifyDataHandler}
              />
            ) : null}
          </div>
          <div>
            {PermissionDelete ? (
              <>
                {item.id && (
                  <DeletePartnerSchedulesPopup
                    rule={item}
                    name={days[day - 1]}
                    triggerBtn={<Icon name="trash" size={20} color="var(--red)" />}
                    // onCloseModal={() => console.log('a')}
                    updateComponent={modifyDataHandler}
                  />
                )}
              </>
            ) : null}
          </div>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default PartnerSchedulesTable;

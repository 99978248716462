import { Fragment } from 'react';
import { Icon, IconName } from 'components/ui/Icon';
import { TablePaginationButton } from '../TablePaginationButton/TablePaginationButton';
import { useTablePaginationPages } from './useTablePaginationPages';
import styles from './table-pagination-buttons.module.scss';

type Props = {
  currentPage: number;
  pageCount: number;
  onChangePage: (page: number) => void;
};

const ArrowIcon = (name: IconName) => <Icon name={name} size={20} color="var(--grey-blue)" />;

export const TablePaginationButtons = ({ currentPage, pageCount, onChangePage }: Props) => {
  const { pages, hasLeftArrow, hasRightArrow } = useTablePaginationPages({
    currentPage,
    pageCount,
  });

  return (
    <div className={styles.buttons}>
      {pages.map((page, index) => (
        <Fragment key={page}>
          {hasRightArrow(index) && ArrowIcon('arrowRight')}
          <TablePaginationButton
            page={page}
            isSelected={page === currentPage}
            onClick={() => {
              if (currentPage !== page) {
                onChangePage(page);
              }
            }}
          />
          {hasLeftArrow(index) && ArrowIcon('arrowLeft')}
        </Fragment>
      ))}
    </div>
  );
};

import { Icon } from 'components/ui/Icon';
import Button from 'components/ui/Button';
import cn from 'classnames';
import styles from './payments-page-title.module.scss';

type Props = {
  title: string;
  isExpanded: boolean;
  onToggleFilters: () => void;
};

export const PaymentsPageTitle = ({ title, isExpanded, onToggleFilters }: Props) => {
  return (
    <div className={styles.pageTitle}>
      <div>{title}</div>
      <Button
        className={cn(styles.toggleButton, isExpanded && styles.toggleButton__rotated)}
        onClick={onToggleFilters}
        icon={<Icon name="doubleCaretDown" size={20} />}
      />
    </div>
  );
};

import { useEffect, useState } from 'react';

export function useFilteredList(list) {
  const [filteredList, setFilteredList] = useState(list);
  useEffect(() => {
    setFilteredList(list);
  }, [list]);
  const onFilterList = (selectedArr) => {
    let fullList = [...list];
    let selectedCountryArr_start = selectedArr;
    let selectedCountryArr_label = selectedCountryArr_start?.map((item) => {
      return item?.id?.label;
    });
    let country = fullList?.filter((item) => {
      if (!selectedCountryArr_label.includes(item.label)) return item;
    });
    setFilteredList(country);
  };
  return { filteredList, onFilterList };
}

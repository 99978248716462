export const TABLE_SYSTEM_MESSAGES_COLUMNS_NAMES = [
    {
        Header: 'Message',
        accessor: 'message',
    },
    {
        Header: 'Start date',
        accessor: 'start_date',
    },
    {
        Header: 'End date',
        accessor: 'end_date',
    },
    {
        Header: 'Actions',
        accessor: 'action',
    },
];
import { findPayments } from 'api/payments';
import cn from 'classnames';

import TablePagination from 'components/ui/TablePagination';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';
import { usePermission } from '../../utils/usePermission.tsx';
import styles from './page.module.scss';
import PaymentsFilter from './PaymentsFilter/PaymentsFilter';
import PaymentsTable from '../../components/ui/Table/PaymentsTable';
import { TableLinks, TableMeta } from 'models/Table.js';
import { Sort } from 'models/Sort.js';
import { Payment } from 'models/Payments/Payment.js';
import { PaymentFilter } from 'models/Payments/Filter.js';
import { useAppDispatch } from '../../store';
import { toggleScrollToTop } from '../../store/slice/tableSlice.ts';
import { useCheckAutoRefresh } from '../../utils/useCheckAutoRefresh.tsx';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { PaymentsPageTitle } from './PaymentsPageTitle/PaymentsPageTitle.tsx';
import { usePaymentsPage } from './usePaymentsPage.ts';
import { FormProvider } from 'react-hook-form';
import { TableLayout } from 'components/ui/Table/TableLayout/TableLayout.tsx';
import { PaymentsFields } from './PaymentsFields/PaymentsFields.tsx';

const SORTED_FIELD_NAME = {
  original_agent: 'operator_full_name',
  country: 'country',
  amount: 'amount',
  trading_acc: 'trading_account',
  date: 'created_at',
};

const title = 'Payments';

const PaymentsPage = () => {
  const { permissionGiven: PermissionShowListTransaction } = usePermission(
    'admin.deposit.transaction.index',
  );

  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(true);
  const [paymentsList, setPaymentsList] = useState<Payment[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<PaymentFilter>(null);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const [sort, setSort] = useState<Sort>();

  const checkAutoRefresh = useCheckAutoRefresh('Page Payments');

  const componentMounted = useRef(true);

  usePageTitle(title);

  useEffect(() => {
    getPaymentsList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getPaymentsList = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findPayments(getTablePageParams(pageParams), filtersData)
      .then((res) => {
        if (componentMounted.current) {
          setPaymentsList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
        }
      })
      .catch(console.log)
      .finally(() => setIsListLoading(false));
  };

  const getClientToBulkActions = (rows) => {
    const clients = [];
    rows.forEach((item) => {
      clients.push(paymentsList[item['index']]['id']);
    });
    setSelectedRows(clients);
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (sort && filters) {
      return {
        sort,
        ...filters,
      };
    }

    if (sort && !filters) {
      return {
        sort,
      };
    }

    if (!sort && filters) {
      return filters;
    }
  };

  const { form, table, tableHeader } = usePaymentsPage();

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    getPaymentsList({ navigate: 'first', perPage: 10 });
    setFilters(null);
    form.reset();
    // setSort(null);
  };

  const onSubmit = (filtersData) => {
    if (!filters && !filtersData) {
      return;
    }
    if (filters && !filtersData) {
      clearFiltersSortList();
    } else {
      setFilters(filtersData);
      getPaymentsList({ navigate: 'first', perPage: perPageCount }, filtersData);
    }
  };

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={
          <PaymentsPageTitle
            title={`${PermissionShowListTransaction ? tableMeta?.total || 0 : ''} Payments found`}
            isExpanded={expanded}
            onToggleFilters={() => setExpanded((p) => !p)}
          />
        }
      >
        <>
          <FormProvider {...form}>
            <div className={cn(!expanded && styles.hide)}>
              <PaymentsFields onSubmit={onSubmit} />
            </div>
            <TableLayout
              header={
                <PaymentsFilter
                  onSubmit={onSubmit}
                  reloadFilters={isListLoading}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              {PermissionShowListTransaction && tableHeader ? (
                <PaymentsTable
                  data={paymentsList}
                  perPage={perPageCount}
                  showLoader={isListLoading}
                  onRowSelect={getClientToBulkActions}
                  updateComponent={getPaymentsList}
                  manualSortHandler={(col) => {
                    const sorted = col.isSorted;
                    const desc = col.isSortedDesc;

                    if (!sorted) {
                      const sort = {
                        field: SORTED_FIELD_NAME[col.id],
                        direct: 'desc',
                      };
                      setSort(sort);
                      getPaymentsList(
                        { navigate: 'first' },
                        {
                          ...filters,
                          sort,
                        },
                      );
                      col.toggleSortBy(true);
                    }

                    if (sorted && desc) {
                      const sort = {
                        field: SORTED_FIELD_NAME[col.id],
                        direct: 'asc',
                      };
                      setSort(sort);
                      getPaymentsList(
                        { navigate: 'first' },
                        {
                          ...filters,
                          sort,
                        },
                      );
                      col.toggleSortBy(false);
                    }

                    if (sorted && !desc) {
                      const data = {
                        ...filters,
                      };
                      setSort(null);
                      getPaymentsList({ navigate: 'first' }, data);
                      col.clearSortBy();
                    }
                  }}
                  modifyDataHandler={(callback) => console.log('a')}
                  columns={table.columns}
                  saveColumnOrder={table.saveColumnOrder}
                />
              ) : null}
            </TableLayout>
          </FormProvider>
          {PermissionShowListTransaction && paymentsList?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getPaymentsList({ perPage: value }, getSortFiltersForPageNavigation());
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getPaymentsList({ page }, getSortFiltersForPageNavigation());
                dispatch(toggleScrollToTop());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default PaymentsPage;

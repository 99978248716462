import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
import PaginateSelect from '../../../../components/ui/PaginateSelect';
import { fetchOperatorsSalesRules, fetchPartners } from '../../../../utils/managment/fetchData';
import styles from './sales-rules-popup.mudule.scss';
import { notify } from '../../../../utils/notify';
import PopupTemplate from '../../../../components/ui/PopupTemplate';
import { updateRule } from '../../../../api/rules';
import FormInput from '../../../../components/ui/FormInput';
import JokerSelect from '../../../../components/ui/JokerSelect';
import Button from '../../../../components/ui/Button';
import { IPriorityOpt } from '../../../../types/salesRules';
import { MuiChipsInput, MuiChipsInputChip } from 'mui-chips-input';
import { Icon } from 'components/ui/Icon';
import { MultiSelect } from 'components/ui/MultiSelect';

const cx = classNames.bind(styles);

const priorityOpt: IPriorityOpt[] = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const CreateSalesRulesPopup = ({
  triggerBtn,
  updateComponent,
  countryList,
  langList,
  ruleData,
  sourceArray,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState(sourceArray || []);
  const [sourceIsChange, setSourceIsChange] = useState(false);
  const [summ, setSumm] = useState(0);
  const ref = useRef(null);
  const contentToScroll = useRef(null);
  const dynamicHeightContent = useRef(null);
  const countrySelectRef = useRef(null);
  const langSelectRef = useRef(null);
  const partnerSelectRef = useRef(null);
  const getSourceArray = () => {
    const parsed = JSON.parse(ruleData.source);
    const keys = Object.keys(parsed);
    const array = [];

    for (const key of keys) {
      array.push(parsed[key]);
    }

    setSource(array);
  };
  const setDefaultValues = () => {
    let country_ids = [];
    let language_ids = [];
    let partner_ids = [];
    let operators = [];

    if (!ruleData) {
      return;
    }

    ruleData.countries?.forEach((item) => {
      const target = countryList.filter((target) => target.value === item.id);
      if (target.length) {
        country_ids = country_ids.concat(target);
      }
    });

    ruleData.languages?.forEach((item) => {
      const target = langList.filter((target) => target.value === item.id);
      if (target.length) {
        language_ids = language_ids.concat(target);
      }
    });

    ruleData.partners?.forEach((item) => {
      let data = {
        value: item.id,
        label: `${item.first_name} ${item && item.last_name !== null ? item.last_name : ''}`,
      };
      partner_ids.push(data);
    });

    ruleData.operators?.forEach((item) => {
      let data = {
        admin_user_id: {
          value: item.id,
          label: `${item.first_name} ${item && item.last_name !== null ? item.last_name : ''}`,
        },
        ration: item.ration,
      };
      operators.push(data);
    });
    return {
      name: ruleData.name,
      priority: { value: ruleData.priority, label: ruleData.priority },
      country_ids,
      language_ids,
      partner_ids,
      operators:
        operators.length > 9
          ? operators
          : operators.concat([
              {
                ration: null,
                admin_user_id: null,
              },
            ]),
    };
  };

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'operators',
  });

  const operatorArr = watch('operators').map((el) => +el.ration);

  const closeModal = (close) => {
    sourceArray = source;
    setSource([]);
    // setSource(sourceArray || []);
    reset(setDefaultValues(), { keepDefaultValues: true });
    close?.();
  };
  useEffect(() => {
    const defaultValue = sourceArray ? sourceArray.join(', ') : '';
    const target = source ? source.join(', ') : '';

    if (defaultValue !== target) {
      setSourceIsChange(true);
    } else {
      setSourceIsChange(false);
    }
  }, [source]);
  useEffect(() => {
    getSourceArray();
  }, [ruleData]);

  useEffect(() => {
    reset(setDefaultValues(), { keepDefaultValues: true });
  }, [countryList, langList, ruleData]);

  const onSubmit = (close, data) => {
    if (summ > 10) {
      notify({
        type: 'error',
        message: 'The ratio must not exceed 10.',
        timeOut: 3000,
      });
      return;
    }

    const operators = [];

    data.operators?.forEach((item, index) => {
      if (!item['admin_user_id']?.value) {
        return;
      }

      operators.push({
        ...item,
        admin_user_id: item['admin_user_id'].value,
      });
    });

    const sourceJson = {};

    source.forEach((item, index) => {
      sourceJson[index] = item;
    });

    const sendData = {
      name: data.name,
      priority: getValue(data['priority']),
      country_ids: getValue(data['country_ids']),
      language_ids: getValue(data['language_ids']),
      partner_ids: getValue(data['partner_ids']),
      source: JSON.stringify(sourceJson),
      operators,
    };
    editRuleHandler(sendData, close);
  };

  const editRuleHandler = (sendData, close) => {
    setIsLoading(true);

    updateRule(ruleData.id, sendData)
      .then((res) => {
        if (res.data.data) {
          notify({
            type: 'success',
            message: 'Rule edited successfully',
            timeOut: 3000,
          });
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getLastIndex = () => {
    return fields.length - 1;
  };

  const checkIsLastSelectInArrayIsEmpty = (currentIndex) => {
    return (
      !fields[getLastIndex()].admin_user_id &&
      !fields[getLastIndex()].ration &&
      getLastIndex() === currentIndex
    );
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data)) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };
  const onOpen = () => {
    getSourceArray();
  };

  const matchSumm = () => {
    let initialValue = 0;

    const sum = operatorArr.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue,
    );
    setSumm(sum);
  };

  useEffect(() => {
    matchSumm();
  }, [operatorArr]);

  const checkOperators = () => {
    const emptyOperatorsSum = Object.values(fields).filter((item) => item.admin_user_id === null);
    return emptyOperatorsSum.length > 1;
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onClose={closeModal}
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Edit rule'}
            style={{
              wrapper: {
                maxWidth: '600px',
              },
            }}
            rightContent={
              <div className={cx('content')}>
                <div
                  className={cx('content-form', 'popupForm', 'sales-rules')}
                  ref={contentToScroll}
                >
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container ref={dynamicHeightContent} className="d-flex flex-column gap-3">
                      <Row>
                        <Col md={12}>
                          <FormInput
                            label="Rule name"
                            id="rule_name"
                            control={control}
                            name="name"
                            placeholder="Rule name"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <JokerSelect
                            label="Priority"
                            control={control}
                            id="priority"
                            placeholder={'--Select--'}
                            name="priority"
                            rightaligned={true}
                            options={priorityOpt}
                          />
                        </Col>
                      </Row>
                      <Row ref={countrySelectRef}>
                        <Col md={12}>
                          <MultiSelect
                            label="Country"
                            control={control}
                            id="country"
                            name="country_ids"
                            onMenuOpen={() => {
                              setTimeout(
                                () =>
                                  contentToScroll.current.scrollTo({
                                    top: countrySelectRef.current.offsetTop - 25,
                                    left: 0,
                                    behavior: 'smooth',
                                  }),
                                10,
                              );
                            }}
                            placeholder={'--Select one or multiple options--'}
                            options={countryList}
                          />
                        </Col>
                      </Row>
                      <Row ref={langSelectRef}>
                        <Col md={12}>
                          <MultiSelect
                            label="Language"
                            control={control}
                            id="language"
                            onMenuOpen={() => {
                              setTimeout(
                                () =>
                                  contentToScroll.current.scrollTo({
                                    top: langSelectRef.current.offsetTop - 25,
                                    left: 0,
                                    behavior: 'smooth',
                                  }),
                                10,
                              );
                            }}
                            name="language_ids"
                            placeholder={'--Select one or multiple options--'}
                            options={langList}
                          />
                        </Col>
                      </Row>
                      <Row ref={partnerSelectRef}>
                        <Col md={12}>
                          <PaginateSelect
                            placeholder={'--Select one or multiple options--'}
                            isMulti={true}
                            label="Affiliates"
                            control={control}
                            id="partner"
                            name="partner_ids"
                            onChange={(page, search) => fetchPartners(page, search)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className={cx('chip-col')}>
                          <div className={cx('label-wrap')}>
                            <label className={cx('label')}>Source</label>
                          </div>

                          <MuiChipsInput
                            value={source}
                            className={cx('chip-col__chips')}
                            fullWidth
                            hideClearAll
                            size="small"
                            placeholder={'Source'}
                            onChange={(chips: MuiChipsInputChip[]): void => {
                              setSource(chips);
                            }}
                          />
                        </Col>
                      </Row>
                      {fields.map((item, index) => (
                        <Row key={item.id} className="row-gap-3">
                          <Col md={5}>
                            <PaginateSelect
                              isMulti={false}
                              label={`Operators`}
                              control={control}
                              id="operator"
                              name={`operators.${index}.admin_user_id`}
                              selectedArr={getValues('operators')}
                              onSelectChange={() => {
                                if (checkIsLastSelectInArrayIsEmpty(index) && fields.length < 10) {
                                  append({
                                    ration: null,
                                    admin_user_id: null,
                                  });
                                }
                              }}
                              onChange={(page, search) => {
                                return fetchOperatorsSalesRules(page, search);
                              }}
                              onMenuOpen={() => {
                                setTimeout(
                                  () =>
                                    contentToScroll.current.scrollTo({
                                      top: dynamicHeightContent.current.clientHeight,
                                      left: 0,
                                      behavior: 'smooth',
                                    }),
                                  100,
                                );
                              }}
                            />
                          </Col>
                          <Col md={3}>
                            <FormInput
                              label="Ratio"
                              id={`operators.${index}.ration`}
                              control={control}
                              type={'number'}
                              step={1}
                              min={1}
                              max={10}
                              name={`operators.${index}.ration`}
                              disabled={!getValues(`operators.${index}.admin_user_id`)}
                            />
                          </Col>
                          <Col md={3}>
                            <FormInput
                              label="Persent"
                              id={`operators.${index}.persent`}
                              control={control}
                              type="text"
                              step={1}
                              min={0}
                              name={`operators.${index}.persent`}
                              value={
                                operatorArr[index]
                                  ? ((operatorArr[index] / summ) * 100).toFixed(2)
                                  : '0.00'
                              }
                              placeholder="--"
                              disabled={true}
                            />
                          </Col>
                          <Col md={1}>
                            {getLastIndex() !== index || fields.length === 10 ? (
                              <div
                                className={cx('delete-col')}
                                onClick={() => {
                                  remove(index);
                                  if (fields.length === 10 && checkOperators()) {
                                    return;
                                  }
                                  if (fields.length === 10) {
                                    append({
                                      ration: null,
                                      admin_user_id: null,
                                    });
                                  }
                                }}
                              >
                                <Icon name="trash" size={20} />
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      ))}
                    </Container>
                    <div className={cx('content-controls')}>
                      <Container>
                        <Row>
                          <Col md={12}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <Button
                                isLoading={isLoading}
                                disabled={!isDirty && !sourceIsChange}
                                buttonText={'Apply change'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateSalesRulesPopup;

import { documentTypes, findDocumentMethod } from 'api/documents';
import KycDocumentsTable from 'components/ui/Table/KycDocumentsTable';
import TablePagination from 'components/ui/TablePagination';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import { usePermission } from '../../utils/usePermission.tsx';
import KycDocumentsFilter from './KycDocumentsFilter/KycDocumentsFilter';
import styles from './page-style.module.scss';
import { getTablePageParams } from '../../utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { KYCDocumentsTypesData } from 'models/KYCDocuments/KYCDocsPage/Types.js';
import { KYCDocumentsFilter } from 'models/KYCDocuments/KYCDocsPage/Filter.js';
import { KYCDocument } from 'models/KYCDocuments/Document.js';
import { ListValue } from 'models/ListValue.js';
import { toggleScrollToTop } from '../../store/slice/tableSlice.ts';
import { useAppDispatch } from '../../store';
import { useCheckAutoRefresh } from '../../utils/useCheckAutoRefresh.tsx';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { useKycDocumentsPage } from './useKycDocumentsPage.ts';
import { FormProvider } from 'react-hook-form';
import { TableLayout } from 'components/ui/Table/TableLayout/TableLayout.tsx';
import { KucDocumentsFields } from './KycDocumentsFields/KycDocumentsFields.tsx';

const title = 'KYC documents';

const KycDocumentsPage = () => {
  const { permissionGiven: PermissionList } = usePermission('admin.document.index');

  const dispatch = useAppDispatch();
  const [documentsList, setDocumentsList] = useState<KYCDocument[]>([]);

  const [isListLoading, setIsListLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<KYCDocumentsFilter>(null);

  const [typesData, setTypesData] = useState<KYCDocumentsTypesData[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<ListValue[]>();
  const [typesOptions, setTypesOptions] = useState<ListValue[]>();
  const [selectedCategory, setSelectedCategory] = useState<(string | number)[]>([]);

  const [selectedType, setSelectedType] = useState(null);

  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const { id } = useParams();
  const checkAutoRefresh = useCheckAutoRefresh('Page KYC documents');

  const componentMounted = useRef(false);

  usePageTitle(title);

  const fetchDocumentsList = (options?, data?) => {
    setIsListLoading(true);
    findDocumentMethod(getTablePageParams(options), data)
      .then((res) => {
        if (res.status === 200) {
          setDocumentsList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => setIsListLoading(false));
  };

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      fetchDocumentsList();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionList]);

  const getDocumentsList = (options, data?) => {
    setIsListLoading(true);

    findDocumentMethod(getTablePageParams(options, perPageCount, tableLinks), data || filters)
      .then((res) => {
        setDocumentsList(res.data.data);
        setTableMeta(res.data.meta);
        setTableLinks(res.data.links);
        setPerPageCount(res.data.meta.per_page);
      })
      .catch(console.log)
      .finally(() => setIsListLoading(false));
  };

  const changeTypesDefaultValue = (selectedCategory) => {
    let result = [];
    let docTypeOpt = [];

    typesData?.filter(function (arr_item) {
      return (
        selectedCategory.filter(function (idArr_item) {
          if (idArr_item == arr_item.key) {
            return (result = [...result, ...arr_item.activeStatuses]);
          }
        }).length !== 0
      );
    });
    if (selectedCategory.length) {
      setTypesOptions(
        result.map((el) => ({
          value: el.key,
          label: el.translate,
        })),
      );
    } else {
      typesData?.forEach((item) => {
        const arr = item.activeStatuses.map((item) => ({
          value: item.key,
          label: item.translate,
        }));

        if (arr.length) {
          docTypeOpt = docTypeOpt.concat(arr);
          setTypesOptions(docTypeOpt);
        }
      });
    }

    setSelectedType(null);
  };

  useEffect(() => {
    documentTypes()
      .then((res) => {
        setTypesData(res.data.data);
        const categoryOptions = res.data.data.map((item) => ({
          value: item.key,
          label: item.translate,
        }));

        let docTypeOpt = [];

        res.data.data.forEach((item) => {
          const arr = item.activeStatuses.map((item) => ({
            value: item.key,
            label: item.translate,
          }));

          if (arr.length) {
            docTypeOpt = docTypeOpt.concat(arr);
          }
        });

        setTypesOptions(docTypeOpt);

        setCategoryOptions(categoryOptions);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    selectedCategory && changeTypesDefaultValue(selectedCategory);
  }, [selectedCategory]);

  const { form, table } = useKycDocumentsPage();

  return (
    <>
      <DashboardLayout>
        <Page isStickyTitle title={`${tableMeta?.total || 0} KYC Documents`}>
          <>
            <FormProvider {...form}>
              <KucDocumentsFields
                categoryOptions={categoryOptions}
                typesOptions={typesOptions}
                changeDocumentCategory={(val) => {
                  setSelectedCategory(val.map((el) => el.value));
                }}
              />
              <TableLayout
                header={
                  <KycDocumentsFilter
                    onSubmit={(data) => {
                      setFilters(data);
                      getDocumentsList({ navigate: 'first', perPage: data?.filter?.limit }, data);
                    }}
                    isLoading={isListLoading}
                    setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                  />
                }
              >
                {PermissionList ? (
                  <KycDocumentsTable
                    data={documentsList}
                    perPage={perPageCount}
                    showLoader={isListLoading}
                    onRowSelect={setSelectedRows}
                    userId={id}
                    columns={table.columns}
                    saveColumnOrder={table.saveColumnOrder}
                  />
                ) : null}
              </TableLayout>
            </FormProvider>
            {documentsList?.length ? (
              <TablePagination
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getDocumentsList({ perPage: value });
                  dispatch(toggleScrollToTop());
                }}
                goToSelectedPage={(page) => {
                  getDocumentsList({ page });
                  dispatch(toggleScrollToTop());
                }}
              />
            ) : null}
          </>
        </Page>
      </DashboardLayout>
    </>
  );
};

export default KycDocumentsPage;

import { CryptoTransaction } from 'models/CustomerService/CryptoTransaction';
import moment from 'moment';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';

type Props = {
  data: any;
  transaction: CryptoTransaction;
};

export const getCryptoTransactionPopupInfoTableData = ({ data, transaction }: Props) => [
  {
    title: 'User:',
    value: `${data.user.name} ${data.user.lname}`,
  },
  {
    title: 'Account:',
    value: data.account,
    onClick: () => copyToBuffer(data.account, true),
  },
  {
    title: 'Transaction:',
    value: getShortUuid(data.id),
    onClick: () => copyToBuffer(data.id, true),
  },
  {
    title: 'Type:',
    value: data.type,
  },
  {
    title: 'Amount:',
    value: `${data.amount} ${data.amount_type} => ${data.crypto_amount} ${transaction.crypto_type}`,
  },
  {
    title: 'Amount USD:',
    value: `${data.amount_usd} USD`,
  },
  {
    title: 'Date:',
    value: moment.unix(data.created_at).format('DD.MM.YYYY HH:mm'),
  },
];

import { usePermission } from 'utils/usePermission';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { ListValue } from 'models/ListValue';
import CreateOrEditDesksPopup from './DesksPopups/CreateOrEditDesksPopup';
import Button from 'components/ui/Button';
import styles from './desks-page.module.scss';

type Props = {
  total?: number;
  deskTypes: ListValue[];
  updateComponentCallback: () => void;
};

export const DesksPageTitle = ({ total, deskTypes, updateComponentCallback }: Props) => {
  const { permissionGiven: PermissionCreate } = usePermission('admin.desk.create');

  return (
    <div className={styles.pageTitle}>
      <div>{total || 0} Desks</div>
      {PermissionCreate ? (
        <CreateOrEditDesksPopup
          deskTypeList={deskTypes}
          updateComponent={updateComponentCallback}
          actionType={SALES_RULES_ACTION_TYPE.CREATE}
          triggerBtn={
            <Button buttonText={'+ Add desk'} onClick={() => null} buttonType={'outline'} />
          }
        />
      ) : null}
    </div>
  );
};

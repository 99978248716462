import { ReactNode } from "react";
import { OnlineStatusesVerbose, UserTypes } from "../../../../models/Chat";
import { ChatDialogUser } from "../ChatDialogUser/ChatDialogUser";
import './ChatDialogHeader.scss';

type ChatDialogCardProps = {
	name: string;
	type: UserTypes;
	status?: OnlineStatusesVerbose;
	isGroup: boolean;
	children?: ReactNode;
};

export const ChatDialogHeader = ({name, type, status, isGroup, children = null}: ChatDialogCardProps) => {
	const isOnline = status === OnlineStatusesVerbose.ONLINE;
	return (
		<div className="chat-dialog-header">
			<ChatDialogUser name={name} type={type} isGroup={isGroup} isOnline={isOnline}/>
			<div className="chat-dialog-actions">
				{children}
			</div>
		</div>
	)
}
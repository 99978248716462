import { useRef, useState } from "react";
import { PaperPlaneRight } from "phosphor-react";
import { useAppDispatch } from "../../../../store";
import Button from "../../../../components/ui/Button";
import { ChatInput } from "./ChatInput";
import { useEvent } from "../../helpers/useEvent";
import { ChatUploadInput } from "./ChatUploadInput";
import { createThread } from "../../../../store/slice/chat/thunks";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import './ChatMessageForm.scss';

export const ChatRecipientForm = ({ id, isLoading }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState('');

    const fileMessageSubmitHandler = (file: File) => {
        dispatch(createThread({ recipientId: id, file })).then(response => {
            navigate(`/chat/${response.payload.id}`);
        });
    };

    const textMessageSubmitHandler = () => {
        if (!text.trim().length || isLoading) return;

        dispatch(createThread({ recipientId: id, message: text })).then(response => {
            setText('');
            navigate(`/chat/${response.payload.id}`);
        });
    };

    const keyDownHandler = event => {
        if (event.key === 'Enter' && document.activeElement === textAreaRef.current) {
            event.preventDefault();
            textMessageSubmitHandler();
        }
    };

    useEvent('keydown', keyDownHandler);

    return (
        <div className={cn('chat-message-form', { isRedirecting: isLoading})}>
            <ChatUploadInput onChangeHandler={fileMessageSubmitHandler}/>
            <ChatInput
                text={text}
                placeholder='Write a message'
                onChange={e => setText(e.target.value)}
                textAreaRef={textAreaRef}
                disabled={isLoading}
            />
            <Button
                onClick={textMessageSubmitHandler}
                className='message-sent-button'
                icon={<PaperPlaneRight size={16} color="#fafafa"/>}
            />
        </div>
    )
}

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { login, selectAuthIsAuthorized, selectAuthIsLoading } from 'store/slice/authSlice';
import { getUserInfoMethod } from 'store/slice/userInfoSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { AuthButton } from '../components/AuthButton/AuthButton';
import { AuthFormInput } from '../components/AuthFormInput/AuthFormInput';
import { AuthLayout } from '../components/AuthLayout/AuthLayout';
import styles from './signin.module.scss';

type SignInFormType = {
  email: string;
  password: string;
};

const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const intl = useIntl();
  const isLoading = useAppSelector(selectAuthIsLoading);
  const isAuthorized = useAppSelector(selectAuthIsAuthorized);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignInFormType>({
    reValidateMode: 'onChange',
  });

  const onSubmit = ({ email, password }) => {
    dispatch(login({ email, password })).then((res) => {
      if (res && res.meta.requestStatus === 'fulfilled') {
        navigate('/search-clients');
        dispatch(getUserInfoMethod());
      }
    });
  };

  const changingTabTitle = () => {
    const title = intl.formatMessage({
      id: 'auth.signIn.title',
      defaultMessage: 'Sign in',
    });
    document.title = title;
  };

  useEffect(() => {
    changingTabTitle();
    if (isAuthorized) {
      navigate('/search-clients');
    }
  }, []);

  return (
    <AuthLayout>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <AuthFormInput<SignInFormType>
          wrapperClassName={styles.email}
          id="email"
          name="email"
          type="email"
          label="Email"
          control={control}
          rules={{ required: 'Field is required' }}
          errorMessage={errors.email?.message}
        />
        <AuthFormInput<SignInFormType>
          wrapperClassName={styles.password}
          id="password"
          name="password"
          type="password"
          label="Password"
          control={control}
          rules={{ required: 'Field is required' }}
          errorMessage={errors.password?.message}
        />
        <AuthButton isLoading={isLoading} text="Sign In" />

        <Link className={styles.forgotLink} to={'/forgot-password'}>
          Forgot your password?
        </Link>
      </form>
    </AuthLayout>
  );
};

export default SignIn;

import { CryptoTransaction } from 'models/CustomerService/CryptoTransaction';
import { getCryptoTransactionPopupInfoTableData } from './utils';
import cn from 'classnames';

import styles from './crypto-transaction-popup-info.module.scss';

type Props = {
  data: any;
  transaction: CryptoTransaction;
};

export const CryptoTransactionPopupInfo = ({ data, transaction }: Props) => {
  const tableData = getCryptoTransactionPopupInfoTableData({ data, transaction });

  return (
    <div className={styles.container}>
      <div className={styles.title}>Internal transaction</div>
      <table>
        <tbody>
          {tableData.map(
            (item, index) =>
              !!item.value && (
                <tr key={index}>
                  <td className={styles.tableTitle}>{item.title}</td>
                  <td
                    className={cn(styles.tableValue, item?.onClick && styles.tableValue__clickable)}
                    onClick={item?.onClick}
                  >
                    {item.value}
                  </td>
                </tr>
              ),
          )}
        </tbody>
      </table>
    </div>
  );
};

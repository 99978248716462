import { Client } from 'models/Clients/Clients';
import ClientSalesUpdatePopup from 'pages/Clients/Client/ClientPopups/ClientSalesUpdatePopup';
import { usePermission } from 'utils/usePermission';
import { ClientAcquisitionStatusOld } from './components/oldStatus/ClientAcquisitionStatusOld';
import { ClientSales } from './components/sales/ClientSales';
import { ClientRetention } from './components/retention/ClientRetention';
import styles from './client-acquisition-status.module.scss';
type Props = {
  client: Client;
  updateCallback: () => void;
};

export const ClientAcquisitionStatus = ({ client, updateCallback }: Props) => {
  const { permissionGiven: PermissionAssociateClientToOperator } =
    usePermission('admin.client.team');

  return (
    <div className={styles.container}>
      {client.hasOwnProperty('sales') ? (
        <ClientSalesUpdatePopup
          actionType="sales"
          updateComponent={updateCallback}
          userId={String(client.id)}
          triggerBtn={<ClientSales client={client} onClick={() => null} />}
          disabled={!PermissionAssociateClientToOperator}
        />
      ) : null}
      {client.hasOwnProperty('retention') ? (
        <ClientSalesUpdatePopup
          actionType="retention"
          updateComponent={updateCallback}
          userId={String(client.id)}
          triggerBtn={<ClientRetention client={client} onClick={() => null} />}
          disabled={!PermissionAssociateClientToOperator}
        />
      ) : null}
      {client?.old_values?.retention ? (
        <ClientAcquisitionStatusOld
          oldValues={client?.old_values?.retention}
          status={client?.old_values}
          type={'retention'}
        />
      ) : null}
      {client?.old_values?.sales ? (
        <ClientAcquisitionStatusOld
          oldValues={client?.old_values?.sales}
          status={client?.old_values}
          type={'sales'}
        />
      ) : null}
    </div>
  );
};

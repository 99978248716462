import React from "react";
import './ColorSetCircles.scss';

export const ColorSetCircles = ({ colorSet }) => {
  return (
    <div className="color-set">
      {
        colorSet.map((color: string) => (
          <div key={color} className="color-set__circle" style={{ backgroundColor: color }}/>
        ))
      }
    </div>
  );
};
import { useState } from 'react';
import { Client } from 'models/Clients/Clients';
import { usePermission } from 'utils/usePermission';
import { notify } from 'utils/notify';
import AfilateEditPopup from 'pages/Clients/Client/AfilateEditPopup';
import styles from './client-affiliate.module.scss';

type Props = {
  client: Client;
};

export const ClientAffiliate = ({ client }: Props) => {
  const { permissionGiven: PermissionChangeAffiliationData } = usePermission(
    'admin.client.change-affiliation-data',
  );
  const [source, setSource] = useState(client.source);
  const [referral, setReferral] = useState(client.referral);

  const box = (
    <div className={styles.box}>
      {client?.affiliate?.first_name || client?.affiliate?.last_name ? (
        <div>{`${client?.affiliate?.first_name || ''} ${client?.affiliate?.last_name || ''}`}</div>
      ) : null}
      <div>Source: {source ? source : '---'}</div>
      <div>Referral: {referral ? referral : '---'}</div>
    </div>
  );

  return (
    <>
      {PermissionChangeAffiliationData ? (
        <AfilateEditPopup
          user_id={client.id}
          affilate={`${client?.affiliate?.first_name || ''} ${client?.affiliate?.last_name || ''}`}
          source={source || ''}
          setSource={setSource}
          referral={referral || ''}
          setReferral={setReferral}
          trigger={box}
        />
      ) : (
        <div
          onClick={() => {
            notify({
              type: 'warning',
              message: `You don't have permission to edit these settings`,
              timeOut: 3000,
            });
          }}
        >
          {box}
        </div>
      )}
    </>
  );
};

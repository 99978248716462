import { Spinner } from 'components/ui/Spinner';
import { LeadNote } from 'models/Leads/LeadNotes';
import { TableLinks, TableMeta } from 'models/Table';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';

import styles from './lead-pinned-notes.module.scss';

type Props = {
  id: number;
  notes: LeadNote[];
  links: TableLinks;
  meta: TableMeta;
  isLoading: boolean;
  request: (params: { id: number }, isNextCall?: boolean) => void;
};

export const LeadPinnedNotes = ({ id, notes, links, meta, isLoading, request }: Props) => {
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div id="scrollableDiv" className={styles.container}>
      {notes.length > 0 && links && meta ? (
        <InfiniteScroll
          dataLength={notes.length}
          next={() => request({ id }, true)}
          hasMore={!!links.next}
          loader={
            <div style={{ position: 'relative', height: '50px' }}>
              <Spinner />
            </div>
          }
          height={290}
          scrollableTarget="scrollableDiv"
        >
          {notes.map((note) => (
            <div key={note.id} className={styles.note}>
              <div className={styles.note__subject}>{note.subject}</div>
              <div className={styles.note__operator}>
                {`by ${note?.operator ? note.operator.first_name : ''} ${
                  note?.operator && note.operator.last_name ? note.operator.last_name : ''
                }`}
              </div>
              <div className={styles.note__date}>
                {moment.unix(note.create_at).format('DD.MM.YYYY HH:mm')} to{' '}
                <span className="copuToBuffer" onClick={() => copyToBuffer(note.to, true)}>
                  {getShortUuid(note.to)}
                </span>
              </div>
              <div className={styles.note__body}>{note.body}</div>
            </div>
          ))}
        </InfiniteScroll>
      ) : null}
    </div>
  );
};

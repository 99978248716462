import api from "../api";

export const getTickerMessages = () => api.get(`/api/v1/system-message`);

export const getTickerActive = (current_time: string) => api.get(`/api/v1/system-message/active?current_time=${current_time}`);

export const getTickerMessageColors = () => api.get(`/api/v1/system-message/colors`);

export const getTickerMessageSpeed = () => api.get(`/api/v1/system-message/speed`);

export const createTickerMessage = (data) => api.post(`/api/v1/system-message`, data);

export const updateTickerMessage = (data, id) => api.put(`/api/v1/system-message/${id}`, data);

export const updateTickerMessageStatus = (id, status) => api.put(`/api/v1/system-message/${id}/status`, { status });

export const deleteTickerMessage = (id) => api.delete(`/api/v1/system-message/${id}`);

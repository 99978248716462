import { disableClientDemoAccounts, enableClientDemoAccounts } from 'api/clients';
import { MultiSelect } from 'components/ui/MultiSelect';
import { Switch } from 'components/ui/Switch';
import { getEnableWebTrader } from 'constants/selectors';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'store';
import { usePermission } from 'utils/usePermission';

import styles from './trading-acc-fields.module.scss';

export const TradingAccFields = ({ userId, taStatusesOpt, initialAllowDemo, isListLoading }) => {
  const { permissionGiven: PermissionToggleDemoAccount } = usePermission(
    'admin.trading-account.edit-demo',
  );

  const [demoAllowed, setDemoAllowed] = useState(initialAllowDemo);
  const wtEnabled = useAppSelector(getEnableWebTrader);

  const { control } = useFormContext();

  return (
    <Row className={styles.row}>
      <Col xs={12} md={12} lg={6} xl={3} className="mb-3">
        <MultiSelect
          id="status"
          name="status"
          label="Status"
          control={control}
          options={taStatusesOpt}
        />
      </Col>
      {PermissionToggleDemoAccount && wtEnabled && (
        <Col xs={12} md={12} lg={6} xl={3} className="mb-3">
          <div className={styles.allowSwitch}>
            <Switch
              isDisabled={isListLoading}
              isChecked={Boolean(Number(demoAllowed))}
              onChange={(e) => {
                if (e.target.checked) {
                  enableClientDemoAccounts(userId);
                } else {
                  disableClientDemoAccounts(userId);
                }
                setDemoAllowed(e.target.checked);
              }}
            />
            <p className={styles.allowSwitch__text}>Allow this client to create demo accounts</p>
          </div>
        </Col>
      )}
    </Row>
  );
};

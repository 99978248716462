import PaginateSelect from 'components/ui/PaginateSelect/PaginateSelect';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { TeamsFilterType } from '../useTeamsPage';
import { fetchDesks, fetchOffices } from 'utils/managment/fetchData';
import { useState } from 'react';

export const TeamsFields = () => {
  const [office, setOffice] = useState(null);

  const { control, getValues, reset } = useFormContext<TeamsFilterType>();

  const setDefaultValuesDesk = () => {
    return {
      name: getValues('name'),
      office_ids: getValues('office_ids'),
      desk_ids: '',
    };
  };

  const updateDeskWithOffice = (office) => {
    setOffice(office);
    reset(setDefaultValuesDesk());
  };

  return (
    <Row>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <PaginateSelect
          isMulti={false}
          label="Office"
          control={control}
          id="office_ids"
          name="office_ids"
          onChange={(page, search) => fetchOffices(page, search)}
          onSelectChange={() => {
            updateDeskWithOffice(getValues('office_ids'));
          }}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <PaginateSelect
          isDisabled={!getValues('office_ids')}
          isMulti={false}
          label="Desk"
          control={control}
          id="desk_ids"
          name="desk_ids"
          onChange={(page, search, office) => fetchDesks(page, search, office?.value)}
          mainVal={office}
          key={JSON.stringify(office)}
        />
      </Col>
    </Row>
  );
};

import { RefObject, useState } from "react";
import { useEvent } from "../../../pages/Chat/helpers/useEvent";

export const useJokerDropdown = (buttonRef: RefObject<HTMLDivElement>) => {
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const handleSwitch = () => setDropdownOpened(o => !o);

    useEvent('click',(e: MouseEvent) => {
        if (dropdownOpened && buttonRef.current && !buttonRef.current.contains(e.target as Node)) {
            setDropdownOpened(false);
        }
    });

    return {
        switchDropdown: handleSwitch,
        dropdownOpened
    }
};

export const useJokerListDropdown = () => {
    const [dropdownId, setDropdownId] = useState(null);

    useEvent('click',() => {
        if (dropdownId) {
            setDropdownId(null);
        }
    });

    return { dropdownId, setDropdownId }
};
import API from '../api';

export const operatorsList = (params) => API.get(`/api/v1/admin-users?${params}`);
export const operatorsListWithSearch = (params, query = '') =>
  API.post(`/api/v1/search/admin-users?${params}`, query && { query: query });

export const operatorsListSalesRules = (params) =>
  API.get(`/api/v1/admin-users/sales-rules?${params}`);

export const operatorsListForBulkActions = (params, teams_ids) =>
  API.get(`/api/v1/admin-users/list/team-operators?${params}`, { params: { teams: teams_ids } });
export const updateOperatorProfileMethod = (id, data) => API.put(`/api/v1/admin-users/${id}`, data);
export const getBranchListMethod = (type, per_page) =>
  API.get(`/api/v1/branches/list/type?branch_type=${type}&per_page=100`);

export const getBranchListMethodWithParams = (type, page, params) => {
  if (params) {
    return API.get(`/api/v1/branches/list/type?branch_type=${type}&per_page=10&page=${page}&admin_user_id=${params}`);
  }
  return API.get(`/api/v1/branches/list/type?branch_type=${type}&per_page=10&page=${page}`);
}


export const operatorsListPaginateForBulkActions = (params, teams_ids) =>
  API.get(`/api/v1/admin-users/list/team-operators?${params}`, { params: { teams: teams_ids } });
  
export const operatorsListPaginateForBulkActionsByDesk = (params, desks) =>
  API.get(`/api/v1/admin-users/list/team-operators?${params}`, { params: { desk_ids: desks } });

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from './slice/authSlice';
import langListReducer from './slice/langSlice';
import { notifyReducer } from './slice/notifySlice';
import { searchCategoriesQuery } from "./slice/searchCategoriesQuery";
import { tableSliceReducer } from "./slice/tableSlice";
import userInfoReducer from './slice/userInfoSlice';
import chatReducer from "./slice/chat/chatSlice";
import { modalReducer } from "./slice/modalSlice";
import ChatListenerMiddleware from "./slice/chat/effects";
import { tickerReducer } from "./slice/tickerSlice";
import visualSliceReducer from './slice/visualSlice.ts';
import { reducer as remindersReducers, RemindersListenerMiddleware } from "./slice/reminders";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    langList: langListReducer,
    userInfo: userInfoReducer,
    notify: notifyReducer,
    table: tableSliceReducer,
    chat: chatReducer,
    modal: modalReducer,
    visual: visualSliceReducer,
    ticker: tickerReducer,
    reminders: remindersReducers,
    [searchCategoriesQuery.reducerPath]: searchCategoriesQuery.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ChatListenerMiddleware.middleware, searchCategoriesQuery.middleware, RemindersListenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

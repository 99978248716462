import { CSSProperties } from "react";

export const ChatInfiniteScrollStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    marginTop: 'auto',
    paddingBottom: '10px'
};

export const ChatScrollToBottomOffset = 100;

export const ChatFoundMessageAnimationDuration = 1500;

import { format } from "date-fns";
import { SystemMessageFormSubmitData, SystemMessageFormValues } from "../../../../models/Ticker";

const formatPattern = 'yyyy-MM-dd HH:mm:ss xx';

export const systemMessageFormValuesAdapter = (data): SystemMessageFormValues => ({
    message: data.message,
    color: { value:data.color, label: data.color },
    scrolling_speed: { value: data.scrolling_speed, label: data.scrolling_speed },
    date: {
        from: new Date(data.start_date),
        to: new Date(data.end_date),
    }
});

export const systemMessageFormSubmitAdapter = (data): SystemMessageFormSubmitData => ({
    message: data.message,
    color: data.color.value,
    scrolling_speed: data.scrolling_speed.value,
    start_date: format(new Date(data.date.from), formatPattern),
    end_date: format(new Date(data.date.to), formatPattern),
});
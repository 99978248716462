import { Client } from 'models/Clients/Clients';
import moment from 'moment';

import styles from './client-pinned-notes.module.scss';

type Props = {
  client: Client;
};

export const ClientPinnedNotes = ({ client }: Props) => {
  return (
    <div className={styles.container}>
      {client && client.notes_pinned
        ? client.notes_pinned
            .sort((a, b) => (a.create_at < b.create_at ? 1 : -1))
            .map((note) => {
              return (
                <div key={note.id} className={styles.note}>
                  <div className={styles.note__subject}>{note?.subject}</div>
                  <div className={styles.note__operator}>{`by ${
                    note?.operator?.first_name ? note?.operator?.first_name : ''
                  } ${note?.operator?.last_name ? note?.operator?.last_name : ''}`}</div>
                  <div className={styles.note__date}>
                    {moment(note?.create_at * 1000).format('DD.MM.YYYY')}{' '}
                    {moment(note?.create_at * 1000).format('HH:mm:ss')}
                  </div>
                  <div className={styles.note__body}>{note?.body}</div>
                </div>
              );
            })
        : null}
    </div>
  );
};

import cn from "classnames";
import { ReactNode, useRef } from "react";
import { useJokerDropdown } from "./useJokerDropdown";
import { createPortal } from "react-dom";
import './JokerDropdown.scss';

type DropdownPosition = 'top-left' | 'top' | 'top-right' | 'bottom-right' | 'bottom' | 'bottom-left' | 'left' | 'right';

type JokerDropdownProps = {
    button: ReactNode;
    position?: DropdownPosition;
    className?: string;
    children: ReactNode;
};

type JokerListDropdownProps = JokerDropdownProps & {
    dropdownOpened: boolean;
};

type JokerPortalDropdownProps = Omit<JokerDropdownProps, 'button'> & {
    positionX: number;
    positionY: number;
    onClickOutside?: () => void;
}

export const JokerDropdown = ({ button, position, className, children }: JokerDropdownProps) => {
    const buttonRef = useRef(null);
    const { dropdownOpened, switchDropdown } = useJokerDropdown(buttonRef);

    return (
        <div className={cn('joker-dropdown-wrap', position, className, { isOpened: dropdownOpened })}>
            <div className="joker-dropdown-button" ref={buttonRef} onClick={switchDropdown}>{button}</div>
            {dropdownOpened ? <div className='joker-dropdown'>{children}</div> : null}
        </div>
    )
};

export const JokerListDropdown = ({ dropdownOpened, button, position, className, children }: JokerListDropdownProps) => {
    return (
        <div className={cn('joker-dropdown-wrap', position, className, { isOpened: dropdownOpened })}>
            <div className="joker-dropdown-button">{button}</div>
            {dropdownOpened ? <div className='joker-dropdown'>{children}</div> : null}
        </div>
    )
};


export const JokerPortalDropdown = ({ className, positionX, positionY, onClickOutside, children }: JokerPortalDropdownProps) => {
    const handleClickOutside = () => {
        onClickOutside && onClickOutside();
    };

    return createPortal(
        <div className='joker-dropdown-portal' onClick={handleClickOutside}>
            <div className={cn('joker-dropdown', className)} style={{top: positionY, left: positionX}}>{children}</div>
        </div>, document.body
    )
};

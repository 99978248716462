import { ChatMessageType, MessageTypesVerbose } from "../../../../models/Chat";
import { renderDangerHtml } from "../../helpers/renderDangerHtml";
import { ChatBadge } from "../ChatBadge/ChatBadge";
import { chatUrl } from "../../../../api/chat";
import { ThreadMessageDocument } from "./ThreadMessageDocument";
// import { ThreadMessageStatus } from "./ThreadMessageStatus";

type ChatCardMessageBodyProps = {
	isOwnMessage: boolean;
	isGroupChat: boolean;
	unreadCount: number,
	message: ChatMessageType
};

export const ThreadMessageBody = ({isOwnMessage, isGroupChat, unreadCount, message}: ChatCardMessageBodyProps) => {
	const {type_verbose, body, owner} = message;

	const showSenderName = !isOwnMessage && isGroupChat;
	const showBadge = unreadCount && !isOwnMessage;

	const isImageMessage = type_verbose === MessageTypesVerbose.IMAGE_MESSAGE;
	const isDocumentMessage = type_verbose === MessageTypesVerbose.DOCUMENT_MESSAGE;

	return (
		<div className="message-body-preview message-wrap">
			{showSenderName ? <span className="message-sender-name">{owner.name}:</span> : null}
			{isImageMessage ? <div className='message-img'>
				<img src={chatUrl + message.image.sm} alt=''/>
			</div> : null}
			{isDocumentMessage ? <ThreadMessageDocument filename={body} /> : renderDangerHtml(body, 'message-text')}
			<div className="message-status-meta">
				{showBadge ? <ChatBadge count={unreadCount}/> : null}
				{/*TODO: CHAT - use real message status*/}
				{/*{isOwnMessage ? <ThreadMessageStatus read={true}/> : null}*/}
			</div>
		</div>
	)
}
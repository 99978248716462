import { Icon } from 'components/ui/Icon';
import { useAppSelector } from 'store';
import { getUserInfoFullName } from 'constants/selectors';

import styles from './user-info.module.scss';

type Props = {
  role: string;
};

export const UserInfo = ({ role }: Props) => {
  const name = useAppSelector(getUserInfoFullName);

  return (
    <div className={styles.info}>
      <Icon name="userLogo" size={38} />
      <div className={styles.block}>
        <p className={styles.name}>{name}</p>
        <p className={styles.role}>{role}</p>
      </div>
    </div>
  );
};

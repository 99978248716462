import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from '../clients-table.module.scss';

const cx = classNames.bind(styles);

interface IBalanceProps {
  balance: number;
}
const Balance: FC<IBalanceProps> = ({ balance }) => {
  return (
    <div className={cx('client__balance')}>
      <span>{balance.toLocaleString('en-US')}&nbsp;USD</span>
    </div>
  );
};

export default memo(Balance);

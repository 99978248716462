import React from 'react';
import classNames from 'classnames/bind';
import styles from './table.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import ReactTooltip from 'react-tooltip';

import moment from 'moment';
import { DownloadSimple } from 'phosphor-react';
import EditFilesPopup from 'pages/Clients/Client/ClientTabs/Files/EditFilesPopup';
import { fetchDownloadFileRequest } from 'api/documents';
import { usePermission } from 'utils/usePermission';
import { Icon } from 'components/ui/Icon';

const cx = classNames.bind(styles);

const FilesTable = ({
  data,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  colSortCheck,
  userId,
  deleteAction,
  changeStatusAction,
  updateComponent,
  typesOpt,
  columns,
  saveColumnOrder,
}) => {
  const { permissionGiven: PermissionDownloadDocument } = usePermission('admin.document.download');
  const { permissionGiven: PermissionDeleteDocument } = usePermission('admin.document.delete');
  const { permissionGiven: PermissionUpdateDocument } = usePermission('admin.document.update');

  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, original_name, time, created_at, status, kyc_type, kyc_category } = item;

    return {
      name: (
        <div className={cx('files-table_th')}>
          <span>{original_name}</span>
        </div>
      ),
      category: <span>{kyc_category?.translate}</span>,
      type: <span>{kyc_type?.translate}</span>,
      status: (
        <div className={cx('files-table_th')}>
          <span className={cx(status)}>{status}</span>
        </div>
      ),
      created_at: (
        <div className={cx('files-table_th')}>
          <div className={cx('client__registration')}>
            <span>{moment(created_at * 1000).format('DD.MM.YYYY HH:mm')}</span>
          </div>
        </div>
      ),
      action: (
        <div className={cx('action', 'files-table_th')}>
          {PermissionUpdateDocument ? (
            <EditFilesPopup
              editData={item}
              updateComponent={() => updateComponent()}
              // userId={id}
              triggerBtn={<Icon name="notePencil" size={20} color="var(--main-color)" />}
              // onCloseModal={() => console.log('a')}
              typesOpt={typesOpt}
            />
          ) : null}
          {PermissionDownloadDocument ? (
            <Icon
              name="download"
              size={20}
              color="var(--main-color)"
              onClick={() => fetchDownloadFileRequest(item.id, item.user_id, original_name)}
            />
          ) : null}
          {PermissionDeleteDocument ? (
            <Icon
              name="trash"
              size={20}
              color="var(--red)"
              onClick={() => deleteAction(item.id, item.user_id)}
            />
          ) : null}
          <ReactTooltip id="disabled" />
          <ReactTooltip id="success" />
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('files-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row } = cellInfo;
        const odd = row.index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default FilesTable;

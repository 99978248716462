import { APIManagementDictionaryResponse, APIManagementGetDesksResponse } from 'models/API/Management/Desks';
import API from '../../api';

export const getDeskTypeMethod = () => API.get<APIManagementDictionaryResponse>(`/api/v1/dictionary/system`);
export const findDesksMethod = (params, data) => API.post<APIManagementGetDesksResponse>(`/api/v1/search/desks?${params}`, data);

// response unused
export const addDesksMethod = (data) => API.post(`/api/v1/desks`, data);
// response unused
export const updateDesksMethod = (id, data) => API.put(`/api/v1/desks/${id}`, data);
// response unused
export const deleteDesksMethod = (id) => API.delete(`/api/v1/desks/${id}`);
// method unused
export const getTotalDeskListMethod = (office_id) => {
  return office_id
    ? API.get(`/api/v1/desks?per_page=10&page=1&office_id=${office_id}`)
    : API.get(`/api/v1/desks?per_page=10&page=1`);
};
// method unused
export const getDeskListMethod = (params) => API.get(`/api/v1/desks?${params}`);

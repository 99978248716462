import { ManagementOperator } from 'models/Management/Operators/Operator';
import moment from 'moment';
import styles from './operator-personal-info.module.scss';

type Props = {
  operator: ManagementOperator;
};

export const OperatorPersonalInfo = ({ operator }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <span>First name: </span>
        {operator && operator?.first_name}
      </div>
      <div className={styles.row}>
        <span>Last name: </span>
        {operator && operator?.last_name}
      </div>
      <div className={styles.row}>
        <span>Email: </span>
        {operator && operator?.email ? operator.email : '---'}
      </div>
      <div className={styles.row}>
        <span>Phone number: </span>
        {operator && operator?.phone ? operator.phone : '---'}
      </div>
      {operator && operator?.email_verified_at ? (
        <div className={styles.row}>
          <span>Registration date: </span>
          <span>{moment.unix(operator.created_at).format('DD.MM.YYYY')}</span>{' '}
          <span>{moment.unix(operator.created_at).format('HH:mm')}</span>
        </div>
      ) : null}
      <div className={styles.row}>
        <span>SIP account: </span>
        {operator && operator?.sip_account ? operator.sip_account : '---'}
      </div>
    </div>
  );
};

import React from "react";
import { ChatMessageType, UserTypeLabels } from "../../../../models/Chat";
import { ChatAvatar } from "../ChatAvatar/ChatAvatar";
import { unEscapeHtml } from "../../helpers/unscapeHtml";
import { ChatDate } from "../ChatDate/ChatDate";

type SearchResultMessageProps = {
	message: ChatMessageType,
	isOnline: boolean,
	onClick: (id: string) => void
}

export const SearchResultMessage = ({message, isOnline, onClick}: SearchResultMessageProps) => {
	const { name, base } = message.owner;

	return (
		<div className='dialog-search-message-result dialog-search-result' onClick={() => onClick(message.id)}>
			<ChatAvatar name={unEscapeHtml(name)} status={isOnline} className={UserTypeLabels[base.type]}/>
			<div className="search-result-content">
				<div className="search-result-message-heading">
					<div className="message-owner-name">{unEscapeHtml(name)}</div>
					<ChatDate date={message.updated_at} dateFormat='time' className='time-format'/>
					<ChatDate date={message.updated_at} dateFormat='date' className='date-format'/>
				</div>
				<div className="message-text">
					{unEscapeHtml(message.body)}
				</div>
			</div>
		</div>
	)
}
import { createPortal } from 'react-dom';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';
import styles from '../client-filter.module.scss';
import JokerSelect from 'components/ui/JokerSelect';
import PaginateSelect from 'components/ui/PaginateSelect';
import SaveFilterPopup from '../../SearchClientsPopups/SaveFilterPopup';
import Button from 'components/ui/Button';
import DeleteFilterPopup from '../../SearchClientsPopups/DeleteFilterPopup/DeleteFilterPopup';
import FormInput from 'components/ui/FormInput';
import { Control, UseFormGetValues, UseFormHandleSubmit, useWatch } from 'react-hook-form';
import { CustomFilter } from 'models/Clients/ClientFilter';
import { ListValue } from 'models/ListValue';
import { clearEmptyFilter } from 'utils/clearEmptyFilter.ts';
import {
  defaultDateTimeFormat,
  ModernDateRangeInput,
  ModernDateTimeInputHeader,
} from 'components/ui/ModernDateRangeInput';
import { MultiSelect } from 'components/ui/MultiSelect';
import PaginateAllSelect from 'components/ui/PaginateAllSelect';

const cx = classNames.bind(styles);

type Props = {
  selectedDesks: ListValue[];
  setSelectedDesks: (value: ListValue[]) => void;
  selectedTeams: ListValue[];
  setSelectedTeams: (value: ListValue[]) => void;
  desks: ListValue[];
  teams: ListValue[];
  operators: ListValue[];

  control: Control<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  getValues: UseFormGetValues<any>;

  submit: (data?: any) => void;
  setSelectedDynamicFilter: (val: any[]) => void;
  fetchFilterSet: () => void;
  fetchClientDynamicStatuses: () => void;
  resetForm: () => void;
  clearValues: () => void;
  filterSet: any[];
  customFilterList: CustomFilter[];

  fields: any[];

  isMobile: () => boolean;
  isNotEmptyFilterOption: (data: any) => boolean;
  isEmpty: (data: any) => boolean;

  oldFilters: boolean;

  assignStatusList: ListValue[];
  reloadFilters: boolean;
};

const SearchSection = (props: Props) => {
  const {
    selectedDesks,
    setSelectedDesks,
    selectedTeams,
    setSelectedTeams,
    desks,
    teams,
    operators,
    fields,
    control,
    handleSubmit,
    submit,
    getValues,
    isMobile,
    isNotEmptyFilterOption,
    isEmpty,
    oldFilters,
    filterSet,
    customFilterList,
    assignStatusList,
    fetchFilterSet,
    fetchClientDynamicStatuses,
    resetForm,
    clearValues,
    setSelectedDynamicFilter,
    reloadFilters,
  } = props;

  const sourcesWatch = useWatch({ name: 'sources' });
  const referralsWatch = useWatch({ name: 'referrals' });

  const hasAllSources = (sourcesWatch || [])[0]?.value === 'all';
  const hasAllReferrals = (referralsWatch || [])[0]?.value === 'all';

  return (
    <>
      <Row className="mt-4">
        {fields?.map((item, index) => {
          if (item.type === 'input') {
            return (
              <Col xl={4} lg={6} md={6} key={index} className="mb-3">
                <FormInput
                  label="Search by"
                  id="query"
                  name="query"
                  control={control}
                  placeholder="Name, E-mail, Phone, ID, Trading account"
                  onChange={(v: string) => !v.length && submit(getValues())}
                />
              </Col>
            );
          }
          if (item.type === 'range') {
            return (
              <Col xl={2} lg={4} md={4} key={index} className="mb-3">
                <Row>
                  <Col md={6}>
                    <FormInput
                      labelStyle={{ width: isMobile() ? '200%' : '300%' }}
                      name={`${item.value}.from`}
                      type="number"
                      placeholder="0.00"
                      min={0}
                      label={item.label}
                      control={control}
                    />

                    <span className={cx('range-divider')}>-</span>
                  </Col>
                  <Col md={6}>
                    <FormInput
                      name={`${item.value}.to`}
                      min={0}
                      type="number"
                      label="&nbsp;"
                      placeholder="0.00"
                      control={control}
                    />
                  </Col>
                </Row>
              </Col>
            );
          }
          if (item.type === 'date') {
            return (
              <Col xl={6} lg={6} md={6} key={index} className="mb-3">
                {createPortal(
                  <div
                    id={`custom-${item.value}`}
                    className="date-input"
                    style={{ position: 'relative', zIndex: 99 }}
                  />,
                  document.body,
                )}
                <ModernDateRangeInput
                  name={item.value}
                  control={control}
                  label={item.label}
                  placeholder="From - To"
                  portalId={`custom-${item.value}`}
                  dateFormat={defaultDateTimeFormat}
                  allowToChangeViaInput={true}
                  renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                    <ModernDateTimeInputHeader
                      {...props}
                      from={props.from}
                      to={props.to}
                      onTimeChangeHandler={onTimeChangeHandler}
                      showRange
                    />
                  )}
                />
              </Col>
            );
          }
          if (item.type === 'select_one') {
            return (
              <Col xl={2} lg={6} md={6} key={index} className="mb-3">
                <JokerSelect
                  label={item.label}
                  control={control}
                  id={item.value}
                  name={item.value}
                  options={item.value === 'assign_status' ? assignStatusList : item.options}
                />
              </Col>
            );
          }
          if (item.type === 'paginate_select') {
            const isPaginateAllSelect = item.value === 'sources' || item.value === 'referrals';

            if (isPaginateAllSelect) {
              return (
                <Col xl={4} lg={6} md={6} key={index} className="mb-3">
                  <PaginateAllSelect
                    isMulti
                    id={item.value}
                    placeholder={'Any'}
                    label={item.label}
                    control={control}
                    name={item.value}
                    onChange={(page, search) =>
                      customFilterList
                        .find((filter) => filter.label === item.label)
                        ?.getOptions(page, search)
                    }
                  />
                </Col>
              );
            }
            const isExcludeSources = item.value === 'sources_exclude';
            const isExcludeReferrals = item.value === 'referrals_exclude';

            const isDisabled =
              (isExcludeSources && !hasAllSources) || (isExcludeReferrals && !hasAllReferrals);

            return (
              <Col xl={4} lg={6} md={6} key={index} className="mb-3">
                <PaginateSelect
                  isDisabled={isDisabled}
                  isSearchable={true}
                  isMulti={true}
                  placeholder={'Any'}
                  label={item.label}
                  control={control}
                  name={item.value}
                  onChange={(page, search) =>
                    customFilterList
                      .find((filter) => filter.label === item.label)
                      ?.getOptions(page, search)
                  }
                />
              </Col>
            );
          }

          if (item.type === 'descsSelect') {
            return (
              <Col xl={4} lg={6} md={6} key={item.id} className="mb-3">
                <MultiSelect
                  label="Desks"
                  control={control}
                  id="desks"
                  name="deskIds"
                  options={desks}
                  onChange={(value) => {
                    setSelectedDesks(value);
                  }}
                />
              </Col>
            );
          }
          if (item.type === 'teamsSelect') {
            return (
              <Col xl={4} lg={6} md={6} key={item.id} className="mb-3">
                <MultiSelect
                  label="Teams"
                  control={control}
                  id="teams"
                  name="teamIds"
                  options={teams}
                  onChange={(value) => {
                    setSelectedTeams(value);
                  }}
                />
              </Col>
            );
          }
          if (item.type === 'operatorsSelect') {
            return (
              <Col xl={4} lg={6} md={6} key={item.id} className="mb-3">
                <MultiSelect
                  label="Operators"
                  control={control}
                  id="operators"
                  name="operatorIds"
                  options={operators}
                />
              </Col>
            );
          }

          return (
            <Col xl={2} lg={4} md={4} key={item.id} className="mb-3">
              <MultiSelect
                label={item.label}
                control={control}
                id={item.value}
                name={`dynamic_statuses.${index}.${item.value}`}
                options={item.options}
                onChange={item.onchange}
              />
            </Col>
          );
        })}
      </Row>
      <div className="d-flex gap-3 items-center">
        {/*{fields?.length && isNotEmptyFilterOption(getValues()) ?*/}
        {/*TODO Если нужно вернуть проверку на пустые фильтра*/}
        {!isEmpty(getValues('custom_filters')) ? (
          <DeleteFilterPopup
            triggerBtn={
              <Button
                buttonText="Delete"
                buttonType="delete"
                buttonStyle={{ maxWidth: 70, justifyContent: 'center' }}
                onClick={null}
              />
            }
            prepareFiltersData={() => {
              return { ...clearEmptyFilter(getValues()), oldFilters: oldFilters };
            }}
            onCloseModal={() => {
              fetchFilterSet();
              fetchClientDynamicStatuses();
              resetForm();
              setSelectedDynamicFilter([]);
            }}
          />
        ) : null}
        {fields?.length ? (
          <>
            <SaveFilterPopup
              filterSet={filterSet}
              triggerBtn={
                <Button
                  buttonText={!isEmpty(getValues('custom_filters')) ? 'Update' : 'Save'}
                  buttonStyle={{ maxWidth: 70, justifyContent: 'center' }}
                  // buttonType='outline'
                  onClick={null}
                />
              }
              prepareFiltersData={() => {
                return { ...clearEmptyFilter(getValues()), oldFilters: oldFilters };
              }}
              isUpdate={!isEmpty(getValues('custom_filters'))}
              onCloseModal={() => {
                fetchFilterSet();
                fetchClientDynamicStatuses();
              }}
              type="client"
            />
            <Button
              buttonText="Reset filters"
              buttonType="outline"
              isLoading={reloadFilters}
              type="button"
              buttonStyle={{ maxWidth: 120, justifyContent: 'center', marginLeft: 'auto' }}
              onClick={() => clearValues()}
            />
            <Button
              buttonText="Apply filters"
              isLoading={reloadFilters}
              type="submit"
              buttonStyle={{ maxWidth: 120, justifyContent: 'center' }}
              onClick={handleSubmit(submit)}
            />
            {/* <ReloadButton isLoading={reloadFilters} onClick={handleSubmit(submit)} /> */}
          </>
        ) : null}
      </div>
    </>
  );
};

export default SearchSection;

import { Col, Row } from 'react-bootstrap';
import { Card } from 'components/ui/Card';
import { PartnerAccountStatus } from './blocks/status/PartnerAccountStatus';
import { ManagementPartner } from 'models/Management/Partners/Partner';
import cn from 'classnames';
import styles from './partner-info.module.scss';
import { PartnerRegistered } from './blocks/registered/PartnerRegistered';
import { PartnerPersonalInfo } from './blocks/personalInfo/PartnerPersonalInfo';
import { Icon } from 'components/ui/Icon';

type Props = {
  partner: ManagementPartner;
  onChangeStatus: (status: string) => void;
};

export const PartnerInfo = ({ partner, onChangeStatus }: Props) => {
  return (
    <Row className="row-gap-4">
      <Col xl={2} lg={3} md={3} xs={12}>
        <Row className={cn(styles.meta, 'row-gap-4')}>
          <Col md={12} xs={6}>
            <Card className={cn(styles.card, styles.accountStatus)} title="Account status">
              <PartnerAccountStatus partner={partner} onChangeStatus={onChangeStatus} />
            </Card>
          </Col>
          <Col md={12} xs={6}>
            <Card className={cn(styles.card, styles.registered)} title="Registered">
              <PartnerRegistered date={partner?.created_at} />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xl={6} lg={9} md={9} xs={12}>
        <Card
          className={cn(styles.card, styles.personalInfo)}
          titleClassName={styles.personalInfoTitle}
          title="Personal information"
          iconTitle={<Icon name="personalInfo" color="var(--main-color)" />}
        >
          <PartnerPersonalInfo partner={partner} />
        </Card>
      </Col>
    </Row>
  );
};

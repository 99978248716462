import classNames from 'classnames/bind';
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import styles from './delete-filter-popup.module.scss';
import Button from 'components/ui/Button/Button';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import PopupTemplate from 'components/ui/PopupTemplate';
import { Col, Container, Row } from 'react-bootstrap';
import { deleteFilterSet } from 'api/filters';

const cx = classNames.bind(styles);

const DeleteFilterPopup = ({ triggerBtn, onCloseModal, prepareFiltersData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (closeModal) => {
    closeModal();
    onCloseModal();
  };

  const onSubmit = async (close) => {
    setIsLoading(true);

    try {
      setIsLoading(false);

      const id = prepareFiltersData()?.custom_filters?.value ?? null;

      const res = await deleteFilterSet(id);
      NotificationManager.success(`Filter deleted`, 'Success', 5000);
      closeModal(close);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle="Delete filter"
            style={{
              popup: {
                overflowY: 'hidden',
              },
            }}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form')}>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <span className={cx('delete__text')}>
                          Are you sure, you want to delete{' '}
                          <b>{`"${prepareFiltersData()?.custom_filters?.label}"` ?? 'this'}</b>{' '}
                          filter?
                        </span>
                      </Col>
                    </Row>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText="Cancel"
                          buttonType="outline"
                          type="button"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          isLoading={isLoading}
                          buttonText={'Submit'}
                          type="submit"
                          onClick={onSubmit.bind(undefined, close)}
                        />
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default DeleteFilterPopup;

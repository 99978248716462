import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './callbacks.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import { updateCallbacks } from 'api/callbaks';
import DatePicker from 'components/ui/DatePicker';
import JokerMultiSelect from 'components/ui/JokerMultiSelect';
import PaginateSelect from 'components/ui/PaginateSelect';
import { CallbackEditData } from 'models/Clients/ClientCallback';
import { optionsReminder } from './constants';
import { fetchCallbacksToPopulateReminders } from 'store/slice/reminders';
import { useDispatch } from 'react-redux';
import { remindersValidation, timeValidation } from './validations';
import { fetchOperators } from 'utils/managment/fetchData';
import { addYears } from 'date-fns';
import { MultiSelect } from 'components/ui/MultiSelect';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  userId: string | number;
  editData: CallbackEditData;
  onCloseModal: () => void;
};

const EditCallbackPopup = ({ triggerBtn, updateComponent, userId, editData }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    trigger,
    formState: { errors, dirtyFields, isSubmitted },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  const onSubmit = (close, data) => {
    setIsLoading(true);
    const sendData = {
      admin_user_id: data.admin_user_id.value,
      user_id: userId,
      time: data && data.time ? Math.round(data.time / 1000) : null,
      reminders: data.reminders.map((item) => item.value),
    };

    updateCallbacks(sendData, editData?.id)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: 'Callback updated successfully',
            timeOut: 3000,
          });
          setTimeout(() => updateComponent(), 2000);
          setIsLoading(false);
          closeModal(close);
          dispatch(fetchCallbacksToPopulateReminders());
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reset({
      admin_user_id: {
        value: editData.operator.id,
        label: editData.operator.full_name,
      },
      time: new Date(editData.time * 1000),
      reminders: editData.reminders.map((item) => {
        const reminderCount = (editData.time - item) / 60;
        return {
          value: reminderCount + '',
          label: reminderCount + ' minutes',
        };
      }),
    });
  }, [editData]);

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Edit callback'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'editCallbackForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col xs={12} className="mb-3">
                          <PaginateSelect
                            isSearchable={true}
                            isMulti={false}
                            label="Operator"
                            isRequired={true}
                            rules={{ required: ' Field is required' }}
                            errors={errors.admin_user_id}
                            control={control}
                            id="admin_user_id"
                            name="admin_user_id"
                            onChange={(page, search) => fetchOperators(page, search)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className="mb-3">
                          <DatePicker
                            name="time"
                            control={control}
                            label="Callback date & time"
                            dateFormat="MMMM d, yyyy HH:mm"
                            showTimeInput
                            minDate={new Date()}
                            maxDate={addYears(new Date(), 10)}
                            rules={{
                              required: ' Field is required',
                              validate: { timeValidation },
                            }}
                            errors={errors.time}
                            autoComplete="off"
                            onChange={() =>
                              isSubmitted && getValues('reminders')?.length && trigger('reminders')
                            }
                          />
                        </Col>
                        <Col xs={6} className="mb-3">
                          <MultiSelect
                            id="reminders"
                            name="reminders"
                            placeholder="Reminders"
                            label="Reminders"
                            control={control}
                            options={optionsReminder}
                            rules={{
                              required: ' Field is required',
                              validate: { isFuture: remindersValidation(getValues) },
                            }}
                            errors={errors.reminders}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className={cx('content-controls')}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <Button
                                isLoading={isLoading}
                                buttonText={'Update'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                                disabled={!Object.keys(dirtyFields).length}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default EditCallbackPopup;

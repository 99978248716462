import { usePermission } from 'utils/usePermission';
import { useEffect, useState } from 'react';
import { getUploadLog, promoteLeadsToClients } from 'api/leads';
import { LeadErrorLogItem } from 'models/Leads/LeadLog';
import LeadUploadLogPopup from './SearchLeadsPopup/LeadUploadLogPopup/LeadUploadLogPopup';
import Button from 'components/ui/Button';
import LeadUploadPopup from './SearchLeadsPopup/LeadUploadPopup/LeadUploadPopup';
import { notify } from 'utils/notify';
import styles from './search-leads-page.module.scss';

type Props = {
  total?: number;
  selectedRows: number[];
  updateComponentCallback: () => void;
};

export const SearchLeadsPageTitle = ({
  total = 0,
  selectedRows = [],
  updateComponentCallback,
}: Props) => {
  const { permissionGiven: PermissionUpload } = usePermission('admin.leads.upload');
  const { permissionGiven: PermissionPromote } = usePermission('admin.lead.promote');

  const [logsData, setLogsData] = useState<LeadErrorLogItem[]>([]);
  const [promoteIsLoading, setPromoteIsLoading] = useState(false);

  const getUploadErrors = () => {
    getUploadLog().then((res) => {
      if (res && res.status === 200) {
        setLogsData(res.data.data);
      }
    });
  };

  useEffect(() => {
    if (PermissionUpload) {
      getUploadErrors();
    }
  }, [PermissionUpload]);

  const promoteLeads = () => {
    setPromoteIsLoading(true);

    promoteLeadsToClients({ leads: selectedRows })
      .then((res) => {
        notify({
          type: 'success',
          message: res.data.message,
          timeOut: 3000,
        });
        setTimeout(() => updateComponentCallback(), 2000);
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: 'Leads not converted to clients',
          timeOut: 3000,
        });
      })
      .finally(() => setPromoteIsLoading(false));
  };

  return (
    <div className={styles.pageTitle}>
      <div>{`${total} Leads found & Selected ${selectedRows.length}`}</div>
      <div className={styles.buttons}>
        {PermissionPromote ? (
          <div>
            <Button
              disabled={selectedRows.length === 0}
              isLoading={promoteIsLoading}
              buttonText={'Promote to clients'}
              type="submit"
              onClick={promoteLeads}
            />
          </div>
        ) : null}
        {PermissionUpload ? (
          <>
            <LeadUploadLogPopup
              triggerBtn={<Button buttonText="View errors log" buttonType={'outline'} />}
              logsData={logsData}
            />
            <LeadUploadPopup
              triggerBtn={
                <Button buttonText="Upload" onClick={() => null} buttonType={'outline'} />
              }
              onCloseModal={getUploadErrors}
              updateComponent={updateComponentCallback}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

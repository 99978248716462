import React from 'react';
import { useAppDispatch } from 'store';
import { openJokerModal } from 'store/slice/modalSlice';
import { SYSTEM_MESSAGE_MODAL_KEY } from '../SystemMessageModal/SystemMessageModal';
import { SYSTEM_MESSAGE_DELETE_MODAL_KEY } from '../DeleteMessageModal/DeleteMessageModal';
import { AlertModalMode } from 'models/Ticker';
import { Icon } from 'components/ui/Icon';

export const SystemMessageActions = ({ message, type }) => {
  const dispatch = useAppDispatch();

  const deleteMessageHandler = () => {
    dispatch(
      openJokerModal({
        key: SYSTEM_MESSAGE_DELETE_MODAL_KEY,
        data: { message },
      }),
    );
  };

  const editMessageHandler = () => {
    dispatch(
      openJokerModal({
        key: SYSTEM_MESSAGE_MODAL_KEY,
        data: {
          title: 'Edit message',
          submitTitle: 'Save',
          mode: AlertModalMode.EDIT,
          message,
        },
      }),
    );
  };

  const archiveMessageHandler = () => {
    dispatch(
      openJokerModal({
        key: SYSTEM_MESSAGE_DELETE_MODAL_KEY,
        data: { message, archive: true },
      }),
    );
  };

  const republishMessageHandler = () => {
    dispatch(
      openJokerModal({
        key: SYSTEM_MESSAGE_MODAL_KEY,
        data: {
          title: 'Republish message',
          submitTitle: 'Republish',
          mode: AlertModalMode.REPUBLISH,
          message,
        },
      }),
    );
  };

  return (
    <div className="system-message-actions">
      {type === 'active' ? (
        <div className="system-message-action pause" onClick={archiveMessageHandler}>
          <Icon name="pause" size={20} />
        </div>
      ) : null}
      {type === 'scheduled' ? (
        <div className="system-message-action edit" onClick={editMessageHandler}>
          <Icon name="notePencil" size={20} />
        </div>
      ) : null}
      {type === 'past' ? (
        <div className="system-message-action play" onClick={republishMessageHandler}>
          <Icon name="play" size={20} />
        </div>
      ) : null}
      {type !== 'active' ? (
        <div className="system-message-action delete" onClick={deleteMessageHandler}>
          <Icon name="trash" size={20} />
        </div>
      ) : null}
    </div>
  );
};

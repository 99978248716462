import { useForm } from 'react-hook-form';
import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { CryptoTransactionFilter } from 'models/CustomerService/CryptoTransactionFilter';
import { TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES } from 'constants/table';
import useTableColumnOrder from 'utils/useTableColumnOrder';

const defaultValues = {
  query: '',
  query_category: defaultQueryCategoryOption,
  status: undefined,
};

export const useCryptoTransactionPage = () => {
  const table = useTableColumnOrder(
    'TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES',
    TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES,
  );

  const form = useForm<CryptoTransactionFilter>({
    reValidateMode: 'onChange',
    defaultValues,
  });

  return { table, form };
};

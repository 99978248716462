import { Link } from 'react-router-dom';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from '../clients-table.module.scss';
import { UserProfile } from 'models/User.ts';

const cx = classNames.bind(styles);

interface IClientProps {
  user_profile: UserProfile;
  uuid: string;
  id?: number;
}

const Client: FC<IClientProps> = ({ user_profile, uuid, id }) => {
  return (
    <div className={cx('client__name')}>
      <Link target={'_blank'} to={`/client/${id ?? 0}`} className={cx('logo')}>
        <span>
          {user_profile?.first_name?.length > 15
            ? user_profile?.first_name.slice(0, 15) + '...'
            : user_profile?.first_name}
          <br />
          {user_profile?.last_name?.length > 15
            ? user_profile?.last_name.slice(0, 15) + '...'
            : user_profile?.last_name}
        </span>
      </Link>
      <span className={cx('client__id')} onClick={() => copyToBuffer(uuid, true)}>
        {getShortUuid(uuid)}
      </span>
    </div>
  );
};

export default memo(Client);

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchStyles } from 'api/system';

import * as STATIC_LOGOS from '../../assets/logos';

interface IVisualInfo {
  theme: string;
  colorset: string;
  properLogo: any;
  logo_pc: string;
  logo_crm: string;
  sign_in: null;
  sign_up: string | null;
  reset_password:  string | null;
  favicon_client:  string | null;
  favicon_crm:  string | null;
}

const initialState: IVisualInfo = {
  properLogo: null,
  theme: '',
  colorset: '',
  logo_pc: null,
  logo_crm: null,
  sign_in: null,
  sign_up: null,
  reset_password:   null,
  favicon_client:  null,
  favicon_crm:  null,
};

export const getStyles = createAsyncThunk('visualSlice/getStyles', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchStyles();

    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

const getProperLogo = (logoURL: string) => {
  if (logoURL) {
    return `${logoURL}?time=${new Date().getTime()}`;
  }

  const staticLogo = STATIC_LOGOS?.[process.env.REACT_APP_DEFAULT_ICON];

  if (typeof staticLogo === 'string') {
    return staticLogo;
  }

  return staticLogo?.default;
}

export const visualSlice = createSlice({
  name: 'visualSlice',
  initialState,
  reducers: {
    changeSystemImage(state, action) {
      if (state?.[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStyles.fulfilled, (state, action) => {
        state.properLogo = getProperLogo(action.payload.logo_src_crm);
        state.theme = action.payload.theme;
        state.colorset = action.payload.colorset;
        state.logo_pc = action.payload.logo_src;
        state.logo_crm = action.payload.logo_src_crm;
        state.sign_up = action.payload.sign_up;
        state.sign_in = action.payload.sign_in;
        state.reset_password = action.payload.reset_password;
        state.favicon_client = action.payload.favicon_src_client;
        state.favicon_crm = action.payload.favicon_src_crm;
    })
  }
});

const { reducer } = visualSlice;
export const { changeSystemImage } = visualSlice.actions;
export const selectClientDesign = state => state.visual;
export const selectFavicon = createSelector(selectClientDesign, state => state.favicon_crm);
export default reducer;

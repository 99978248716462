import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './table.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import moment from 'moment';
import { IDealsTable } from 'models/components/Table/DealsTable/DealsTable.ts';

const cx = classNames.bind(styles);

const DealsTable: FC<IDealsTable> = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  manualSortHandler,
  colSortCheck,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      close_price,
      open_price,
      close_time,
      close_time_dt,
      deal,
      profit,
      symbol,
      volume,
      account,
      side,
      demo,
    } = item;

    return {
      account: (
        <div className={cx('payments-table_td')}>
          {demo === 1 && (
            <>
              <span style={{ color: 'var(--blue)' }}>DEMO</span>{' '}
            </>
          )}
          <span>{account}</span>
        </div>
      ),
      side: (
        <div className={cx('payments-table_td')}>
          <span>{side}</span>
        </div>
      ),
      deal: (
        <div className={cx('payments-table_td')}>
          <span>{deal}</span>
        </div>
      ),
      symbol: (
        <div className={cx('payments-table_td')}>
          <span>{symbol}</span>
        </div>
      ),
      open_price: (
        <div className={cx('payments-table_td')}>
          <span>{open_price}</span>
        </div>
      ),
      close_price: (
        <div className={cx('payments-table_td')}>
          <span>{close_price}</span>
        </div>
      ),
      volume: (
        <div className={cx('payments-table_td')}>
          <span>{volume}</span>
        </div>
      ),
      profit: (
        <div className={cx('payments-table_td')}>
          <span>{profit}</span>
        </div>
      ),
      close_time: (
        <div className={cx('payments-table_td', 'date-cell')}>
          <span>{moment(close_time_dt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}</span>
          <span>{moment(close_time_dt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  const checkSortEnable = (colId) => {
    switch (colId) {
      case 'original_agent':
      case 'country':
      case 'amount':
      case 'trading_acc':
      case 'date':
        return true;
      default:
        return false;
    }
  };

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('payments-table')}
      manualSortHandler={manualSortHandler}
      colSortCheck={colSortCheck || checkSortEnable}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? 'var(--cyan)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default DealsTable;

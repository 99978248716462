import {
    ChangeEvent,
    MutableRefObject,
    TextareaHTMLAttributes,
    useEffect,
    useState
} from "react";
import './ChatInput.scss';

interface IChatInput extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    textAreaRef:  MutableRefObject<HTMLTextAreaElement>;
    text: string;
    placeholder?: string;
}

export const ChatInput = ({ text, placeholder, onChange, textAreaRef, ...restProps}: IChatInput) => {
    const scrollOffset: number = 2;
    const [textAreaHeight, setTextAreaHeight] = useState("auto");

    useEffect(() => {
        if (text.trim().length) {
            setTextAreaHeight(`${textAreaRef.current?.scrollHeight + scrollOffset}px`);
        } else {
            setTextAreaHeight("auto");
        }
    }, [text]);

    const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setTextAreaHeight("auto");
        onChange(event);
    };

    return (
        <div className='chat-input'>
            <textarea
                {...restProps}
                ref={textAreaRef}
                rows={1}
                style={{ height: textAreaHeight }}
                onChange={onChangeHandler}
                placeholder={placeholder}
                value={text}
                maxLength={2000}
            />
        </div>
    );
};
import { TABLE_TRADING_ACC_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const tradingAccPageDefaultValues = {
  query: '',
  type: [],
  status: [],
};

export const useTrandingAccPage = () => {
  const table = useTableColumnOrder(
    'TABLE_TRADING_ACC_COLUMNS_NAMES',
    TABLE_TRADING_ACC_COLUMNS_NAMES,
  );

  const form = useForm({ reValidateMode: 'onChange', defaultValues: tradingAccPageDefaultValues });

  return { table, form };
};

import { useEffect, useState, useRef, FC } from 'react';
import classNames from 'classnames/bind';
import Button from '../Button/Button';
import NotificationPopup from '../NotificationPopup';
import MTCheckPop from './MTCheckPop';
import { notify } from 'utils/notify';
import { useAbsurdLogic } from 'utils/useAbsurdLogic';
import { usePermission } from 'utils/usePermission';
import { findUnreadNotifications } from 'api/notifications';
import { getEnableMT, getEnableWebTrader, getUserInfoRole } from 'constants/selectors';
import { BurgerButton } from './BurgerButton/BurgerButton';
import { useAppDispatch, useAppSelector } from 'store';
import { getNotify, setNotificationCount } from 'store/slice/notifySlice';
import { Icon } from '../Icon';
import { UserInfo } from './UserInfo/UserInfo';
import styles from './header.module.scss';
import { useTheme } from 'components/theme';

const cx = classNames.bind(styles);

export const notification_close_icon_id = 'notification_close_icon';

type HeaderProps = {
  isMobile?: boolean;
  onOpenMenu: () => void;
};

const Header: FC<HeaderProps> = ({ isMobile = false, onOpenMenu }) => {
  const { theme, toggleTheme } = useTheme();

  const [showNotify, setShowNotify] = useState(false);
  const [mtCheckPopIsOpen, setMtCheckPopIsOpen] = useState(false);
  const notifyPopup = useRef(null);

  const dispatch = useAppDispatch();

  const role = useAppSelector(getUserInfoRole);
  const notifyCount = useAppSelector(getNotify);
  const ENABLE_MT = useAppSelector(getEnableMT);
  const ENABLE_WEB_TRADER = useAppSelector(getEnableWebTrader);

  const { permissionGiven: PERMISSION_MT_CHECK } = usePermission('admin.menu-mt-check.view');

  //@ts-ignore
  const { MT } = useAbsurdLogic({ ENABLE_WEB_TRADER, ENABLE_MT });

  const handleShowNotify = () => {
    setShowNotify(!showNotify);
  };

  const onClickOutside = (event) => {
    const id = event.target.getAttribute('id');

    if (
      notifyPopup.current &&
      (!notifyPopup.current.contains(event.target) || id === notification_close_icon_id)
    ) {
      setShowNotify(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);

    return () => {
      document.removeEventListener('click', onClickOutside, true);
    };
  }, []);

  const getUnreadNotify = () => {
    return findUnreadNotifications()
      .then((res) => {
        if (res.status === 200) {
          dispatch(setNotificationCount(res.data.total));
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  useEffect(() => {
    getUnreadNotify();
  }, []);

  const checkMTHandler = () => {
    setMtCheckPopIsOpen(!mtCheckPopIsOpen);
  };

  return (
    <header className={cx('header', { mobile: isMobile })}>
      <div className={cx('header__container')}>
        {isMobile && <BurgerButton onClick={onOpenMenu} />}

        {PERMISSION_MT_CHECK && MT && (
          <div className={cx('mt_button')}>
            <Button
              onClick={checkMTHandler}
              buttonText="MT Check"
              buttonType="outline"
              type="button"
            />
            <MTCheckPop open={mtCheckPopIsOpen} setOpen={setMtCheckPopIsOpen} />
          </div>
        )}
        <div className={cx('notifications')} ref={notifyPopup}>
          <div className={cx('notifications__icon')} onClick={() => handleShowNotify()}>
            <div
              className={cx(notifyCount ? 'notifications__count' : 'notifications__count empty')}
            >
              {notifyCount ? notifyCount : null}
            </div>
            <Icon name="bell" color="var(--black)" />
          </div>
          <div className={cx('notifications__container', showNotify ? 'show' : '')}>
            <NotificationPopup />
          </div>
        </div>
        <Icon name={theme === 'dark' ? 'sun' : 'moon'} onClick={toggleTheme} />
        {!isMobile && role ? <UserInfo role={role} /> : null}
      </div>
    </header>
  );
};

export default Header;

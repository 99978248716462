import cn from 'classnames';

import styles from './switch.module.scss';
import { ChangeEvent } from 'react';

type Props = {
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const Switch = ({ isChecked = false, isDisabled = false, onChange }: Props) => {
  return (
    <label className={styles.switch}>
      <input type="checkbox" disabled={isDisabled} checked={isChecked} onChange={onChange} />
      <span className={cn(styles.slider, isDisabled && styles.disabled)} />
    </label>
  );
};

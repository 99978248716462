import { useFormContext } from 'react-hook-form';
import { ManagementOfficesFilter } from 'models/Management/Offices/Filter';
import { CountryListValue } from 'models/Countries';
import { Col, Row } from 'react-bootstrap';
import { MultiSelect } from 'components/ui/MultiSelect';

type Props = {
  countries: CountryListValue[];
};

export const OfficesPageFields = ({ countries }: Props) => {
  const { control } = useFormContext<ManagementOfficesFilter>();

  return (
    <Row>
      {countries && (
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label="Country"
            control={control}
            id="country_ids"
            name="country_ids"
            options={countries}
          />
        </Col>
      )}
    </Row>
  );
};

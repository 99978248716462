import React, { useState } from 'react';
import classNames from 'classnames/bind';

import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import styles from '../operator-page.module.scss';
import { notify } from 'utils/notify';
import { getDepartmentRolesMethod } from 'api/managements/operators';
import JokerSelect from 'components/ui/JokerSelect';
import { useParams } from 'react-router-dom';
import { addRoleMethod } from 'api/operator';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  departmentsList: ListValue[];
  setOpenForm: (val: boolean) => void;
  updateComponent: () => void;
};

const AddDepartmentForm = ({ departmentsList, setOpenForm, updateComponent }: Props) => {
  const params = useParams();

  const setDefaultValues = () => {
    return {
      department_id: undefined,
      role: undefined,
    };
  };

  const [isLoading, setIsLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      department_id: data?.department_id.value,
      role: data?.role?.label,
      admin_user_id: params.id,
    };
    addRoleMethod(sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          updateComponent();
          reset(setDefaultValues());
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const setDefaultValuesRole = () => {
    return {
      department_id: getValues('department_id'),
      role: '',
    };
  };

  const getRolesList = (department) => {
    reset(setDefaultValuesRole());
    getDepartmentRolesMethod(department.value).then((res) => {
      if ((res.status = 200)) {
        const list = res.data.data.roles.map((opt) => ({
          value: opt.id,
          label: opt.name,
        }));
        setRoleList(list);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mt-4">
        <Col md={4} className="mb-4">
          <JokerSelect
            isRequired={false}
            label={'Department'}
            onSelectChange={() => {
              getRolesList(getValues('department_id'));
            }}
            control={control}
            id="department_id"
            name={'department_id'}
            placeholder={'--Select--'}
            rightaligned={true}
            options={departmentsList}
          />
        </Col>
        <Col md={4} className="mb-4">
          <JokerSelect
            label={'Role'}
            control={control}
            id="role"
            name="role"
            placeholder={'--Select--'}
            rightaligned={true}
            options={roleList}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className={cx('btn-wrapper')}>
            <Button
              buttonText="Cancel"
              buttonType="outline"
              type="button"
              onClick={() => {
                setOpenForm(false);
              }}
            />
            <Button
              isLoading={isLoading}
              disabled={!isDirty}
              buttonText={'Save'}
              type="submit"
              onClick={(data) => handleSubmit(onSubmit.bind(data))}
            />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default AddDepartmentForm;

import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './card.module.scss';

type Props = {
  children: ReactNode;
  title?: string;
  iconTitle?: ReactNode;
  className?: string;
  titleClassName?: string;
};

export const Card = ({ children, title, iconTitle, className, titleClassName }: Props) => {
  return (
    <div className={cn(styles.card, className)}>
      {title && (
        <div className={cn(styles.title, titleClassName)}>
          {title}
          {iconTitle ? iconTitle : null}
        </div>
      )}
      {children}
    </div>
  );
};

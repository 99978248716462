import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { getEnableMT } from 'constants/selectors';
import {
  TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES_NOT_MT,
} from 'constants/table';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const managementPartnersDefaultValues = {
  query: '',
  query_category: defaultQueryCategoryOption,
  country_ids: [],
  created_at: '',
  statuses: undefined,
};

export const usePartnersPage = () => {
  const ENABLE_MT = useAppSelector(getEnableMT);

  const tableHeader = ENABLE_MT
    ? TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES
    : TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES_NOT_MT;

  const table = useTableColumnOrder(
    ENABLE_MT
      ? 'TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES'
      : 'TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES_NOT_MT',
    tableHeader,
  );

  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: managementPartnersDefaultValues,
  });

  return { form, table };
};

import { useState, useEffect, useRef } from 'react';
import styles from './../../client-page.module.scss';
import { useParams } from 'react-router-dom';
import DealsFilter from './DealsFilter';
import DealsTable from 'components/ui/Table/DealsTable';
import { findDeals } from 'api/deals';
import { DealsFields } from './DealsFields/DealsFields';
import { useDeals } from './useDeals';
import { FormProvider } from 'react-hook-form';
import { TableLayout } from 'components/ui/Table/TableLayout';

const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [tickerOpt, setTickerOpt] = useState([]);

  const { id } = useParams();

  const componentMounted = useRef(false);

  useEffect(() => {
    getDeals();
    componentMounted.current = true;

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getDeals = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findDeals(null, filtersData, id)
      .then((res) => {
        if (res && res.status === 200) {
          if (componentMounted.current) {
            setDeals(res.data.data);
            const optTickerOpt = res.data.ticker.map((opt) => ({
              value: opt,
              label: opt,
            }));
            setTickerOpt(optTickerOpt);
          }
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const clearFiltersSortList = () => {
    getDeals({ navigate: 'first', perPage: 10 });
    setFilters(null);
  };

  const { form, table } = useDeals();

  return (
    <>
      <div className={styles.tabTitle}>Deals</div>
      <FormProvider {...form}>
        <DealsFields userId={id} tickerOpt={tickerOpt} />
        <TableLayout
          header={
            <DealsFilter
              onSubmit={(filtersData) => {
                if (!filters && !filtersData) {
                  return;
                }
                if (filters && !filtersData) {
                  clearFiltersSortList();
                } else {
                  setFilters(filtersData);
                  getDeals(
                    { navigate: 'first', perPage: filtersData?.filter?.limit || 10 },
                    filtersData,
                  );
                }
              }}
              reloadFilters={isListLoading}
              setDefaultColumnsOrder={table.setDefaultColumnsOrder}
            />
          }
        >
          <DealsTable
            data={deals}
            perPage={200}
            showLoader={isListLoading}
            columns={table.columns}
            saveColumnOrder={table.saveColumnOrder}
            // modifyDataHandler={(callback) => console.log('a')}
          />
        </TableLayout>
      </FormProvider>
    </>
  );
};

export default Deals;

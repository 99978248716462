import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import styles from './filter.module.scss';
import { useFormContext } from 'react-hook-form';
import Button from 'components/ui/Button/Button';
import { NotificationsFilter as FilterType } from 'models/Notifications/NotificationsFilter';
import { ReloadButton } from 'components/ui/ReloadButton';
import { SearchBar } from 'components/ui/SearchBar';
import { notificationsDefaultValues } from '../useNotificationsPage';

type Props = {
  onSubmit: (data: FilterType) => void;
  reloadFilters: boolean;
  setDefaultColumnsOrder: () => void;
};

const NotificationsFilter = (props: Props) => {
  const { onSubmit, reloadFilters, setDefaultColumnsOrder } = props;

  const notificationsSearchOptions = useSearchCategoriesOptions('notification');

  const {
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { dirtyFields },
    watch,
  } = useFormContext();

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    notificationsSearchOptions,
  );

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        created_at: data['created_at'],
        desk_ids: getValue(data['desk_ids']),
        team_ids: getValue(data['team_ids']),
        agent: getValue(data['agents']),
        type: getValue(data['type']),
        details: getValue(data['details']),
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(notificationsDefaultValues);
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={styles.form}
      >
        <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
        <SearchBar
          className={styles.searchBar}
          inputProps={{
            id: 'query',
            name: 'query',
            control,
            placeholder: dynamicSearchPlaceholder,
            onSearch: () => submit(getValues()),
            onChange: (v) => !v?.length && submit(getValues()),
          }}
          selectProps={{
            id: 'query_category',
            name: 'query_category',
            control,
            options: notificationsSearchOptions,
            onSelectChange: () => {
              const { query } = getValues();
              if (query) submit(getValues());
            },
          }}
        />

        <div className={styles.buttons}>
          <ReloadButton isLoading={reloadFilters} onClick={() => submit(getValues())} />
          <Button
            buttonText="Reset"
            buttonType="outline"
            isLoading={reloadFilters}
            type="reset"
            onClick={() => resetForm()}
            disabled={!getDirtyFieldsLength()}
          />
          <Button
            buttonText="Apply"
            isLoading={reloadFilters}
            type="submit"
            onClick={handleSubmit(submit)}
            disabled={!getDirtyFieldsLength()}
          />
        </div>
      </form>
    </>
  );
};

export default NotificationsFilter;

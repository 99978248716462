import { getTradingAccList } from 'api/tradingAcc';
import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import { MultiSelect } from 'components/ui/MultiSelect';
import { ListValue } from 'models/ListValue';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

export const DealsFields = ({ userId, tickerOpt }) => {
  const [accountsOpt, setAccountsOpt] = useState<ListValue[]>([]);

  const { control } = useFormContext();

  const getTradingAccOpt = () => {
    getTradingAccList(null, userId).then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.trading_account,
          label: opt.name,
        }));
        setAccountsOpt(opt);
      }
    });
  };

  useEffect(() => {
    getTradingAccOpt();
  }, []);

  return (
    <Row>
      <Col xl={3} md={4} sm={6}>
        <MultiSelect
          label="Accounts"
          control={control}
          id="trading_account"
          name="trading_account"
          options={accountsOpt}
        />
      </Col>
      <Col xxl={2} xl={3} md={3} sm={6}>
        <MultiSelect
          label="Symbol"
          control={control}
          id="ticker"
          name="ticker"
          options={tickerOpt}
        />
      </Col>
      <Col xl={3} md={4} sm={12}>
        <DatePickerRange
          name="close_at"
          control={control}
          label="Close time range"
          placeholder="From - To"
        />
      </Col>
    </Row>
  );
};

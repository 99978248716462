import { openJokerModal } from "../../../../store/slice/modalSlice";
import { REMOVE_CHAT_MODAL_KEY } from "../modals/RemoveChatModal/RemoveChatModal";
import { useAppDispatch } from "../../../../store";
import { Thread } from "../../../../models/Chat";
import { MenuOption } from "./MenuOption";
import { Icon } from "components/ui/Icon";

type DeleteContactOptionProps = {
    contact: Thread
};

export const DeleteContactOption = ({ contact }: DeleteContactOptionProps) => {
    const dispatch = useAppDispatch();

    const handleOpenRemoveModal = () => {
        dispatch(openJokerModal({
            key: REMOVE_CHAT_MODAL_KEY,
            data: {
                contact,
                title: contact?.group ? 'Leave the group?' : 'Remove this contact?'
            }
        }));
    };

    return (
        <MenuOption title={contact?.group ? 'Leave group' : 'Delete chat'} onClick={handleOpenRemoveModal}>
            <Icon name="trash" size={16} color="var(--red)"/>
        </MenuOption>
    )
}
import { Client } from 'models/Clients/Clients';

import styles from './client-last-ips.module.scss';

type Props = {
  client: Client;
};

export const ClientLastIps = ({ client }: Props) => {
  const ips = client?.visits || [];

  return (
    <ul className={styles.list}>
      {ips.map((el, index) => (
        <li key={index} className={styles.item}>
          {el?.country ? (
            <img className={styles.flag} src={el?.country?.flag && el?.country?.flag} />
          ) : (
            <span className={styles.flag} />
          )}
          <span>{el?.ip}</span>
        </li>
      ))}
    </ul>
  );
};

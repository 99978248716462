import cn from 'classnames';
import styles from './spinner.module.scss';

type Props = {
  size?: 'big' | 'normal' | 'small';
};

export const Spinner = ({ size = 'normal' }: Props) => {
  return (
    <svg className={cn(styles.spinner, styles[size])} viewBox="0 0 50 50">
      <circle className={styles.path} cx="25" cy="25" r="20" fill="none" stroke-width="4" />
    </svg>
  );
};

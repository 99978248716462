import { APIGetBalanceInfoResponse } from 'models/API/Deposit/Balance';
import API from '../api';
import { APIGetTransactionDetailsResponse } from 'models/API/Deposit/TransactionDetails';

export const getBalanceInfo = (id, period) =>
  API.get<APIGetBalanceInfoResponse>(`/api/v1/deposit/balance/info/user/${id}?last_days=${period ? period : ''}`);

export const updateProfitInfo = (uid) =>
  API.get(`/api/v1/user/${uid}/update-profit`)

// response unused
export const addDeposit = (data) => API.post(`/api/v1/deposit/transaction`, data);

export const getDetailTransaction = (transaction, user) =>
  API.get<APIGetTransactionDetailsResponse>(`/api/v1/deposit/transaction/${transaction}/user/${user}`);
  
// response unused
export const addDepositTransferFounds = (data) => API.post(`/api/v1/deposit/transfer-funds`, data);

// export const getUserTransactionList = (id) => API.get(`/api/v1/deposit/transaction/user/${id}`);

// unused
export const getRejectStatusList = (type) => API.get(`/api/v1/status?type=${type}`);

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    AddParticipantsPayload,
    ChatRecipientType,
    MessageTypesVerbose,
    Participant,
    ProcessedMessageMode,
    SendTextMessagePayload, Thread,
    UpdateChatUserInfoPayload,
} from "../../../models/Chat";
import {
    addGroupParticipants, chatGetCrmUserToken,
    chatGetUser,
    createChatGroup,
    createChatThread,
    deleteChatMessage,
    deleteChatThread,
    deleteGroupParticipant,
    getChatMessages,
    getChatMessagesPage,
    getChatRecipient,
    getChatThreads,
    getGroupParticipants,
    groupParticipantPromote,
    leaveChatGroup, pinChatThread, searchChatClients, searchChatMessagePage,
    searchChatThreads,
    sendChatMessageApi,
    sendTextMessage,
    updateChatUserInfo,
    updateTextMessage, updateThreadSettings
} from "../../../api/chat";
import { allowedMessageTypes, mapMessageApiField } from "../../../pages/Chat/constants";
import { makeTemporaryMessage } from "../../../pages/Chat/helpers/makeTemporaryMessage";
import { getFileExtension } from "../../../pages/Chat/helpers/getFileExtension";
import { getMessageFileConfig } from "../../../pages/Chat/helpers/getMessageFileConfig";
import {
    addGroup,
    addPendingMessage,
    removeMessage,
    selectChatUser,
    selectProcessedMessage,
    selectProcessedMessageMode,
    setProcessedMessage, setRecipient,
    setThreadId
} from "./chatSlice";
import { notify } from "../../../utils/notify";
import { RootState } from "../../index";

type SendTextProps = {
    threadId: string;
    body: string;
};

type UpdateTextProps = SendTextProps & {
    id: string;
};

type DeleteMessageProps = {
    id: string;
    threadId: string;
};

type SendFileProps = {
    threadId: string;
    file: File;
};

type GetNextMessagesProps = {
    threadId: string;
    nextPage: string;
};


type CreateThreadProps = {
    recipientId: string;
    message?: string;
    file?: File;
};

type DeleteGroupParticipantProps = {
    threadId: string;
    id: string;
    ownerId: string;
};

type PromoteParticipantProps = {
    id: string;
    threadId: string;
};

type AddParticipantsProps = {
    threadId: string;
    data: AddParticipantsPayload
};

type UserOption = {
    label: string;
    value: string;
};

type CreateContactProps = {
    data: {
        users: UserOption | UserOption[];
        subject?: string;
    };
    isGroup: boolean;
    isSupport: boolean;
    usersData: ChatRecipientType[];
};

type PinThreadProps = { threadId: string, pin: boolean };

export const updateUserInfo = createAsyncThunk('chat/user', async (data: UpdateChatUserInfoPayload, { rejectWithValue }) => {
    try {
        const response = await updateChatUserInfo(data);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const getThreads = createAsyncThunk('chat/threads', async (_, { rejectWithValue }) => {
    try {
        const response = await getChatThreads();

        const meta = response.data.data.reduce((result, item) => {
            if (!item.group) {
                result.online[item.resources.recipient.provider_id] = item.resources.recipient.options.online_status;
            }

            if (item.unread && item.unread_count > 0) {
                !!item.is_support ? result.unreadSupport.push(item.id) : result.unreadInternal.push(item.id);
            }
            return result;
        }, { unreadSupport: [], unreadInternal: [], online: {} });

        return {
            threads: response.data.data,
            online: meta.online,
            unreadSupport: meta.unreadSupport,
            unreadInternal: meta.unreadInternal,
        }
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const pinThread = createAsyncThunk('chat/thread/pin', async ({ threadId, pin }: PinThreadProps, { rejectWithValue, dispatch }) => {
    try {
        await pinChatThread(threadId, pin);
        dispatch(getThreads());
        return true;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const deleteThread = createAsyncThunk('chat/threads/delete', async (id: string, { rejectWithValue }) => {
    try {
        await deleteChatThread(id);
        return true;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const leaveGroup = createAsyncThunk('chat/groups/delete', async (id: string, { rejectWithValue }) => {
    try {
        await leaveChatGroup(id);
        return true;
    } catch (error) {
        notifyError(error);
        return rejectWithValue(error.response.data.message);
    }
});

export const getMessages = createAsyncThunk('chat/messages', async (threadId: string, { rejectWithValue, dispatch }) => {
    try {
        dispatch(setThreadId(threadId));

        const response = await getChatMessages(threadId);
        const payload = {
            messages: response.data.resources.messages.data.filter(message => allowedMessageTypes.includes(message.type_verbose)),
            next: response.data.resources.messages.meta.next_page_id,
            online: null,
            participants: null,
        };

        if (response.data.group) {
            const { online, participants } = participantsAdapter(response.data.resources?.participants?.data || []);
            payload.online = online;
            payload.participants = participants;
        }

        return payload;
    } catch (error) {
        notifyError(error);
        return rejectWithValue(error.response.data.message);
    }
});

export const getNextMessages = createAsyncThunk('chat/messages/page', async ({threadId, nextPage}: GetNextMessagesProps, { rejectWithValue }) => {
    try {
        const response = await getChatMessagesPage(threadId, nextPage);
        return {
            messages: response.data.data.filter(message => allowedMessageTypes.includes(message.type_verbose)),
            next: response.data.meta.next_page_id
        };
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const getFoundMessagesList = createAsyncThunk('chat/search/messages/get',
  async ({ id, threadId }: { id: string, threadId: string }, { rejectWithValue }) => {
      try {
          const response = await searchChatMessagePage(id, threadId);
          return response.data
      } catch (error) {
          return rejectWithValue(error.response.data);
      }
  });

export const sendText = createAsyncThunk('chat/messages/send/text',
    async ({ threadId, body }: SendTextProps, { rejectWithValue, dispatch, getState }) => {
        const state = getState();
        const user = selectChatUser(state);
        const { temporaryId, temporaryMessage } = makeTemporaryMessage(body, user.provider, MessageTypesVerbose.MESSAGE);

        try {
            await dispatch(addPendingMessage(temporaryMessage));

            const data: SendTextMessagePayload = {
                message: body,
                temporary_id: temporaryId
            };

            const processedMessageMode = selectProcessedMessageMode(state);
            if (processedMessageMode === ProcessedMessageMode.IS_REPLYING) {
                const processedMessage = selectProcessedMessage(state);
                data.reply_to_id = processedMessage.id
            }

            const response = await sendTextMessage(threadId, data);
            return response.data;
        } catch (error) {
            handleRejectedMessage(temporaryId, threadId, dispatch);
            return rejectWithValue(error.response.data.message);
        }
    });

export const updateText = createAsyncThunk('chat/messages/update/text',
    async ({ id, threadId, body }: UpdateTextProps, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setProcessedMessage({ mode: null, message: null }));

            const response = await updateTextMessage(id, threadId, { message: body });
            return response.data;
        } catch (error) {
            notify({
                type: 'error',
                message: 'Failed to edit the message!',
                timeOut: 3000,
            });
            return rejectWithValue(error.response.data);
        }
    });

export const sendFile = createAsyncThunk('chat/messages/send/file',
    async ({ threadId, file }: SendFileProps, { rejectWithValue, dispatch, getState }) => {
        const state = getState();
        const user = selectChatUser(state);
        const fileExtension = getFileExtension(file.name);
        const { type } = getMessageFileConfig(fileExtension);
        const { temporaryId, temporaryMessage } = makeTemporaryMessage(file.name, user.provider, type);

        try {
            await dispatch(addPendingMessage(temporaryMessage));

            const data = new FormData();
            data.append("temporary_id", temporaryId);
            data.append(mapMessageApiField[type], file, file.name);

            const processedMessageMode = selectProcessedMessageMode(state);
            if (processedMessageMode === ProcessedMessageMode.IS_REPLYING) {
                const processedMessage = selectProcessedMessage(state);
                data.append("reply_to_id", processedMessage.id);
            }

            const response = await sendChatMessageApi[type](threadId, data);
            return response.data;
        } catch (error) {
            handleRejectedMessage(temporaryId, threadId, dispatch);
            return rejectWithValue(error.response.data.message);
        }
    });

export const createThread = createAsyncThunk('chat/threads/create',
    async ({ recipientId, message, file }: CreateThreadProps, { rejectWithValue }) => {
        try {
            let data;

            if (file) {
                const fileExtension = getFileExtension(file.name);
                const { type } = getMessageFileConfig(fileExtension);

                data = new FormData();
                data.append("recipient_alias", 'user');
                data.append("recipient_id", recipientId);
                data.append(mapMessageApiField[type], file, file.name);
            } else {
                data = {
                    recipient_alias: 'user',
                    recipient_id: recipientId,
                    message
                }
            }

            const response = await createChatThread(data);
            return {
                ...response.data,
                unread: false,
                unread_count: 0
            };
        } catch (error) {
            return rejectWithValue(error.response.data.message);
        }
    });

export const createContact = createAsyncThunk('chat/contact', async ({ data, isGroup, isSupport, usersData }: CreateContactProps, { rejectWithValue, dispatch, getState }) => {
        try {
            const state = getState() as RootState;

            // Create a support group for a client
            if (isSupport) {
                const clientId = (data.users as UserOption).value;
                const userAuth = await chatGetCrmUserToken(clientId);
                const recipientIdData = await chatGetUser(clientId, userAuth.data);
                const recipientData = await getChatRecipient(recipientIdData.data.id);

                const group = await createChatGroup({
                    subject: `${recipientData.data.recipient.base.name} - Support`,
                    providers: [{ alias: 'user', id: recipientIdData.data.id }]
                });

                dispatch(addGroup({ ...group.data, unread: false, unread_count: 0 }));
                return `/chat/${group.data.id}`;
            }
            // Create a group
            if (isGroup) {
                const group = await createChatGroup({
                    subject: data.subject,
                    providers: (data.users as UserOption[]).map(user => ({alias: 'user', id: user.value}))
                });

                dispatch(addGroup({ ...group.data, unread: false, unread_count: 0 }));
                return `/chat/${group.data.id}`;
            }

            // Redirect to existing chat
            const recipientId = (data.users as UserOption).value;
            const threadExists = state.chat.threads.find(thread => thread.resources?.recipient?.provider_id === recipientId);
            if (threadExists) {
                return `/chat/${threadExists.id}`;
            }

            // Redirect to chat user's page
            const recipient = usersData.find(user => user.provider_id === recipientId);
            dispatch(setRecipient(recipient));
            return `/chat/recipient/${recipientId}`;
        } catch (error) {
            notify({
                type: 'error',
                message: 'Failed to create a contact!',
                timeOut: 3000,
            });
            return rejectWithValue(error.response.data.message);
        }
    });

export const getRecipient = createAsyncThunk('chat/recipient', async (recipientId: string, { rejectWithValue }) => {
    try {
        const response = await getChatRecipient(recipientId);
        return response.data.recipient;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export const deleteMessage = createAsyncThunk('chat/messages/delete',
    async ({ id, threadId }: DeleteMessageProps, { rejectWithValue }) => {
        try {
            await deleteChatMessage(id, threadId);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    });

export const deleteParticipant = createAsyncThunk('chat/participants/delete',
    async ({ threadId, id, ownerId }: DeleteGroupParticipantProps, { rejectWithValue }) => {
        try {
            await deleteGroupParticipant(threadId, id);
        } catch (error) {
            notifyError(error);
            return rejectWithValue(error.response.data);
        }
    });

export const promoteParticipant = createAsyncThunk('chat/participants/promote',
    async ({ threadId, id }: PromoteParticipantProps, { rejectWithValue }) => {
        try {
            const response = await groupParticipantPromote(threadId, id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    });

export const addParticipants = createAsyncThunk('chat/participants/add',
    async ({ threadId, data }: AddParticipantsProps, { rejectWithValue, dispatch }) => {
        try {
            await addGroupParticipants(threadId, data);
            dispatch(getParticipants({ threadId }));
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    });

export const getParticipants = createAsyncThunk('chat/participants/get',
    async ({ threadId }: { threadId: string }, { rejectWithValue }) => {
        try {
            const response = await getGroupParticipants(threadId);
            return participantsAdapter(response.data.data || []);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    });

export const updateChatThreadSettings = async ({ group, subject }: { group: Thread, subject: string }) => {
    try {
        const response = await updateThreadSettings(group.id, {
            ...group.options,
            messaging: group.options.message,
            knocks: group.options.knock,
            subject
        });

        return response.data.name;
    } catch (e) {
        console.warn(e.data.message);
    }
};

export const searchChatUsers = async (search: string) => {
    try {
        const response = await searchChatThreads(search);
        return response.data.data;
    } catch (e) {
        console.warn(e.data.message);
    }
};

export const searchChatClientUsers = async (query: string) => {
    try {
        const response = await searchChatClients({ query });
        return response.data.data;
    } catch (e) {
        console.warn(e.data.message);
    }
};

function participantsAdapter(participants: Participant[]) {
    return participants.reduce((result, participant) => {
        result.participants[participant.owner_id] = participant;
        result.online[participant.owner_id] = participant.owner.options.online_status
        return result;
    }, { online: {}, participants: {}});
}

function notifyError(error){
    if (error.response.status === 403) {
        notify({
            type: 'error',
            message: error.response.data.message,
            timeOut: 3000,
        });
    }
}

function handleRejectedMessage(id, threadId, dispatch) {
    dispatch(removeMessage({ message_id: id, thread_id: threadId }))
    notify({
        type: 'error',
        message: 'Failed to send a message!',
        timeOut: 3000,
    });
}
import { changeTaPassword } from 'api/tradingAcc';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button';

import PasswordInput from 'components/ui/PasswordInput/PasswordInput';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Popup from 'reactjs-popup';
import { notify } from 'utils/notify';
import {
  PASSWORD_CONFIRM_INPUT_VALIDATION,
  PASSWORD_INPUT_VALIDATION_WITH_INTL,
} from 'constants/validators.js';

import styles from '../tradingAccounts.module.scss';
import { Container } from 'react-bootstrap';

const cx = classNames.bind(styles);

const ChangeTAPassword = ({ triggerBtn, updateComponent, tradingAccount }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };
  const new_password = watch('password');

  const onSubmit = (close: any, data: any): void => {
    setIsLoading(true);

    const postData = {
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    changeTaPassword(tradingAccount.id, postData)
      .then((res) => {
        if (res.status === 200) {
          updateComponent();
          closeModal(close);
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((error) => {
        if (error) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close: any) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <FormattedMessage
                id="page.trading.popup.changePassword.title"
                defaultMessage="Change trading account password"
              />
            }
            // leftContent={
            //   <div className={cx('info')}>
            //     <Key size={100} />
            //   </div>
            // }
            rightContent={
              <Container>
                <div className="mb-4">
                  <span>
                    <FormattedMessage
                      id="page.trading.popup.changePassword.for"
                      defaultMessage="Change password for"
                    />{' '}
                    - {tradingAccount.trading_account}{' '}
                    {tradingAccount.name && ` (${tradingAccount.name})`}
                  </span>
                </div>
                <div className={cx('content-form')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <div className="mb-3">
                      <PasswordInput
                        name="password"
                        type="password"
                        placeholder={intl.formatMessage({
                          id: 'page.trading.popup.changePassword.field.name.newPassword',
                          defaultMessage: 'New Password',
                        })}
                        label={
                          <FormattedMessage
                            id="page.trading.popup.changePassword.field.name.newPassword"
                            defaultMessage="New Password"
                          />
                        }
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: (
                              <FormattedMessage
                                id="form.error.passwordMessage_7"
                                defaultMessage="Password field is required"
                              />
                            ),
                          },
                          validate: PASSWORD_INPUT_VALIDATION_WITH_INTL(intl),
                        }}
                        errors={errors.password}
                      />
                    </div>
                    <div className="mb-4">
                      <PasswordInput
                        name="password_confirmation"
                        type="password"
                        placeholder={intl.formatMessage({
                          id: 'page.trading.popup.changePassword.field.name.confirmNewPassword',
                          defaultMessage: 'Confirm New Password',
                        })}
                        label={intl.formatMessage({
                          id: 'page.trading.popup.changePassword.field.name.confirmNewPassword',
                          defaultMessage: 'Confirm New Password',
                        })}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: (
                              <FormattedMessage
                                id="form.error.passwordMessage_7"
                                defaultMessage="Password field is required"
                              />
                            ),
                          },
                          validate: {
                            ...PASSWORD_INPUT_VALIDATION_WITH_INTL(intl),
                            ...PASSWORD_CONFIRM_INPUT_VALIDATION(intl, new_password),
                          },
                        }}
                        errors={errors.password_confirmation}
                      />
                    </div>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText={intl.formatMessage({
                            id: 'page.trading.popup.changePassword.btn.cancel',
                            defaultMessage: 'Cancel',
                          })}
                          buttonType="outline"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          isLoading={isLoading}
                          buttonText={intl.formatMessage({
                            id: 'page.trading.popup.changePassword.btn.change',
                            defaultMessage: 'Change password',
                          })}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Container>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default ChangeTAPassword;

import * as React from "react"

const FolderSvg = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_350_116)">
      <path
        d="M19 3.00031H12.472C12.3173 3.00133 12.1645 2.96713 12.025 2.90031L8.869 1.31631C8.45239 1.10885 7.9934 1.00069 7.528 1.00031H5C3.67441 1.00189 2.40356 1.52919 1.46622 2.46653C0.528882 3.40386 0.00158786 4.67471 0 6.00031L0 18.0003C0.00158786 19.3259 0.528882 20.5967 1.46622 21.5341C2.40356 22.4714 3.67441 22.9987 5 23.0003H19C20.3256 22.9987 21.5964 22.4714 22.5338 21.5341C23.4711 20.5967 23.9984 19.3259 24 18.0003V8.00031C23.9984 6.67471 23.4711 5.40386 22.5338 4.46653C21.5964 3.52919 20.3256 3.00189 19 3.00031ZM5 3.00031H7.528C7.68266 2.99928 7.83552 3.03348 7.975 3.10031L11.131 4.67931C11.5472 4.88849 12.0062 4.99837 12.472 5.00031H19C19.598 5.00128 20.1821 5.18097 20.6773 5.51632C21.1725 5.85166 21.5561 6.32734 21.779 6.88231L2 6.99431V6.00031C2 5.20466 2.31607 4.44159 2.87868 3.87898C3.44129 3.31638 4.20435 3.00031 5 3.00031ZM19 21.0003H5C4.20435 21.0003 3.44129 20.6842 2.87868 20.1216C2.31607 19.559 2 18.796 2 18.0003V8.99431L22 8.8813V18.0003C22 18.796 21.6839 19.559 21.1213 20.1216C20.5587 20.6842 19.7956 21.0003 19 21.0003Z"
        fill="#051367"/>
    </g>
    <defs>
      <clipPath id="clip0_350_116">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export default FolderSvg;

import classNames from 'classnames/bind';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import styles from './leads-table.module.scss';
import { usePermission } from 'utils/usePermission';
import { upperFirst } from 'lodash';

const cx = classNames.bind(styles);

const LeadsTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  manualSortHandler,
  colSortCheck,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const { permissionGiven: PermissionShowLead } = usePermission('admin.lead.show');

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      id,
      name,
      surname,
      uuid,
      country,
      salesAgent,
      notes,
      status,
      language,
      created_at,
      change_status,
      client,
    } = item;

    return {
      lead: (
        <div className={cx('client__name')}>
          {PermissionShowLead ? (
            <Link to={`/lead/${id}`} className={cx('logo')}>
              <span>{name + ' ' + surname}</span>
            </Link>
          ) : (
            <div>
              <span>{name + ' ' + surname}</span>
            </div>
          )}
          <span className={cx('client__id')} onClick={() => copyToBuffer(uuid, true)}>
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      country: (
        <div className={cx('client__country')}>
          <div className={cx('client__country-flag')}>
            <i className={cx('icon')} style={{ backgroundImage: `url(${country?.flag})` }} />
          </div>
          <div className={cx('client__country-text')}>
            <span>{country?.iso_3166_2}</span>
            <span>{language?.name || <span>&mdash;</span>} </span>
          </div>
        </div>
      ),
      sales: (
        <div className={cx('client__sales')}>
          {salesAgent ? (
            <div
              className={cx('client__sales-wrap', salesAgent ? `sales-${salesAgent.status}` : '')}
            >
              <span className={cx(salesAgent.status)}>{upperFirst(salesAgent.status)}</span>
              <span>
                {salesAgent.first_name}{' '}
                {salesAgent && salesAgent.last_name !== null ? salesAgent.last_name : ''}
              </span>
            </div>
          ) : (
            <span className={cx('empty')}>None</span>
          )}
        </div>
      ),
      registration: (
        <div className={cx('client__registration')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      last_note: (
        <div className={cx('client__lastnote')}>
          {!notes?.create_at ? (
            <span className={cx('client__lastnote-empty')}>None</span>
          ) : (
            <>
              <span>{moment.unix(notes.create_at).format('DD.MM.YYYY')}</span>
              <span>{moment.unix(notes.create_at).format('HH:mm:ss')}</span>
              <span>{notes.operator_full_name}</span>
              <span>{notes.subject}</span>
            </>
          )}
        </div>
      ),
      status: (
        <div className={cx('client__status')}>
          <span className={cx(status)}>{upperFirst(status)}</span>
          {change_status ? (
            <>
              <span>
                since {moment.unix(change_status.converted_at).format('DD.MM.YYYY HH:mm')}
              </span>
              {client ? (
                <span className={cx('client__id')} onClick={() => copyToBuffer(client.uuid, true)}>
                  {getShortUuid(client.uuid)}
                </span>
              ) : (
                ''
              )}
            </>
          ) : (
            <span>since {moment.unix(created_at).format('DD.MM.YYYY HH:mm')}</span>
          )}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  const checkSortEnable = (colId) => {
    switch (colId) {
      case 'country':
      case 'registration':
      case 'last_note':
        return true;
      default:
        return false;
    }
  };
  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('leads-table')}
      manualSortHandler={manualSortHandler}
      colSortCheck={colSortCheck || checkSortEnable}
      isSelected
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? 'var(--cyan)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default LeadsTable;

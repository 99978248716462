import PropTypes               from 'prop-types';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../styles/_notify-custom-styles.scss';

export const notify = (props) => {
  const { type, message, timeOut, title, callback, priority } = props;
  if (typeof message !== 'string' && message && Object.keys(message?.data).includes('errors')) {
    let keys = Object.keys(message.data.errors);
    let newMessage = message.data.errors[keys[0]][0];

    switch (type) {
      case 'info':
        NotificationManager.info(newMessage, title, timeOut, callback, priority);
        break;
      case 'success':
        NotificationManager.success(newMessage, title, timeOut, callback, priority);
        break;
      case 'warning':
        NotificationManager.warning(newMessage, title, timeOut, callback, priority);
        break;
      case 'error':
        NotificationManager.error(newMessage, title, timeOut, callback, priority);
        break;
      // no default
    }
  } else if (message && typeof message === 'string') {
    switch (type) {
      case 'info':
        NotificationManager.info(message, title, timeOut, callback, priority);
        break;
      case 'success':
        NotificationManager.success(message, title, timeOut, callback, priority);
        break;
      case 'warning':
        NotificationManager.warning(message, title, timeOut, callback, priority);
        break;
      case 'error':
        NotificationManager.error(message, title, timeOut, callback, priority);
        break;
      // no default
    }
  } else if (message) {
    let newMessage = message?.data?.message;
    switch (type) {
      case 'info':
        NotificationManager.info(newMessage, title, timeOut, callback, priority);
        break;
      case 'success':
        NotificationManager.success(newMessage, title, timeOut, callback, priority);
        break;
      case 'warning':
        NotificationManager.warning(newMessage, title, timeOut, callback, priority);
        break;
      case 'error':
        NotificationManager.error(newMessage, title, timeOut, callback, priority);
        break;
      // no default
    }
  } else return null;
};

notify.PropPtypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.object || PropTypes.string,
  timeOut: PropTypes.string,
  title: PropTypes.string.isRequired,
  callback: PropTypes.func,
  priority: PropTypes.bool,
  validateType: PropTypes.string,
};

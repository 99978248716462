import { Control } from 'react-hook-form';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { searchBarSelectStyles } from './styles';

export type SearchBarSelectProps = {
  name: string;
  id: string;
  options?: IOption[];
  onChange?: () => void;
  placeholder?: string;
  onSelectChange?: () => void;
  control: Control<any>;
};

export interface IOption {
  label: string;
  value: string | number;
}

export const defaultQueryCategoryOption: IOption = { label: 'All', value: 'all' };

export const SearchBarSelect = ({
  control,
  id,
  name,
  options,
  onSelectChange,
}: SearchBarSelectProps) => {
  return (
    <JokerSelect
      id={id}
      control={control}
      name={name}
      options={options}
      placeholder="All"
      isSearchable={false}
      customStyles={searchBarSelectStyles}
      onSelectChange={onSelectChange}
    />
  );
};

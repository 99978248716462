import { ManagementOperator } from 'models/Management/Operators/Operator';
import { Col, Row } from 'react-bootstrap';
import { Card } from 'components/ui/Card';
import { Icon } from 'components/ui/Icon';
import { OperatorAccountStatus } from './blocks/status/OperatorAccountStatus';
import { OperatorRegistered } from './blocks/registered/OperatorRegistered';
import { OperatorPersonalInfo } from './blocks/personalInfo/OperatorPersonalInfo';
import { OperatorAdditionalInfo } from './blocks/additionalInfo/OperatorAdditionalInfo';
import cn from 'classnames';
import styles from './operator-info.module.scss';

type Props = {
  operator: ManagementOperator;
  onChangeStatus: (status: string) => void;
};

export const OperatorInfo = ({ operator, onChangeStatus }: Props) => {
  return (
    <Row className="row-gap-4">
      <Col xl={2} lg={3} md={4} xs={12}>
        <Row className={cn(styles.meta, 'row-gap-4')}>
          <Col md={12} xs={6}>
            <Card className={cn(styles.card, styles.accountStatus)} title="Account status">
              <OperatorAccountStatus operator={operator} onChangeStatus={onChangeStatus} />
            </Card>
          </Col>
          <Col md={12} xs={6}>
            <Card className={cn(styles.card, styles.registered)} title="Registered">
              <OperatorRegistered date={operator?.created_at} />
            </Card>
          </Col>
        </Row>
      </Col>

      <Col xl={6} lg={6} md={8} xs={12}>
        <Card
          className={cn(styles.card, styles.personalInfo)}
          title="Personal information"
          titleClassName={styles.personalInfoTitle}
          iconTitle={<Icon name="personalInfo" color="var(--main-color)" />}
        >
          <OperatorPersonalInfo operator={operator} />
        </Card>
      </Col>
      <Col xl={4} lg={3} md={12} xs={12}>
        <Card className={cn(styles.card, styles.additionalInfo)} title="Additional information">
          <OperatorAdditionalInfo operator={operator} />
        </Card>
      </Col>
    </Row>
  );
};

import DepartmentItem from './DepartmentItem';
import { Col, Row } from 'react-bootstrap';
import Button from 'components/ui/Button';
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './add-departent.module.scss';
import AddDepartmentForm from './AddDepartmentForm';
import {
  getDepartmentsListMethod,
  getTotalDepartmentsTypesListMethod,
} from 'api/managements/operators';
import { deleteRoleMethod } from 'api/operator';
import { notify } from 'utils/notify';
import { usePermission } from 'utils/usePermission';
import { ListValue } from 'models/ListValue';
import { ManagementOperator } from 'models/Management/Operators/Operator';
import { RoleWithDepartment } from 'models/RolesAndPermissions/Department';

type Props = {
  operatorData: ManagementOperator;
  list: RoleWithDepartment[];
  updateComponent: () => void;
};

const cx = classNames.bind(styles);

const AddDepartmentList = (props: Props) => {
  const { operatorData, list, updateComponent } = props;

  const { permissionGiven: PermissionAddRoleToOperator } = usePermission(
    'admin.admin-user.role-add',
  );

  const [departmentsList, setDepartmentsList] = useState<ListValue[]>([]);
  const [openForm, setOpenForm] = useState(false);

  const fetchDepartmentsList = () => {
    getTotalDepartmentsTypesListMethod().then((res) => {
      if (res.status === 200) {
        getDepartmentsListMethod(`page=1&per_page=${res.data.meta.total}`).then((res) => {
          if (res.status === 200) {
            const opt = res.data.data.map((opt) => ({
              value: opt.id,
              label: opt.name,
            }));
            setDepartmentsList(opt);
          }
        });
      }
    });
  };

  const openDepartmentForm = () => {
    setOpenForm(true);
    fetchDepartmentsList();
  };

  const onDeleteDepartment = (data) => {
    const sendData = {
      department_id: data?.department.id,
      role: data?.name,
      admin_user_id: operatorData.id,
    };
    deleteRoleMethod(sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  return (
    <div className={cx('listBlock')}>
      <div className={cx('title')}>Departments</div>
      {list.map((item) => {
        return (
          <div key={item.id}>
            <DepartmentItem item={item} onDeleteDepartment={onDeleteDepartment} />
          </div>
        );
      })}
      {openForm ? (
        <AddDepartmentForm
          departmentsList={departmentsList}
          updateComponent={updateComponent}
          setOpenForm={setOpenForm}
        />
      ) : PermissionAddRoleToOperator ? (
        <Button
          className="my-3"
          buttonType="outline"
          buttonText="+ Add Department"
          onClick={openDepartmentForm}
        />
      ) : null}
    </div>
  );
};
export default AddDepartmentList;

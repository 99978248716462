import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { MultiSelect } from 'components/ui/MultiSelect';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

type Props = {
  countries: any[];
  statuses: any[];
};

export const PartnersPageFields = ({ countries, statuses }: Props) => {
  const { control } = useFormContext();

  return (
    <Row>
      {countries && (
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect
            label={'Country'}
            control={control}
            id="country_ids"
            name="country_ids"
            options={countries}
          />
        </Col>
      )}
      {statuses && (
        <Col xl={3} lg={4} md={6} className="mb-3">
          <JokerSelect
            label={'Status'}
            control={control}
            id="statuses"
            name="statuses"
            options={statuses}
          />
        </Col>
      )}
      <Col xl={3} lg={4} md={6} className="mb-3">
        <DatePickerRange
          name="created_at"
          control={control}
          label={'Registration date range'}
          placeholder="From - To"
        />
      </Col>
    </Row>
  );
};

import { CryptoTransaction } from 'models/CustomerService/CryptoTransaction';
import { getTransactionPageInfoTableData } from './utils';
import cn from 'classnames';
import styles from './transaction-page-info.module.scss';

type Props = {
  transaction: CryptoTransaction;
};

export const TransactionPageInfo = ({ transaction }: Props) => {
  const tableData = getTransactionPageInfoTableData({ transaction });

  return (
    <div>
      <div className={styles.title}>Transaction information</div>
      <table>
        <tbody>
          {tableData.map(
            (item, index) =>
              !!item.value && (
                <tr key={index}>
                  <td className={styles.tableTitle}>{item.title}</td>
                  <td
                    className={cn(styles.tableValue, item?.onClick && styles.tableValue__clickable)}
                    onClick={item?.onClick}
                  >
                    {item.value}
                  </td>
                </tr>
              ),
          )}
        </tbody>
      </table>
    </div>
  );
};

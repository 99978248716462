import { getOfficesList } from '../../api/office';
import { getDeskList } from '../../api/desk';
import {
  getDepartmentRolesMethod,
  getDepartmentsListMethod,
  getUserTypesListMethod,
} from '../../api/managements/operators';
import {
  getBranchListMethodWithParams,
  operatorsListPaginateForBulkActions, operatorsListPaginateForBulkActionsByDesk, operatorsListSalesRules,
  operatorsListWithSearch,
} from '../../api/operators';
import { getColorfulLabel } from '../../pages/Management/Operators/Operator/BranchesBlock/utils';
import { getPartnersList } from '../../api/partners';
import { getTemplatesListMethod } from '../../api/templateRoles';
import { getLanguagesList } from 'api/lang';
import { getTeamsMethod } from '../../api/team';
import { getTradingAccPaginatedList } from '../../api/tradingAcc';
import { findClientsList } from '../../api/clients';

const fetchOffices = async (page, query) => {
  if (query) {
    if (query.length > 2) {
      return await getOfficesList(`per_page=50&page=${page}`, query.length > 0 && query).then(
        (res) => {
          const opt = res.data.data.map((opt) => ({
            value: opt.id,
            label: opt.name,
          }));
          return { data: opt, total: res.data.meta.last_page };
        },
      )
        .catch(console.log);
    } else return;
  }

  return await getOfficesList(`per_page=50&page=${page}`).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchDesks = async (page, query, office_id) => {
  let filter = {
    filter: office_id && { office_ids: [office_id] },
  };
  return await getDeskList(`per_page=50&page=${page}`, query, office_id && filter).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page, fullData: res };
  })
    .catch(console.log);
};
const fetchDesksNoPagination = async (page, query, office_id) => {
  let filter = {
    filter: office_id && { office_ids: [office_id] },
  };
  return await getDeskList(`per_page=50000&page=${page}`, query, office_id && filter).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page, fullData: res };
  })
    .catch(console.log);
};
const fetchDesksForType = async (page, query, type) => {
  let filter = {
    filter: type && { types: [type] },
  };
  return await getDeskList(`per_page=50&page=${page}`, query, type && filter).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};

const fetchTeams = async (page, query, office_id) => {
  let filter = {
    filter: office_id && { office_ids: [office_id] },
  };
  return await getTeamsMethod(`per_page=50&page=${page}`, query, office_id && filter).then(
    (res) => {
      const opt = res.data.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));
      return { data: opt, total: res.data.meta.last_page };
    },
  )
    .catch(console.log);
};

const fetchTeamsNoPagination = async (page, query, office_id) => {
  let filter = {
    filter: office_id && { office_ids: [office_id] },
  };
  return await getTeamsMethod(`per_page=50000&page=${page}`, query, office_id && filter).then(
    (res) => {
      const opt = res.data.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));
      return { data: opt, total: res.data.meta.last_page };
    },
  )
    .catch(console.log);
};

const fetchTeamsForDesk = async (page, query, desk_ids) => {
  let filter = {
    filter: desk_ids && { desk_ids: desk_ids.length ? desk_ids : [desk_ids] },
  };
  return await getTeamsMethod(`per_page=100&page=${page}`, query, desk_ids && filter).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchTeamsForDeskNoPagination = async (page, query, desk_ids) => {
  let filter = {
    filter: desk_ids && { desk_ids: desk_ids.length ? desk_ids : [desk_ids] },
  };
  return await getTeamsMethod(`per_page=50000&page=${page}`, query, desk_ids && filter).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchOperatorsForBulkActions = async (bulkType, teams_ids) => {
  let params = (bulkType ? `bulk_type=${bulkType}&` : ``) + `per_page=100`;
  return await operatorsListPaginateForBulkActions(params, teams_ids.length ? teams_ids : [teams_ids]).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: `${opt.first_name} ${opt && opt.last_name !== null ? opt.last_name : ''}`,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};

const fetchOperatorsForBulkActionsNoPagination = async (bulkType, teams_ids) => {
  let params = (bulkType ? `bulk_type=${bulkType}&` : ``) + `per_page=50000`;
  return await operatorsListPaginateForBulkActions(params, teams_ids.length ? teams_ids : [teams_ids]).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: `${opt.first_name} ${opt && opt.last_name !== null ? opt.last_name : ''}`,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};

const fetchOperatorsForBulkActionsByDesk = async (bulkType, desks) => {
  let params = (bulkType ? `bulk_type=${bulkType}&` : ``) + `per_page=100`;
  return await operatorsListPaginateForBulkActionsByDesk(params, desks.length ? desks : [desks]).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: `${opt.first_name} ${opt && opt.last_name !== null ? opt.last_name : ''}`,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchOperatorsForBulkActionsByDeskNoPagination = async (bulkType, desks) => {
  let params = (bulkType ? `bulk_type=${bulkType}&` : ``) + `per_page=50000`;
  return await operatorsListPaginateForBulkActionsByDesk(params, desks.length ? desks : [desks]).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: `${opt.first_name} ${opt && opt.last_name !== null ? opt.last_name : ''}`,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};

const fetchUserTypes = async (page, query) => {
  return await getUserTypesListMethod(`per_page=50&page=${page}`, query).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchDepartments = async (page, query) => {
  return await getDepartmentsListMethod(`per_page=50&page=${page}`, query).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchRoleForDepartmentList = async (id) => {
  return await getDepartmentRolesMethod(id).then((res) => {
    const opt = res.data.data.roles.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: 0 };
  })
    .catch(console.log);
};
const fetchBranchListWithParams = async (page, type, params) => {
  return await getBranchListMethodWithParams(page, type, params).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.branch ? getColorfulLabel(opt) : opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchPartners = async (page, query) => {
  return await getPartnersList(`per_page=50&page=${page}`, query).then((res) => {
    const opt = res.data.data.map(opt => ({
      value: opt.id,
      label: `${opt.first_name} ${opt && opt.last_name !== null ? opt.last_name : ''}`,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};

const fetchOperators = async (page, query, teams_ids) => {
  return await operatorsListWithSearch(`per_page=50&page=${page}`, query).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: `${opt?.first_name} ${opt?.last_name}`,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};

const fetchOperatorsNoPagination = async (page, query, teams_ids) => {
  return await operatorsListWithSearch(`per_page=50000&page=${page}`, query).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: `${opt?.first_name} ${opt?.last_name}`,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};

export const fetchOperatorsSalesRules = async () => {
  return await operatorsListSalesRules(`per_page=1000`)
    .then((res) => {
      const opt = res.data.data.map((opt) => ({
        value: opt.id,
        label: `${opt?.first_name} ${opt?.last_name}`,
      }));
      return { data: opt, total: res.data.meta.last_page };
    })
    .catch(console.log);
};
const fetchTemplates = async (page, query) => {
  return await getTemplatesListMethod(`per_page=50&page=${page}`, query).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt?.name,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchLanguagesPaginate = async (page, search) => {
  return await getLanguagesList(`per_page=200&page=${page}${search && `&query=${search}`}`).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt?.name,
    }));
    const uniqueLang = opt.reduce((accumulator, currentValue) => {
      if (accumulator.every(item => !(item.label === currentValue.label && item.id === currentValue.id))) accumulator.push(currentValue);
      return accumulator;
    }, []);
    return { data: uniqueLang, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchClientsPaginate = async (page, query) => {
  return await findClientsList(`per_page=50&page=${page}`, query).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: `${opt?.user_profile?.first_name} ${opt && opt.user_profile && opt.user_profile.last_name ? opt.user_profile.last_name : ''} [${opt.uuid}]`,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchLanguage = async (page, query) => {
  return await getLanguagesList(`per_page=50&page=${page}`, query).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
const fetchTradingAccount = async (page, query, id) => {
  return await getTradingAccPaginatedList(`per_page=50&page=${page}`, id).then((res) => {
    const opt = res.data.data.map((opt) => ({
      value: opt.trading_account,
      label: opt.name,
      name: opt.name,
      status: opt.status,
      type: opt.type,
      balance: opt.balance,
      trading_account: opt.trading_account,
      amount_type: opt.amount_type,
      credit: opt.credit,
      demo: opt.demo,
    }));
    return { data: opt, total: res.data.meta.last_page };
  })
    .catch(console.log);
};
export {
  fetchOffices,
  fetchDesks,
  fetchDesksNoPagination,
  fetchUserTypes,
  fetchDepartments,
  fetchRoleForDepartmentList,
  fetchBranchListWithParams,
  fetchPartners,
  fetchOperators,
  fetchOperatorsNoPagination,
  fetchTemplates,
  fetchTeams,
  fetchTeamsNoPagination,
  fetchLanguagesPaginate,
  fetchClientsPaginate,
  fetchLanguage,
  fetchTradingAccount,
  fetchTeamsForDesk,
  fetchTeamsForDeskNoPagination,
  fetchOperatorsForBulkActions,
  fetchOperatorsForBulkActionsNoPagination,
  fetchOperatorsForBulkActionsByDesk,
  fetchOperatorsForBulkActionsByDeskNoPagination,
  fetchDesksForType,
};

import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from '../crypto-payments-page.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import { updateWalletToUser } from 'api/customer-service/crypto-payments';
import DatePicker from 'components/ui/DatePicker';
import CheckBox from 'components/ui/CheckBox';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  bindInfo: any;
  onCloseModal: () => void;
};

const EditPinToUserPopup = (props: Props) => {
  const { triggerBtn, updateComponent, bindInfo } = props;

  const [isLoading, setIsLoading] = useState(false);
  // const [storedFormDataToErrorRequest, setStoreFormDataToErrorRequest] = useState([]);
  // const ref = useRef(null);

  const { handleSubmit, getValues, watch, control, setValue, reset, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      time_from: bindInfo.pinned ? null : bindInfo.available_from * 1000,
      time_to: bindInfo.pinned ? null : bindInfo.available_to * 1000,
      pinned: bindInfo.pinned ? true : false,
    },
  });
  const { errors } = formState;

  const pinnedWatch = watch('pinned');

  const closeModal = (closeModal) => {
    reset({
      time_from: bindInfo.pinned ? null : bindInfo.available_from * 1000,
      time_to: bindInfo.pinned ? null : bindInfo.available_to * 1000,
      pinned: bindInfo.pinned ? true : false,
    });
    closeModal();
  };

  const onSubmit = (close, data) => {
    let sendData;
    if (data && data.pinned) {
      sendData = {
        pinned: data.pinned,
      };
    } else {
      // const form = getUTCtime(null, data.time_from);
      // const to = getUTCtime(null, data.time_to);
      const form = data.time_from;
      const to = data.time_to;
      sendData = {
        time_from: Math.round(form / 1000),
        time_to: Math.round(to / 1000),
        pinned: data.pinned,
      };
    }

    setIsLoading(true);
    updateWalletToUser(bindInfo.id, sendData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          notify({
            type: 'success',
            message: 'Update was successful',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Update wallet binding'}
            rightContent={
              <div className={cx('content wallet__pin')}>
                <div className={cx('content-form', 'popupForm', 'pin-to-user')}>
                  <input type="text" autoFocus={true} style={{ display: 'none' }} />
                  {bindInfo ? (
                    <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                      <Row>
                        {!pinnedWatch ? (
                          <>
                            <Col md={6}>
                              <DatePicker
                                name="time_from"
                                control={control}
                                label="Wallet available date"
                                dateFormat="MMMM d, yyyy HH:mm"
                                showTimeInput
                                placeholderText={'Date from'}
                                rules={{ required: ' Field is required' }}
                                errors={errors.time_from}
                              />
                            </Col>
                            <Col md={6}>
                              <DatePicker
                                name="time_to"
                                control={control}
                                label="Wallet available date"
                                dateFormat="MMMM d, yyyy HH:mm"
                                showTimeInput
                                placeholderText={'Date to'}
                                rules={{ required: 'Field is required' }}
                                errors={errors.time_to}
                              />
                            </Col>
                          </>
                        ) : null}
                        <Col md={12}>
                          <CheckBox
                            control={control}
                            name="pinned"
                            type="checkbox"
                            label={'Pin to user'}
                            id="pinned"
                          />
                        </Col>
                      </Row>
                      <div className={cx('content-controls')}>
                        <Row>
                          <Col md={12}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <Button
                                isLoading={isLoading}
                                buttonText={'OK'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  ) : null}
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default EditPinToUserPopup;

import { getCryptoTransactions } from 'api/customer-service/crypto-payments';
import TablePagination from 'components/ui/TablePagination/TablePagination';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import { useEffect, useRef, useState } from 'react';
import { usePermission } from 'utils/usePermission.tsx';
import CryptoTransactionsFilters from './CryptoTransactionsFilters/CryptoTransactionsFilters';
import CryptoTransactionsTable from 'components/ui/Table/CryptoTransactionsTable';
import { CryptoTransaction } from 'models/CustomerService/CryptoTransaction.js';
import { CryptoTransactionFilter } from 'models/CustomerService/CryptoTransactionFilter.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { useCheckAutoRefresh } from 'utils/useCheckAutoRefresh.tsx';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { useCryptoTransactionPage } from './useCryptoTransactionPage';
import { FormProvider } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { toggleScrollToTop } from 'store/slice/tableSlice.ts';
import { useAppDispatch } from 'store';

const status = [
  {
    value: 'accepted',
    label: 'Accepted',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
  {
    value: 'new',
    label: 'New',
  },
];

const title = 'Crypto transactions';

const CryptoTransactionsPage = () => {
  const { permissionGiven: PermissionViewCryptoTransactions } = usePermission(
    'admin.crypto_transactions.index',
  );

  const [transactionList, setTransactionList] = useState<CryptoTransaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState<CryptoTransactionFilter>();
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(15);
  const checkAutoRefresh = useCheckAutoRefresh('Page Crypto transactions');

  const componentMounted = useRef(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (PermissionViewCryptoTransactions) {
      componentMounted.current = true;
      getCryptoTransactionsList();
    }
    return () => {
      componentMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermissionViewCryptoTransactions]);

  usePageTitle(title);

  const getCryptoTransactionsList = (options?, filter?) => {
    setIsLoading(true);

    getCryptoTransactions(getTablePageParams(options, filter))
      .then((res) => {
        if (componentMounted.current) {
          setTransactionList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
        }
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  const getTablePageParams = (pageParams, filter) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    const perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    const params = `${toPage}&${perPageParams}`;

    if (filter) {
      let withFilters = `?${params}`;

      if (filter['status']) {
        withFilters = `${withFilters}&status=${filter['status']}`;
      }

      if (filter['query'] !== '') {
        withFilters = `${withFilters}&query=${filter['query']}`;
      }

      if (filter['searchCategory'] && filter['searchCategory'] !== '') {
        withFilters = `${withFilters}&searchCategory=${filter['searchCategory']}`;
      }

      return withFilters;
    }

    return `?${params}`;
  };

  const { form, table } = useCryptoTransactionPage();

  return (
    <DashboardLayout>
      <Page isStickyTitle title={`${tableMeta?.total || 0} Crypto Transactions`}>
        <FormProvider {...form}>
          <Row>
            <Col xl={3} lg={4} md={6}>
              <JokerSelect
                label="Status"
                control={form.control}
                id="status"
                name="status"
                options={status}
                isSearchable={false}
              />
            </Col>
          </Row>
          <TableLayout
            header={
              <CryptoTransactionsFilters
                onSubmit={(data) => {
                  if (!filters && !data) {
                    return;
                  }
                  if (filters && !data) {
                    getCryptoTransactionsList({ navigate: 'first' });
                    setFilters(null);
                  } else {
                    setFilters(data);
                    getCryptoTransactionsList({ navigate: 'first' }, data);
                  }
                }}
                isLoading={isLoading}
                setDefaultColumnsOrder={table.setDefaultColumnsOrder}
              />
            }
          >
            {PermissionViewCryptoTransactions ? (
              <CryptoTransactionsTable
                data={transactionList}
                perPage={perPageCount}
                showLoader={isLoading}
                columns={table.columns}
                onSaveColumnOrder={table.saveColumnOrder}
                modifyDataHandler={() =>
                  getCryptoTransactionsList({ page: tableMeta?.current_page || 1 }, filters)
                }
                updateData={(pos, status) => {
                  transactionList[pos].status = status;
                  setTransactionList([...transactionList]);
                }}
              />
            ) : null}
          </TableLayout>
          {transactionList?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getCryptoTransactionsList({ perPage: value }, filters);
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getCryptoTransactionsList({ page }, filters);
                dispatch(toggleScrollToTop());
              }}
              hidePageSelect={false}
            />
          ) : null}
        </FormProvider>
      </Page>
    </DashboardLayout>
  );
};

export default CryptoTransactionsPage;

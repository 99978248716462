import API from '../api';

export const getSource = ({ page = 1, perPage = 50, query = '' }) =>
  API.post(
    `/api/v1/search/sources?page=${page}&per_page=${perPage}${query ? `&query=${query}` : ''}`,
  );

export const getReferrals = ({ page = 1, perPage = 50, query = '' }) =>
  API.post(
    `/api/v1/search/referrals?page=${page}&per_page=${perPage}${query ? `&query=${query}` : ''}`,
  );

export const setAffiliationData = (user_id, data) =>
  API.put(`/api/v1/user/${user_id}/change-affiliation-data`, data);

import { ArrowBendUpLeft } from "phosphor-react";
import { setProcessedMessage } from "../../../../store/slice/chat/chatSlice";
import { ChatMessageType, ProcessedMessageMode } from "../../../../models/Chat";
import { useAppDispatch } from "../../../../store";
import { MenuOption } from "./MenuOption";

type ReplyMessageOptionProps = { message: ChatMessageType };

export const ReplyMessageOption = ({ message }: ReplyMessageOptionProps) => {
    const dispatch = useAppDispatch();

    const handleReplyMessage = () => {
        dispatch(setProcessedMessage({ message: message, mode: ProcessedMessageMode.IS_REPLYING }));
    };

    return (
        <MenuOption title='Reply' onClick={handleReplyMessage}>
            <ArrowBendUpLeft size={32} color="var(--main-color)"/>
        </MenuOption>
    )
}
import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES } from 'constants/table';
import { ManagementOperatorsFilter } from 'models/Management/Operators/Filter';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const managementOperatorsDefaultValues = {
  query: '',
  query_category: defaultQueryCategoryOption,
  country_ids: [],
  created_at: '',
  statuses: undefined,
  deskIds: [],
  teamIds: [],
};

export const useOperatorsPage = () => {
  const table = useTableColumnOrder(
    'TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES',
    TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES,
    true,
  );

  const form = useForm<ManagementOperatorsFilter>({
    reValidateMode: 'onChange',
    defaultValues: managementOperatorsDefaultValues,
  });

  return { table, form };
};

import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';

import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import styles from './../client-page.module.scss';
import { notify } from 'utils/notify';
import { clientDynamicStatuses, setStatusToUser } from 'api/clients';
import { Client } from 'models/Clients/Clients';
import { ListValue } from 'models/ListValue';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

const cx = classNames.bind(styles);

type Props = {
  formData: Client;
  updateComponent: () => void;
};

const KycForm = (props: Props) => {
  const { formData, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [kycStatusOpt, setKycStatusOpt] = useState<ListValue[]>([]);
  const componentMounted = useRef(true);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    setStatusToUser(formData.id, data.assigned_status_types.value)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const fetchClientDynamicStatuses = () => {
    clientDynamicStatuses()
      .then((res) => {
        if (componentMounted.current) {
          const kyc_status = res.data.data
            .find((el) => el.key === 'kyc')
            .activeStatuses.map((opt) => ({
              value: opt.id,
              label: opt.translate,
            }));

          setKycStatusOpt(kyc_status);
        }
      })
      .catch((error) => {
        console.log(error);
        notify({
          type: 'error',
          message: error.message,
          timeOut: 3000,
        });
      });
  };

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      reset({
        assigned_status_types:
          formData.assigned_status_types && formData.assigned_status_types.kyc
            ? {
                value: formData.assigned_status_types.kyc.id,
                label: formData.assigned_status_types.kyc.translate,
              }
            : null,
      });
      setIsLoading(false);
    }
  }, [formData]);

  useEffect(() => {
    fetchClientDynamicStatuses();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row>
          <Col md={12}>
            <div className={styles.tabTitle}>
              KYC status
              {isDirty && <Button buttonText="Save changes" />}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4} xs={12} className="mb-3">
            <JokerSelect
              id="assigned_status_types"
              name="assigned_status_types"
              label="Status"
              control={control}
              options={kycStatusOpt}
            />
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default KycForm;

import { langOptLight } from '../lang';

export const PROFILE_STATUS_ENUM = {
  BLOCKED: 'blocked',
  VERIFIED: 'verified',
  NOT_VERIFIED: 'not_verified',
};

export const USER_SELECT_FILTER_DEFAULT_VALUE = [{ value: 'any', label: 'Any' }];

export const PROFILE_STATUS_SELECT_OPTIONS = [
  { value: 'any', label: 'Any' },
  {
    value: 'blocked',
    label: 'Blocked',
  },
  {
    value: 'verified',
    label: 'Verified',
  },
  {
    value: 'not verified',
    label: 'Not Verified',
  },
];

export const ACCOUNT_STATUS_SELECT_OPTIONS = [
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'converted',
    label: 'Converted',
  },
];

export const ACTIVITY_SELECT_OPTIONS = [
  { value: 'any', label: 'Any' },
  {
    label: 'Online',
    value: 'yes',
  },
  {
    label: 'Offline',
    value: 'no',
  },
];

export const REFFERAL_SELECT_OPTIONS = [
  { value: 'any', label: 'Any' },
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const ACQUISITION_SELECT_OPTIONS = [
  { value: 'any', label: 'Any' },
  {
    label: 'Retention',
    value: 'retention',
  },
  {
    label: 'Sales',
    value: 'sales',
  },
  {
    label: 'Not status',
    value: 'not status',
  },
];

export const SALES_STATUS_SELECT_OPTIONS = [
  {
    label: 'Callback',
    value: 'callback',
  },
  {
    label: 'Converted',
    value: 'converted',
  },
  {
    label: 'Depositor',
    value: 'depositor',
  },
  {
    label: 'Do not fall',
    value: 'do not fall',
  },
  {
    label: 'Duplicate',
    value: 'duplicate',
  },
  {
    label: 'Failed deposit',
    value: 'failed deposit',
  },
  {
    label: 'Initial call',
    value: 'initial call',
  },
  {
    label: 'Invalid country',
    value: 'invalid country',
  },
  {
    label: 'Invalid language',
    value: 'invalid language',
  },
  {
    label: 'Never answer',
    value: 'never answer',
  },
  {
    label: 'New',
    value: 'New',
  },
  {
    label: 'No answer',
    value: 'no answer',
  },
  {
    label: 'No interest',
    value: 'no interest',
  },
  {
    label: 'No money',
    value: 'no money',
  },
  {
    label: 'Potential high',
    value: 'potential high',
  },
  {
    label: 'Potential low',
    value: 'potential low',
  },
  {
    label: 'Reassign',
    value: 'reassign',
  },
  {
    label: 'Under 18',
    value: 'under 18',
  },
  {
    label: 'Test',
    value: 'test',
  },
  {
    label: 'Voicemail',
    value: 'voicemail',
  },
];

export const RETENTION_STATUS_SELECT_OPTIONS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Active VIP',
    value: 'active vip',
  },
  {
    label: 'Callback',
    value: 'callback',
  },
  {
    label: 'Depositor',
    value: 'depositor',
  },
  {
    label: 'Do not call',
    value: 'do not call',
  },
  {
    label: 'Deposit with me',
    value: 'deposit with me',
  },
  {
    label: 'Invalid language',
    value: 'invalid language',
  },
  {
    label: 'Never docs',
    value: 'never docs',
  },
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'No answer',
    value: 'no answer',
  },
  {
    label: 'No interest',
    value: 'no interest',
  },
  {
    label: 'No potential',
    value: 'no potential',
  },
  {
    label: 'No trades',
    value: 'no trades',
  },
];

export const ASSIGN_STATUS_SELECT_OPTIONS = [
  { value: 'any', label: 'Any' },
  {
    label: 'Assign',
    value: 'assign',
  },
  {
    label: 'Unassigned',
    value: 'unassigned',
  },
];

export const FIRST_TIME_DEPOS_SELECT_OPTIONS = [
  { value: 'any', label: 'Any' },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Yes',
    value: 'yes',
  },
];

export const NOTES_SELECT_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const WARNING_SELECT_OPTIONS = [
  { value: 'any', label: 'Any' },
  {
    label: 'Cardholder invalid',
    value: 'cardholder invalid',
  },
];

export const KYC_STATUSE_SELECT_OPTIONS = [
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Approved awaiting review',
    value: 'approved awaiting review',
  },
  {
    label: 'Awaiting review',
    value: 'awaiting review',
  },
  {
    label: 'Fake deposit',
    value: 'fake deposit',
  },
  {
    label: 'Flagged not compliant',
    value: 'flagged not compliant',
  },
  {
    label: 'No KYC',
    value: 'no kyc',
  },
  {
    label: 'Partial',
    value: 'partial',
  },
  {
    label: 'Partial KYC can trade',
    value: 'partial kyc can trade',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Prior to refund',
    value: 'prior to refund',
  },
  {
    label: 'Refund',
    value: 'refund',
  },
  {
    label: 'Refunded not compliant',
    value: 'refunded not compliant',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Risk',
    value: 'risk',
  },
];

export const LANG_SELECT_OPTIONS = [{ value: '0', label: 'Other' }, ...langOptLight];

export const FILTERS_STRUCTURE = {
  languageIds: LANG_SELECT_OPTIONS,
  // referral: REFFERAL_SELECT_OPTIONS,
  profile_status: PROFILE_STATUS_SELECT_OPTIONS,
  kyc_statuses: KYC_STATUSE_SELECT_OPTIONS,
  acq_status: ACQUISITION_SELECT_OPTIONS,
  sales_status: SALES_STATUS_SELECT_OPTIONS,
  warning: WARNING_SELECT_OPTIONS,
  first_time_deposit: FIRST_TIME_DEPOS_SELECT_OPTIONS,
  activity: ACTIVITY_SELECT_OPTIONS,
  retention_satus: RETENTION_STATUS_SELECT_OPTIONS,
  assign_satus: ASSIGN_STATUS_SELECT_OPTIONS,
};

export const CURRENCY_OPTIONS = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
];

export const CURRENCY_OPTIONS_ENABLE_MT = [
  {
    value: 'USD',
    label: 'USD',
  },
];

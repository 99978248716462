import API from '../api';

export const partnersList = (params) => API.get(`api/v1/partners?${params}`);
export const getOperatorsTotalListMethod = (params) =>
  API.get(`api/v1/admin-users?per_page=100&page=1`);
export const getOperatorsListMethod = (params) =>
  API.get(`api/v1/admin-users?per_page=${params.per_page}&page=${params.page}`);
export const createPartnersMethod = (data) => API.post(`/api/v1/partners`, data);
export const getPartnerMethod = (id) => API.get(`/api/v1/partners/${id}`);
export const changePartnerPassword = (id, data) =>
  API.put(`/api/v1/partners/${id}/change_password`, data);
export const activationPartnerMethod = (id, data) => API.put(`/api/v1/partners/${id}/active`, data);
export const updatePartnerProfileMethod = (id, data) => API.put(`/api/v1/partners/${id}`, data);
export const updateScheduleEvent = (id, data) => API.put(`/api/v1/partner-schedules/${id}`, data);
export const deleteScheduleEvent = (id) => API.delete(`/api/v1/partner-schedules/${id}`);
export const createScheduleEvent = (id, data) => API.post(`/api/v1/partner-schedules`, data);
export const getPartnerFeedList = (params, id) => API.get(`/api/v1/partners/${id}/logs?${params}`);
export const getPartnersList = (params, query?) =>
  API.post(`/api/v1/search/partners?${params}`, query && { query });
export const deletePartner = (id) => API.delete(`/api/v1/partners/${id}`);

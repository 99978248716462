import { RefObject } from "react";
import { ContextMenu } from "../../../../components/ui/ContextMenu/ContextMenu";
import { ChatMessageType, MessageTypesVerbose, UserTypes } from "../../../../models/Chat";
import { selectChatUserId, selectThread } from "../../../../store/slice/chat/chatSlice";
import { useAppSelector } from "../../../../store";
import { ReplyMessageOption } from "../../components/menuOptions/ReplyMessageOption";
import { CopyTextOption } from "../../components/menuOptions/CopyTextOption";
import { EditMessageOption } from "../../components/menuOptions/EditMessageOption";
import { DeleteMessageOption } from "../../components/menuOptions/DeleteMessageOption";
import { ViewProfileOption } from "../../components/menuOptions/ViewProfileOption";

type MessageContextMenuProps = {
    message: ChatMessageType;
    menuRef: RefObject<HTMLDivElement>;
    isToggled: boolean;
    points: { x: number, y: number };
};

export const MessageContextMenu = ({ message, menuRef, isToggled, points }: MessageContextMenuProps) => {
    const userId = useAppSelector(selectChatUserId);
    const thread = useAppSelector(selectThread);

    const selectedOwnMessage = message?.owner_id === userId;

    return (
        <>
            <ContextMenu
                contextMenuRef={menuRef}
                isToggled={isToggled}
                positionX={points.x}
                positionY={points.y}
                className='message-menu'
            >
                {message?.owner?.base?.type === UserTypes.CLIENTS ? (
                    <ViewProfileOption url={`/client/${message?.owner?.base?.out_user_id}`}/>
                ) : null}
                <ReplyMessageOption message={message}/>
                <CopyTextOption text={message?.body}/>
                {(selectedOwnMessage && !thread.is_support) ? (
                    <>
                        {message.type_verbose === MessageTypesVerbose.MESSAGE ?
                            <EditMessageOption message={message}/> : null
                        }
                        <DeleteMessageOption id={message?.id} threadId={thread.id}/>
                    </>
                ) : null}
            </ContextMenu>
        </>
    )
}
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import {
  changeCryptoTransactionStatus,
  changeWebHookStatus,
} from 'api/customer-service/crypto-payments';
import { getCryptoExchangeRate } from 'api/customer-service/request';
import {
  CryptoTransaction,
  NestedCryptoTransaction,
} from 'models/CustomerService/CryptoTransaction';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { CryptoTransactionPopupInfo } from './CryptoTransactionPopupInfo/CryptoTransactionPopupInfo';
import styles from '../crypto-transactions-page.module.scss';

const cx = classNames.bind(styles);

const statusOptions = [
  {
    value: 'accepted',
    label: 'Accepted',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'internal',
    label: 'Internal',
  },
];

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: (params?: any) => void;

  webHookId: number;

  info: NestedCryptoTransaction;
  transactionData: CryptoTransaction;

  cryptoFullType: string;
};

const CryptoTransactionsPopups = (props: Props) => {
  const { triggerBtn, updateComponent, info, webHookId, cryptoFullType, transactionData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [webHookStatus, setWebHookStatus] = useState(null);
  const [cryptoRate, setCryptoRate] = useState(null);
  const [usdAmount, setUsdAmount] = useState(null);

  const acceptedAmount = transactionData && transactionData.amount ? transactionData.amount : '0';

  const {
    handleSubmit,
    control,
    reset,
    // watch,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      deposit_transaction_id: undefined,
      web_hook_status: undefined,
      usd_accepted_amount: undefined,
      accept_crypto_rate: undefined,
    },
  });

  // const transactionStatus = watch('web_hook_status');

  const closeModal = (close) => {
    reset({
      deposit_transaction_id: undefined,
      web_hook_status: undefined,
      usd_accepted_amount: null,
      accept_crypto_rate: null,
    });
    close();
  };

  let data;
  if (info) {
    data = {
      id: info?.deposit_transaction_id,
      user: {
        mail: info?.transaction?.user?.email,
        name: info?.transaction?.user?.user_profile?.first_name,
        lname: info?.transaction?.user?.user_profile?.last_name,
      },
      account: info?.transaction?.trading_account_id,
      amount: info?.transaction?.amount,
      amount_type: info?.transaction?.amount_type,
      amount_usd: info?.transaction?.amount_usd,
      type: info?.transaction?.type,
      crypto_amount: info?.crypto_amount,
      crypto_type: info?.crypto_type,
      created_at: info?.transaction?.created_at,
    };
  }

  const onSubmit = (close, formData) => {
    if (info) {
      const postData = {
        deposit_transaction_id: data.id,
        web_hook_status: formData['web_hook_status'].value,
        usd_accepted_amount: formData['usd_accepted_amount'],
        accept_crypto_rate: formData['accept_crypto_rate'],
      };
      changeWebHookAndInternalTransactionStatus(postData, close);
    } else {
      const postData = {
        web_hook_status: formData['web_hook_status'].value,
        usd_accepted_amount: formData['usd_accepted_amount'],
        accept_crypto_rate: formData['accept_crypto_rate'],
      };
      changeOnlyWebHookStatus(postData, close);
    }
  };

  const changeOnlyWebHookStatus = (postData, close) => {
    setIsLoading(true);

    changeWebHookStatus(webHookId, postData)
      .then(() => {
        updateComponent();
        notify({
          type: 'success',
          message: 'Status changed success',
          timeOut: 3000,
        });
        closeModal(close);
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const changeWebHookAndInternalTransactionStatus = (postData, close) => {
    setIsLoading(true);

    changeCryptoTransactionStatus(webHookId, postData)
      .then(() => {
        updateComponent();
        notify({
          type: 'success',
          message: 'Status changed success',
          timeOut: 3000,
        });
        closeModal(close);
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const fetchCryptoRate = () => {
    if (cryptoFullType) {
      getCryptoExchangeRate(cryptoFullType).then((res) => {
        if (res && res.status === 200) {
          setCryptoRate(res.data.data.rateUsd);
          setUsdAmount(parseFloat(res.data.data.rateUsd) * parseFloat(acceptedAmount));
        }
      });
    }
  };

  const matchCount = (type, value) => {
    if (type === 'rate') {
      setCryptoRate(value);
      setUsdAmount(value * parseFloat(acceptedAmount));
    }
    if (type === 'amount_usd') {
      setUsdAmount(value);
      setCryptoRate(value / parseFloat(acceptedAmount));
    }
  };

  useEffect(() => {
    if (webHookStatus?.value === 'accepted') {
      fetchCryptoRate();
    }
  }, [webHookStatus]);

  useEffect(() => {
    reset({
      ...getValues(),
      usd_accepted_amount: usdAmount,
      accept_crypto_rate: cryptoRate,
    });
  }, [cryptoRate, usdAmount]);

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            size="md"
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Change transaction status'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm', 'crypto-transaction')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        {!data && (
                          <Col md={12}>
                            <span className={cx('content-form__title')}>
                              Change crypto transaction status
                            </span>
                          </Col>
                        )}
                        {data && (
                          <Col md={12}>
                            <CryptoTransactionPopupInfo data={data} transaction={transactionData} />
                          </Col>
                        )}

                        <Col md={12}>
                          <JokerSelect
                            id="web_hook_status"
                            name="web_hook_status"
                            label="Status"
                            isRequired
                            control={control}
                            isSearchable={false}
                            options={statusOptions}
                            errors={errors.web_hook_status}
                            onSelectChange={(value) => {
                              setWebHookStatus(value);
                            }}
                            rules={{
                              required: 'Field is required',
                            }}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText="Cancel"
                          buttonType="outline"
                          type="button"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          isLoading={isLoading}
                          buttonText={'Apply change'}
                          type="submit"
                          onClick={(data) => handleSubmit(onSubmit.bind(undefined, close))}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default CryptoTransactionsPopups;

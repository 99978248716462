import { Client } from 'models/Clients/Clients';
import cn from 'classnames';

import styles from './client-retention.module.scss';

type Props = {
  client: Client;
  onClick: () => null;
};

export const ClientRetention = ({ client, onClick }: Props) => {
  return (
    <div
      style={{ border: `1px solid ${client?.color_status || 'var(--main-color)'}` }}
      className={cn(
        styles.container,
        client?.assigned_status_types?.hasOwnProperty('retention') && styles.active,
      )}
      onClick={onClick}
    >
      <div className={styles.title}>
        <span>Retention: </span>
        <span style={{ color: `${client?.color_status || 'var(--main-color)'}` }}>
          {client?.status_name || 'None'}
        </span>
      </div>
      <div className={styles.info}>
        <div>
          <span>
            {client?.retention?.operator?.full_name
              ? client?.retention?.operator?.full_name
              : '---'}
          </span>
        </div>
        <div>
          <span>Desk:</span>
          {client?.retention?.team?.desk?.name ? client?.retention?.team?.desk?.name : '---'}
        </div>
        <div>
          <span>Team:</span> {client?.retention?.team?.name ? client?.retention?.team?.name : '---'}
        </div>
      </div>
    </div>
  );
};

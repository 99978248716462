import { deleteImage } from "../api/settingsLK";
import { notify } from "../utils/notify";

export const removeImage = async (type: string): Promise<boolean> => {
  const postData = { key: type };
  try {
    const res = await deleteImage(postData);
    if (res.status === 200 && res.data.success) {
      notify({
        type: 'success',
        message: `${type === 'logo' ? 'Logo' : 'Image'} removed successfully`,
        timeOut: 3000,
      });
      return true
    }
  } catch (e) {
    notify({
      type: 'error',
      message: `${type === 'logo' ? 'Logo' : 'Image'} not removed`,
      timeOut: 3000,
    });
    return false
  }
}

import { useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { setChatOpened, setThreadId } from "../../../store/slice/chat/chatSlice";

export const useChatOpen = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setChatOpened(true));

        return () => {
            dispatch(setChatOpened(false));
            dispatch(setThreadId(null));
        }
    },[]);
};
import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { copyToBuffer } from 'utils/copyToBuffer';
import moment from 'moment';
import CryptoTransactionsPopups from 'pages/CustomerService/CryptoTransactions/CryptoTransactionsPopups/CryptoTransactionsPopups';
import { getShortUuid } from 'utils/getShortUuid';
import { ICryptoInnerTransactionsTableProps } from 'models/components/Table/CryptoInnerTransactionsTable/CryptoInnerTransactionsTable.ts';
import { upperFirst } from 'lodash';
import styles from './crypto-transactions-table.module.scss';
import { Icon } from 'components/ui/Icon';

const cx = classNames.bind(styles);

const CryptoInnerTransactionsTable: FC<ICryptoInnerTransactionsTableProps> = ({
  data,
  cryptoType,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  mainWebHookId,
  cryptoFullType,
  transactionData,
  permission,
  columns,
  onSaveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, crypto_amount, created_at, exchange_rate, status, transaction } = item;
    const user = transaction?.user;
    const userProfile = user?.user_profile;

    return {
      transaction_id: (
        <div className={cx('transaction__id')}>
          <span className={cx('copy_id')} onClick={() => copyToBuffer(transaction?.id, true)}>
            {getShortUuid(transaction?.id)}
          </span>
        </div>
      ),
      crypto_transaction: (
        <div className={cx('transaction__desc')}>
          <div className={cx('transaction__desc-amount')}>
            <span>Crypto amount:</span>
            <span>
              {crypto_amount} {cryptoType}
            </span>
          </div>
          <div className={cx('transaction__desc-amount')}>
            <span>Exchange rate:</span>
            <span>{exchange_rate}</span>
          </div>
        </div>
      ),
      inner_transaction: (
        <div className={cx('transaction__inner')}>
          <div className={cx('transaction__inner-amount')}>
            <span>Trading account:</span>
            <span
              className={cx('transaction__user-id', 'copy_id')}
              onClick={() => copyToBuffer(user.uuid, true)}
            >
              {transaction?.trading_account_id}
            </span>
          </div>
          <div className={cx('transaction__inner-type')}>
            <span>Type:</span>
            <span className={cx(transaction?.type)}>{upperFirst(transaction?.type)}</span>
          </div>
          <div className={cx('transaction__inner-method')}>
            <span>Method:</span>
            <span className={cx(transaction?.method)}>{transaction?.method}</span>
          </div>
          <div className={cx('transaction__inner-amount')}>
            <span>Amount:</span>
            <span>
              {transaction?.amount} {transaction?.amount_type}
            </span>
          </div>
          <div className={cx('transaction__inner-status')}>
            <span>Status:</span>
            <span className={cx(transaction?.status)}>{upperFirst(transaction?.status)}&nbsp;</span>
            <span>
              at &nbsp;
              {moment.unix(transaction?.status_created_date).format('DD.MM.YYYY HH:mm')}
            </span>
          </div>
        </div>
      ),
      user: (
        <div className={cx('transaction__user')}>
          <span className={cx('transaction__user-name')}>
            {userProfile?.first_name} {userProfile?.last_name}
          </span>

          <span
            className={cx('transaction__user-id', 'copy_id')}
            onClick={() => copyToBuffer(user?.uuid, true)}
          >
            {getShortUuid(user?.uuid)}
          </span>
          <span>Balance: {user?.balance}</span>
        </div>
      ),
      created: (
        <div className={cx('transaction__created')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      status: (
        <div className={cx('transaction__pinned')}>
          <span className={cx(status)}>{upperFirst(status)}</span>
          <span className={cx('edit-status')}>
            {item && permission && !item?.transaction?.fintegrity ? (
              <CryptoTransactionsPopups
                triggerBtn={<Icon name="notePencil" size={18} color="var(--main-color)" />}
                info={item}
                webHookId={mainWebHookId}
                updateComponent={(params) => modifyDataHandler?.(params)}
                // cryptoType={cryptoType}
                cryptoFullType={cryptoFullType}
                transactionData={transactionData}
              />
            ) : null}
          </span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('transaction-table')}
      isSelected={false}
      saveColumnOrder={onSaveColumnOrder}
      getCellProps={(cellInfo) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default CryptoInnerTransactionsTable;

import { components } from "react-select";

export const Option = (props) => {
    const { data } = props;

    if (!data) {
        return null;
    }

    return (
        <components.Option {...props}>
            <div className='select-color-option'>
                <span className='select-color-option-indicator' style={{ background: data.value }}/>
                {props.children}
            </div>
        </components.Option>
    );
};
import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
    addNewMessage,
    chatAuth,
    chatInit,
    editMessage,
    incomingThread,
    addThread,
    readThread,
    removeMessage,
    removeThread,
    selectChatToken,
    selectChatUserId,
    selectMutedThreads,
    selectThreadId,
    setChatToken,
    setMutedThreads,
    setThreadId,
    updateChatThreadSettings
} from "./chatSlice";
import { playNotificationSound } from "../../../pages/Chat/helpers/playNotificationSound";
import { getThreads } from "./thunks";
import { createChatConnection } from "../../../api/chat/socket";
import { isChatEnabled } from "../../../pages/Chat/helpers/isChatEnabled";
import { logout } from "../authSlice";
import { refreshTokenInterval } from "../../../pages/Chat/constants";
import { chatGetToken, chatLogin, getChatThread } from "../../../api/chat";
import { notify } from "../../../utils/notify";

const ChatListenerMiddleware = createListenerMiddleware();

ChatListenerMiddleware.startListening({
    actionCreator: chatInit,
    effect: async (action, { dispatch, getState }) => {
        if (isChatEnabled()) {
            const storedToken = localStorage.getItem('chat-token');
            const token = selectChatToken(getState());

            if (storedToken) {
                storedToken !== token && dispatch(setChatToken(storedToken));
            } else {
                dispatch(chatAuth());
            }
        }
    },
});

ChatListenerMiddleware.startListening({
    actionCreator: chatAuth,
    effect: async (action, { dispatch, unsubscribe, subscribe }) => {
        try {
            unsubscribe();
            const response = await chatGetToken();
            const chatToken = await chatLogin(response.data.token);

            localStorage.setItem('chat-token', chatToken.data.token);

            dispatch(setChatToken(chatToken.data.token));
            subscribe();
        } catch (error) {
            notify({ type: 'error', message: 'Failed to authenticate to the chat.'})
        }
    },
});

ChatListenerMiddleware.startListening({
    actionCreator: setChatToken,
    effect: async (action, { getState, dispatch, delay, cancelActiveListeners }) => {
        cancelActiveListeners();

        const state = getState();
        const token = action.payload;
        const { privateChannel, presenceChannel } = getChannelNames(state);
        
        removeChatSubscriptions(privateChannel, presenceChannel);
        
        if (token) {
            createChatConnection(token, dispatch);
            initPrivateSubscriptions(dispatch, privateChannel);
            initPresenceSubscriptions(dispatch, presenceChannel, selectThreadId(state));

            await delay(refreshTokenInterval);
            dispatch(chatAuth());
        } else {
            dispatch(chatAuth());
        }
    },
});

ChatListenerMiddleware.startListening({
    predicate: (action, currentState, previousState) => {
        if (action.type === 'chat/user/fulfilled') {
            return !previousState.chat.userInfo;
        }

        return false
    },
    effect: async (action, { getState, dispatch }) => {
        const state = getState();
        const { privateChannel } = getChannelNames(state);

        initChatThreads(state, dispatch);
        initPrivateSubscriptions(dispatch, privateChannel);
    }
});

ChatListenerMiddleware.startListening({
    actionCreator: setThreadId,
    effect: async (action, { dispatch, condition }) => {
        if (action.payload) {
            initPresenceSubscriptions(dispatch, `messenger.thread.${action.payload}`, action.payload);
        }

        let channels;
        if (await condition((action, currentState, previousState) => {
            if (!!previousState.chat.threadId) {
                channels = getChannelNames(previousState);
            }
            return action.type === 'chat/setThreadId' && !!previousState.chat.threadId
        })) {
            const { presenceChannel } = channels;
            window.ChatEcho.leave(presenceChannel);
        }
    }
});

ChatListenerMiddleware.startListening({
    actionCreator: logout.pending,
    effect: async (action, listenerApi) => {
        if (isChatEnabled()) {
            const { privateChannel, presenceChannel } = getChannelNames(listenerApi.getState());
            removeChatSubscriptions(privateChannel, presenceChannel);
        }
    }
});

ChatListenerMiddleware.startListening({
    actionCreator: addNewMessage,
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState();
        const userId = selectChatUserId(state);
        const mutedThreads = selectMutedThreads(state);

        if (action.payload.owner_id !== userId && !mutedThreads[action.payload.thread_id]) {
            playNotificationSound();
        }
    },
});

ChatListenerMiddleware.startListening({
    actionCreator: incomingThread,
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        try {
            const response = await getChatThread(action.payload);
            playNotificationSound();

            listenerApi.dispatch(addThread(response.data));
        } catch (error) {
            console.error(error.response.data.message);
        }
    },
});

const initPrivateSubscriptions = (dispatch, privateChannel) => {
    if (privateChannel) {
        window.ChatEcho.private(privateChannel)
            .listen('.new.message', (message) => { dispatch(addNewMessage(message)) })
            .listen('.new.thread', (response) => { dispatch(incomingThread(response.thread.id)) })
            .listen('.message.archived', (response) => { dispatch(removeMessage(response)) })
            .listen('.thread.read', (response) => { dispatch(readThread(response)) })
            .listen('.thread.archived', (response) => { dispatch(removeThread(response)) });
    }
};

const initPresenceSubscriptions = (dispatch, presenceChannel, threadId) => {
    if (presenceChannel) {
        window.ChatEcho.join(presenceChannel)
          .listen('.message.edited', (response) => { dispatch(editMessage(response)) })
          .listen('.thread.settings', (response) => {
              dispatch(updateChatThreadSettings({ id: threadId, settings: response }))
          });
    }
};

const removeChatSubscriptions = (privateChannel, presenceChannel) => {
    privateChannel && window.ChatEcho.leave(privateChannel);
    presenceChannel && window.ChatEcho.leave(presenceChannel);
};

const initChatThreads = (state, dispatch) => {
    if (!state.chat.opened && !state.chat.threads.length) {
        dispatch(getThreads());
    }

    if (!state.chat.mutedThreads.length) {
        const mutedThreadsJSON = localStorage.getItem('chat-muted-threads');
        if (mutedThreadsJSON) {
            dispatch(setMutedThreads(JSON.parse(mutedThreadsJSON)));
        }
    }
};

const getChannelNames = (state) => {
    const userId = selectChatUserId(state);
    const threadId = selectThreadId(state);

    return {
        privateChannel: userId ? `messenger.user.${userId}` : null,
        presenceChannel: threadId ? `messenger.thread.${threadId}` : null
    }
};

export default ChatListenerMiddleware;
import React, { useState, useEffect } from 'react';
import styles from './../../client-page.module.scss';

import Button from 'components/ui/Button';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import {
  removeCallbackItem,
  disabledCallbackItem,
  findCallbacksMethod,
  getUserCalbecksList,
} from 'api/callbaks';
import CallbacksTable from 'components/ui/Table/CallbacksTable';
import TablePagination from 'components/ui/TablePagination';
import AddCallbackPopup from './AddCallbackPopup';
import CallbacksFilter from './CallbacksFilter';
import { usePermission } from 'utils/usePermission';
import { getTablePageParams } from 'utils/getTablePageParams';
import { TableMeta, TableLinks } from 'models/Table';
import { CallbackEditData } from 'models/Clients/ClientCallback';
import { CallbackFilter } from 'models/Callbacks/CallbackFilter';
import { useCallbacks } from './useCallbacks';
import { FormProvider } from 'react-hook-form';
import { CallbacksFields } from './CallbacksFields/CallbackFields';
import { TableLayout } from 'components/ui/Table/TableLayout';

const Callbacks = () => {
  const { permissionGiven: PermissionCreateCallback } = usePermission('admin.callback.create');

  const [callbacksList, setCallbacksList] = useState<CallbackEditData[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [filters, setFilters] = useState<CallbackFilter>(null);
  const [tableMeta, setTableMeta] = useState<TableMeta | undefined>(undefined);
  const [tableLinks, setTableLinks] = useState<TableLinks | undefined>(undefined);
  const [perPageCount, setPerPageCount] = useState(10);
  const { id } = useParams();

  useEffect(() => {
    getCallbacks();
  }, []);

  const deleteCallbackItem = (id) => {
    setIsListLoading(true);
    removeCallbackItem(id)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          setTimeout(() => getCallbacks({ navigate: 'first' }, filters), 2000);
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsListLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  const changeStatusAction = (id, status) => {
    setIsListLoading(true);
    disabledCallbackItem(id, status)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          setTimeout(() => getCallbacks({ navigate: 'first' }, filters), 2000);
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsListLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  const getUserCallbacksList = () => {
    setIsListLoading(true);

    getUserCalbecksList(id)
      .then((res) => {
        if (res.status === 200) {
          setCallbacksList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const getCallbacks = (options?, data?) => {
    setIsListLoading(true);

    findCallbacksMethod(getTablePageParams(options, perPageCount, tableLinks), data || filters, id)
      .then((res) => {
        if (res.status === 200) {
          setCallbacksList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const { form, table } = useCallbacks();

  return (
    <>
      <div className={styles.tabTitle}>
        Callbacks
        {PermissionCreateCallback ? (
          <AddCallbackPopup
            updateComponent={() => {
              getUserCallbacksList();
            }}
            userId={id}
            triggerBtn={<Button buttonText="+ Add callback" buttonType="outline" onClick={null} />}
          />
        ) : null}
      </div>
      <FormProvider {...form}>
        <CallbacksFields />
        <TableLayout
          header={
            <CallbacksFilter
              onSubmit={(data: CallbackFilter) => {
                setFilters(data);
                getCallbacks({ navigate: 'first', perPage: data?.filter?.limit }, data);
              }}
              reloadFilters={isListLoading}
              setDefaultColumnsOrder={table.setDefaultColumnsOrder}
            />
          }
        >
          <CallbacksTable
            data={callbacksList}
            perPage={tableMeta?.per_page}
            showLoader={isListLoading}
            userId={id}
            deleteAction={(id) => deleteCallbackItem(id)}
            changeStatusAction={(id, status) => changeStatusAction(id, status)}
            updateComponent={() => getCallbacks()}
            columns={table.columns}
            saveColumnOrder={table.saveColumnOrder}
          />
        </TableLayout>
      </FormProvider>

      {callbacksList?.length ? (
        <TablePagination
          hidePageSelect={true}
          currentPage={tableMeta?.current_page}
          pagesLength={tableMeta?.last_page}
          perPageChange={(value) => {
            setPerPageCount(value);
            getCallbacks({ perPage: value });
          }}
          goToSelectedPage={(page) => {
            getCallbacks({ page });
          }}
        />
      ) : null}
    </>
  );
};

export default Callbacks;

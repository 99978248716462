import { ReactNode, useEffect } from "react";
import { useScrollBlock } from "../useScrollBlock";
import '../JokerModal.scss';

type ModalContainerProps = {
    children: ReactNode;
    disableBodyScroll: boolean;
    onOverlayClick: () => void;
};

export const ModalContainer = ({ disableBodyScroll, onOverlayClick, children }: ModalContainerProps) => {
    const [blockScroll, allowScroll] = useScrollBlock();

    useEffect(() => {
        disableBodyScroll && blockScroll();
        return () => {
            disableBodyScroll && allowScroll();
        }
    }, [disableBodyScroll]);

    return (
        <div className="joker-modal">
            <div className={'joker-modal-container'}>
                {children}
            </div>
            <div className="joker-modal-overlay" onClick={onOverlayClick}/>
        </div>
    )
}
import React, { FC, memo } from 'react'
import classNames from 'classnames/bind'
import styles from '../clients-table.module.scss'
import { Language } from "../../../../../models/Languages.ts";
import { ICountry } from "../../../../../models/Countries.ts";

const cx = classNames.bind(styles)

interface ICountryProps {
  country: ICountry,
  language: Language,
}
const Country: FC<ICountryProps> = ({ country, language }) => {
  return (
    <div className={cx('client__country')}>
      <div className={cx('client__country-flag')}>
        <i
          className={cx('icon')}
          style={{ backgroundImage: `url(${country.flag})` }}/>
      </div>
      <div className={cx('client__country-text')}>
        <span>{country.iso_3166_2}</span>
        <span>{language?.name || <span>&mdash;</span>} </span>
      </div>
    </div>
  )
}

export default memo(Country)

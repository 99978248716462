import Preloader from "../../../../components/ui/Preloader";
import { ChatRecipientForm } from "../../components/ChatMessageForm/ChatRecipientForm";
import { ChatDialogHeader } from "../../components/ChatDialogHeader/ChatDialogHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
    addGroup,
    selectChatRecipient,
    selectChatThreads,
    selectMessagesLoading,
    selectUserStatuses,
    setRecipient
} from "../../../../store/slice/chat/chatSlice";
import { useEffect } from "react";
import { getRecipient } from "../../../../store/slice/chat/thunks";
import { UserTypes } from "../../../../models/Chat";
import { createChatGroup } from "../../../../api/chat";
import './ChatRecipient.scss';

export const ChatRecipient = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const recipient = useAppSelector(selectChatRecipient);
    const threads = useAppSelector(selectChatThreads);
    const userStatuses = useAppSelector(selectUserStatuses);
    const isLoading = useAppSelector(selectMessagesLoading);

    useEffect(() => {
        const existedThread = threads.find(thread => thread.resources?.recipient?.base?.id === id);
        if (existedThread) {
            navigate(`/chat/${existedThread.id}`);
        }
    }, [threads, id]);

    useEffect(() => {
        if (recipient?.base?.type === UserTypes.CLIENTS) {
            createChatGroup({
                subject: `${recipient.base.name} - Support`,
                providers: [{ alias: 'user', id: recipient.base.id }]
            }).then((group) => {
                dispatch(addGroup(group.data));
                navigate(`/chat/${group.data.id}`);
            });
        }
    }, [recipient]);

    useEffect(() => {
        if (!recipient && id) {
            dispatch(getRecipient(id));
        }
        return () => {
            dispatch(setRecipient(null));
        }
    }, []);

    return (
        <div className='recipient-page'>
            {recipient ? (
                <ChatDialogHeader
                    name={recipient.name}
                    type={recipient.base.type}
                    status={userStatuses[recipient.provider_id]}
                    isGroup={false}
                />
            ) : null}
            <div className="recipient-page-body">
                {isLoading ? <Preloader /> : <div className='chat-empty-title'>Write the first message</div>}
            </div>
            <ChatRecipientForm id={id} isLoading={isLoading} />
        </div>
    )
};

import { ListValue } from 'models/ListValue';
import { useFormContext } from 'react-hook-form';
import { fetchOperators, fetchPartners } from 'utils/managment/fetchData';
import { Col, Row } from 'react-bootstrap';
import PaginateSelect from 'components/ui/PaginateSelect/PaginateSelect';
import { MultiSelect } from 'components/ui/MultiSelect';

type Props = {
  countries: ListValue[];
  languages: ListValue[];
};

export const SalesFields = ({ countries, languages }: Props) => {
  const { control } = useFormContext();

  return (
    <Row>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <MultiSelect
          label="Country"
          control={control}
          id="country_ids"
          name="country_ids"
          options={countries}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <MultiSelect
          label="Languages"
          control={control}
          id="language_ids"
          name="language_ids"
          options={languages}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <PaginateSelect
          isMulti={true}
          label="Affiliate"
          control={control}
          id="partner_ids"
          name="partner_ids"
          onChange={(page, search) => fetchPartners(page, search)}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <PaginateSelect
          isMulti={true}
          label="Operators"
          control={control}
          id="operator_ids"
          name="operator_ids"
          onChange={(page, search) => fetchOperators(page, search)}
        />
      </Col>
    </Row>
  );
};

import DragDropUploadImage from 'components/ui/DragDropUploadImage/DragDropUploadImage';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { changeSystemImage, getStyles, selectClientDesign } from 'store/slice/visualSlice';
import { uploadLogoMethod } from 'api/settingsLK';
import { removeImage } from 'helpers/deleteImageForLK';
import { Col, Row } from 'react-bootstrap';
import { ConfirmationModal } from 'components/ui/Modal/components/ConfirmationModal/ConfirmationModal';
import {
  CONFIRM_UPLOAD_IMG_MODAL_KEY,
  REMOVE_IMG_MODAL_KEY,
  SystemStyleUploadImages,
} from 'constants/systemStyles';
import { openJokerModal } from 'store/slice/modalSlice';
import { notify } from 'utils/notify';

export const LogosTab = () => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState(null);
  const images = useAppSelector(selectClientDesign);

  const removeImageConfirmation = (key) => {
    setSelected({ key });
    dispatch(
      openJokerModal({
        key: REMOVE_IMG_MODAL_KEY,
        data: {
          title: 'Remove image',
          description: 'Are you sure you want to remove this image from the system?',
          cancelTitle: 'Return to editing',
          confirmTitle: 'Remove image',
        },
      }),
    );
  };

  const handleRemoveImage = async (key: string) => {
    const isRemoved = await removeImage(key);

    if (isRemoved) {
      dispatch(changeSystemImage({ key, data: null }));
    }
  };

  const onSubmit = async (selectedImage, setPreview?: (preview: any[]) => void) => {
    try {
      const data = new FormData();
      data.append('key', selectedImage?.key);
      data.append('file', selectedImage?.img.file);
      await uploadLogoMethod(data);

      dispatch(getStyles());
      notify({
        type: 'success',
        message: 'Image upload success',
        timeOut: 2000,
      });
    } catch (error) {
      //Rollback image preview on error
      setPreview?.(images?.[selectedImage?.key] ? [images[selectedImage.key]] : []);
      notify({
        type: 'error',
        message: error.response,
        timeOut: 2000,
      });
    } finally {
      setSelected(null);
    }
  };

  const onChangeImage = (img, key, setPreview) => {
    if (img) {
      setSelected({ img, key });

      if (images?.[key]) {
        dispatch(
          openJokerModal({
            key: CONFIRM_UPLOAD_IMG_MODAL_KEY,
            data: {
              title: 'Save image',
              description:
                'You already have one. \n Are you sure you want to remove it and upload a new one?',
              cancelTitle: 'Return to editing',
              confirmTitle: 'Save image',
            },
          }),
        );
      } else {
        onSubmit({ img, key }, setPreview);
      }
    }
  };

  const onTypeError = (error) => {
    if (error?.acceptType) {
      notify({
        type: 'error',
        message: 'Unsupported file type.',
        timeOut: 5000,
      });
    }
  };

  return (
    <div className="logos-tab-panel">
      <Row className="align-items-end">
        {SystemStyleUploadImages.map((item) => (
          <Col xl={3} lg={4} md={6} sm={6} xs={12} key={item.id}>
            <div className="upload-item">
              <div className="title">{item.title}</div>
              <span className="description">{item.description}</span>
              <DragDropUploadImage
                src={images?.[item.id]}
                name={item.id}
                onChange={onChangeImage}
                onError={onTypeError}
                onRemove={handleRemoveImage}
              />
            </div>
          </Col>
        ))}
      </Row>
      <ConfirmationModal id={CONFIRM_UPLOAD_IMG_MODAL_KEY} onConfirm={() => onSubmit(selected)} />
    </div>
  );
};

import styles from '../multi-select.module.scss';

const Blanket = (props) => <div className={styles.blank} {...props} />;

const Menu = (props) => {
  return <div className={styles.menu} {...props} />;
};

export const Dropdown = ({ children, isOpen, target, onClose }) => {
  return (
    <div className={styles.dropdown}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );
};

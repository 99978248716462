import React, { useEffect, useRef, useState } from 'react';
import { findLeads } from 'api/leads/index';
import LeadsTable from 'components/ui/Table/LeadsTable';
import TablePagination from 'components/ui/TablePagination';
import { usePermission } from 'utils/usePermission.tsx';
import Page from '../../Page/Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import LeadFilter from './LeadFilter/LeadFilter';

import { TableMeta, TableLinks } from 'models/Table';
import { Sort } from 'models/Sort';
import { Lead } from 'models/Leads/Lead';
import { LeadsFilter } from 'models/Leads/LeadsFilter';
import { useAppDispatch } from 'store';
import { useCheckAutoRefresh } from 'utils/useCheckAutoRefresh.tsx';
import { usePageTitle } from 'utils/usePageTitle';
import { toggleScrollToTop } from 'store/slice/tableSlice';
import { SearchLeadsPageTitle } from './SearchLeadsPageTitle';
import { useSearchLeadsPage } from './useSearchLeadsPage';
import { FormProvider } from 'react-hook-form';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { SearchLeadsFields } from './Fields/SearchLeadsFields';
import styles from './search-leads-page.module.scss';

const SORTED_FIELD_NAME = {
  country: 'country',
  registration: 'created_at',
  last_note: 'last_note',
};

const title = 'Search Leads';

const SearchLeadsPage = () => {
  const { permissionGiven: PermissionList } = usePermission('admin.lead.index');

  const [leads, setLeads] = useState<Lead[]>([]);
  const dispatch = useAppDispatch();

  const [isListLoading, setIsListLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<LeadsFilter>(null);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const [sort, setSort] = useState<Sort>();

  const componentMounted = useRef(false);
  const checkAutoRefresh = useCheckAutoRefresh('Page Search Leads');

  usePageTitle(title);

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      getLeads();
    }
    return () => {
      componentMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermissionList]);

  // const changeLimits = (val) => {
  //   setPerPageCount(val);
  // };

  const getLeads = (pageParams?, filtersData?, hasReindex = false) => {
    setIsListLoading(true);

    const params = `${getTablePageParams(pageParams)}${hasReindex ? '&reindex=1' : ''}`;

    findLeads(params, filtersData)
      .then((res) => {
        if (componentMounted.current) {
          setLeads(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
        }
      })
      .catch(console.log)
      .finally(() => setIsListLoading(false));
  };

  const getClientToBulkActions = (rows) => {
    const clients = [];
    rows.forEach((item) => {
      clients.push(leads[item['index']]['id']);
    });
    setSelectedRows(clients);
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (sort && filters) {
      return {
        sort,
        ...filters,
      };
    }

    if (sort && !filters) {
      return {
        sort,
      };
    }

    if (!sort && filters) {
      return filters;
    }
  };

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    getLeads({ navigate: 'first', perPage: 10 });
    setFilters(null);
    setSort(null);
  };

  const { form, table } = useSearchLeadsPage();

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={
          <SearchLeadsPageTitle
            total={tableMeta?.total}
            selectedRows={selectedRows}
            updateComponentCallback={getLeads}
          />
        }
      >
        <>
          <FormProvider {...form}>
            <SearchLeadsFields />
            <TableLayout
              header={
                <LeadFilter
                  onSubmit={(filtersData, hasReindex) => {
                    if (!filters && !filtersData) {
                      return;
                    }
                    if (filters && !filtersData) {
                      clearFiltersSortList();
                    } else {
                      setFilters(filtersData);
                      getLeads(
                        { navigate: 'first', perPage: filtersData?.filter?.limit || 10 },
                        filtersData,
                        hasReindex,
                      );
                    }
                  }}
                  isLoading={isListLoading}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              {PermissionList ? (
                <LeadsTable
                  perPage={perPageCount}
                  data={leads}
                  showLoader={isListLoading}
                  onRowSelect={getClientToBulkActions}
                  manualSortHandler={(col) => {
                    const sorted = col.isSorted;
                    const desc = col.isSortedDesc;

                    if (!sorted) {
                      const sort = {
                        field: SORTED_FIELD_NAME[col.id],
                        direct: 'desc',
                      };
                      setSort(sort);
                      getLeads(
                        { navigate: 'first' },
                        {
                          ...filters,
                          sort,
                        },
                      );
                      col.toggleSortBy(true);
                    }

                    if (sorted && desc) {
                      const sort = {
                        field: SORTED_FIELD_NAME[col.id],
                        direct: 'asc',
                      };
                      setSort(sort);
                      getLeads(
                        { navigate: 'first' },
                        {
                          ...filters,
                          sort,
                        },
                      );
                      col.toggleSortBy(false);
                    }

                    if (sorted && !desc) {
                      const data = {
                        ...filters,
                      };
                      setSort(null);
                      getLeads({ navigate: 'first' }, data);
                      col.clearSortBy();
                    }
                  }}
                  modifyDataHandler={(callback) => console.log('a')}
                  columns={table.columns}
                  saveColumnOrder={table.saveColumnOrder}
                />
              ) : null}
            </TableLayout>
          </FormProvider>

          {leads?.length ? (
            <TablePagination
              isRareCase
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getLeads({ perPage: value }, getSortFiltersForPageNavigation());
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getLeads({ page }, getSortFiltersForPageNavigation());
                dispatch(toggleScrollToTop());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default SearchLeadsPage;

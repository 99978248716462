import React, { useEffect, useRef, useState } from 'react';
import Page from '../../Page/Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import SalesFilter from './SalesFilter/SalesFilter';
import { notify } from 'utils/notify.tsx';
import { getLangList } from 'api/lang/index';
import { getCountryList } from 'api/country/index';
import { findRules } from 'api/rules/index';
import SalesRulesTable from 'components/ui/Table/SalesRulesTable';
import TablePagination from 'components/ui/TablePagination';
import { TableLinks, TableMeta } from 'models/Table';
import { ListValue } from 'models/ListValue';
import { SalesRule } from 'models/Management/SalesRules/Rule';
import { SalesRulesFilter } from 'models/Management/SalesRules/Filter';
import { getPartnersList } from 'api/partners/index.ts';
import { SalesRulesPageTitle } from './SalesRulesPageTitle';
import { usePageTitle } from 'utils/usePageTitle';
import { useSalesRulesPage } from './useSalesRulesPage';
import { FormProvider } from 'react-hook-form';
import { TableLayout } from 'components/ui/Table/TableLayout';
import { SalesFields } from './Fields/SalesFields';
import styles from './sales-rules-page.module.scss';

const title = 'Sales Rules';

const SalesRules = () => {
  const [rules, setRules] = useState<SalesRule[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [countryOpt, setCountryOpt] = useState<ListValue[]>([]);
  const [operator, setOperator] = useState([]);
  const [partners, setPartnersList] = useState([]);
  const [langListArr, setLangListArr] = useState<ListValue[]>([]);
  const [filters, setFilters] = useState<SalesRulesFilter>(null);

  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const componentMounted = useRef(false);

  usePageTitle(title);

  useEffect(() => {
    componentMounted.current = true;

    getRules();
    fetchCountryList();
    fetchPartners();
    getLangList().then((res) => {
      const mapped = res.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setLangListArr(mapped);
    });

    return () => {
      componentMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPartners = () => {
    getPartnersList('per_page=500').then((res) => {
      setPartnersList(res.data.data);
    });
  };

  const fetchCountryList = () => {
    getCountryList()
      .then((res) => {
        if (componentMounted.current) {
          const opt = res.data.data.map((opt) => ({
            value: opt.id,
            label: opt.name,
            iso_3166_2: opt.iso_3166_2,
          }));
          setCountryOpt(opt);
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      });
  };

  const getRules = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findRules(getTablePageParams(pageParams), filtersData)
      .then((res) => {
        if (componentMounted.current) {
          setRules(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (filters) {
      return filters;
    }
  };

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    getRules({ navigate: 'first', perPage: 10 });
    setFilters(null);
  };

  const { form, table } = useSalesRulesPage();

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={
          <SalesRulesPageTitle
            total={tableMeta?.total}
            countries={countryOpt}
            languages={langListArr}
            operators={operator}
            partners={partners}
            updateComponentCallback={getRules}
          />
        }
      >
        <>
          <FormProvider {...form}>
            <SalesFields countries={countryOpt} languages={langListArr} />
            <TableLayout
              header={
                <SalesFilter
                  onSubmit={(filtersData) => {
                    if (!filters && !filtersData) {
                      return;
                    }
                    if (filters && !filtersData) {
                      clearFiltersSortList();
                    } else {
                      setFilters(filtersData);
                      getRules(
                        { navigate: 'first', perPage: filtersData?.filter?.limit || 10 },
                        filtersData,
                      );
                    }
                  }}
                  isLoading={isListLoading}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              <SalesRulesTable
                data={rules}
                countryList={countryOpt}
                langList={langListArr}
                operatorsList={operator}
                partnersList={partners}
                perPage={perPageCount}
                showLoader={isListLoading}
                modifyDataHandler={() => getRules()}
                columns={table.columns}
                saveColumnOrder={table.saveColumnOrder}
              />
            </TableLayout>
          </FormProvider>
          {rules?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getRules({ perPage: value }, getSortFiltersForPageNavigation());
              }}
              goToSelectedPage={(page) => {
                getRules({ page }, getSortFiltersForPageNavigation());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default SalesRules;

import { AsyncPaginate } from 'react-select-async-paginate';
import { FC, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import styles from './style.module.scss';
import cn from 'classnames';
import { Tooltip } from 'bootstrap';
import {
  IPaginateSelect,
  IPaginateSelectMain,
  IPaginateSelectOptions,
  IResponsePaginateSelect,
} from '../../../models/components/PaginateSelect/PaginateSelect.ts';
import { Icon } from '../Icon';
import { simpleSelectStyles } from '../SimpleSelect';
import { getMultiSelectStyles } from '../MultiSelect/styles.ts';
import { Spinner } from '../Spinner/Spinner.tsx';
import { ActionMeta, FormatOptionLabelMeta } from 'react-select';

const allOption = [{ label: 'All', value: 'all' }];

const PaginateAllSelect: FC<IPaginateSelect> = (props) => {
  const [selectedAllOption, setSelectedAllOption] = useState(false);
  const [main, setMain] = useState<IPaginateSelectMain | null | undefined>(null);

  const { field } = useController(props);

  useEffect(() => {
    setMain(props?.mainVal);
  }, [props?.mainVal]);

  const handleChange = (value: IPaginateSelectOptions[]) => {
    field.onChange(value);
    props.onSelectChange?.(value);
  };

  const onSelectChange = (values: IPaginateSelectOptions[], meta: ActionMeta<IPaginateSelectOptions>) => {
    const { action = '', option } = meta;

    if (action === 'clear') {
      handleChange([]);
      setSelectedAllOption(false);
    }

    if (action === 'deselect-option') {
      if (option.value === 'all') {
        handleChange([]);
        setSelectedAllOption(false);
      } else {
        handleChange(values);
      }
    }

    if (field.value.some((o) => o.value === 'all')) return;

    if (action === 'select-option') {
      if (option.value === 'all') {
        handleChange(allOption);
        setSelectedAllOption(true);
        return;
      } else {
        handleChange(values);
      }
    }
  };

  useEffect(() => {
    if (!field.value.length) {
      setSelectedAllOption(false);
    }
  }, [field.value]);

  const checkHasMore = (total, page) => {
    if (props.onePage) return false;
    return total > page;
  };

  async function loadOptions(search, loadedOptions, { page }) {
    const response: IResponsePaginateSelect = await props.onChange(page, search, main);

    const options = page === 1 ? allOption.concat(response.data) : response.data;

    return {
      options,
      hasMore: response.total ? checkHasMore(response.total, page) : false,
      additional: { page: page + 1 },
    };
  }

  const getOptionLabel = (data, meta: FormatOptionLabelMeta<IPaginateSelectOptions>) => {
    let isSelected = meta.selectValue.some((o) => o.value === data.value);

    if (selectedAllOption) {
      isSelected = meta.selectValue.some((o) => o.value === allOption[0].value);
    }

    return (
      <div
        className={cn(
          styles.optionWrapper,
          selectedAllOption && data.value !== allOption[0].value && styles.optionWrapperDisabled,
        )}
      >
        <Icon
          className={styles.optionCheckbox}
          name={isSelected ? 'checkedIcon' : 'uncheckedIcon'}
          size={20}
          color="var(--main-color)"
        />
        <span className={styles.optionText}>{data.label}</span>
      </div>
    );
  };

  const onMenuOpen = () => props.onMenuOpen?.();

  return (
    <div>
      {props.label && (
        <div className={styles.labelWrap}>
          <label htmlFor={props.id} className={styles.label}>
            {props.label}
            {props.isRequired && <span className={styles.required}>*</span>}
          </label>
          {props.tooltip && (
            <div className={styles.iconContainer}>
              {props.tooltip.show ? <Tooltip params={props.tooltip} /> : null}
            </div>
          )}
        </div>
      )}
      <AsyncPaginate
        key={props.trigger}
        {...field}
        isDisabled={props.isDisabled}
        // isSearchable={!selectedAllOption}
        styles={{
          ...simpleSelectStyles({ errors: props.errors, isDisabled: props.isDisabled }),
          option: getMultiSelectStyles().option,
        }}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        debounceTimeout={1000}
        defaultValue={props.def}
        onMenuOpen={onMenuOpen}
        loadOptions={loadOptions}
        cacheUniqs={props?.cacheUniqs}
        menuPlacement={props?.menuPlacement}
        onChange={onSelectChange}
        menuPosition={props?.menuPosition || 'absolute'}
        additional={{ page: 1 }}
        formatOptionLabel={getOptionLabel}
        placeholder={props.placeholder ? props.placeholder : '--Select--'}
        components={{
          DropdownIndicator,
          MultiValue,
          LoadingIndicator,
          ...props.components,
          IndicatorSeparator: null,
        }}
      />
      {props.errors && <p className={styles.errorName}>{props.errors.message}</p>}
    </div>
  );
};

const MultiValue = ({ index, getValue }) => {
  const values = getValue();
  const selectedCount = values.some((o) => o.value === 'all') ? 'All' : values.length;

  if (index === 0) {
    return <div>{`${selectedCount} selected`}</div>;
  }

  return null;
};

const DropdownIndicator = () => (
  <div className={styles.dropdownIndicator}>
    <Icon name="caretDown" size={16} />
  </div>
);

const LoadingIndicator = () => (
  <div className={styles.loadingIndicator}>
    <Spinner size="small" />
  </div>
);

export default PaginateAllSelect;

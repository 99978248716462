import DatePicker from 'components/ui/DatePicker/DatePicker';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { CryptoWalletsFilter } from 'models/CustomerService/CryptoWalletsFilter';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';

const walletStatus = [
  { value: 'any', label: 'Any' },
  { value: 1, label: 'Available' },
  { value: 0, label: 'Not available' },
];

const walletOpt = [
  { value: 'any', label: 'Any' },
  { value: 'BTC', label: 'Bitcoin' },
  { value: 'LTC', label: 'Litecoin' },
  { value: 'ETH', label: 'Ethereum' },
  { value: 'DASH', label: 'Dash' },
  { value: 'DOGE', label: 'Doge' },
];

export const CryptoPaymentsFields = () => {
  const [showRange, setShowRange] = useState(false);

  const {
    control,
    formState: { errors },
  } = useFormContext<CryptoWalletsFilter>();

  const availableWatch = useWatch({ name: 'available' });

  useEffect(() => {
    if (availableWatch && availableWatch.value !== 'any') {
      setShowRange(true);
    } else {
      setShowRange(false);
    }
  }, [availableWatch]);

  return (
    <Row>
      <Col xl={3} lg={4} md={6}>
        <JokerSelect
          label="Currency Type"
          control={control}
          id="type"
          name="type"
          options={walletOpt}
        />
      </Col>
      <Col xl={3} lg={4} md={6}>
        <JokerSelect
          label="Wallet Status"
          control={control}
          id="available"
          name="available"
          options={walletStatus}
        />
      </Col>
      {showRange ? (
        <>
          <Col xl={3} lg={4} md={6}>
            <div className={'datepicker'}>
              <DatePicker
                name="time_from"
                control={control}
                label="Available date from"
                dateFormat="MMMM d, yyyy h:mm aa"
                showTimeInput
                placeholderText={'Date from'}
                rules={{ required: 'Field is required' }}
                errors={errors.time_from}
              />
            </div>
          </Col>
          <Col xl={3} lg={4} md={6}>
            <div className={'datepicker'}>
              <DatePicker
                name="time_to"
                control={control}
                label="Available date to"
                dateFormat="MMMM d, yyyy h:mm aa"
                showTimeInput
                placeholderText={'Date to'}
                rules={{ required: 'Field is required' }}
                errors={errors.time_to}
              />
            </div>
          </Col>
        </>
      ) : null}
    </Row>
  );
};

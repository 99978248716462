import React from "react";
import cn from "classnames";
import { AsyncPaginate } from "react-select-async-paginate";
import { DropdownIndicator } from "./DropdownIndicator";
import { useController } from "react-hook-form";
import { XCircle } from "phosphor-react";
import './ChatSelectSearch.scss';

export const ChatSelectSearch = (props) => {
    const { field } = useController(props);
    const { ref, ...restFieldProps } = field;

    const onSelectChange = (value) => {
        field.onChange(value);
    };

    async function loadOptions(search) {
        if (search.trim().length > 1) {
            const response = await props.onChange(search);
            return {
                options: response,
                hasMore: false,
            };
        }

        return {
            options: [],
            hasMore: false,
        }
    }

    return (
        <div className={cn("chat-select-search", { multi: props.isMulti })}>
            <label htmlFor={props.id}>{props.label}</label>
            <AsyncPaginate
                {...restFieldProps}
                isDisabled={props.disabled}
                classNamePrefix='chat-async-search'
                menuPosition="fixed"
                debounceTimeout={500}
                isMulti={props.isMulti}
                isClearable={!props.isMulti}
                loadOptions={loadOptions}
                onChange={onSelectChange}
                placeholder={props.placeholder}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
            />

            {field.value?.length ? (
                <div className='chat-search-selected-options'>
                    {field.value.map(option =>
                        <div className='chat-search-selected-option' key={option.value}>
                            {option.label}
                            <XCircle
                                onClick={() => props.setValue(field.name, field.value.filter(o => o.value !== option.value))}
                                size={16}
                            />
                        </div>)
                    }
                </div>
            ) : null}
        </div>
    )
};

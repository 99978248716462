import { getCountryList } from 'api/country';
import React, { useEffect, useRef, useState } from 'react';

import { getDictionaryMethod } from 'api/managements/operators/index';
import { findPartnersMethod } from 'api/managements/partners/index';
import PartnersTable from 'components/ui/Table/PartnersTable';
import TablePagination from 'components/ui/TablePagination';
import { firstLetterUppercase } from 'utils/firstLetterUppercase.js';
import { usePermission } from 'utils/usePermission.tsx';
import Page from '../../Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import PartnersFilter from './Filter';

import { getTablePageParams } from 'utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { ListValue } from 'models/ListValue';
import { ManagementPartner } from 'models/Management/Partners/Partner.js';
import { ManagementPartnersFilter } from 'models/Management/Partners/Filter.js';
import { CountryListValue } from 'models/Countries.js';
import { toggleScrollToTop } from 'store/slice/tableSlice.ts';
import { useAppDispatch } from 'store';
import { PartnersPageTitle } from './PartnersPageTitle.tsx';
import { TableLayout } from 'components/ui/Table/TableLayout/TableLayout.tsx';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { PartnersPageFields } from './Fields/PartnersPageFields.tsx';
import { FormProvider } from 'react-hook-form';
import { usePartnersPage } from './usePartnersPage.ts';
import styles from './partners-page.module.scss';

const SORTED_FIELD_NAME = {
  partner: 'first_name',
  external_id: 'external_id',
  country: 'country_name',
  created_at: 'created_at',
  status: 'status',
};

const title = 'Affiliates';

const PartnersPage = () => {
  const { permissionGiven: PermissionListPartners } = usePermission('admin.partner.index');

  const dispatch = useAppDispatch();
  const [partners, setPartners] = useState<ManagementPartner[]>([]);

  const [isListLoading, setIsListLoading] = useState(true);
  const [statusList, setStatusList] = useState<ListValue[]>([]);
  const [countryOpt, setCountryOpt] = useState<CountryListValue[]>([]);
  const [filters, setFilters] = useState<ManagementPartnersFilter>(null);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  // const [sort, setSort] = useState();

  const componentMounted = useRef(false);

  usePageTitle(title);

  useEffect(() => {
    if (PermissionListPartners) {
      componentMounted.current = true;
      getPartners();
    }
    return () => {
      componentMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermissionListPartners]);

  useEffect(() => {
    fetchCountryList();
    fetchDictionaryList();
  }, []);

  const fetchCountryList = () => {
    getCountryList().then((res) => {
      const options = res.data.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
        iso_3166_2: opt.iso_3166_2,
      }));
      setCountryOpt([{ value: 0, label: 'Other' }].concat(options));
    });
  };
  const fetchDictionaryList = () => {
    getDictionaryMethod().then((res) => {
      const list1 = res.data.operator_statuses.map((opt) => ({
        value: opt,
        label: firstLetterUppercase(opt),
      }));
      setStatusList(list1);
    });
  };

  const getPartners = (options?, data?) => {
    setIsListLoading(true);

    findPartnersMethod(getTablePageParams(options, perPageCount, tableLinks), data || filters)
      .then((res) => {
        if (componentMounted.current) {
          setPartners(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
        }
      })
      .catch(console.log)
      .finally(() => setIsListLoading(false));
  };

  const sortColumn = (col) => {
    const sorted = col.isSorted;
    const desc = col.isSortedDesc;
    const sortedCountryAndAffiliate =
      SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.partner ||
      SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.country;

    if (!sorted) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedCountryAndAffiliate ? 'asc' : 'desc',
      };
      // setSort(sort);
      getPartners(null, {
        ...filters,
        sort,
      });
      col.toggleSortBy(true);
    }

    if (sorted && desc) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedCountryAndAffiliate ? 'desc' : 'asc',
      };
      // setSort(sort);
      getPartners(null, {
        ...filters,
        sort,
      });
      col.toggleSortBy(false);
    }

    if (sorted && !desc) {
      const data = {
        ...filters,
      };
      // setSort(null);
      getPartners(null, data);
      col.clearSortBy();
    }
  };

  const { form, table } = usePartnersPage();

  return (
    <DashboardLayout>
      <Page
        isStickyTitle
        title={
          <PartnersPageTitle
            total={tableMeta?.total}
            countries={countryOpt}
            updateComponentCallback={getPartners}
          />
        }
      >
        <>
          <FormProvider {...form}>
            <PartnersPageFields countries={countryOpt} statuses={statusList} />
            <TableLayout
              header={
                <PartnersFilter
                  onSubmit={(data) => {
                    setFilters(data);
                    getPartners({ navigate: 'first', perPage: data?.filter?.limit }, data);
                  }}
                  isLoading={isListLoading}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              {PermissionListPartners ? (
                <PartnersTable
                  manualSortHandler={sortColumn}
                  data={partners}
                  perPage={partners?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={() => getPartners()}
                  columns={table.columns}
                  saveColumnOrder={table.saveColumnOrder}
                />
              ) : null}
            </TableLayout>
          </FormProvider>
          {partners?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                getPartners({ perPage: value });
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                getPartners({ page });
                dispatch(toggleScrollToTop());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default PartnersPage;

import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { TABLE_CLIENTS_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

const defaultValues = {
  languageIds: [],
  countryIds: [],
  searchName: '',
  query: '',
  query_category: defaultQueryCategoryOption,
  searchById: '',
  searchMigration: '',
  deskIds: [],
  teamIds: [],
  first_time_deposit: undefined,
  assign_status: undefined,
  profile_status: undefined,
  desk_type: undefined,
  activity: undefined,
  operatorIds: [],
  affiliateIds: [],
  balance: {
    from: '',
    to: '',
  },
  number_deposits: {
    from: '',
    to: '',
  },
  last_trade: '',
  note_first: '',
  note_last: '',
  notes: '',
  sources: [],
  referrals: [],
  sources_exclude: [],
  referrals_exclude: [],
  updated_at: '',
  last_login: '',
  ftd_range: '',
  created_at: '',
  // limit: '',
  custom_filters: '',
  dynamic_statuses: [],
};

type Props = {
  dynamicStatuses: {};
};

export const useSearchClientsPage = ({ dynamicStatuses }: Props) => {
  const table = useTableColumnOrder('TABLE_CLIENTS_COLUMNS_NAMES', TABLE_CLIENTS_COLUMNS_NAMES);

  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues, ...dynamicStatuses },
  });

  return { table, form, defaultValues: { ...defaultValues, ...dynamicStatuses } };
};

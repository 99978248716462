import React from 'react';
import FormTextarea from 'components/ui/FormTextarea';
import PaginateSelect from 'components/ui/PaginateSelect';
import Button from 'components/ui/Button';
import { useForm } from 'react-hook-form';
import { fetchColors, fetchSpeed } from '../../helpers/fetchOptions';
import {
  defaultDateTimeFormat,
  ModernDateRangeInput,
  ModernDateTimeInputHeader,
} from 'components/ui/ModernDateRangeInput';
import {
  systemMessageFormSubmitAdapter,
  systemMessageFormValuesAdapter,
} from '../../helpers/adapter';
import { SystemMessageFormSubmitData, SystemMessageFormValues } from 'models/Ticker';
import { isFormFilled } from '../../helpers/isFormFilled';
import './SystemMessageForm.scss';
import { Col, Row } from 'react-bootstrap';
import { Option } from '../SelectMessageColor/Option';
import { SingleValue } from '../SelectMessageColor/SingleValue';

type SystemMessageFormProps = {
  onSubmit: (data: SystemMessageFormSubmitData) => Promise<void>;
  onCancel: () => void;
  formValues?: SystemMessageFormValues | null;
  edit: boolean;
  loading: boolean;
  submitTitle?: string;
};

const defaultValues = {
  message: '',
  color: null,
  scrolling_speed: null,
  date: { from: null, to: null },
};

export const SystemMessageForm = ({
  onSubmit,
  onCancel,
  formValues,
  edit,
  loading,
  submitTitle = 'Create',
}: SystemMessageFormProps) => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: formValues ? systemMessageFormValuesAdapter(formValues) : defaultValues,
  });

  const onSubmitHandler = (data) => onSubmit(systemMessageFormSubmitAdapter(data));

  const onCancelHandler = () => {
    reset();
    onCancel();
  };

  const values = watch();

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="system-message-form">
      <Row>
        <Col md={12} className="mb-3">
          <FormTextarea name="message" label="Message text" control={control} />
        </Col>
        <Col md={12} className="mb-3">
          <PaginateSelect
            id="message_color"
            name="color"
            label="Message color"
            placeholder="Select message color"
            isSearchable={false}
            control={control}
            components={{ Option, SingleValue }}
            onChange={fetchColors}
          />
        </Col>
        <Col md={12} className="mb-3">
          <ModernDateRangeInput
            name="date"
            control={control}
            label="Date & time"
            placeholder="From - To"
            dateFormat={defaultDateTimeFormat}
            allowToChangeViaInput={true}
            renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
              <ModernDateTimeInputHeader
                {...props}
                from={props.from}
                to={props.to}
                onTimeChangeHandler={onTimeChangeHandler}
                showRange
              />
            )}
          />
        </Col>
        <Col md={12} className="mb-3">
          <PaginateSelect
            isSearchable={false}
            isMulti={false}
            label="Scrolling speed"
            placeholder="Select scrolling speed"
            control={control}
            id="scrolling_speed"
            name="scrolling_speed"
            onChange={fetchSpeed}
          />
        </Col>
      </Row>

      <div className="form-actions">
        <Button buttonText="Cancel" buttonType="outline" type="reset" onClick={onCancelHandler} />
        <Button
          buttonText={submitTitle}
          buttonType="primary"
          isLoading={loading}
          type="submit"
          disabled={!isFormFilled(values) || (edit && !isDirty)}
        />
      </div>
    </form>
  );
};

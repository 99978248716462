import { CSSProperties } from 'react';
import cn from 'classnames';
import styles from './icon.module.scss';
import { iconSvgs } from 'assets/Svg';

export type IconName = keyof typeof iconSvgs;

export type IconProps = {
  name: IconName;
  size?: number;
  style?: CSSProperties;
  className?: string;
  color?: string;
  id?: string;
  onClick?: (e?: any) => void;
};

export const Icon = ({ name, size = 24, style, className, color, id, onClick }: IconProps) => {
  const SvgIcon = iconSvgs[name];

  const icon = (
    <SvgIcon
      id={id}
      className={cn(styles.icon, className)}
      style={{ ...style, width: size, height: size, color }}
    />
  );

  if (onClick) {
    return (
      <button type="button" id={id} className={cn(styles.button, className)} onClick={onClick}>
        {icon}
      </button>
    );
  }

  return icon;
};

import { components } from 'react-select';
import { colorOptions } from './colorOptions';
import './SelectMessageColor.scss';

export const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div className="select-color-option">
        <div className="select-color-option-indicator" style={{ background: props.data.value }} />
        <div>{colorOptions[props.children] || props.children}</div>
      </div>
    </components.SingleValue>
  );
};

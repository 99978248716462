import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePermission } from 'utils/usePermission';
import styles from './page-style.module.scss';
import TradingAccFilter from './TradingAccFilter';
import { getTablePageParams } from 'utils/getTablePageParams';
import { findTradingAccMethod, getTradingAccStatuses } from 'api/tradingAcc';
import TradingAccTable from 'components/ui/Table/TradingAccTable';
import { DashboardLayout } from '../UserView/DashboardLayout';
import Page from '../Page';
import TablePagination from 'components/ui/TablePagination';
import { notify } from 'utils/notify';
import { TableLinks, TableMeta } from 'models/Table';
import { ClientTradingAccountFilter } from 'models/Clients/TradingAccounts/Filter';
import { ClientTradingAccount } from 'models/Clients/TradingAccounts/Account';
import { ListValue } from 'models/ListValue';
import { useAppDispatch } from 'store';
import { useCheckAutoRefresh } from 'utils/useCheckAutoRefresh.tsx';
import { toggleScrollToTop } from 'store/slice/tableSlice.ts';
import { usePageTitle } from 'utils/usePageTitle.ts';
import { useTrandingAccPage } from './useTradingAccPage';
import { TrandingAccFields } from './TradingAccFields/TradingAccFields';
import { FormProvider } from 'react-hook-form';
import { TableLayout } from 'components/ui/Table/TableLayout';

const title = 'Trading Accounts';

const TradingAccPage = () => {
  const { permissionGiven: PermissionShowListTradingAccounts } = usePermission(
    'admin.trading-account.index',
  );

  const dispatch = useAppDispatch();
  const [tradingAccList, setTradingAccList] = useState<ClientTradingAccount[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<ClientTradingAccountFilter>();
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const [taStatusesOpt, setTaStatusesOpt] = useState<ListValue[]>([]);

  const componentMounted = useRef(false);

  const { id } = useParams();
  const checkAutoRefresh = useCheckAutoRefresh('Page Trading accounts');

  usePageTitle(title);

  const fetchTAStatusesOpt = () => {
    getTradingAccStatuses()
      .then((res) => {
        if (res.status === 200) {
          setTaStatusesOpt(
            res.data.data.map((item) => ({
              value: item,
              label: item,
            })),
          );
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  const fetchTradingAccList = (options?, data?) => {
    setIsListLoading(true);
    findTradingAccMethod(getTablePageParams(options, perPageCount, tableLinks), data)
      .then((res) => {
        if (res.status === 200) {
          setTradingAccList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
        }
      })
      .catch((error) => {
        if (error && error?.response?.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => setIsListLoading(false));
  };

  useEffect(() => {
    if (PermissionShowListTradingAccounts) {
      componentMounted.current = true;
      fetchTradingAccList();
    }

    return () => {
      componentMounted.current = false;
    };
  }, [PermissionShowListTradingAccounts]);

  const getSortFiltersForPageNavigation = () => {
    if (filters) {
      return {
        ...filters,
      };
    }
  };
  const getTradingAccList = (options, data) => {
    setIsListLoading(true);

    findTradingAccMethod(getTablePageParams(options, perPageCount, tableLinks), data || filters)
      .then((res) => {
        if (componentMounted.current) {
          setTradingAccList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
        }
      })
      .catch(console.log)
      .finally(() => setIsListLoading(false));
  };

  useEffect(() => {
    fetchTAStatusesOpt();
  }, []);

  const { table, form } = useTrandingAccPage();

  return (
    <DashboardLayout>
      <Page isStickyTitle title={`${tableMeta?.total || 0} Trading accounts`}>
        <>
          <FormProvider {...form}>
            <TrandingAccFields taStatuses={taStatusesOpt} />
            <TableLayout
              header={
                <TradingAccFilter
                  onSubmit={(data) => {
                    setFilters(data);
                    getTradingAccList({ navigate: 'first', perPage: data?.filter?.limit }, data);
                  }}
                  isLoading={isListLoading}
                  setDefaultColumnsOrder={table.setDefaultColumnsOrder}
                />
              }
            >
              {PermissionShowListTradingAccounts ? (
                <TradingAccTable
                  data={tradingAccList}
                  perPage={perPageCount}
                  showLoader={isListLoading}
                  onRowSelect={setSelectedRows}
                  userId={id}
                  columns={table.columns}
                  saveColumnOrder={table.saveColumnOrder}
                />
              ) : null}
            </TableLayout>
          </FormProvider>

          {PermissionShowListTradingAccounts && tradingAccList?.length ? (
            <TablePagination
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                fetchTradingAccList({ perPage: value }, getSortFiltersForPageNavigation());
                dispatch(toggleScrollToTop());
              }}
              goToSelectedPage={(page) => {
                fetchTradingAccList({ page }, getSortFiltersForPageNavigation());
                dispatch(toggleScrollToTop());
              }}
            />
          ) : null}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default TradingAccPage;

import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { MultiSelect } from 'components/ui/MultiSelect';
import { CountryListValue } from 'models/Countries';
import { ListValue } from 'models/ListValue';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import {
  fetchDesksNoPagination,
  fetchTeamsNoPagination,
  fetchTeamsForDeskNoPagination,
} from 'utils/managment/fetchData';

type Props = {
  countryList: CountryListValue[];
  statusList: ListValue[];
};

export const OperatorsPageFields = ({ countryList, statusList }: Props) => {
  const [desks, setDesks] = useState([]);
  const [teams, setTeams] = useState([]);

  const { control, getValues, setValue, watch } = useFormContext();

  const fetchDesksList = async () => {
    const response = await fetchDesksNoPagination(1);

    if (response.fullData.status === 200) {
      setDesks(response.data);
    }
  };

  const fetchTeamsList = async (desks: ListValue[]) => {
    if (desks.length > 0) {
      const response = await fetchTeamsForDeskNoPagination(
        1,
        undefined,
        desks.map((desk) => desk.value),
      );
      setTeams(response.data);
    } else {
      const response = await fetchTeamsNoPagination(1);
      setTeams(response.data);
    }
  };

  useEffect(() => {
    fetchDesksList();
    fetchTeamsList([]);
  }, []);

  useEffect(() => {
    const newTeams = getValues().teamIds.filter((item) =>
      teams.find((t) => t.value === item.value),
    );
    setValue('teamIds', newTeams);
  }, [teams]);

  useEffect(() => {
    if (!watch().statuses) {
      setValue(
        'statuses',
        statusList.find((s) => s.value === 'active'),
      );
    }
  }, [statusList]);

  return (
    <>
      <Row>
        {countryList && (
          <Col xl={3} lg={4} md={6} className="mb-3">
            <MultiSelect
              label={'Country'}
              control={control}
              id="country_ids"
              name="country_ids"
              options={countryList}
            />
          </Col>
        )}
        {statusList && (
          <Col xl={3} lg={4} md={6} className="mb-3">
            <JokerSelect
              label={'Status'}
              control={control}
              id="statuses"
              name="statuses"
              options={statusList}
              placeholder="Select"
            />
          </Col>
        )}
        <Col xl={3} lg={4} md={6} className="mb-3">
          <DatePickerRange
            name="created_at"
            control={control}
            label={'Registration date range'}
            placeholder="From - To"
          />
        </Col>
      </Row>
      <Row>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect label="Desks" control={control} id="desks" name="deskIds" options={desks} />
        </Col>
        <Col xl={3} lg={4} md={6} className="mb-3">
          <MultiSelect label="Teams" control={control} id="teams" name="teamIds" options={teams} />
        </Col>
      </Row>
    </>
  );
};

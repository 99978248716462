import React, { useEffect, useRef, useState } from 'react';
import { CryptoWallet } from 'models/CustomerService/CryptoWallets.ts';
import { getWalletsById } from 'api/clients/CryptoWalletsTab';
import { useParams } from 'react-router-dom';
import CryptoWalletsUserByIdTable from 'components/ui/Table/CryptoWalletsUserByIdTable/CryptoWalletsUserByIdTable.tsx';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import { TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES } from 'constants/table';
import Button from 'components/ui/Button';
import { TableLayout } from 'components/ui/Table/TableLayout';
import styles from './crypto-wallet-tab.module.scss';

const CryptoWalletsTab = () => {
  const [walletList, setWalletList] = useState<CryptoWallet[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const componentMounted = useRef(false);
  const params = useParams();

  useEffect(() => {
    componentMounted.current = true;
    getWallets(params.id);

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getWallets = (userId: string) => {
    setIsLoading(true);

    getWalletsById(userId)
      .then((res) => {
        if (componentMounted.current) {
          setWalletList(res?.data?.data?.wallet.sort((a, b) => a.general - b.general));
        }
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  const table = useTableColumnOrder(
    'TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES',
    TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES,
  );

  return (
    <div>
      <div className={styles.tabTitle}>Crypto Wallets</div>
      <TableLayout
        header={
          <Button
            className={styles.button}
            buttonText="Reset column order"
            onClick={table.setDefaultColumnsOrder}
          />
        }
      >
        <CryptoWalletsUserByIdTable
          data={walletList}
          showLoader={isLoading}
          columns={table.columns}
          saveColumnOrder={table.saveColumnOrder}
        />
      </TableLayout>
    </div>
  );
};

export default CryptoWalletsTab;

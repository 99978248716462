import { CSSProperties } from 'react';
import { Icon } from '../Icon';
import cn from 'classnames';
import styles from './reload-button.module.scss';

type Props = {
  isLoading: boolean;
  style?: CSSProperties;
  onClick: () => void;
};

export const ReloadButton = ({ isLoading, style, onClick }: Props) => {
  return (
    <button
      type="button"
      className={cn(styles.button, isLoading && styles.button__loading)}
      disabled={isLoading}
      style={style}
      onClick={(e) => {
        e.preventDefault();
        onClick()
      }}
    >
      <Icon name="arrowsClockwise" size={20} color="var(--white)" />
    </button>
  );
};

import { Lead } from 'models/Leads/Lead';
import moment from 'moment';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import cn from 'classnames';

import styles from './lead-account-status.module.scss';

type Props = {
  lead: Lead;
};

export const LeadAccountStatus = ({ lead }: Props) => {
  return (
    <div>
      <div className={cn(styles.status, styles[lead.status])}>
        {lead && lead.status ? lead.status : '---'}
      </div>
      <div>
        since{' '}
        {lead && lead.change_status
          ? moment(lead.change_status.converted_at * 1000).format('DD.MM.YYYY HH:mm:ss')
          : moment(lead.created_at * 1000).format('DD.MM.YYYY HH:mm:ss')}
        {lead && lead.client?.uuid ? (
          <div onClick={() => copyToBuffer(lead.client?.uuid, true)}>
            by {getShortUuid(lead.client?.uuid)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

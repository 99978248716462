import { getWallet } from 'api/customer-service/crypto-payments';
import Button from 'components/ui/Button';
import { CRYPTO_WALLETS_TYPES } from 'constants/payments.const';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { usePermission } from 'utils/usePermission.tsx';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import PinToUserPopup from '../CryptoPayments/CryptoPaymentsPopups/PinToUserPopup';
import CryptoWalletBindingsTable from 'components/ui/Table/CryptoWalletBindingsTable';
import { CryptoWallet } from 'models/CustomerService/CryptoWallets.js';
import Page from 'pages/Page';
import { usePageTitle } from 'utils/usePageTitle';
import styles from './wallet-page.module.scss';
import { WalletPageInfo } from './WalletPageInfo/WalletPageInfo';
import { Icon, IconName } from 'components/ui/Icon';
import { Spinner } from 'components/ui/Spinner';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import { TABLE_WALLET_BINDINGS } from 'constants/table';
import { TableLayout } from 'components/ui/Table/TableLayout';

const WalletPage = () => {
  const { permissionGiven: PermissionSetOrEditWalletToClient } = usePermission(
    'admin.crypto_wallets.set-wallet',
  );

  const params = useParams();

  const [walletNumber, setWalletNumber] = useState('');
  const [walletData, setWalletData] = useState<CryptoWallet>();

  const [isLoading, setIsLoading] = useState(true);
  const title = 'Wallet - ' + walletNumber;

  const [clientsList, setClientsList] = useState([]);

  const fetchWalletInfo = (params) => {
    setIsLoading(true);

    getWallet(params.id)
      .then((res) => {
        setWalletData(res.data.data);
        setWalletNumber(res.data.data.wallet);
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  usePageTitle(title);

  useEffect(() => {
    fetchWalletInfo(params);
  }, [params]);

  const Title = () => {
    return (
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <Icon name={walletData?.crypto_type?.toLowerCase() as IconName} size={30} />
          <div className={styles.title}>
            {CRYPTO_WALLETS_TYPES[walletData?.crypto_type.toLowerCase()]}{' '}
            {walletData?.crypto_type === 'USDT' &&
              walletData.crypto_network &&
              `(${walletData.crypto_network})`}{' '}
            Wallet
          </div>
        </div>
        <div className={styles.headerButtons}>
          {PermissionSetOrEditWalletToClient && walletData.general === 0 ? (
            <PinToUserPopup
              wallet={walletData}
              clientsList={clientsList}
              updateComponent={() => fetchWalletInfo(params)}
              triggerBtn={<Button buttonType="outline" buttonText="Bind to user" />}
            />
          ) : null}
          <Link to={`/customer-crypto`} className={styles.link}>
            <Button buttonType="outline" buttonText="Go to wallet list" onClick={() => null} />
          </Link>
        </div>
      </div>
    );
  };

  const table = useTableColumnOrder('TABLE_WALLET_BINDINGS', TABLE_WALLET_BINDINGS);

  return (
    <DashboardLayout>
      {!isLoading ? (
        <Page title={<Title />}>
          <>
            <WalletPageInfo wallet={walletData} />
            {walletData?.general === 0 ? (
              <div className={styles.table}>
                <div className={styles.tableHeader}>Wallet bindings</div>
                <TableLayout
                  header={
                    <Button
                      className={styles.resetColumnButton}
                      buttonText="Reset column order"
                      onClick={table.setDefaultColumnsOrder}
                    />
                  }
                >
                  <CryptoWalletBindingsTable
                    data={walletData.binded_wallet_to_users}
                    perPage={walletData.binded_wallet_to_users.length}
                    showLoader={false}
                    onRowSelect={null}
                    modifyDataHandler={() => fetchWalletInfo(params)}
                    permission={PermissionSetOrEditWalletToClient}
                    columns={table.columns}
                    saveColumnOrder={table.saveColumnOrder}
                  />
                </TableLayout>
              </div>
            ) : null}
          </>
        </Page>
      ) : (
        <div className={styles.preloader}>
          <div className={styles.preloader__inner}>
            <Spinner />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default WalletPage;

interface IOption {
  value: string;
  label: string;
}

export const optionsFirstTimeDeposit: IOption[] = [
  {
    value: 'any',
    label: 'Any'
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Yes',
    value: 'yes',
  },
]

export const optionsTransactionStatus = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
  {
    value: 'canceled',
    label: 'Canceled',
  },
]

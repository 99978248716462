import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';
import styles from './resetPassword.module.scss';
import { Link, useParams } from 'react-router-dom';
import PasswordInput from '../../../components/ui/PasswordInput';
import Button from '../../../components/ui/Button';
import { Key } from 'phosphor-react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../../utils/notify';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { newPassword } from '../../../api/auth';

const cx = classNames.bind(styles);

const ResetPassword = () => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const url = window.location.href.split('/');
  let { code: confirmCode, email } = useParams();
  const companyName = 'Joker CRM';
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const passwordValue = watch('password');

  const onSubmit = () => {
    setIsLoading(true);
    let sendData = {
      email: email,
      code: confirmCode,
      // password: data.password,
      // password_confirmation: data.password_confirmation,
    };

    newPassword(sendData)
      .then((res) => {
        if (res) {
          setIsLoading(false);
          notify({
            type: 'success',
            message: 'Reset password successful',
            timeOut: 3000,
          });
          setTimeout(() => {
            navigate('/');
          }, 1000);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    document.title = 'Reset Password';
  }, []);
  useEffect(() => {
    onSubmit();
  }, [confirmCode]);
  return (
    <>
      <div className={cx('page')}>
        <div className={cx('login', 'auth')}>
          <div className={cx('login__title')}>Reset password</div>
          {isLoading && <Preloader />}
          {/*<div className={cx('login__col', 'auth__descr')}>*/}
          {/*  <div className={cx('auth__descr-img')}>*/}
          {/*    <Key size={170} />*/}
          {/*  </div>*/}

          {/*  <div className={cx('auth__descr-text')}>*/}
          {/*    <p>Already have an account?</p>*/}
          {/*    <Link to="/" className={cx('link-text')}>*/}
          {/*      Sign in*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className={cx('login__col')}>*/}
          {/*  <div className={cx('login__header')}>*/}
          {/*    <div className={cx('login__title')}>Reset password</div>*/}
          {/*  </div>*/}
          {/*  <div className={cx('login__content')}>*/}
          {/*    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>*/}
          {/*      {isLoading ? <Preloader /> : null}*/}
          {/*      <Container className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>*/}
          {/*        <Row className={cx('form__line')}>*/}
          {/*          <Col md={12}>*/}
          {/*            <PasswordInput*/}
          {/*              name="password"*/}
          {/*              type="password"*/}
          {/*              label="New Password"*/}
          {/*              control={control}*/}
          {/*              rules={{*/}
          {/*                required: 'Обязательное поле',*/}
          {/*                validate: {*/}
          {/*                  isNumber: (value) =>*/}
          {/*                    /^(?=.*[0-9])/.test(value) ||*/}
          {/*                    'Пароль должен содержать хотя бы одну цыфру',*/}
          {/*                  isUpper: (value) =>*/}
          {/*                    /^(?=.*[a-z])/.test(value) ||*/}
          {/*                    'Пароль должен содержать хотя бы одну маленькую латинскую букву',*/}
          {/*                  isLower: (value) =>*/}
          {/*                    /^(?=.*[A-Z])/.test(value) ||*/}
          {/*                    'Пароль должен содержать хотя бы одну большую латинскую букву',*/}
          {/*                  isSimbol: (value) =>*/}
          {/*                    /^(?=.*[!@#$%^&*])/.test(value) ||*/}
          {/*                    'Пароль должен содержать хотя бы один спец символ',*/}
          {/*                  minLength: (value) =>*/}
          {/*                    /^.{8,}$/.test(value) || 'Пароль должен содержать минимум 8 символов',*/}
          {/*                },*/}
          {/*              }}*/}
          {/*              errors={errors.password}*/}
          {/*            />*/}
          {/*          </Col>*/}
          {/*          <Col md={12}>*/}
          {/*            <PasswordInput*/}
          {/*              name="password_confirmation"*/}
          {/*              type="password"*/}
          {/*              label="Confirm New Password"*/}
          {/*              control={control}*/}
          {/*              rules={{*/}
          {/*                required: 'Обязательное поле',*/}
          {/*                validate: {*/}
          {/*                  isNumber: (value) =>*/}
          {/*                    /^(?=.*[0-9])/.test(value) ||*/}
          {/*                    'Пароль должен содержать хотя бы одну цыфру',*/}
          {/*                  isUpper: (value) =>*/}
          {/*                    /^(?=.*[a-z])/.test(value) ||*/}
          {/*                    'Пароль должен содержать хотя бы одну маленькую латинскую букву',*/}
          {/*                  isLower: (value) =>*/}
          {/*                    /^(?=.*[A-Z])/.test(value) ||*/}
          {/*                    'Пароль должен содержать хотя бы одну большую латинскую букву',*/}
          {/*                  isSimbol: (value) =>*/}
          {/*                    /^(?=.*[!@#$%^&*])/.test(value) ||*/}
          {/*                    'Пароль должен содержать хотя бы один спец символ',*/}
          {/*                  minLength: (value) =>*/}
          {/*                    /^.{8,}$/.test(value) || 'Пароль должен содержать минимум 8 символов',*/}
          {/*                  match: (value) => value === passwordValue || 'Пароли не совпадают',*/}
          {/*                },*/}
          {/*              }}*/}
          {/*              errors={errors.password_confirmation}*/}
          {/*            />*/}
          {/*          </Col>*/}
          {/*        </Row>*/}
          {/*        <div className={cx('button-wrap')}>*/}
          {/*          <Button*/}
          {/*            type="submit"*/}
          {/*            buttonText="Reset password"*/}
          {/*            size="big"*/}
          {/*            loading={isLoading}*/}
          {/*            disabled={isLoading}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      </Container>*/}
          {/*    </form>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className={cx('copyright')}>
        © {new Date().getFullYear()} {process.env.REACT_APP_COMPANY_NAME}, Inc. All rights Reserved
      </div>
    </>
  );
};

export default ResetPassword;

import { CryptoWallet } from 'models/CustomerService/CryptoWallets';
import { getWalletPageInfoTableData } from './utils';
import cn from 'classnames';
import styles from './wallet-page-info.module.scss';

type Props = {
  wallet: CryptoWallet;
};

export const WalletPageInfo = ({ wallet }: Props) => {
  const tableData = getWalletPageInfoTableData({ wallet });

  return (
    <div className={styles.container}>
      <div className={styles.title}>Wallet information</div>
      <table>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={index}>
              <td className={styles.tableTitle}>{item.title}</td>
              <td
                className={cn(styles.tableValue, item?.onClick && styles.tableValue__clickable)}
                onClick={item?.onClick}
              >
                {item.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import { ReactNode } from 'react';
import { useAppSelector } from 'store';
import { getLogo } from 'constants/selectors';

import styles from './auth-layout.module.scss';

type Props = {
  children: ReactNode;
};

export const AuthLayout = ({ children }: Props) => {
  const logo = useAppSelector(getLogo);

  return (
    <div className={styles.layout}>
      <div className={styles.logoWrapper}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.formWrapper}>{children}</div>
      <div className={styles.copyright}>
        © {new Date().getFullYear()} {process.env.REACT_APP_COMPANY_NAME}, Inc. All rights Reserved
      </div>
    </div>
  );
};

import Popup from 'reactjs-popup';
import PopupTemplate from 'components/ui/PopupTemplate';
import Button from 'components/ui/Button/Button';
import FormInput from 'components/ui/FormInput/FormInput';
import { useForm } from 'react-hook-form';
import { setAffiliationData } from 'api/Source';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'react-bootstrap';

const AfilateEditPopup = ({
  trigger,
  affilate,
  source,
  referral,
  user_id,
  setSource,
  setReferral,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      affilate: affilate,
      source: source,
      referral: referral,
    },
  });

  const onSubmit = (close, data) => {
    setAffiliationData(user_id, data).then((r) => {
      setSource(r.data.data.source);
      setReferral(r.data.data.referral);
      setValue('source', r.data.data.source);
      setValue('referral', r.data.data.referral);
    });
    close();
  };

  const onError = (close, errors) => {
    console.log(errors);
  };

  const closeModal = () => {
    clearErrors();
    setValue('referral', referral);
    setValue('source', source);
  };

  return (
    <Popup
      modal
      trigger={trigger}
      closeOnEscape
      repositionOnResize
      lockScroll
      closeOnDocumentClick
      onClose={closeModal}
    >
      {(close) => (
        <form
          onSubmit={handleSubmit(onSubmit.bind(undefined, close), onError.bind(undefined, close))}
        >
          <PopupTemplate
            dismissModal={() => {
              closeModal();
              close();
            }}
            headerTitle="Set source and referral"
            rightContent={
              <>
                <Container>
                  <Row>
                    <Col md={12} className="mb-3">
                      <FormInput name="affilate" label="Affilate" control={control} disabled />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <FormInput
                        name="source"
                        label="Source"
                        control={control}
                        rules={{
                          maxLength: {
                            value: 20,
                            message: (
                              <FormattedMessage
                                id="form.error.maxLengthPassword"
                                defaultMessage="Maximum length is 20 characters"
                              />
                            ),
                          },
                        }}
                        errors={errors.source}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <FormInput
                        name="referral"
                        label="Referral"
                        control={control}
                        rules={{
                          maxLength: {
                            value: 20,
                            message: (
                              <FormattedMessage
                                id="form.error.maxLengthPassword"
                                defaultMessage="Maximum length is 20 characters"
                              />
                            ),
                          },
                        }}
                        errors={errors.referral}
                      />
                    </Col>
                  </Row>
                </Container>
                <div className="d-flex justify-content-end gap-4 mt-3">
                  <Button
                    type="button"
                    buttonText="Cancel"
                    buttonType="outline"
                    onClick={() => {
                      closeModal();
                      close();
                    }}
                  />
                  <Button
                    type="submit"
                    buttonText="Save Change"
                    disabled={!!Object.entries(errors).length}
                    onClick={() => {
                      handleSubmit(onSubmit, onError);
                    }}
                  />
                </div>
              </>
            }
          />
        </form>
      )}
    </Popup>
  );
};

export default AfilateEditPopup;

import classNames from 'classnames/bind';
import Button from 'components/ui/Button';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
// import { clientDynamicStatusesByKey } from '../../../../api/clients/index.js';
import { changeStatusLead, getLeadStatuses } from 'api/leads/index';
import { notify } from 'utils/notify.tsx';

import styles from './lead-popup.mudule.scss';
import { Lead } from 'models/Leads/Lead';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  user: Lead;
};

const ChangeStatusPopup = (props: Props) => {
  const { triggerBtn, updateComponent, user } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [dynamicFilter, setDynamicFilter] = useState(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const closeModal = (close) => {
    reset();
    close();
  };

  const onOpen = () => {
    fetchClientDynamicStatuses();
  };

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      const status_id =
        dynamicFilter && dynamicFilter.options.find((item) => item.label === user.status);
      setIsLoading(false);
      reset({
        status_id,
      });
    }
  }, [user, dynamicFilter]);

  const fetchClientDynamicStatuses = () => {
    setIsLoading(true);

    getLeadStatuses().then((res) => {
      const filter = res.data.data;

      setDynamicFilter({
        options: filter.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      });
      setIsLoading(false);
    });
  };
  const onSubmit = (close, data) => {
    setIsLoading(true);

    const postData = {
      status_id: data['status_id']['value'],
    };

    changeStatusLead(user.id, postData)
      .then((res) => {
        setIsLoading(false);

        notify({
          type: 'success',
          message: res.data.message,
          timeOut: 3000,
        });
        updateComponent();
        close();
      })
      .catch((err) => {
        console.log(err.response);
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            isBigPopup={false}
            headerTitle={'Change status'}
            rightContent={
              <div className={cx('content')}>
                <span className={cx('content__title')}>
                  You are going to change status {user.name + ' ' + user.surname}
                </span>
                <div className={cx('content-form', 'popupForm', 'leads')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12}>
                          {dynamicFilter && (
                            <JokerSelect
                              id="status_id"
                              name="status_id"
                              label="Status"
                              control={control}
                              options={dynamicFilter.options}
                              errors={errors.status_id}
                              rules={{
                                required: 'Field is required',
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText="Cancel"
                          buttonType="outline"
                          type="button"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          isLoading={isLoading}
                          buttonText={'Apply change'}
                          type="submit"
                          onClick={(data) => handleSubmit(onSubmit.bind(undefined, close))}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default ChangeStatusPopup;

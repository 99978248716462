import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useFieldArray, useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from '../crypto-payments-page.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { addWallet, getSystemDataMethod } from 'api/customer-service/crypto-payments';
import { WALLET_ACTION_TYPE } from 'constants/payments.const';
import CheckBox from '../../../../components/ui/CheckBox';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  actionType: string;
};

const AddCryptoWalletPopup = (props: Props) => {
  const { triggerBtn, updateComponent, actionType } = props;

  const [isLoading, setIsLoading] = useState(false);
  // const [storedFormDataToErrorRequest, setStoreFormDataToErrorRequest] = useState([]);
  // const ref = useRef(null);
  const [walletType, setWalletType] = useState<ListValue[]>();

  const setDefaultValues = () => {
    if (actionType === WALLET_ACTION_TYPE.CREATE) {
      return {
        type: undefined,
        wallet: undefined,
        description: undefined,
        general: 0,
      };
    }
    return {};
  };

  const { handleSubmit, getValues, control, reset, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const { isDirty, isValid, dirtyFields, isSubmitSuccessful, errors } = formState;

  const closeModal = (closeModal) => {
    reset(null, { keepDefaultValues: true });
    closeModal();
  };

  useEffect(() => {
    reset(setDefaultValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (isSubmitSuccessful) {
  //     reset(setDefaultValues());
  //   } else {
  //     reset(storedFormDataToErrorRequest, { keepDirty: true });
  //   }
  // }, [isSubmitSuccessful]);

  const onSubmit = (close, data) => {
    const sendData = {
      type: data['type'].value.toUpperCase(),
      wallet: data['wallet'],
      description: data['description'],
      general: Number(data.general),
    };

    if (actionType === WALLET_ACTION_TYPE.CREATE) {
      addWalletHandler(sendData, close);
    } else {
      editWalletHandler(sendData, close);
    }
  };

  const addWalletHandler = (sendData, close) => {
    setIsLoading(true);

    addWallet(sendData)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: 'Wallet created successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response || 'Unable to add Wallet',
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const editWalletHandler = (sendData, close) => {
    setIsLoading(true);

    // updateRule(ruleData.id, sendData)
    //   .then((res) => {
    //     if (res.data.data) {
    //       notify({
    //         type: 'success',
    //         message: 'Rule edited successfully',
    //         timeOut: 3000,
    //       });
    //       setIsLoading(false);
    //       updateComponent();
    //       closeModal(close);
    //     }
    //   })
    //   .catch((error) => {
    //     notify({
    //       type: 'error',
    //       message: error.response,
    //       timeOut: 3000,
    //     });
    //     setIsLoading(false);
    //   });
  };
  const onOpen = () => {
    getSystemDataMethod().then((res) => {
      if (res.status === 200) {
        let list = res.data.crypto.map((item) => {
          return { value: item, label: item };
        });
        setWalletType(list);
      }
    });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              actionType === WALLET_ACTION_TYPE.CREATE ? 'Add new wallet' : 'Edit wallet'
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm', 'add-wallet')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Row className="gap-4">
                      <Col md={12}>
                        <FormInput
                          name="wallet"
                          type="text"
                          autoComplete={'none'}
                          placeholder="Enter wallet address"
                          label="Wallet address"
                          control={control}
                          rules={{ required: ' Field is required' }}
                          errors={errors.wallet}
                        />
                      </Col>
                      <Col md={12}>
                        <JokerSelect
                          label="Wallet type"
                          control={control}
                          id="wallet_type"
                          placeholder={'--Select--'}
                          name="type"
                          isRequired
                          rightaligned={true}
                          options={walletType}
                          rules={{ required: ' Field is required' }}
                          errors={errors.type}
                        />
                      </Col>

                      <Col md={12}>
                        <FormInput
                          name="description"
                          type="text"
                          autoComplete={'none'}
                          placeholder="Enter wallet description"
                          label="Wallet description"
                          control={control}
                        />
                      </Col>
                      <CheckBox
                        control={control}
                        name="general"
                        type="checkbox"
                        label={'Shared wallet?'}
                        id="general"
                      />
                    </Row>
                    <div className={cx('content-controls')}>
                      <Row>
                        <Col md={12}>
                          <div className={cx('controls__buttons')}>
                            <Button
                              buttonText="Cancel"
                              buttonType="outline"
                              type="button"
                              onClick={closeModal.bind(undefined, close)}
                            />
                            <Button
                              isLoading={isLoading}
                              disabled={!isDirty}
                              buttonText={'Apply change'}
                              type="submit"
                              onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default AddCryptoWalletPopup;

import { NestedCryptoTransaction } from 'models/CustomerService/CryptoTransaction';
import { ArrowFatLineRight } from 'phosphor-react';
import { copyToBuffer } from 'utils/copyToBuffer';

type Props = {
  cryptoTransaction: NestedCryptoTransaction;
  currencyType: string;
};

export const getTransactionPageAcceptedCryptoTableData = ({
  cryptoTransaction,
  currencyType,
}: Props) => [
  {
    title: 'Transaction ID:',
    value: cryptoTransaction?.transaction?.id,
    onClick: () => copyToBuffer(cryptoTransaction?.transaction?.id, true),
  },
  {
    title: 'Amount:',
    value: (
      <>
        {cryptoTransaction?.transaction.amount} {cryptoTransaction?.transaction.amount_type}
        <ArrowFatLineRight size={14} />
        {cryptoTransaction?.crypto_amount} {currencyType}
      </>
    ),
  },
  {
    title: 'Exchange rate:',
    value: cryptoTransaction?.exchange_rate,
  },
  {
    title: 'Type:',
    value: cryptoTransaction?.transaction?.type,
  },
  {
    title: 'Method:',
    value: cryptoTransaction?.transaction?.method,
  },
  {
    title: 'Status:',
    value: cryptoTransaction?.transaction?.status,
  },
];

export const getTransactionPageAcceptedCryptoClientTableData = ({
  cryptoTransaction,
}: Pick<Props, 'cryptoTransaction'>) => [
  {
    title: 'Name:',
    value: `${cryptoTransaction?.transaction?.user?.user_profile?.first_name} ${cryptoTransaction?.transaction?.user?.user_profile?.last_name}`,
  },
  {
    title: 'Trading account ID:',
    value: cryptoTransaction?.transaction?.trading_account_id,
    onClick: () => copyToBuffer(cryptoTransaction?.transaction?.trading_account_id, true),
  },
  {
    title: 'Balance:',
    value: cryptoTransaction?.transaction?.user?.balance,
  },
];

export const timeValidation = (value) => value > Date.now() || 'You can not choose past time';

export const remindersValidation = (getValues) => (selected) => {
    const time = getValues("time");
    if (!time) {
        return true;
    }

    const timeLeft = time - Date.now();
    return selected.every(option => +option.value * 60000 < timeLeft) || 'You can not choose past time for reminders'
};
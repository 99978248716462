import Popup from "reactjs-popup";
import { useState } from "react";
import styles from './offices-table.module.scss'
import classNames from "classnames/bind";
import { getOperatorsForTeamMethod } from "api/hierarchy";
import { HierarchyOperator } from "models/Hierarchy/HierarchyData";

const cx = classNames.bind(styles);

type Props = {
  triggerElement: JSX.Element;
  teamID: number;
  teamName: string;
  onOpen?: () => void;
  onClose?: () => void;
  nested?: boolean;
}

export const ManagementTeamPopup = (props: Props) => {
  const { triggerElement, teamID, teamName, onOpen, onClose, nested } = props;
  
  const [team, setTeam] = useState<HierarchyOperator[] | null>(null);
  
  const fetchDeskTeams = () => {
    getOperatorsForTeamMethod(teamID).then(res => setTeam(res.data.data.operators));
  }
  
  return (
    <Popup 
      modal 
      trigger={triggerElement} 
      onOpen={() => {
        fetchDeskTeams();
        if (onOpen) {
          onOpen();
        }
      }} 
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      nested={nested}
    >
      {(close) => (
        <div className={cx("management_team_popup_container")}>
          <div className={cx("popup_title_block")}>
            <span>User List for {teamName}</span>
            <i className={cx("close")} onClick={(e) => {
              e.stopPropagation();
              close();
            }} />
          </div>
          <div className={cx(`management_team_popup_content${nested ? '_nested' : ''}`)}>
            {team?.map(user => 
              <div className={cx("management_team_popup_entry")}>
                <p key={`USER-${user.id}-title`} className={cx("title")}>{user.first_name + (user.last_name && ` `) + user.last_name}</p>
              </div>
            )}
            {team?.length === 0 && <p>No users</p>}
          </div>
        </div>
      )}
    </Popup>
  )
}
import { FC } from 'react';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import './customStyle.css';

import { MenuItems } from './components/MenuItems/MenuItems.tsx';
import { Logo } from './components/Logo/Logo.tsx';
import cn from 'classnames';
import styles from './menu.module.scss';

type MenuProps = {
  isOpen: boolean;
  isMobile?: boolean;
  onClose: () => void;
};

// проверка что юзер заходит с Microsoft Edge
const isEdge = window.navigator.userAgent.indexOf('Edg') > -1;

const Menu: FC<MenuProps> = ({ isOpen, isMobile = false, onClose }) => {
  return (
    <>
      <div
        className={cn(
          styles.menu,
          isOpen && styles.menu_open,
          isMobile ? styles.menu_mobile : styles.menu_desktop,
        )}
      >
        <Logo onClick={onClose} />
        <div className={styles.title}>Control panel</div>
        <MenuItems onClose={onClose} isMobile={isMobile} />
      </div>
      <div
        className={cn(
          styles.overlay,
          isOpen && styles.overlay_show,
          isEdge && styles.overlay_noAnimation,
        )}
        onClick={onClose}
      />
    </>
  );
};

export default Menu;

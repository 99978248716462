import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

export const FeedFields = ({ actionTypesList }) => {
  const { control } = useFormContext();

  return (
    <Row>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <JokerSelect
          label={'Action types'}
          control={control}
          id="action_type"
          name="action_type"
          options={actionTypesList}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <DatePickerRange
          name="created_at"
          control={control}
          label={'Action date range'}
          placeholder="From - To"
        />
      </Col>
    </Row>
  );
};

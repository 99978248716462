import styles from './filter.module.scss';
import Button from 'components/ui/Button';
import timeToInteger from 'utils/timeToInteger';
import { useFormContext } from 'react-hook-form';
import { ReloadButton } from 'components/ui/ReloadButton';
import { dealsClientDefaultValues } from '../useDeals';

type Props = {
  onSubmit: (data?: any) => void;
  reloadFilters: boolean;
  setDefaultColumnsOrder: () => void;
};

const DealsFilter = (props: Props) => {
  const { onSubmit, reloadFilters, setDefaultColumnsOrder } = props;

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useFormContext();

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        trading_account: getValue(data['trading_account']),
        symbol: getValue(data['ticker']),
        close_at: getDateRange(data['close_at']),
      },
    };

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };

    onSubmit(postData);
  };

  const resetForm = () => {
    reset(dealsClientDefaultValues);
    onSubmit(null);
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const getDateRange = (data) => {
    if (!data) {
      return;
    }

    if (!data.from && !data.to) {
      return;
    }

    const res = {};

    if (data.from) {
      res['from'] = timeToInteger(data['from']) / 1000;
    }

    if (data.to) {
      res['to'] = timeToInteger(data['to']) / 1000;
    }

    return res;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={styles.form}
    >
      <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
      <div className={styles.buttons}>
        <ReloadButton isLoading={reloadFilters} onClick={() => submit(getValues())} />
        <Button
          buttonText="Reset"
          buttonType="outline"
          isLoading={reloadFilters}
          type="reset"
          onClick={resetForm}
          disabled={!getDirtyFieldsLength()}
        />
        <Button
          buttonText="Apply"
          isLoading={reloadFilters}
          type="submit"
          onClick={handleSubmit(submit)}
          disabled={!getDirtyFieldsLength()}
        />
      </div>
    </form>
  );
};

export default DealsFilter;

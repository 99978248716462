export const permissionMenu = (data, topMenuItems) => {
  const temp = [];
  const menu = [];
  data?.forEach(item => {
    temp.push(item.key);
    const sub = item.subItems || item.subNav;
    if (sub) {
      sub.forEach(el => {
        temp.push(el.key);
      });
    }
  });
  topMenuItems.slice(0).forEach(item => {
    if (temp.includes(item.key)) {
      menu.push(item);
      if (item.subNav) {
        item.subNav.forEach((el, i) => {
          if (!temp.includes(el.key)) {
            delete item.subNav[i];
          }
        });
      }
    }
  });
  menu.forEach(item => {
    if (item.subNav) {
      if (!Object.values(item.subNav).length) {
        item.subNav = [];
      }
    }
  });
  return menu;
};

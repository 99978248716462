import { GroupBase, StylesConfig } from 'react-select';

type Option = { value: number | string; label: number | string };

type Props = {
  isDisabled?: boolean;
  errors?: any;
};

export const simpleSelectStyles: (
  props: Props,
) => StylesConfig<Option, false, GroupBase<Option>> = ({ errors, isDisabled }: Props) => ({
  container: (base) => ({ ...base, boxShadow: '0px 1px 3px 0px rgba(96, 108, 128, 0.05)' }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (base) => ({ ...base, padding: 0 }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
    margin: '2px 0',
    backgroundColor: 'var(--white)',
    borderRadius: 8,
    border: '1px solid var(--cyan-100)',
    boxShadow: '0px 1px 3px 0px rgba(96, 108, 128, 0.05)',
  }),
  menuList: (base) => ({
    ...base,
    padding: 6,
    maxHeight: 180,
  }),
  control: (base, state) => ({
    ...base,
    fontWeight: 700,
    fontSize: 'var(--font-size-12)',
    lineHeight: '20px',
    minHeight: 32,
    color: 'var(--grey)',
    backgroundColor: isDisabled ? 'var(--light-grey-disabled)' : 'var(--white)',
    borderRadius: 8,
    border:
      state.isFocused || state.menuIsOpen
        ? '1px solid var(--main-color)'
        : errors
        ? ' 1px solid var(--red)'
        : isDisabled
        ? '1px solid var(--light-grey-secondary)'
        : ' 1px solid var(--cyan-100)',
    boxShadow: 'none',
    transition: 'border 0.1s linear',
    cursor: 'pointer',
    ':hover': {
      border: '1px solid var(--main-color)',
    },
  }),
  option: (base, state) => ({
    ...base,
    fontWeight: 500,
    fontSize: 'var(--font-size-13)',
    lineHeight: '34px',
    letterSpacing: '0.36px',
    padding: '0 8px',
    color: 'var(--primary-text-color)',
    backgroundColor: state.isSelected ? 'var(--cyan)' : 'var(--white)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? 'var(--cyan)' : 'var(--main-light-color)',
    },
  }),
  input: (s) => ({ ...s, color: 'var(--primary-text-color)' }),
  singleValue: (base) => ({ ...base, color: 'var(--grey)' }),
  valueContainer: (base) => ({ ...base, padding: '0 16px' }),
  clearIndicator: (base) => ({ ...base, padding: 0 }),
});

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './datepicker-range.module.scss';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import FormInput from '../FormInput';
import { useOnClickOutside } from '../../../utils/useClickOutside';
import moment from 'moment';
import { useController } from 'react-hook-form';
import './customStyle.scss';
import { IRange } from '../../../models/components/DatePickerRange/DatePickerRange';
import { Icon } from '../Icon';

const cx = classNames.bind(styles);

const DatePickerRange = (props) => {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [range, setRange] = useState<IRange[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const contentRef = useRef(null);
  const { field } = useController(props);

  const handleSelect = ({ selection }): void => {
    setRange([selection]);
    const from: string = moment(selection.startDate).format('Y-MM-DD');
    const to: string = moment(selection.endDate).format('Y-MM-DD');
    field.onChange({ from, to });
    props.onChange?.({ from, to });
    if (props.handleChange) {
      props.handleChange({ from: from, to: to });
    }
  };

  useOnClickOutside(contentRef, (): void => {
    setShowDatePicker(false);
  });

  useEffect((): void => {
    if (!field.value) {
      setInputValue('');
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
      return;
    }

    setInputValue(`${field?.value?.from} - ${field?.value?.to}`);
    setRange([
      {
        startDate: new Date(field?.value?.from),
        endDate: new Date(field?.value?.to),
        key: 'selection',
      },
    ]);
  }, [field.value]);

  return (
    <div className={cx('date-range')}>
      <div className={cx('date-input')}>
        <FormInput
          name={props.name}
          type="text"
          value={inputValue}
          onChange={(): void => {
            field.onChange(null);
            props.onChange?.(null);
          }}
          autoFocus={props.autoFocus}
          autoComplete={'none'}
          placeholder={props.placeholder}
          label={props.label}
          control={props.control}
          rules={props.rules}
          errors={props.errors}
          onFocus={() => setShowDatePicker(true)}
        />
        <span className={cx('icon')}>
          <Icon name="calendar" />
        </span>
      </div>
      {showDatePicker && (
        <div className={cx('date-range')} ref={contentRef}>
          <DateRange
            className={cx('date-range__calendar')}
            editableDateInputs={false}
            moveRangeOnFirstSelection={false}
            ranges={range}
            onChange={handleSelect}
          />
        </div>
      )}
    </div>
  );
};

export default DatePickerRange;

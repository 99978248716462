export const getNumberRange = (data): undefined | object => {
  if (!data) {
    return;
  }

  if (!data.from && !data.to) {
    return;
  }

  const res: object = {};

  if (+data.from) {
    res['from'] = +data.from;
  }

  if (+data.to) {
    res['to'] = +data.to;
  }

  return res;
};

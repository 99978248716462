import { usePermission } from 'utils/usePermission';
import { ListValue } from 'models/ListValue';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import Button from 'components/ui/Button';
import CreateOperatorsPopup from './OperatorsPopups/CreateOperatorsPopup';
import styles from './operators-page.module.scss';

type Props = {
  total?: number;
  branchTypeList: ListValue[];
  updateComponentCallback: () => void;
};

export const OperatorsPageTitle = ({ total, branchTypeList, updateComponentCallback }: Props) => {
  const { permissionGiven: PermissionCreateOperator } = usePermission('admin.admin-user.create');

  return (
    <div className={styles.pageTitle}>
      <div>{total || 0} Operators found</div>
      {PermissionCreateOperator ? (
        <CreateOperatorsPopup
          branchTypeList={branchTypeList}
          updateComponent={updateComponentCallback}
          actionType={SALES_RULES_ACTION_TYPE.CREATE}
          triggerBtn={
            <Button buttonText={'+ New operator'} onClick={() => null} buttonType={'outline'} />
          }
        />
      ) : null}
    </div>
  );
};

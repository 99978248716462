import { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import Select from 'react-select';
import {
  MultiSelectOption,
  MultiSelectField,
  MultiSelectMenu,
  Dropdown,
  MultiSelectAllCheckbox,
} from './components';
import { getMultiSelectStyles } from './styles';
import styles from './multi-select.module.scss';

type Option = {
  value: string | number;
  label: string;
};

type Props = {
  label?: string;
  control: Control<any>;
  id: string;
  name: string;
  options?: Option[];
  placeholder?: string;
  isDisabled?: boolean;
  rules?: any;
  errors?: any;
  maxSelected?: number;
  onChange?: (data: any) => void;
};

export const MultiSelect = ({
  control,
  id,
  label,
  name,
  options,
  placeholder = 'Any',
  isDisabled,
  rules,
  errors,
  maxSelected,
  onChange,
}: Props) => {
  const { field } = useController({ name, control });

  const [isOpen, setIsOpen] = useState(false);

  const onToggleMenu = () => setIsOpen((v) => !v);

  const handleChange = (data: any) => {
    if (!maxSelected || data.length <= maxSelected) {
      field.onChange(data);
      onChange?.(data);
    }
  };

  const onDeleteChipOption = (v: string | number) => {
    const newSelectedChips = field.value.filter((o) => String(o.value) !== String(v));
    handleChange(newSelectedChips);
  };

  const hasOptions = (options || []).length > 0;

  return (
    <>
      <Dropdown
        isOpen={isOpen}
        onClose={onToggleMenu}
        target={
          <>
            {label && (
              <label htmlFor={id} className={styles.label}>
                {label}
                {rules && rules.required ? <span className={styles.required}>*</span> : null}
              </label>
            )}
            <MultiSelectField
              isOpen={isOpen}
              isDisabled={isDisabled}
              chips={field.value}
              placeholder={placeholder}
              hasErrors={!!errors}
              onToggleMenu={onToggleMenu}
              onDeleteValue={onDeleteChipOption}
            />
          </>
        }
      >
        {hasOptions ? (
          <MultiSelectAllCheckbox
            selectedCount={field.value?.length || 0}
            options={options}
            onChange={handleChange}
          />
        ) : null}
        <Select
          {...field}
          {...{ id, options, isDisabled }}
          autoFocus
          backspaceRemovesValue={false}
          placeholder="Search..."
          styles={getMultiSelectStyles(hasOptions)}
          menuIsOpen
          isMulti
          isSearchable
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          tabSelectsValue={false}
          onChange={handleChange}
          components={{
            ClearIndicator: null,
            DropdownIndicator: null,
            Option: MultiSelectOption,
            Menu: MultiSelectMenu,
          }}
        />
      </Dropdown>
      {errors && <p className={styles.error}>{errors.message}</p>}
    </>
  );
};

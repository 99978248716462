import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from '../crypto-payments-page.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import { deleteWallet, restoreWallet } from 'api/customer-service/crypto-payments';
import { WALLET_ACTION_TYPE } from 'constants/payments.const';
import { ShortCryptoWallet } from 'models/CustomerService/CryptoWallets';

const cx = classNames.bind(styles);

type Props = {
  wallet: ShortCryptoWallet;
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  actionType: string;
  onCloseModal: () => void;
};

const DeleteRestorePopup = (props: Props) => {
  const { triggerBtn, updateComponent, wallet, actionType } = props;

  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (closeModal): void => {
    closeModal();
  };

  const onSubmit = (close): void => {
    setIsLoading(true);

    if (actionType === WALLET_ACTION_TYPE.DELETE) {
      deleteWalletHandler(close);
    } else {
      restoreWalletHandler(close);
    }
  };

  const deleteWalletHandler = (close): void => {
    deleteWallet(wallet.id)
      .then((res) => {
        setIsLoading(false);
        updateComponent?.();
        closeModal(close);
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const restoreWalletHandler = (close) => {
    restoreWallet(wallet.id)
      .then((res) => {
        setIsLoading(false);
        updateComponent?.();
        closeModal(close);
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              actionType === WALLET_ACTION_TYPE.DELETE ? 'Delete wallet' : 'Restore  wallet'
            }
            rightContent={
              <div className={cx('content-form', 'popupForm', 'delete-wallet')}>
                <div className={cx('content-controls')}>
                  <Row>
                    <Col md={12}>
                      <span>
                        Are you sure, you want to{' '}
                        {actionType === WALLET_ACTION_TYPE.DELETE ? 'delete' : 'restore'}{' '}
                        <b>{wallet?.wallet}</b> wallet?
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText="Cancel"
                          buttonType="outline"
                          type="button"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          isLoading={isLoading}
                          buttonText={
                            actionType === WALLET_ACTION_TYPE.DELETE ? 'Delete' : 'Restore'
                          }
                          onClick={(data) => onSubmit(close)}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default DeleteRestorePopup;

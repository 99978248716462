import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './../../../ClientTabs/TradingAccounts/tradingAccounts.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { getTradingAccPassword } from 'api/tradingAcc';
import { notify } from 'utils/notify';

const cx = classNames.bind(styles);

const ShowPassword = ({ triggerBtn, tradingAccount }) => {
  const { reset } = useForm({
    reValidateMode: 'onChange',
  });

  const [password, setPassword] = useState('');

  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  useEffect(() => {
    getTradingAccPassword(tradingAccount?.trading_account)
      .then((data) => {
        setPassword(data.data.password);
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err,
          timeOut: 2000,
        });
      });
  }, [tradingAccount]);

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Show password'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          <div className={cx('content-password')}>
                            <span>{'Password: '}</span>
                            <span>{password}</span>
                          </div>
                        </Col>
                      </Row>
                      <div className={cx('content-controls')}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="OK"
                            buttonType="outline"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />
                        </div>
                      </div>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default ShowPassword;

import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import en from '../languages/en.json';
import ru from '../languages/ru.json';
import de from '../languages/de.json';
import fr from '../languages/fr.json';
import es from '../languages/es.json';
import lt from '../languages/lt.json';
import sa from '../languages/sa.json';
import pl from '../languages/pl.json';
import { ILangLocalStorage, ILangSwitcher } from "../models/components/LangWrapper.ts";

export const Context: React.Context<ILangSwitcher> = React.createContext(null);

const local: string =
  localStorage.getItem('lang') !== null ? JSON.parse(localStorage.getItem('lang'))[0].value : 'en';

let lang;

if (local === 'en') {
  lang = en;
} else if (local === 'ru') {
  lang = ru;
} else if (local === 'de') {
  lang = de;
} else if (local === 'fr') {
  lang = fr;
} else if (local === 'es') {
  lang = es;
} else if (local === 'lt') {
  lang = lt;
} else if (local === 'pl') {
  lang = pl;
} else if (local === 'sa') {
  lang = sa;
} else {
  lang = en;
}

const LangWrapper = (props) => {
  const [locale, setLocale] = useState<string>(local);
  const [messages, setMessages] = useState(lang);

  const selectLang = (value): void => {
    const newLocale = value.value;
    setLocale(newLocale);

    let saveLangLocalStorage: ILangLocalStorage[] = [
      {
        value: value.value,
        icon: value.icon,
        text: value.text,
        label: value.label,
      },
    ];

    localStorage.setItem('lang', JSON.stringify(saveLangLocalStorage));

    if (newLocale === 'en') {
      setMessages(en);
    } else if (newLocale === 'ru') {
      setMessages(ru);
    } else if (newLocale === 'de') {
      setMessages(de);
    } else if (newLocale === 'fr') {
      setMessages(fr);
    } else if (newLocale === 'es') {
      setMessages(es);
    } else if (newLocale === 'lt') {
      setMessages(lt);
    } else if (newLocale === 'pl') {
      setMessages(pl);
    } else if (newLocale === 'sa') {
      setMessages(sa);
    } else {
      setMessages(en);
    }
  };

  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default LangWrapper;

import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import styles from './checkbox.module.scss';

const cx = classNames.bind(styles);

const CheckBox = (props) => {
  const { zIndex, label } = props;
  const { field } = useController(props);
  const [checked, setCheck] = useState<boolean>(field.value);

  const onChange = (value): void => {
    field.onChange(value.target.checked);
    setCheck(!field.value);
    props?.setOpen && props.setOpen(!field.value);
  };

  return (
    <>
      <div className={cx('input-wrap')}>
        <input
          style={{ zIndex }}
          {...field}
          id={props.id}
          checked={field.value}
          className={props.errors ? cx('input', 'error') : cx('input')}
          type={props.type}
          placeholder={props.placeholder}
          defaultChecked={props.checked}
          onChange={onChange}
        />
        <div className={cx('label-wrap', props.labelNoClickable && 'no-click')}>
          {!props.labelNoClickable && (
            <label
              htmlFor={props.id}
              className={props.errors ? cx('input__label', 'error') : cx('input__label')}
            >
              {label}
              {props.rules && props.rules.required ? (
                <span className={cx('required')}>*</span>
              ) : null}
            </label>
          )}
          {props.labelNoClickable && (
            <>
              <i
                className={cx('checkbox-icon', checked && 'icon-checked', props.errors && 'error')}
                onClick={() => setCheck(!checked)}
              ></i>
              <div
                className={
                  props.errors ? cx('input__label-no-click', 'error') : cx('input__label-no-click')
                }
              >
                {label}
                {props.rules && props.rules.required ? (
                  <span className={cx('required')}>*</span>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckBox;

import { useSelector } from 'react-redux';
import { getProgress, getTotalProgress } from '../../../constants/selectors';
import { memo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './search-clients.module.scss';
import { ProgressBar } from 'react-bootstrap';

const cx = classNames.bind(styles);

const ProgressBarSection = () => {
  const total = useSelector(getTotalProgress) || 0;
  const [progress, setProgress] = useState(0);
  const [loop, setLoop] = useState<NodeJS.Timeout | null>(null);

  const fillingLoop = (prev_progress, total_amount, rate) => {
    const newLoop = setTimeout(() => {
      const clamped = Math.min(prev_progress + rate, total_amount);
      if (prev_progress < (total_amount * 0.9)) {
        setProgress(clamped);
      }

      if (total !== 0) {
        fillingLoop(clamped, total_amount, rate);
      }
    }, 1000)
    setLoop(newLoop);
  }

  useEffect(() => {
    if (total !== 0) {
      let rate = 8;
      if (total <= 200) {
        rate = 7;
      } else if (total <= 50) {
        rate = 5;
      }
      setProgress(0);
      fillingLoop(0, total, rate);
    } else {
      if (loop) {
        clearTimeout(loop);
      }
    }
  }, [total])

  return (
    <>
      {total !== 0 ? (
        <div className={cx('progress-bar')}>
          <span className={cx('progress-bar__status')}>{`${progress} out of ${total} ready`}</span>
          <ProgressBar
            now={progress}
            animated
            max={total}
          />
        </div>
      ) : null}
    </>
  );
};

export default memo(ProgressBarSection);

import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TabPanel, Tabs } from 'react-tabs';
import Popup from 'reactjs-popup';
import FormInputAutoExternalId from 'components/ui/FormInputAutoExternalId';
import FormInputAutoPassword from 'components/ui/FormInputAutoPassword';
import {
  EXTERNAL_ID_VALIDATION,
  PASSWORD_INPUT_VALIDATION,
  VALID_EMAIL,
} from 'constants/validators';
import styles from './partners-popup.module.scss';
import { createPartnersMethod } from 'api/partners';
import { notify } from 'utils/notify';
import { useSelector } from 'react-redux';
import { getEnableMT } from 'constants/selectors';
import { ListValue } from 'models/ListValue';
import CheckBox from 'components/ui/CheckBox';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  countryList: ListValue[];
  actionType: string;

  // Следующие поля используются, но нигде не передаются:
  branchTypeList?: any;
  deskTypeList?: any;
  userTypesList?: any;
  langList?: any;
  ruleData?: any;
  departmentsList?: any;
  branchList?: any;
};

const CreatePartnersPopup = (props: Props) => {
  const {
    updateComponent,
    triggerBtn,
    branchTypeList,
    deskTypeList,
    userTypesList,
    langList,
    actionType,
    ruleData,
    departmentsList,
    countryList,
    branchList,
  } = props;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allowApi, setAllowApi] = useState(false);
  const ENABLE_MT = useSelector(getEnableMT);
  const setDefaultValues = () => {
    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      return {
        first_name: undefined,
        last_name: undefined,
        email: undefined,
        password: undefined,
        external_id: undefined,
        phone: undefined,
      };
    }
  };

  const generatePassword = (pass) => {
    setValue('password', pass);
  };
  const generateExternalId = (pass) => {
    setValue('external_id', pass);
  };

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const closeModal = (closeModal) => {
    reset(setDefaultValues());
    closeModal();
  };

  useEffect(() => {
    reset(setDefaultValues());
  }, [langList, userTypesList, deskTypeList, ruleData, departmentsList, branchTypeList]);

  const onSubmit = (close, data) => {
    const sendData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      external_id: data.external_id,
      phone: data.phone,
      public_api_status: data.public_api_status,
    };

    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      addDeskHandler(sendData, close);
    }
  };

  const addDeskHandler = (sendData, close) => {
    setIsLoading(true);

    createPartnersMethod(sendData)
      .then((res) => {
        if (res.status === 201) {
          if (res.data.data.id) {
            window.open(`/partner/${res.data.data.id}`);
            // navigate(`/operator/${res.data.data.id}`);
          }
          notify({
            type: 'success',
            message: 'Affiliate created successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              actionType === SALES_RULES_ACTION_TYPE.CREATE ? 'New affiliate' : 'Edit affiliate'
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container className="d-flex flex-column gap-4">
                      <Row className="row-gap-3">
                        <Col md={6}>
                          <FormInput
                            rules={{
                              required: 'Name field is required',
                              validate: {
                                correctName: (value) => value.length < 50 || 'Name too long',
                              },
                            }}
                            label={'Name'}
                            id="first_name"
                            control={control}
                            name="first_name"
                            placeholder={'Name'}
                            errors={errors.first_name}
                          />
                        </Col>

                        <Col md={6}>
                          <FormInput
                            type="email"
                            errors={errors.email}
                            label={'Email'}
                            id="email"
                            control={control}
                            name="email"
                            placeholder={'Email'}
                            rules={{
                              required: 'E-mail field is required',
                              validate: VALID_EMAIL,
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="row-gap-3">
                        <Col md={6}>
                          <FormInputAutoPassword
                            generatePassword={generatePassword}
                            label={'Password'}
                            id="password"
                            control={control}
                            name="password"
                            placeholder={'Password'}
                            errors={errors.password}
                            rules={{
                              required: 'Password field is required',
                              validate: PASSWORD_INPUT_VALIDATION,
                            }}
                          />
                        </Col>
                        {ENABLE_MT ? (
                          <Col md={6}>
                            <FormInputAutoExternalId
                              generatePassword={generateExternalId}
                              label={'External ID'}
                              id="external_id"
                              control={control}
                              name="external_id"
                              placeholder={'External ID'}
                              errors={errors.external_id}
                              rules={{
                                required: 'External ID field is required',
                                validate: EXTERNAL_ID_VALIDATION,
                              }}
                            />
                          </Col>
                        ) : null}
                      </Row>
                      <Row className="row-gap-3">
                        <CheckBox
                          type="checkbox"
                          id={'public_api_status'}
                          name="public_api_status"
                          control={control}
                          label={'Allow to create Clients through public API'}
                        />
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <Container>
                        <Row>
                          <Col md={12}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <Button
                                isLoading={isLoading}
                                buttonText={'Confirm'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreatePartnersPopup;

import { Icon } from 'components/ui/Icon';
import cn from 'classnames';
import styles from '../multi-select.module.scss';

type Props = {
  isOpen: boolean;
  isDisabled: boolean;
  chips: any[];
  placeholder?: string;
  hasErrors: boolean;
  onToggleMenu: () => void;
  onDeleteValue: (value: string | number) => void;
};

export const MultiSelectField = ({
  isOpen,
  isDisabled,
  chips,
  placeholder = 'Any',
  hasErrors,
  onToggleMenu,
  onDeleteValue,
}: Props) => {
  return (
    <div
      className={cn(
        styles.field,
        isOpen && styles.field__focus,
        isDisabled && styles.field__disabled,
        hasErrors && styles.field__error,
      )}
      onClick={onToggleMenu}
    >
      {!chips?.length ? (
        <div className={styles.placeholder}>{placeholder}</div>
      ) : (
        <div className={styles.chips}>
          {chips.map((chip) => (
            <span key={chip.value} className={styles.chip}>
              <span className={styles.chip__text}>{chip.label}</span>
              <Icon
                name="close"
                size={10}
                className={styles.chip_clear}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteValue(chip.value);
                }}
              />
            </span>
          ))}
        </div>
      )}

      <div className={styles.field__caret}>
        <Icon name="caretDown" size={16} color="var(--black)" />
      </div>
    </div>
  );
};

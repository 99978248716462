import React, { useState } from "react";
import Button from "../../../../components/ui/Button";
import { ChatCircleDots } from "phosphor-react";
import { chatGetCrmUserToken, chatGetUser, createChatGroup, getChatRecipient } from "../../../../api/chat";
import { useNavigate } from "react-router-dom";
import { addGroup } from "../../../../store/slice/chat/chatSlice";
import { useAppDispatch } from "../../../../store";
import './ChatButton.scss';
import {ThreadTypes} from "../../../../models/Chat";

export const ChatButton = ({ id }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);

    const handleClick = async () => {
        try {
            setDisabled(true);
            const userAuth = await chatGetCrmUserToken(id);
            const user = await chatGetUser(id, userAuth.data);
            const recipientData = await getChatRecipient(user.data.id);

            const group = await createChatGroup({
                subject: `${recipientData.data.recipient.base.name} - Support`,
                providers: [{ alias: 'user', id: user.data.id }]
            });

            sessionStorage.setItem('chat-tab', `${ThreadTypes.support}`);
            dispatch(addGroup(group.data));
            navigate(`/chat/${group.data.id}`);
        } catch (e) {
            console.log(e);
        } finally {
            setDisabled(false);
        }
    };

    return (
        <Button
            buttonType='outline'
            disabled={disabled}
            icon={<ChatCircleDots size={32} />}
            onClick={handleClick}
            className='chat-button'
        />
    )
}
import axios from 'axios'
import Echo from 'laravel-echo'

import Pusher from 'pusher-js'
import { notify } from 'utils/notify'
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { setProgress, setStatus, setTotal } from '../store/slice/userInfoSlice';
import { fetchCallbacksToPopulateReminders } from "../store/slice/reminders";
import { addNotification, increaseNotificationCount } from "../store/slice/notifySlice";
import { clientRelatedTypes } from "../constants/notifications";

window.Pusher = Pusher;

export const ConnectWSS = ({ token, user_id, dispatch }) => {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'jokerKey',
    wsHost: process.env.REACT_APP_API_URL_WS,
    wsPort: process.env.REACT_APP_API_URL_WS_PORT,
    wssHost: process.env.REACT_APP_API_URL_WS,
    wssPort: process.env.REACT_APP_API_URL_WS_PORT,
    forceTLS: false,
    enabledTransports: ['ws', 'wss'],

    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          socketId &&
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
              {
                socket_id: socketId,
                channel_name: channel.name,
              },
              {
                headers: {
                  Authorization: `Bearer ${token} `,
                },
              },
            )
            .then((response) => {
              callback(false, response.data)
            })
            .catch((error) => {
              callback(true, error)
            })
        },
      }
    },
  })

  window.Echo.private(`notifications.${user_id}`)
    .listen('.private.notification', (e) => {
      if (e) {
        dispatch(increaseNotificationCount(1));
        dispatch(addNotification(e.notification));

        if (clientRelatedTypes.includes(e.notification.type)) {
          notify({ type: 'info', message: e.notification.alert_text, timeOut: 0 });
        } else {
          notify({ type: 'info', message: e.notification.details, timeOut: 2000 })

          if (e.notification.type === 'callback') {
            dispatch(fetchCallbacksToPopulateReminders());
          }
        }
      }
    })
    .subscribed(function (e) {
      // console.log('Connecting to WebSocket is successful');
    })

  window.Echo.private(`bulk.${user_id}`)
    .listen('.private.bulk', (e) => {
      if (e && e.status === 'started') {
        dispatch(setTotal(e.total_count))
      }
      if (e && e.status === 'ended') {
        dispatch(setStatus('new'))
        dispatch(setProgress(0))
        dispatch(setTotal(0))
        setTimeout(NotificationManager.success(`Clients have been transferred, expect full loading.`, 'Success', 5000), 1000)
        dispatch(setStatus(null))
      }
    })
    .subscribed(function (e) {
      // console.log('Connecting to WebSocket is successful');
    })
  return null
}

export const DisconnectWSS = () => {
  const user_id = localStorage.getItem('user_id');

  if (user_id) {
    window?.Echo?.leave(`notifications.${user_id}`);
    window?.Echo?.leave(`bulk.${user_id}`);
  }
};

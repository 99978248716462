const CheckRole = ({ children, permissionType, roleType }) => {
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const roles = JSON.parse(localStorage.getItem('role'));

  const checkPermission = permissions.some((element) => element.name === permissionType);
  const checkSuperAdminRole = roles.indexOf('Super Admin') > -1;
  const checkRole: any = roles.indexOf(roleType) > -1;

  if (checkPermission === true || checkSuperAdminRole === true || checkRole === 'Super Admin') {
    return children;
  } else {
    return null;
  }
};

export default CheckRole;

import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './offices-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { addOfficeMethod, updateOfficeMethod } from 'api/managements/offices';
import { CountryListValue } from 'models/Countries';
import { HierarchyOffice } from 'models/Hierarchy/HierarchyData';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  countryList: CountryListValue[];
  actionType: string;
  ruleData?: HierarchyOffice;
};

const CreateOfficesPopup = (props: Props) => {
  const { triggerBtn, updateComponent, countryList, actionType, ruleData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const setDefaultValues = () => {
    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      return {
        name: undefined,
        country_ids: undefined,
        brand_id: 1,
      };
    }
    return {
      name: ruleData?.name,
      country_ids: { value: ruleData?.country?.id, label: ruleData?.country?.name },
      brand_id: 1,
    };
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  useEffect(() => {
    reset(setDefaultValues());
  }, [countryList, ruleData]);

  const onSubmit = (close, data) => {
    const sendData = {
      name: data.name,
      country_id: getValue(data['country_ids']),
      brand_id: data.brand_id,
    };

    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      addOfficeHandler(sendData, close);
    } else {
      editRuleHandler(sendData, close);
    }
  };

  const addOfficeHandler = (sendData, close) => {
    setIsLoading(true);

    addOfficeMethod(sendData)
      .then((res) => {
        if (res.status === 201) {
          notify({
            type: 'success',
            message: 'Office created successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const editRuleHandler = (sendData, close) => {
    setIsLoading(true);

    updateOfficeMethod(ruleData?.id, sendData)
      .then((res) => {
        if (res.data.data) {
          notify({
            type: 'success',
            message: 'Office edited successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              actionType === SALES_RULES_ACTION_TYPE.CREATE ? 'New office' : 'Edit office'
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container className="d-flex flex-column gap-4">
                      <Row>
                        <Col md={12}>
                          <FormInput
                            rules={{
                              required: 'Office name field is required',
                            }}
                            label={'Office name'}
                            id="office_name"
                            control={control}
                            name="name"
                            placeholder={'Office name'}
                            errors={errors.name}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <JokerSelect
                            isRequired
                            label="Country"
                            control={control}
                            id="country"
                            name="country_ids"
                            placeholder={'Search'}
                            options={countryList}
                            errors={errors.country_ids}
                            rules={{
                              required: 'Country field is required',
                            }}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <Container>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="Cancel"
                            buttonType="outline"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />
                          <Button
                            isLoading={isLoading}
                            buttonText={
                              actionType === SALES_RULES_ACTION_TYPE.CREATE
                                ? 'Create office'
                                : 'Apply change'
                            }
                            type="submit"
                            onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                          />
                        </div>
                      </Container>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateOfficesPopup;

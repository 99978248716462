import { getPagesArray, MAX_SHOW_PAGES } from './utils';

type Props = {
  currentPage: number;
  pageCount: number;
};

export const useTablePaginationPages = ({ currentPage, pageCount }: Props) => {
  const pages = getPagesArray(currentPage, pageCount);
  const isMoreThanMax = pageCount > MAX_SHOW_PAGES;

  return {
    pages,
    hasRightArrow: (index: number) =>
      index === pages.length - 1 && pageCount - currentPage > 2 && isMoreThanMax,
    hasLeftArrow: (index: number) => !index && currentPage > 3 && isMoreThanMax,
  };
};

import useContextMenu from "../../../../hooks/useContextMenu";
import { useRef } from "react";
import { Thread, UnreadTypeRoutes } from "../../../../models/Chat";
import { ChatThread } from "../ChatThread/ChatThread";
import { useAppSelector } from "../../../../store";
import {
    selectChatUserId,
    selectMutedThreads,
    selectThreadId,
    selectUserStatuses
} from "../../../../store/slice/chat/chatSlice";
import { ContextMenu } from "../../../../components/ui/ContextMenu/ContextMenu";
import { useNavigate } from "react-router-dom";
import { RemoveChatModal } from "../modals/RemoveChatModal/RemoveChatModal";
import { ViewProfileOption } from "../menuOptions/ViewProfileOption";
import { DeleteContactOption } from "../menuOptions/DeleteContactOption";
import { OpenInNewTabOption } from "../menuOptions/OpenInNewTabOption";
import { MuteNotificationsOption } from "../menuOptions/MuteNotificationsOption";
import { PinThreadOption } from "../menuOptions/PinThreadOption";
import './ChatThreads.scss';

type ChatThreadsProps = {
    threads: Thread[];
};

export const ChatThreads = ({ threads }: ChatThreadsProps) => {
    const navigate = useNavigate();
    const userId = useAppSelector(selectChatUserId);
    const mutedThreads = useAppSelector(selectMutedThreads);
    const threadId = useAppSelector(selectThreadId);
    const userStatuses = useAppSelector(selectUserStatuses);
    const contextMenuRef = useRef(null);
    const { clicked, setClicked, selectedEntity: selectedThread, setSelectedEntity, points, setPoints } = useContextMenu<any>(contextMenuRef);
    const profileId = selectedThread?.resources?.recipient?.base?.out_user_id;

    const handleContextMenu = (event: any, entity: any) => {
        event.preventDefault();
        setClicked(true);
        setSelectedEntity(entity);
        setPoints(event);
    };

    const handleThreadClick = (id) => () => navigate(`/chat/${id}`);

    return (
        <>
            <div className="chat-threads">
                {threads.map(thread => (
                    <ChatThread
                        userId={userId}
                        thread={thread}
                        status={userStatuses[thread.resources?.recipient?.provider_id]}
                        active={(thread.id === selectedThread?.id) || (threadId === thread.id)}
                        isMuted={mutedThreads[thread.id]}
                        handleContextMenu={handleContextMenu}
                        onClick={handleThreadClick(thread.id)}
                        key={thread.id}
                    />
                ))}
            </div>
            <ContextMenu
                contextMenuRef={contextMenuRef}
                isToggled={clicked}
                positionX={points.x}
                positionY={points.y}
            >
                <OpenInNewTabOption threadId={selectedThread?.id}/>
                {(!selectedThread?.group && profileId) ? (
                    <ViewProfileOption url={`/${UnreadTypeRoutes[selectedThread?.resources?.recipient?.base?.type]}/${profileId}`}/>
                ): null}
                <PinThreadOption threadId={selectedThread?.id} isPinned={selectedThread?.pinned}/>
                <MuteNotificationsOption threadId={selectedThread?.id}/>
                <DeleteContactOption contact={selectedThread}/>
            </ContextMenu>
            <RemoveChatModal/>
        </>
    )
}
import Button from 'components/ui/Button';
import RoleTable from 'components/ui/Table/TemplateTable';
import TablePagination from 'components/ui/TablePagination';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React, { useEffect, useRef, useState } from 'react';
import { getRolesListMethod } from 'api/templateRoles';
import CreateRolePopup from './CreateRolePopup';

import styles from './style.module.scss';
import { getTablePageParams } from 'utils/getTablePageParams';
import { TemplateRole } from 'models/RolesAndPermissions/Templates/Role';
import { TableLinks, TableMeta } from 'models/Table';
import { TemplateListEntry } from 'models/RolesAndPermissions/Templates/Template';
import { usePageTitle } from 'utils/usePageTitle';
import { TableLayout } from 'components/ui/Table/TableLayout';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import { TABLE_TEMPLATE_ROLES } from 'constants/table';

type Props = {
  PermissionShowList: boolean;
  templateList: TemplateListEntry[];
  PermissionEdit: boolean;
  PermissionCreate: boolean;
};

const title = 'Template roles';

const RolesTab = (props: Props) => {
  const [roleList, setRoleList] = useState<TemplateRole[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const componentMounted = useRef(true);

  usePageTitle(title);

  useEffect(() => {
    getRoleList();
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const getRoleList = (options?, data?) => {
    setIsListLoading(true);

    getRolesListMethod(getTablePageParams(options, perPageCount, tableLinks))
      .then((res) => {
        if (componentMounted.current) {
          setRoleList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const table = useTableColumnOrder('TABLE_TEMPLATE_ROLES', TABLE_TEMPLATE_ROLES);

  return (
    <>
      <div className={styles.tabContainer}>
        <div className={styles.tabTitle}>
          {tableMeta?.total || 0} Roles
          {props.PermissionCreate ? (
            <CreateRolePopup
              updateComponent={getRoleList}
              actionType={SALES_RULES_ACTION_TYPE.CREATE}
              triggerBtn={
                <Button buttonText={'+ Add role'} onClick={() => null} buttonType={'outline'} />
              }
            />
          ) : null}
        </div>
        <TableLayout
          className={styles.tableLayout}
          header={
            <Button
              buttonStyle={{ marginBottom: 26 }}
              buttonText="Reset column order"
              onClick={table.setDefaultColumnsOrder}
            />
          }
        >
          {props.PermissionShowList ? (
            <RoleTable
              PermissionEdit={props.PermissionEdit}
              templateList={props.templateList}
              data={roleList}
              perPage={roleList?.length}
              showLoader={isListLoading}
              columns={table.columns}
              saveColumnOrder={table.saveColumnOrder}
              modifyDataHandler={getRoleList}
            />
          ) : null}
        </TableLayout>
      </div>
      {props.PermissionShowList && roleList?.length ? (
        <TablePagination
          currentPage={tableMeta?.current_page}
          pagesLength={tableMeta?.last_page}
          perPageChange={(value) => {
            setPerPageCount(value);
            getRoleList({ perPage: value });
          }}
          goToSelectedPage={(page) => {
            getRoleList({ page });
          }}
          hidePageSelect={true}
        />
      ) : null}
    </>
  );
};

export default RolesTab;

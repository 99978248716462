import moment from 'moment/moment';
import { Client } from 'models/Clients/Clients';

type Props = {
  client: Client;
};

export const ClientLastLogin = ({ client }: Props) => {
  return (
    <>
      <div className="mb-1">
        {client?.visits?.length && client?.visits[0]?.last_visit
          ? moment(client?.visits[0]?.last_visit * 1000).fromNow()
          : '---'}
      </div>
      <div className="mb-1">
        {client?.visits?.length && client?.visits[0]?.last_visit
          ? moment(client?.visits[0]?.last_visit * 1000).format('DD.MM.YYYY')
          : '---'}
      </div>
      <div className="mb-1">
        from{' '}
        {client?.visits?.length && client?.visits[0]?.country
          ? client?.visits[0]?.country.name
          : '---'}
      </div>
    </>
  );
};

import Preloader from "../../../../components/ui/Preloader";
import usePolling from "../../../../utils/usePolling";
import { ChatTypeSwitcher } from "../ChatTypeSwitcher/ChatTypeSwitcher";
import { ChatCreateButton } from "../ChatCreateButton/ChatCreateButton";
import { ChatSearchInputField } from "../ChatSearchInput/ChatSearchInputField";
import { ChatThreads } from "../ChatThreads/ChatThreads";
import { ChatThreadsEmpty } from "../ChatThreads/ChatThreadsEmpty";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectChatLoading } from "../../../../store/slice/chat/chatSlice";
import { getThreads } from "../../../../store/slice/chat/thunks";
import { loadThreadsInterval } from "../../constants";
import { useThreadsFilter } from "./useThreadsFilter";

export const ChatThreadsContainer = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectChatLoading);

    const { threads, type, setType, setSearch } = useThreadsFilter();

    usePolling(() => dispatch(getThreads()), loadThreadsInterval);

    return (
        <div className="chat-threads-wrap">
            {loading ? <Preloader /> : (
                <>
                    <div className="chat-thread-actions">
                        <ChatTypeSwitcher type={type} setType={setType} />
                        <ChatCreateButton type={type} />
                    </div>
                    <ChatSearchInputField>
                        <input placeholder='Search' onChange={(e) => setSearch(e?.target?.value)} />
                    </ChatSearchInputField>
                    {threads.length ? <ChatThreads threads={threads}/> : <ChatThreadsEmpty />}
                </>
            )}
        </div>
    )
}
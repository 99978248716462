import { FunctionComponent } from 'react';
import { DropdownIndicatorProps, components } from 'react-select';
import { Icon } from 'components/ui/Icon';

export const DropdownIndicator: FunctionComponent<DropdownIndicatorProps> = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon name="search" size={20} color='#262626FF'/>
        </components.DropdownIndicator>
    );
};

import API from "../api"

export const getPrefixes = () => (
    API.get('/api/v1/voip/get-prefixes')
)

// POST /api/v1/voip/init-call
// phone - номер телефона
// prefix - префикс, который клиент выбрал из списка или который он ввел в форме ввода префикса
export const initCall = ( prefix, phone ) => (
  API.post('/api/v1/voip/init-call',{ prefix, phone })
)
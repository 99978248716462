import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import { useController } from 'react-hook-form';
import { ITime } from '../../../models/components/CounterTime/CounterTime';
import { Icon } from '../Icon';

const cx = classNames.bind(styles);

const CounterTime = (props) => {
  const { field } = useController(props);
  const [time, setTime] = useState<ITime>(
    field.value
      ? { hours: parseInt(field.value.hours), minutes: parseInt(field.value.minutes) }
      : {
          hours: 0,
          minutes: 0,
        },
  );
  const minusCount = (count, key, max): void => {
    if (count > 0) setTime({ ...time, [key]: parseInt(time[key]) - 1 });
    else {
      if (key === 'hours') {
        setTime({ ...time, [key]: 23 });
      } else setTime({ ...time, [key]: 59 });
    }
  };
  const plusCount = (count, key, max): void => {
    if (count < max) setTime({ ...time, [key]: parseInt(time[key]) + 1 });
  };

  const onChange = (data, max): void => {
    const val: string = Object.values(data).join('');
    let fd = { ...data };
    if (
      (parseInt(val) >= 0 && parseInt(val) <= max) ||
      (parseInt(val) <= max && parseInt(val) >= 0)
    ) {
      if (max === 24) {
        fd = { ...data, hours: `${parseInt(val)}` };
      } else {
        fd = { ...data, minutes: `${parseInt(val)}` };
      }
      setTime({ ...time, ...fd });
    }
  };
  useEffect((): void => {
    field.onChange(time);
  }, [time]);
  return (
    <>
      <div className={cx('label-wrap')} style={props.labelStyle}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
          </label>
        )}
      </div>
      <div className={props.errors ? cx('input-wrap', 'error') : cx('input-wrap')}>
        <div className={styles.mainWrapper}>
          <div className={styles.wrapperItem}>
            <button
              type={'button'}
              onClick={(): void => {
                plusCount(time.hours, 'hours', 24);
              }}
            >
              <Icon name="caretUp" size={24} />
            </button>
            <input
              className={cx('input')}
              min={0}
              max={24}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                onChange({ hours: e.target.value }, 24);
              }}
              type={'number'}
              value={time.hours < 10 ? '0' + time.hours : time.hours}
            />
            <button
              type={'button'}
              onClick={(): void => {
                minusCount(time.hours, 'hours', 24);
              }}
            >
              <Icon name="caretDown" size={24} />
            </button>
          </div>
          <span>:</span>
          <div className={styles.wrapperItem}>
            <button
              type={'button'}
              onClick={(): void => {
                plusCount(time.minutes, 'minutes', 60);
              }}
            >
              <Icon name="caretUp" size={24} />
            </button>

            <input
              className={cx('input')}
              min={0}
              max={60}
              type={'number'}
              value={time.minutes < 10 ? '0' + time.minutes : time.minutes}
              // value={time.minutes}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                onChange({ minutes: e.target.value }, 60);
              }}
            />
            <button
              type={'button'}
              onClick={(): void => {
                minusCount(time.minutes, 'minutes', 60);
              }}
            >
              <Icon name="caretDown" size={24} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CounterTime;

import { ColorSetOption } from '../ColorSetOption/ColorSetOption';
import { ColorSetSingleValue } from '../ColorSetSingleValue/ColorSetSingleValue';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { getStyles, selectClientDesign } from 'store/slice/visualSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { colorSetOptions, styleTypeOptions } from 'constants/systemStyles';
import { setStyleListMethod } from 'api/settingsLK';
import { notify } from 'utils/notify';
import { SystemStylePreview } from '../SystemStylePreview/SystemStylePreview';
import Button from 'components/ui/Button';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

export const ThemesTab = () => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    theme: selectedTheme,
    colorset: selectedColor,
    logo_pc,
  } = useAppSelector(selectClientDesign);

  const { handleSubmit, control, reset } = useForm();
  const { theme, colorset } = useWatch({ control });

  useEffect(() => {
    if (selectedTheme && selectedColor) {
      reset({
        theme: styleTypeOptions.find((option) => option.value === selectedTheme),
        colorset: colorSetOptions.find((option) => option.value === selectedColor),
      });
    }
  }, [selectedTheme, selectedColor]);

  const onSubmitStyles = async (data) => {
    try {
      setSubmitting(true);
      await setStyleListMethod({
        theme: data.theme.value,
        colorset: data.colorset.value,
      });
      dispatch(getStyles());
      notify({
        type: 'success',
        message: 'The styles are updated',
        timeOut: 2000,
      });
    } catch (e) {
      notify({
        type: 'error',
        message: 'Failed to update the styles',
        timeOut: 2000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit(onSubmitStyles)}>
        <Row>
          <Col md={4} className="mb-3">
            <JokerSelect
              id="theme"
              name="theme"
              label="Style type"
              control={control}
              options={styleTypeOptions}
            />
          </Col>
          <Col md={4} className="mb-3">
            <JokerSelect
              id="colorset"
              name="colorset"
              label="Color set"
              isSearchable={false}
              control={control}
              options={colorSetOptions}
              customStyles={{ valueContainer: (s) => ({ ...s, display: 'flex' }) }}
              components={{ Option: ColorSetOption, SingleValue: ColorSetSingleValue }}
            />
          </Col>
          <Col md={4} className="d-flex items-center justify-content-end">
            <Button type="submit" buttonText="Save changes" isLoading={isSubmitting} />
          </Col>
        </Row>
      </form>
      <div className="title">Preview</div>
      <div className="client-preview">
        <div className="client-preview__content">
          <SystemStylePreview
            className={`light ${colorset?.value ?? colorset} ${theme?.value ?? theme}`}
            logo={logo_pc}
          />
          <SystemStylePreview
            className={`dark ${colorset?.value ?? colorset} ${theme?.value ?? theme}`}
            logo={logo_pc}
          />
        </div>
      </div>
    </>
  );
};

import { BalancesData } from 'models/Balances';
import { ReloadButton } from 'components/ui/ReloadButton';
import { useAppSelector } from 'store';
import { getEnableMT } from 'constants/selectors';
import { useRef, useState } from 'react';
import { SimpleSelect } from 'components/ui/SimpleSelect';
import { useParams } from 'react-router-dom';
import { useOnClickOutside } from 'utils/useClickOutside';
import cn from 'classnames';
import styles from './client-balance.module.scss';

type Props = {
  balance: BalancesData;
  fetchBalance: (params: any, period?: string, refreshProfit?: boolean) => Promise<void>;
};

const periodOptions = [
  { value: '', label: 'All time' },
  { value: '1', label: 'One day' },
  { value: '7', label: 'One week' },
  { value: '31', label: 'One month' },
  { value: '366', label: 'One year' },
];

const getStatus = (balance: string | number) => {
  const numericBalance = Number(String(balance).replace(/,/g, ''));

  if (numericBalance === 0) {
    return '';
  }

  return numericBalance > 0 ? 'positive' : 'negative';
};

export const ClientBalance = ({ balance, fetchBalance }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [period, setPeriod] = useState<string | undefined>(undefined);

  const params = useParams();
  const ref = useRef<HTMLDivElement>(null);

  const ENABLE_MT = useAppSelector(getEnableMT);

  useOnClickOutside(ref, () => setIsOpen(false));

  const balances = {
    total_net: balance?.total?.net_balance ? balance?.total?.net_balance : '0.00',
    total_credit: balance?.total?.credit ? balance?.total?.credit : '0.00',
    euro_net: balance?.EUR ? balance?.EUR?.net_balance : '0.00',
    euro_credit: balance?.EUR?.credit ? balance?.EUR?.credit : '0.00',
    usd_net: balance?.USD?.net_balance ? balance?.USD?.net_balance : '0.00',
    usd_credit: balance?.USD?.credit ? balance?.USD?.credit : '0.00',
    total_deposit_count: balance?.total?.deposit_count ? balance?.total?.deposit_count : '0',
    total_withdraw_count: balance?.total?.withdraw_count ? balance?.total?.withdraw_count : '0',
    total_deposit: balance?.total?.deposit ? balance?.total?.deposit : '0.00',
    total_withdraw: balance?.total?.withdraw ? balance?.total?.withdraw : '0.00',
    total_profit: balance?.total?.profit ? balance?.total?.profit : '0.00',
    total_trade: balance?.total?.trade_balance ? balance?.total?.trade_balance : '0.00',
    total_trade_count: balance?.total?.trade_balance_count
      ? balance?.total?.trade_balance_count
      : '0.00',
    total_net_deposit: balance?.total?.net_deposit ? balance?.total?.net_deposit : '0.00',
  };

  return (
    <div ref={ref} className={styles.container}>
      <div>
        <div
          className={cn(
            styles.totalBalance,
            isOpen && styles.totalBalanceOpen,
            isOpen && styles[getStatus(balances.total_net)],
          )}
        >
          USD {balances.total_net}
        </div>
        <div className={styles.totalCredit}>Credit: USD {balances.total_credit}</div>
        {!isOpen && (
          <div className={styles.seeMore} onClick={() => setIsOpen(true)}>
            See more
          </div>
        )}
      </div>
      <div className={cn(styles.dropdown, isOpen && styles.open)}>
        {ENABLE_MT ? (
          <div className={styles.balanceNet}>
            <div className={styles.titleNet}>EUR Balance</div>
            <div className={cn(styles.value, styles[getStatus(balances.euro_net)])}>
              EUR {balances.euro_net}
            </div>
            <div className={styles.description}>Credit: EUR {balances.euro_credit}</div>
          </div>
        ) : null}
        <div className={styles.balanceNet}>
          <div className={styles.titleNet}>USD Balance</div>
          <div className={cn(styles.value, styles[getStatus(balances.usd_net)])}>
            USD {balances.usd_net}
          </div>
          <div className={styles.description}>Credit: USD {balances.usd_credit}</div>
        </div>
        <div className={'mb-3 mt-1'}>
          <SimpleSelect
            options={periodOptions}
            onChange={(option) => {
              setPeriod(option.value);
              setIsLoading(true);
              fetchBalance(params, option.value).finally(() => setIsLoading(false));
            }}
          />
        </div>
        <div className={'mb-3'}>
          <span className={styles.title}>Deposits: </span>
          <span className={cn(styles.value, styles.value__count)}>
            {balances.total_deposit_count}
          </span>
        </div>
        <div className={'mb-3'}>
          <span className={styles.title}>Withdrawals: </span>
          <span className={cn(styles.value, styles.value__count)}>
            {balances.total_withdraw_count}
          </span>
        </div>
        <div className={'mb-3'}>
          <div className={styles.title}>Deposited</div>
          <div className={cn(styles.value, styles[getStatus(balances.total_deposit)])}>
            USD: {balances.total_deposit}
          </div>
        </div>
        <div className={'mb-3'}>
          <div className={styles.title}>Withdrawn</div>
          <div className={cn(styles.value, styles[getStatus(balances.total_withdraw)])}>
            USD: {balances.total_withdraw}
          </div>
        </div>
        <div className={'mb-3'}>
          <div className={styles.title}>Profit</div>
          <div className={cn(styles.value, styles[getStatus(balances.total_profit)])}>
            USD: {balances.total_profit}
          </div>
        </div>
        {ENABLE_MT ? (
          <>
            <div className={'mb-3'}>
              <div className={styles.title}>Trade balance</div>
              <div className={cn(styles.value, styles[getStatus(balances.total_trade)])}>
                USD: {balances.total_trade}
              </div>
            </div>
            <div className={'mb-3'}>
              <div className={styles.title}>Trade count</div>
              <div className={cn(styles.value, styles[getStatus(balances.total_trade_count)])}>
                USD: {balances.total_trade_count}
              </div>
            </div>
            <div className={'mb-3'}>
              <div className={styles.title}>Net deposit</div>
              <div className={cn(styles.value, styles[getStatus(balances.total_net_deposit)])}>
                USD: {balances.total_net_deposit}
              </div>
            </div>
          </>
        ) : null}
        <div className="mb-3">
          <ReloadButton
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              fetchBalance(params, period, true).finally(() => setIsLoading(false));
            }}
          />
        </div>
      </div>
    </div>
  );
};

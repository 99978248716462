import useContextMenu from "../../../../hooks/useContextMenu";
import { ChatMessagesEmpty } from "../../components/ChatMessagesList/ChatMessagesEmpty";
import { ChatMessagesList } from "../../components/ChatMessagesList/ChatMessagesList";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
    selectMessages,
    selectMessagesLoading,
    selectMessagesNextPage,
    selectThreadId,
} from "../../../../store/slice/chat/chatSlice";
import { useRef } from "react";
import { getNextMessages } from "../../../../store/slice/chat/thunks";
import { MessageContextMenu } from "./MessageContextMenu";
import { RemoveMessageModal } from "../../components/modals/RemoveMessageModal/RemoveMessageModal";

export const MessagesContainer = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectMessagesLoading);
    const threadId = useAppSelector(selectThreadId);
    const messages = useAppSelector(selectMessages);
    const nextPage = useAppSelector(selectMessagesNextPage);

    const contextMenuRef = useRef(null);
    const { clicked, setClicked, selectedEntity: selectedMessage, setSelectedEntity, points, setPoints } = useContextMenu<any>(contextMenuRef);

    if (!messages.length || loading) {
        return <ChatMessagesEmpty isLoading={loading}/>
    }

    const handleContextMenu = (event: any, entity: any) => {
        event.preventDefault();
        setClicked(true);
        setPoints(event);
        setSelectedEntity(entity);
    };

    const handleLoadMore = async () => {
				await dispatch(getNextMessages({ threadId, nextPage }));
    };

    return (
        <>
            <ChatMessagesList
                messages={messages}
                handleContextMenu={handleContextMenu}
                selectedMessage={selectedMessage}
                loadMore={handleLoadMore}
                hasMore={!!nextPage}
            />
            <MessageContextMenu isToggled={clicked} menuRef={contextMenuRef} message={selectedMessage} points={points} />
            <RemoveMessageModal/>
        </>
    )
}
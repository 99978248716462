import { ConfirmationModal } from "../../../../../components/ui/Modal/components/ConfirmationModal/ConfirmationModal";
import { deleteThread, leaveGroup } from "../../../../../store/slice/chat/thunks";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { selectModalProps } from "../../../../../store/slice/modalSlice";
import { setThreadId } from "../../../../../store/slice/chat/chatSlice";

export const REMOVE_CHAT_MODAL_KEY = 'REMOVE_CHAT_MODAL_KEY';

export const RemoveChatModal = () => {
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectModalProps);
    const navigate = useNavigate();
    const { id } = useParams();

    const handleDeleteThread = async () => {
        const action = data?.contact?.group ? leaveGroup : deleteThread;
        await dispatch(action(data?.contact?.id)).then(({ meta }) => {
            if (meta?.requestStatus === 'fulfilled' && data?.contact?.id === id) {
                navigate(`/chat`);
                dispatch(setThreadId(null));
            }
        });
    };

    return (
        <ConfirmationModal
            id={REMOVE_CHAT_MODAL_KEY}
            onConfirm={handleDeleteThread}
            size='sm'
        />
    )
}
import { promoteLead } from 'api/leads';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import FormInputAutoPassword from 'components/ui/FormInputAutoPassword';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Select from 'components/ui/Select';
import { PASSWORD_INPUT_VALIDATION } from 'constants/validators.js';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { notify } from 'utils/notify';
import PaginateSelect from 'components/ui/PaginateSelect';
import { fetchLanguagesPaginate } from 'utils/managment/fetchData';

import styles from './lead-popup.mudule.scss';
import { ICountry } from 'models/Countries';
import { Language } from 'models/Languages';
import { Lead } from 'models/Leads/Lead';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  countryList: ICountry[];
  langList: Language[];
  updateComponent: () => void;
  user: Lead;
};

const PromotePopup = (props: Props) => {
  const { triggerBtn, countryList, langList, updateComponent, user } = props;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const closeModal = (close) => {
    reset();
    close();
  };

  const onSubmit = (close, data) => {
    setIsLoading(true);

    const postData = {
      first_name: data['first_name'],
      last_name: data['last_name'],
      email: data['email'],
      showemail: data['email'],
      birthday: user?.birthDate,
      city: user?.city,
      gender: user?.gender,
      country_id: data?.country_id.id || user?.country.id,
      password: data['password'],
      language_id: data?.language_id?.value || user?.language.id?.value || 40,
    };

    promoteLead(user.id, postData)
      .then((res) => {
        setIsLoading(false);
        notify({
          type: 'success',
          message: res.data.message,
          timeOut: 3000,
        });
        updateComponent();
        close();
        if (res.data?.client_id) {
          navigate(`/client/${res.data?.client_id}`);
        } else {
          navigate('/search-clients');
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      const country_id = countryList && countryList.find((item) => item.id === user.country?.id);
      // const lang = langList && langList.find((item) => item.code === user.language?.code);
      setIsLoading(false);
      reset({
        first_name: user.name,
        last_name: user.surname,
        email: user.email,
        country_id: country_id?.id,
        password: '',
        language_id: {
          value: user.language.id,
          label: user.language.name,
        },
      });
    }
  }, [user, countryList, langList]);

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            isBigPopup={false}
            headerTitle={'Promote to client'}
            rightContent={
              <div className={cx('content')}>
                <span className={cx('content__title')}>
                  You are about to promote {user.name + ' ' + user.surname} to a client
                </span>
                <div className={cx('content-form', 'popupForm', 'leads')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={6} className="mb-3">
                          <FormInput
                            name="first_name"
                            type="text"
                            label="First name"
                            control={control}
                            errors={errors.first_name}
                            rules={{
                              required: 'Field is required',
                            }}
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          <FormInput
                            name="last_name"
                            type="text"
                            label="Last name"
                            control={control}
                            errors={errors.last_name}
                            rules={{
                              required: 'Field is required',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} className="mb-3">
                          <FormInput
                            name="showemail"
                            disabled
                            type="email"
                            label="E-mail"
                            control={control}
                            placeholder={user.email}
                            // rules={{ validate: VALID_EMAIL }}
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          <FormInputAutoPassword
                            rules={{
                              required: (
                                <FormattedMessage
                                  id="form.error.passwordMessage_7"
                                  defaultMessage="Password field is required"
                                />
                              ),
                              validate: PASSWORD_INPUT_VALIDATION,
                            }}
                            label="Password"
                            id="password"
                            control={control}
                            generatePassword={() => {}}
                            errors={errors.password}
                            name="password"
                            placeholder={'Enter password'}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} className="mb-3">
                          <JokerSelect
                            id="country_id"
                            name="country_id"
                            label="Country"
                            isSearchable={true}
                            control={control}
                            options={countryList.map((c) => ({ value: c.id, label: c.name }))}
                            errors={errors.country_id}
                            rules={{
                              required: 'Field is required',
                            }}
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          <PaginateSelect
                            isSearchable={true}
                            isMulti={false}
                            placeholder={'Any'}
                            label="Languages"
                            control={control}
                            id="language_id"
                            name="language_id"
                            onePage={true}
                            onChange={(page, search) => fetchLanguagesPaginate(page, search)}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText="Cancel"
                          buttonType="outline"
                          type="button"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button
                          isLoading={isLoading}
                          buttonText={'Apply change'}
                          type="submit"
                          onClick={handleSubmit((data) => onSubmit(close, data))}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default PromotePopup;

import s from './mtcheckpop.module.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { checkMTStatus } from 'api/mtcheck';
import { Spinner } from 'components/ui/Spinner';

const MTCheckPop = ({ open, setOpen }) => {
  const defaultValues = {
    errors: [],
    success: [],
  };
  const [loadind, setLoading] = useState(false);
  const [data, setData] = useState(defaultValues);

  const overlayHandler = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      checkMTStatus()
        .then((r) => {
          setData(r.data);
        })
        .catch(console.log)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setData(defaultValues);
    }
  }, [open]);
  return (
    <>
      <div className={cn(s.mtcheckpop, open ? s.open : s.hide)}>
        {loadind && <Spinner />}
        {data?.errors.map((item, key) => (
          <div key={key} className={s.error}>
            {item}
          </div>
        ))}
        {data?.success.map((item, key) => (
          <div key={key} className={s.success}>
            {item}
          </div>
        ))}
      </div>
      {open && <div className={s.overlay} onClick={overlayHandler} />}
    </>
  );
};

export default MTCheckPop;

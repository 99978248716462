import classNames from 'classnames/bind';
import FileUploadComponent from 'components/ui/FileUploadComponent/FileUploadComponent';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import { FILE_MAX_SIZE, FILE_TYPES } from 'constants/file-upload';
import Popup from 'reactjs-popup';

import styles from './files.module.scss';
import { Container } from 'react-bootstrap';

const cx = classNames.bind(styles);

const AddFilePopup = ({ triggerBtn, updateComponent, getDocumentId, userId }) => {
  const closeModal = (closeModal) => {
    closeModal();
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Add file'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('addFilesForm')}>
                  <Container>
                    <FileUploadComponent
                      type="kyc_document"
                      userId={userId}
                      id="uploader"
                      isMultiple={false}
                      maxSize={FILE_MAX_SIZE}
                      types={FILE_TYPES}
                      getDocumentId={(id) => getDocumentId(id)}
                      onSuccess={() => {
                        updateComponent();
                        closeModal(close);
                      }}
                    />
                  </Container>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default AddFilePopup;

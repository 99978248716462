import { Icon } from 'components/ui/Icon';
import { components, OptionProps } from 'react-select';
import styles from '../multi-select.module.scss';

export const MultiSelectOption = (props: OptionProps) => {
  const iconName = props.isSelected ? 'checkedIcon' : 'uncheckedIcon';

  return (
    <components.Option {...props}>
      <div className={styles.optionWrapper}>
        <Icon
          className={styles.optionCheckbox}
          name={iconName}
          size={20}
          color="var(--main-color)"
        />
        <span className={styles.optionText}>{props.children}</span>
      </div>
    </components.Option>
  );
};

import { TABLE_OPENED_POSITIONS_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const openedPositionsClientDefaultValues = {
  trading_account: [],
  ticker: [],
  open_at: '',
};

export const useOpenedPositions = () => {
  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: openedPositionsClientDefaultValues,
  });

  const table = useTableColumnOrder(
    'TABLE_OPENED_POSITIONS_COLUMNS_NAMES',
    TABLE_OPENED_POSITIONS_COLUMNS_NAMES,
  );

  return { form, table };
};

import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './style.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { editNameRoleMethod } from 'api/templateRoles';
import { TemplateRole } from 'models/RolesAndPermissions/Templates/Role';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  actionType: string;
  dataItem: TemplateRole;
};

const EditNameRolePopup = (props: Props) => {
  const { triggerBtn, updateComponent, actionType, dataItem } = props;

  const [isLoading, setIsLoading] = useState(false);

  const contentToScrol = useRef(null);
  const setDefaultValues = () => {
    return {
      name: dataItem.name,
    };
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };
  useEffect(() => {
    reset(setDefaultValues());
  }, [dataItem]);

  const onSubmit = (close, data) => {
    const sendData = {
      name: data.name,
    };

    if (actionType === SALES_RULES_ACTION_TYPE.EDIT) {
      addRoleHandler(sendData, close);
    }
  };

  const addRoleHandler = (sendData, close) => {
    setIsLoading(true);
    editNameRoleMethod(dataItem.id, sendData)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: 'Role name changed successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Change role name'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')} ref={contentToScrol}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          <FormInput
                            rules={{
                              required: 'Role name field is required',
                            }}
                            label={'Role name'}
                            id="role_name"
                            control={control}
                            name="name"
                            placeholder={'Role name'}
                            errors={errors.name}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <Container>
                        <Row>
                          <Col md={12}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <Button
                                isLoading={isLoading}
                                buttonText={'Change'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default EditNameRolePopup;

import React, { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './clientsPopups.mudule.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify';
import { clientDynamicStatusesByKey, bulkActionClientToTeam } from 'api/clients';
import { operatorsListForBulkActions } from 'api/operators';
import PaginateSelect from 'components/ui/PaginateSelect';
import {
  fetchOperators as fetchOperatorsAPI,
  fetchDesksForType,
  fetchOperatorsForBulkActions,
  fetchOperatorsForBulkActionsByDesk,
  fetchTeamsForDesk,
} from 'utils/managment/fetchData';
import { USER_SELECT_FILTER_DEFAULT_VALUE } from 'constants/clients/filters.const';
import { DynamicFilter } from 'models/Clients/ClientFilter';
import { ListValue } from 'models/ListValue';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  userId: string | number;
  actionType: string;
  disabled: boolean;
};

const ClientSalesUpdatePopup = (props: Props) => {
  const { triggerBtn, updateComponent, userId, actionType, disabled } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [desk, setDesk] = useState<ListValue>();
  const [teams, setTeams] = useState<ListValue>();
  const [operator, setOperator] = useState([]);
  const [dynamicFilter, setDynamicFilter] = useState<DynamicFilter | undefined>(undefined);

  const componentMounted = useRef(true);
  const setDefaultValues = () => {
    return {
      desk_id: USER_SELECT_FILTER_DEFAULT_VALUE[0],
      team_id: USER_SELECT_FILTER_DEFAULT_VALUE[0],
      admin_user_ids: null,
      status: null,
      sales_status: null,
    };
  };
  const fetchOperators = (deskId?, teamId?) => {
    const desk = deskId && deskId !== 'any' ? `&desk_id=${deskId}` : '';

    operatorsListForBulkActions(`per_page=100&page=1&bulk_type=${actionType}${desk}`, [
      teamId,
    ]).then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: `${opt.first_name} ${opt && opt.last_name !== null ? opt.last_name : ''}`,
        }));
        setOperator(opt);
      }
    });
  };

  const fetchClientDynamicStatuses = () => {
    clientDynamicStatusesByKey(actionType).then((res) => {
      if (componentMounted.current) {
        const filter = res.data.data;
        setDynamicFilter({
          value: filter.key,
          label: filter.translate,
          options: filter.activeStatuses.map((item) => ({
            value: item.id,
            label: item.translate,
          })),
        });
      }
    });
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const clearEmptyField = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  const closeModal = (closeModal) => {
    setDesk(null);
    setTeams(null);
    reset(setDefaultValues());
    // fetchOperators();
    reset();
    closeModal();
  };
  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };
  const onSubmit = (close, data) => {
    setIsLoading(true);
    const sendData = {
      desk_id: data && data.desk_id ? data.desk_id.value : null,
      team_id: data && data.team_id ? data.team_id.value : null,
      bulk_type: actionType,
      admin_user_ids: getValue(data['admin_user_ids']) ? [getValue(data['admin_user_ids'])] : null,
      statusId: data && data.sales_status ? data.sales_status.value : null,
      user_ids: [userId],
    };

    bulkActionClientToTeam(clearEmptyField(sendData))
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          setTimeout(updateComponent, 3000);
          setTimeout(
            () =>
              notify({
                type: 'success',
                message: `Client sales ${actionType} successfull`,
                timeOut: 3000,
              }),
            3000,
          );
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onOpenPopup = () => {
    fetchClientDynamicStatuses();
  };
  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const setDefaultValuesTeam = () => {
    return {
      bulk_type: 'sales',
      desk_id: getValues('desk_id'),
      team_id: undefined,
      admin_user_ids: getValues('admin_user_ids'),
      statusId: getValues('status'),
      user_ids: [userId],
    };
  };
  const setDefaultValuesOperator = () => {
    return {
      bulk_type: 'sales',
      desk_id: getValues('desk_id'),
      team_id: getValues('team_id'),
      admin_user_ids: '',
      statusId: getValues('status'),
      user_ids: [userId],
    };
  };
  const updateTeamWithDesk = (desk) => {
    setDesk(desk);
    reset(setDefaultValuesTeam());
  };
  const updateOperatorWithTeam = (team) => {
    setTeams(team);
    reset(setDefaultValuesOperator());
  };

  const fetchOps = async (page, query) => {
    let ops: { data: ListValue[] } = { data: [] };

    if (teams?.value) {
      ops = await fetchOperatorsForBulkActions(actionType, teams.value);
    } else if (desk?.value) {
      ops = await fetchOperatorsForBulkActionsByDesk(actionType, desk.value);
    } else {
      ops = await fetchOperatorsAPI(page, query);
    }

    const filtered = ops.data.filter((op) => op.label.includes(query || ''));

    return { data: filtered };
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpenPopup}
        disabled={disabled}
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={`Client ${actionType} update`}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          {/*<SelectComponent*/}
                          {/*  name="desk_id"*/}
                          {/*  label={`${actionType} Desk`}*/}
                          {/*  control={control}*/}
                          {/*  options={desks}*/}
                          {/*  errors={errors.desk_id}*/}
                          {/*  isSearchable={false}*/}
                          {/*/>*/}
                          <PaginateSelect
                            isRequired={true}
                            isMulti={false}
                            label={`${actionType} Desk`}
                            control={control}
                            id="desk_id"
                            name="desk_id"
                            onChange={(page, search) => fetchDesksForType(page, search, actionType)}
                            errors={errors.desk_id}
                            rules={{
                              required: 'Desk field is required',
                            }}
                            onSelectChange={() => {
                              updateTeamWithDesk(getValues('desk_id'));
                            }}
                          />
                        </Col>
                      </Row>
                      {desk?.value && (
                        <>
                          <Row>
                            <Col md={12} className="mb-3">
                              <PaginateSelect
                                isRequired={false}
                                isMulti={false}
                                label="Teams"
                                control={control}
                                id="team_id"
                                name="team_id"
                                onChange={(page, search, desk) => {
                                  return fetchTeamsForDesk(page, search, desk?.value);
                                }}
                                mainVal={desk}
                                onSelectChange={() => {
                                  updateOperatorWithTeam(getValues('team_id'));
                                }}
                                key={JSON.stringify(desk)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} className="mb-3">
                              {/*<SelectComponent*/}
                              {/*  name="sales_representative"*/}
                              {/*  label={`${actionType} representative`}*/}
                              {/*  control={control}*/}
                              {/*  options={operator}*/}
                              {/*  rules={{ required: ' Field is required' }}*/}
                              {/*  errors={errors.sales_representative}*/}
                              {/*  isSearchable={false}*/}
                              {/*/>*/}
                              <PaginateSelect
                                isRequired={true}
                                isMulti={false}
                                label="Sales representative"
                                control={control}
                                id="admin_user_ids"
                                name="admin_user_ids"
                                onChange={(page, search) => {
                                  return fetchOps(page, search);
                                }}
                                mainVal={teams}
                                errors={errors.admin_user_ids}
                                rules={{
                                  required: 'Field is required',
                                }}
                                key={JSON.stringify(desk) + JSON.stringify(teams)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} className="mb-3">
                              <JokerSelect
                                id="sales_status"
                                name="sales_status"
                                label={`${actionType} Status`}
                                control={control}
                                options={dynamicFilter.options}
                                rules={{ required: ' Field is required' }}
                                errors={errors.sales_status}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      <div className={cx('content-controls')}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="Cancel"
                            buttonType="outline"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />
                          <Button
                            isLoading={isLoading}
                            buttonText={'Apply change'}
                            type="submit"
                            onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                          />
                        </div>
                      </div>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default ClientSalesUpdatePopup;

import { usePermission } from 'utils/usePermission';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import Button from 'components/ui/Button';
import PartnerChangePasswordPopup from './PartnerPopups/PartnerChangePasswordPopup';
import styles from './partner-page.module.scss';
import PopupYesOrNo from 'components/ui/PopupYesOrNo/PopupYesOrNo';
import { deletePartner } from 'api/partners';
import { Icon } from 'components/ui/Icon';
import { useNavigate } from 'react-router-dom';

type Props = {
  id: string;
  partnerName: string;
  partnerUuid: string;
  updateCallback: () => void;
};

export const PartnerPageHeader = ({ id, partnerName, partnerUuid, updateCallback }: Props) => {
  const { permissionGiven: PermissionChangePass } = usePermission('admin.partner.change-password');
  const { permissionGiven: PermissionDelete } = usePermission('admin.partner.delete');

  const navigate = useNavigate();

  return (
    <div className={styles.pageTitle}>
      <div className={styles.title}>
        <div className={styles.name}>{partnerName}</div>
        <div className={styles.uuid} onClick={() => copyToBuffer(partnerUuid, true)}>
          {getShortUuid(partnerUuid)}
        </div>
      </div>
      <div className={styles.buttons}>
        {PermissionChangePass ? (
          <PartnerChangePasswordPopup
            updateComponent={updateCallback}
            userId={id}
            triggerBtn={<Button buttonType="outline" buttonText="Change password" />}
          />
        ) : null}
        {PermissionDelete ? (
          <PopupYesOrNo
            onClick={() => {
              deletePartner(id).then(() => navigate('/management-partners'));
            }}
            headerTitle={'Delete partner'}
            bodyText={`Are you sure that you want to delete partner ${partnerName}?`}
            triggerBtn={
              <Button
                buttonType="delete"
                className={styles.circleButton}
                icon={<Icon name="trash" size={18} />}
              />
            }
          />
        ) : null}
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { IButton } from 'models/components/Button/Button';
import { Spinner } from '../Spinner';
import cn from 'classnames';

import styles from './button.module.scss';

const Button: FC<IButton> = React.forwardRef((props, ref): React.JSX.Element => {
  const {
    buttonText,
    buttonType = 'primary',
    icon,
    iconPrefix,
    buttonStyle,
    onClick,
    className,
    disabled,
    type = 'submit',
    isLoading = false,
    ...other
  } = props;

  return (
    <button
      style={buttonStyle}
      className={cn(styles.btn, styles[buttonType], isLoading && styles.btn__loading, className)}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
      {...other}
    >
      {icon && iconPrefix ? <span className={styles.icon}>{icon}</span> : null}
      {buttonText && <span className={styles.text}>{buttonText}</span>}
      {isLoading && <Spinner size="small" />}
      {icon && !iconPrefix ? <span className={styles.icon}>{icon}</span> : null}
    </button>
  );
});

export default Button;

import React, { useState } from "react";
import { ModalTemplate } from "../../../../../components/ui/Modal/components/JokerModalTemplate/JokerModalTemplate";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { closeJokerModal } from "../../../../../store/slice/modalSlice";
import { selectDialogParticipants, selectThread } from "../../../../../store/slice/chat/chatSlice";
import { AddParticipants } from "./AddParticipants";
import { GroupParticipants } from "./GroupParticipants";
import { unEscapeHtml } from "../../../helpers/unscapeHtml";
import "./GroupParticipantsModal.scss";

export const PARTICIPANTS_MODAL_KEY = 'PARTICIPANTS_MODAL_KEY';

export const GroupParticipantsModal = () => {
    const [searchMode, setSearchMode] = useState(false);
    const dispatch = useAppDispatch();
    const thread = useAppSelector(selectThread);
    const participants = useAppSelector(selectDialogParticipants);

    const closeModal = () => {
        setSearchMode(false);
        dispatch(closeJokerModal());
    }

    if (!thread) {
        return null;
    }

    return (
        <ModalTemplate
            id={PARTICIPANTS_MODAL_KEY}
            title={!!thread.is_support ? 'Supervisors' : unEscapeHtml(thread.name)}
            onClose={closeModal}
            size='sm'
            className="group-participants-modal"
        >
            {!searchMode ?
                <GroupParticipants
                    thread={thread}
                    switchMode={() => setSearchMode(true)}
                    closeModal={closeModal}
                    participants={participants}
                />
                : <AddParticipants
                    threadId={thread.id}
                    participants={participants}
                    switchMode={() => setSearchMode(false)}
                />
            }
        </ModalTemplate>
    )
}
import API from '../api';

export const getClientsList = (params) => API.get(`/api/v1/users?${params}`);

export const findPayments = (params, data) => API.post(`/api/v1/search/deposits?${params}`, data);

export const getPaymentsMethodList = (params) => API.get(`/api/v1/deposit/methods`);
export const getTransactionsTypesList = () => API.get(`/api/v1/deposit/types`);
export const getTransactionsTypeMethods = () => API.get(`/api/v1/deposit/type-methods`);
export const getRejectedStatusesListMethods = () => API.get(`/api/v1/deposit/rejected-statuses`);
export const updateTransactionWithdrawal = (id, data) =>
  API.put(`/api/v1/deposit/transaction/${id}/withdrawal`, data);
export const deleteTransaction = (id) => API.delete(`/api/v1/deposit/transaction/${id}`, id);

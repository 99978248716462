import React, { FC } from 'react';
import { Wallet } from 'phosphor-react';
import Table from '../Table.tsx';
import classNames from 'classnames/bind';
import styles from './crypto-wallets-user-by-id-table.module.scss';
import { copyToBuffer } from 'utils/copyToBuffer.js';
import { CRYPTO_WALLETS_TYPES } from 'constants/payments.const.ts';
import { makeTableData } from 'helpers/table.helper.ts';
import { ICryptoWalletsUserByIdTable } from 'models/components/Table/CryptoWalletsUserBiIdTable/CryptoWalletsUserBiIdTable.ts';
import { Icon } from 'components/ui/Icon';

const cx = classNames.bind(styles);

const CryptoWalletsUserByIdTable: FC<ICryptoWalletsUserByIdTable> = ({
  data,
  showLoader,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item) => {
    const { wallet, crypto_type, crypto_network, description, general, fintegrity } = item;

    return {
      crypto_type: (
        <div className={cx('wallet__type')}>
          <div className={cx('wallet__item')}>
            <Icon name={crypto_type?.toLowerCase()} size={18} />
            <div className={cx('wallet__text')}>
              <span>
                {CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]}{' '}
                {crypto_type === 'USDT' && crypto_network && `(${crypto_network})`}
              </span>
              {fintegrity === 1 && <span>Fintegrity</span>}
            </div>
          </div>
        </div>
      ),

      wallet: (
        <div className={cx('wallet__desc')}>
          <span className={cx('wallet__name')} onClick={() => copyToBuffer(wallet, true)}>
            <Icon name="wallet" size={18} color="var(--main-color)" />
            <span>{wallet}</span>
          </span>
          <span>{description}</span>
        </div>
      ),

      shared_wallet: (
        <div>
          <span className={cx('wallet__shared')}>{general === 1 ? 'Yes' : 'No'}</span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
      <Table
        saveColumnOrder={saveColumnOrder}
        columns={columns}
        data={tableDataMeMo}
        showLoader={showLoader}
        perPage={100}
        className={cx('wallet-table-user-by-id')}
        isSelected={false}
        getCellProps={(cellInfo, cx) => {
          const { row, value, column } = cellInfo;
          return {
            style: {
              backgroundColor: `${row.isSelected ? 'var(--cyan)' : 'var(--white)'}`,
            },
          };
        }}
      />
    </>
  );
};

export default CryptoWalletsUserByIdTable;

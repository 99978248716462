import { useFormContext } from 'react-hook-form';
import Button from 'components/ui/Button';
import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import styles from './filter.module.scss';
import { ReloadButton } from 'components/ui/ReloadButton';
import { SearchBar } from 'components/ui/SearchBar';
import { getClientPaymentsDefaultValues } from '../usePaymentTab';

const PaymentsFilter = ({ onSubmit, reloadFilters, userId }) => {
  const paymentsSearchOptions = useSearchCategoriesOptions('depositTransaction');

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    control,
    formState: { dirtyFields },
  } = useFormContext();

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    paymentsSearchOptions,
  );

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        payment_method: getValue(data['payment_method']),
        payment_type: getValue(data['payment_type']),
        transaction_type: getValue(data['transaction_type']),
        transaction_status: getValue(data['transaction_status']),
        status_updated_at: getDateRange(data['status_updated_at']),
        currencyIds: getValue(data['currencyIds']),
        accountType: getValue(data['accountType']) ? [getValue(data['accountType'])] : '',
        first_time_deposit: getValue(data['first_time_deposit']),
        amount: getNumberRange(data['amount']),
        created_at: getDateRange(data['created_at']),
        user_id: [+userId],
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getClientPaymentsDefaultValues(userId));
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const getNumberRange = (data) => {
    if (!data) {
      return;
    }

    if (!data.from && !data.to) {
      return;
    }

    const res = {};

    if (+data.from) {
      res['from'] = +data.from;
    }

    if (+data.to) {
      res['to'] = +data.to;
    }

    return res;
  };

  const getDateRange = (data) => {
    if (!data) {
      return;
    }

    if (!data.from && !data.to) {
      return;
    }

    const res = {};

    if (data.from) {
      // res['from'] = timeToInteger(data['from']) / 1000;
      res['from'] = data['from'];
    }

    if (data.to) {
      // res['to'] = timeToInteger(data['to']) / 1000;
      res['to'] = data['to'];
    }

    return res;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={styles.form}
    >
      <SearchBar
        className={styles.searchBar}
        inputProps={{
          id: 'query',
          name: 'query',
          control,
          placeholder: dynamicSearchPlaceholder,
          onSearch: () => submit(getValues()),
          onChange: (v) => !v?.length && submit(getValues()),
        }}
        selectProps={{
          id: 'query_category',
          name: 'query_category',
          control,
          options: paymentsSearchOptions,
          onSelectChange: () => {
            const { query } = getValues();
            if (query) submit(getValues());
          },
        }}
      />
      <div className={styles.buttons}>
        <ReloadButton isLoading={reloadFilters} onClick={() => submit(getValues())} />
        <Button
          buttonText="Reset"
          buttonType="outline"
          isLoading={reloadFilters}
          type="reset"
          onClick={resetForm}
          disabled={!getDirtyFieldsLength()}
        />
        <Button
          buttonText="Apply"
          isLoading={reloadFilters}
          type="submit"
          onClick={handleSubmit(submit)}
          disabled={!getDirtyFieldsLength()}
        />
      </div>
    </form>
  );
};

export default PaymentsFilter;

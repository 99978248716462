import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './dashboard.module.scss';
import { DashboardLayout } from '../UserView/DashboardLayout';
import Page from '../Page/Page';

const cx = classNames.bind(styles);

const Dashboard: FC = () => {
  document.title = 'Dashboard';
  return (
    <DashboardLayout>
      <Page title="Dashboard"></Page>
    </DashboardLayout>
  );
};

export default Dashboard;

import { useEffect, useRef } from 'react';
import { uploadDataAutoRefresh } from "../api/update-page";
export interface IPostDataRefresh {
  admin_user_id: string,
  role: string,
  page_title: string,
  date: number,
}
export const useCheckAutoRefresh = (pageTitle: string, maxTime: number = 300000, maxNumberReload: number = 9) => {
  const role = JSON.parse(localStorage.getItem('role'))
  const user_id = JSON.parse(localStorage.getItem('user_id'))
  const checkAutoRefresh = JSON.parse(sessionStorage.getItem('autorefresh'))
  const timeAutoRefresh = JSON.parse(sessionStorage.getItem('autorefresh_time'))
  const currentTime = new Date().getTime();
  const checkRefresh = useRef(false);
  const time = currentTime - timeAutoRefresh;
  let counter = 0;

  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType('navigation');
    if (navigationEntries.length > 0 && (navigationEntries[0] as any).type === 'reload') {
      if (checkAutoRefresh > 0) {
        sessionStorage.setItem('autorefresh', JSON.stringify(checkAutoRefresh + 1))
      } else {
        sessionStorage.setItem('autorefresh', JSON.stringify(counter + 1))
        sessionStorage.setItem('autorefresh_time', JSON.stringify(currentTime))
      }
      if (checkAutoRefresh >= maxNumberReload && time < maxTime) {
        checkRefresh.current = true;

        const postData: IPostDataRefresh = {
          admin_user_id: user_id,
          role: role[0],
          page_title: pageTitle,
          date: Math.ceil(Date.now() / 1000),
        }

        uploadDataAutoRefresh(postData)
          .then(res => {
            // console.log(res)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            sessionStorage.clear();
          })
      }
      if (time > maxTime && checkAutoRefresh > maxNumberReload) {
        sessionStorage.clear()
      }
    }

    return () => {
      sessionStorage.clear()
    }
  }, [pageTitle]);

  return checkRefresh.current;
}

/*
Это устраняет логическую неточность в конфигах с параметрами ENABLE_WEB_TRADER и ENABLE_MT.
Так исторически сложилось что параметр MT завмисим от WT следующим образом 
WT:1 && MT:1 = WT:1, MT:0.
Этот хук возвращает истинный параметр МТ
*/
export const useAbsurdLogic = ({ENABLE_WEB_TRADER, ENABLE_MT})=>{
  const WT = ENABLE_WEB_TRADER;
  const MT = ENABLE_MT;

  if (WT && MT){
    return {WT:true, MT:false}
  }

  if (!WT && MT){
    return {WT:false, MT:true}
  }

  // Это невозможный случай. Описан для целостности кода на случай ошибки в конфигурации. Выдает правильные значения и выводит варинг в консоль.
  if (WT && !MT){
    console.warn('Вы указали параметр ENABLE_MT=0, это невозможное значение в силу особенностей проекта')
    return {WT:true, MT:true}
  }

  if (!WT && !MT){
    return {WT:false, MT:false}
  }
}
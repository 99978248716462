import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { isFormChange } from 'utils/isFormChange';
import { CryptoWithdrawFilter } from 'models/CustomerService/CryptoWithdrawFilter';
import { ReloadButton } from 'components/ui/ReloadButton';
import Button from 'components/ui/Button';
import styles from './wallet-filter.module.scss';

type Props = {
  onSubmit: (data: CryptoWithdrawFilter) => void;
  isLoading: boolean;
  onResetColumnsOrder: () => void;
};

const CryptoPaymentsFilters = (props: Props) => {
  const { onSubmit, isLoading, onResetColumnsOrder } = props;

  const {
    reset,
    getValues,
    formState: { dirtyFields },
  } = useFormContext();

  const resetForm = () => {
    onSubmit(null);
    reset();
  };

  const submit = (data) => {
    onSubmit({ status: data?.status });
  };

  return (
    <Row>
      <Col md={12}>
        <div className={styles.filters__btns}>
          <Button buttonText="Reset column order" onClick={onResetColumnsOrder} />
          <ReloadButton isLoading={isLoading} onClick={() => submit(getValues())} />
          <Button
            buttonText="Reset"
            buttonType="outline"
            isLoading={isLoading}
            type="reset"
            onClick={resetForm}
            disabled={!isFormChange(dirtyFields)}
          />
          <Button
            buttonText="Apply"
            isLoading={isLoading}
            type="submit"
            disabled={!isFormChange(dirtyFields)}
          />
        </div>
      </Col>
    </Row>
  );
};

export default CryptoPaymentsFilters;

import React, { useState } from 'react';
import { getOperatorsForDeskMethod, getTeamForDeskMethod } from 'api/hierarchy';
import HierarchyComponentOperators from './HierarchyComponentOperators';
import { checkDevise } from 'utils/checkDevise';
import UserPopup from './UsersPopup';
import Button from 'components/ui/Button';
import { HierarchyDesk } from 'models/Hierarchy/HierarchyData';
import { Icon } from 'components/ui/Icon';
import cn from 'classnames';

import styles from './hierarchy-page.module.scss';

type Props = {
  data: HierarchyDesk;
};

const HierarchyComponentTeams = ({ data }: Props) => {
  const [desk, setDesk] = useState(data);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const isMobile = checkDevise() === 'mobile';

  const handleClickOnDesk = () => {
    setOpen(true);
    getTeamForDeskMethod(desk.id).then((res) => {
      if (res.status === 200) {
        setDesk((prev) => ({ ...prev, teams: res.data.data }));
      }
    });
  };

  const ToggleButton = () => (
    <button
      className={styles.btnIcon}
      onClick={() => {
        open ? setOpen(false) : handleClickOnDesk();
      }}
    >
      <Icon name={open ? 'minusCircle' : 'plusCircle'} size={30} />
    </button>
  );

  const onHover = (v: boolean) => !isMobile && setHover(v);

  return (
    <div className={styles.wrapperItem}>
      <div
        className={cn(styles.wrapperContent, styles.wrapperDesk)}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        <div className={styles.beforeEl}></div>
        {isMobile && desk?.branches_count > 0 ? (
          <ToggleButton />
        ) : desk?.branches_count > 0 && hover ? (
          <ToggleButton />
        ) : (
          <div className={styles.btnIcon}>
            <Icon name="desk" size={30} />
          </div>
        )}

        <div className={styles.wrapperInfo}>
          <div>
            <p className={styles.topBlock}>{`${desk?.name} Desk`}</p>
            <p className={styles.bottomBlock}>
              {`${desk?.branches_count} branches / ${desk?.operators_count}`}{' '}
              {desk?.operators_count === 1 ? 'user' : 'users'}
            </p>
          </div>
          {desk?.operators_count > 0 && (
            <UserPopup
              fetchMethod={getOperatorsForDeskMethod}
              data={desk}
              namePopup={`desk ${desk.name}`}
              triggerBtn={
                <Button
                  icon={<Icon name="user" />}
                  className={styles.userButton}
                  onClick={() => null}
                  buttonType={'outline'}
                />
              }
            />
          )}
        </div>
      </div>

      {open && desk?.teams?.length > 0 && (
        <div className={styles.wrapperList}>
          {desk.teams?.map((team) => {
            return (
              <div key={team.id} className={styles.itemList}>
                <HierarchyComponentOperators data={team} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default HierarchyComponentTeams;

import classNames from 'classnames/bind';
import React, { FC, useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import styles from './bulk-actions-popup.module.scss';
import Button from 'components/ui/Button/Button';
import { useForm } from 'react-hook-form';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import PopupTemplate from 'components/ui/PopupTemplate';
import { firstLetterUppercase } from 'utils/firstLetterUppercase';
import { Col, Container, Row } from 'react-bootstrap';
import JokerSelect from 'components/ui/JokerSelect';
import { bulkActionClientToBuffer } from 'api/clients';

const cx = classNames.bind(styles);

interface IProps {
  trigger: JSX.Element;
  bulkActionsData: number[];
  bulkActionsType: string;
  onCloseModal?: () => void;
}

const bulkType = [
  {
    value: 'sales',
    label: 'Sales',
  },
  {
    value: 'retention',
    label: 'Retention',
  },
];

const MoveBulkActionsPopup: FC<IProps> = ({
  trigger,
  bulkActionsData,
  bulkActionsType,
  onCloseModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentLang = localStorage.getItem('lang');
  const componentMounted = useRef(true);

  const { handleSubmit, control, reset, watch, getValues, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      bulk_type: undefined,
    },
  });
  const bulkTypeField = watch('bulk_type');

  const closeModal = (closeModal) => {
    reset();
    closeModal();
    onCloseModal?.();
  };

  const onSubmit = async (close, data) => {
    setIsLoading(true);

    const postData = {
      user_ids: bulkActionsData,
      bulk_type: data['bulk_type'].value,
    };

    bulkActionClientToBuffer(postData)
      .then((res) => {
        if (componentMounted.current) {
          closeModal(close);
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error(`Bulk move fail`, 'Error', 5000);
        closeModal(close);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <div className={cx('popup-title')}>
                <span>{`Bulk Actions - ${firstLetterUppercase(
                  bulkActionsType,
                  currentLang,
                )}`}</span>
                <span>{bulkActionsData.length} Clients selected</span>
              </div>
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-title')}></div>
                <div className={cx('content-form')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12} className="mb-3">
                          <JokerSelect
                            label="Buffer Type"
                            control={control}
                            id="bulk_type"
                            name="bulk_type"
                            rightaligned={true}
                            options={bulkType}
                          />
                        </Col>
                      </Row>
                      <div className={cx('content-controls')}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="Cancel"
                            buttonType="outline"
                            onClick={closeModal.bind(undefined, close)}
                          />
                          <Button
                            isLoading={isLoading}
                            disabled={!bulkTypeField}
                            buttonText="Move"
                          />
                        </div>
                      </div>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default MoveBulkActionsPopup;

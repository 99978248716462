import { getCountryList } from 'api/country';
import { getLeadStatuses } from 'api/leads';
import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import { MultiSelect } from 'components/ui/MultiSelect';
import PaginateSelect from 'components/ui/PaginateSelect/PaginateSelect';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { fetchLanguagesPaginate } from 'utils/managment/fetchData';

export const SearchLeadsFields = () => {
  const { control } = useFormContext();

  const [countryOptions, setCountryOptions] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);

  const componentMounted = useRef(true);

  useEffect(() => {
    fetchCountryList();
    getLeadStatuses().then((response) => {
      const statuses = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setLeadStatuses(statuses);
    });

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchCountryList = () => {
    getCountryList().then((res) => {
      if (componentMounted.current) {
        const opt = res.data.data.map((opt) => ({
          value: opt.iso_3166_2,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }));
        setCountryOptions([{ value: '0', label: 'Other' }].concat(opt));
      }
    });
  };

  return (
    <Row>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <PaginateSelect
          isSearchable={true}
          isMulti={true}
          placeholder={'Any'}
          label="Languages"
          control={control}
          id="languages"
          name="languages"
          onChange={(page, search) => fetchLanguagesPaginate(page, search)}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <MultiSelect
          label="Country"
          control={control}
          id="country"
          name="countries"
          options={countryOptions}
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <DatePickerRange
          name="note_last"
          control={control}
          label="Last note date"
          placeholder="From - To"
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <DatePickerRange
          name="created_at"
          control={control}
          label="Registration date range"
          placeholder="From - To"
        />
      </Col>
      <Col xl={3} lg={4} md={6} className="mb-3">
        <MultiSelect
          label="Account status"
          control={control}
          id="account_status"
          name="account_statuses"
          options={leadStatuses}
        />
      </Col>
    </Row>
  );
};

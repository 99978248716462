import React from 'react';
import classNames from 'classnames/bind';
import styles from './table.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import moment from 'moment';
import TransactionDetailPopup from 'pages/Payments/PaymentsPopups/TransactionDetailPopup/TransactionDetailPopup';
import { usePermission } from 'utils/usePermission';
import ChangeOperatorPopup from 'pages/Clients/Client/ClientTabs/Payments/ChangeOperatorPopup';
import { getShortUuid } from 'utils/getShortUuid';
import { copyToBuffer } from 'utils/copyToBuffer';
import { useSelector } from 'react-redux';
import { getEnableMT } from 'constants/selectors';
import ReactTooltip from 'react-tooltip';
import { deleteTransaction } from 'api/payments';
import PopupYesOrNo from '../../PopupYesOrNo/PopupYesOrNo';
import { Link } from 'react-router-dom';
import { Icon } from 'components/ui/Icon';
import { upperFirst } from 'lodash';

const cx = classNames.bind(styles);

const PaymentsTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  manualSortHandler,
  colSortCheck,
  onSort,
  isChangeOperator,
  updateComponent,
  columns,
  saveColumnOrder,
}) => {
  const { permissionGiven: PermissionShowDepositTransaction } = usePermission(
    'admin.deposit.transaction.show',
  );
  const { permissionGiven: PermissionShowWithdrawalRequest } =
    usePermission('admin.withdrawal.show');
  const { permissionGiven: PermissionChangeOperator } = usePermission(
    'admin.deposit.change-operator',
  );
  const { permissionGiven: PermissionDeletePayment } = usePermission(
    'admin.crypto_transaction.delete-payment',
  );
  const ENABLE_MT = useSelector(getEnableMT);

  // const columns = React.useMemo(() => tableHeader, [ENABLE_MT, ENABLE_PAYMENT]);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const deletePayment = (id) => {
    deleteTransaction(id)
      .then(() => updateComponent())
      .catch(console.log);
  };

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      id,
      user,
      agent,
      type,
      amount_type,
      amount,
      method,
      created_at,
      status,
      trading_account,
      transaction_id,
      operator,
      amount_usd,
      status_created_date,
      reference_id,
      gateway,
      fintegrity,
      payment,
      sales,
      retention,
      crypto_wallet,
      credit_card_number,
      created_from,
    } = item;

    return {
      transactions: (
        <div className={cx('payments-table_td')}>
          {trading_account ? (
            <>
              <div className={cx('header-controls')}>
                {PermissionShowDepositTransaction || PermissionShowWithdrawalRequest ? (
                  !item?.fintegrity ? (
                    <TransactionDetailPopup
                      triggerBtn={
                        <span className={cx('ta')}>
                          {status === 'pending'
                            ? transaction_id || 'Transaction pending'
                            : transaction_id || 'Canceled'}{' '}
                          {/*<span className={cx(trading_account.type)}>{trading_account.type}</span>*/}
                        </span>
                      }
                      rule={item}
                      gateway={gateway}
                      updateComponent={() => updateComponent()}
                    />
                  ) : (
                    <span className={cx('ta')}>{transaction_id} </span>
                  )
                ) : (
                  <span className={cx('ta')}>{transaction_id} </span>
                )}
              </div>

              <span className={cx('uuid')} onClick={() => copyToBuffer(id, true)}>
                {' '}
                by {getShortUuid(item?.user?.uuid)}
              </span>
            </>
          ) : (
            '---'
          )}
        </div>
      ),
      client: (
        <div className={cx('payments-table_td')}>
          <Link
            target={'_blank'}
            to={`/client/${user?.id ?? 0}`}
            className={cx('payments-table_td-link')}
            style={{ display: 'block' }}
          >
            <span>
              {user && user.user_profile && user.user_profile.first_name
                ? user.user_profile.first_name + ' '
                : ''}
              {user && user.user_profile && user.user_profile.last_name
                ? user.user_profile.last_name
                : ''}
            </span>
          </Link>
          <span className={cx('uuid')} onClick={() => copyToBuffer(user?.uuid, true)}>
            {getShortUuid(user?.uuid)}
          </span>
        </div>
      ),
      affiliate: (
        <div className={cx('payments-table_td')}>
          <div>
            {user && user.affiliate ? (
              <div>
                <span className={cx('name')} style={{ display: 'block' }}>
                  {user.affiliate.first_name}
                </span>
                <span
                  className={cx('uuid')}
                  onClick={() => copyToBuffer(user.affiliate.uuid, true)}
                >
                  {getShortUuid(user.affiliate.uuid)}
                </span>
              </div>
            ) : (
              '---'
            )}
          </div>
        </div>
      ),
      original_agent: (
        <div className={cx('payments-table_td')}>
          <div>
            {item?.operator ? (
              <div>
                <span className={cx('name')} style={{ display: 'block' }}>
                  {isChangeOperator && status === 'completed' && PermissionChangeOperator ? (
                    <ChangeOperatorPopup
                      trigger={
                        <span
                          className={cx('ta')}
                          style={{ color: operator.deleted == 1 ? 'var(--red)' : 'inherit' }}
                        >
                          {operator.full_name}
                        </span>
                      }
                      onSuccess={updateComponent}
                      user={user}
                      transactionId={id}
                    />
                  ) : (
                    <span
                      className={cx('name')}
                      style={{ color: operator.deleted == 1 ? 'var(--red)' : 'inherit' }}
                    >
                      {operator.full_name}
                    </span>
                  )}
                </span>
                {operator.deleted !== 1 && (
                  <span className={cx('uuid')} onClick={() => copyToBuffer(operator.uuid, true)}>
                    {getShortUuid(operator.uuid)}
                  </span>
                )}
              </div>
            ) : null}
            {/*{user?.sales ? (*/}
            {/*  <div>*/}
            {/*    <span>*/}
            {/*      {tableHeader === TABLE_PAYMENTS_COLUMNS_NAMES_FOR_TABS ?*/}
            {/*        <ChangeOperatorPopup*/}
            {/*          trigger={<span>{user.sales.operator.full_name}</span>}*/}
            {/*          onSuccess={updateComponent}*/}
            {/*          user={user}*/}
            {/*          transactionId={transaction_id}*/}
            {/*        /> : <span>{user.sales.operator.full_name}</span>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*    <span*/}
            {/*      className='uuid'*/}
            {/*      onClick={() => copyToBuffer(user.sales.operator.uuid, true)}*/}
            {/*    >*/}
            {/*      {getShortUuid(user.sales.operator.uuid)}*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*) : null}*/}
            {/*{user?.retention ? (*/}
            {/*  <div>*/}
            {/*    {tableHeader === TABLE_PAYMENTS_COLUMNS_NAMES_FOR_TABS ?*/}
            {/*      <ChangeOperatorPopup*/}
            {/*        trigger={<span>{user.retention?.operator.full_name}</span>}*/}
            {/*        onSuccess={updateComponent}*/}
            {/*        user={user}*/}
            {/*        transactionId={transaction_id}*/}
            {/*      /> : <span>{user.retention?.operator.full_name}</span>*/}
            {/*    }*/}
            {/*    <span*/}
            {/*      className='uuid'*/}
            {/*      onClick={() => copyToBuffer(user.retention.operator.uuid, true)}*/}
            {/*    >*/}
            {/*      {getShortUuid(user.retention.operator.uuid)}*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*) : null}*/}
          </div>
        </div>
      ),
      internal_id: (
        <div className={cx('payments-table_td')}>
          <span className={cx(id && 'uuid')} onClick={() => id && copyToBuffer(id, true)}>
            {id ? id : '---'}
          </span>
        </div>
      ),
      reference_id: (
        <div className={cx('payments-table_td')}>
          <span
            className={cx(reference_id && 'uuid')}
            onClick={() => reference_id && copyToBuffer(reference_id, true)}
          >
            {reference_id ? reference_id : '---'}
          </span>
        </div>
      ),
      // payment_currency: (
      //   <div className={cx('payments-table_td')}>
      //     <span>{payment ? `${payment?.amount} ${payment?.currency}` : '---'}</span>
      //   </div>
      // ),
      payment_type: (
        <div className={cx('payments-table_td')}>
          <span className={cx(type)}>{type ? upperFirst(type) : '---'}</span>
        </div>
      ),
      amount:
        amount_type === 'EUR' ? (
          <div className={cx('payments-table_td')}>
            <span className={cx('amount')}>{amount_type + ' ' + amount}</span>
            <span className={cx('amount')} style={{ display: 'block' }}></span>
            <span className={cx('amount')}>{'USD' + ' ' + amount_usd}</span>
          </div>
        ) : (
          <div className={cx('payments-table_td')}>
            <span className={cx('amount')}>{amount_type + ' ' + amount}</span>
          </div>
        ),
      trading_acc: ENABLE_MT ? (
        <div className={cx('payments-table_td')}>
          {trading_account ? (
            <>
              <span
                className={cx('ta')}
                onClick={() => copyToBuffer(trading_account.trading_account, true)}
              >
                {trading_account.demo === 1 ? (
                  <>
                    <span style={{ color: 'var(--blue)', fontWeight: 'bold' }}>DEMO</span>{' '}
                  </>
                ) : null}
                {trading_account.trading_account}
                {/*<span className={cx('ta_type', trading_account.type.toLowerCase())}>*/}
                {/*  {trading_account.type ? trading_account.type : '---'}*/}
                {/*</span>*/}
              </span>
              <span>{trading_account.amount_type}</span>
            </>
          ) : (
            '---'
          )}
        </div>
      ) : null,
      payment_method: (
        <div className={cx('payments-table_td')}>
          <span>{method ? method : '---'}</span>
        </div>
      ),
      gateway: (
        <div className={cx('payments-table_td')}>
          <span>{gateway ? gateway : '---'}</span>
        </div>
      ),
      date: (
        <div className={cx('client__registration')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      status:
        status === 'pending' ? (
          <div className={cx('client__status')}>
            <span className={cx(status)}>{status}</span>
            <span>on {moment.unix(created_at).format('DD.MM.YYYY  - HH:mm')} by system</span>
          </div>
        ) : (
          <div className={cx('client__status')}>
            <span className={cx(status)}>{status}</span>
            <span>
              on {moment.unix(status_created_date).format('DD.MM.YYYY  - HH:mm')} by system
            </span>
          </div>
        ),
      cripto_walets: (
        <>
          <div
            className={cx(crypto_wallet && 'cripto_walets')}
            onClick={() => !!crypto_wallet && copyToBuffer(crypto_wallet, true)}
          >
            {!!crypto_wallet ? crypto_wallet : '---'}
          </div>

          {fintegrity === 1 && <span>Fintegrity</span>}
          {created_from === 'WT' && method === 'Crypto' && <span>Web Trader</span>}
        </>
      ),
      credit_card: (
        <>
          <div
            className={cx(credit_card_number && 'cripto_walets')}
            onClick={() => !!credit_card_number && copyToBuffer(credit_card_number, true)}
          >
            {!!credit_card_number ? credit_card_number : '---'}
          </div>
          {created_from === 'WT' && method === 'credit_card_ca' && type === 'withdraw' && (
            <span>Web Trader</span>
          )}
        </>
      ),

      action: PermissionDeletePayment && id && (
        <div className={cx('action')}>
          <PopupYesOrNo
            onClick={() => deletePayment(id)}
            headerTitle={'Delete payment'}
            bodyText={'Are you sure you want to delete payment?'}
            triggerBtn={<Icon name="trash" size={20} color="var(--red)" />}
          />
          <ReactTooltip id="delete" type={'error'} />
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  const checkSortEnable = (colId): boolean => {
    switch (colId) {
      case 'original_agent':
      case 'country':
      case 'amount':
      case 'trading_acc':
      case 'date':
        return true;
      default:
        return false;
    }
  };

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('payments-table')}
      manualSortHandler={manualSortHandler}
      colSortCheck={colSortCheck || checkSortEnable}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { index } = cellInfo.row;
        const odd = index % 2 !== 0;
        return {
          style: {
            backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
          },
        };
      }}
    />
  );
};

export default PaymentsTable;

import { Spinner } from "phosphor-react";
import { ReactNode } from "react";
import './ChatSearchInputField.scss';
import { Icon } from "components/ui/Icon";

type ChatSearchInputFieldProps = {
	children: ReactNode,
	isLoading?: boolean
}

export const ChatSearchInputField = ({ children, isLoading }: ChatSearchInputFieldProps) => {
	return (
		<div className='chat-search-input-field'>
			{children}
			<div className='chat-search-icon'>
				{isLoading ? <Spinner size={20} className='chat-search-spinner'/> : <Icon name="search" size={20} />}
			</div>
		</div>
	)
}
// Этот хук получает и сохраняет порядок столбцов
import { getOrderedColumns, setOrderedColumns } from 'api/userSettings';
import { ITable } from 'models/components/Table/Table';
import { useState, useEffect } from 'react';

const useTableColumnOrder = (tableId, localColumns, i18n = false) => {
  const [columnOrder, setColumnOrder] = useState<ITable['columns']>([...localColumns]);

  const [remotelySavedOrder, setRemotelySavedOrder] = useState([]);

  const getRemotelySavedOrder = () => {
    getOrderedColumns(tableId).then(res => {
      if (res && res.data && res.data.columns) {
        setRemotelySavedOrder(res.data.columns);
      }
    }) 
  }

  const combineRemoteAndLocal = async () => {
    const mergedSavedAndLocalColumns = [
      ...new Set(
        [
          ...remotelySavedOrder.map((c) => c.accessor !== 'action' && c.accessor),
          ...localColumns.map((c) => c.accessor !== 'action' && c.accessor),
          (localColumns.find((item) => item.accessor === 'action')?.accessor !== undefined)&&'action',
        ].filter(Boolean),
      ),
    ];

    const result = [];

    for (let i = 0; i < mergedSavedAndLocalColumns.length; i++) {
      const accessor = mergedSavedAndLocalColumns[i];

      if (accessor == 'action') {
        result.push({
          Header: ' ',
          accessor,
        })
      } else {
        const localCol = localColumns.find(col => col.accessor == accessor);

        if (localCol) {
          result.push({
            Header: localCol.Header,
            accessor
          })
        }
      }
    }

    //@ts-ignore
    setColumnOrder(result);
  };

  useEffect(() => {
    getRemotelySavedOrder();
  }, [tableId])

  useEffect(() => {
    combineRemoteAndLocal();
  }, [remotelySavedOrder, localColumns]);

  const saveColumnOrder = (newColumnOrder) => {
    if (!i18n) {
      setOrderedColumns(tableId, {
        columns: newColumnOrder.map((c) => ({
          Header: c.Header,
          accessor: c.accessor,
        })),
      });

      setColumnOrder(newColumnOrder);
    } else {
      const onlyAcc = newColumnOrder.map((c) => ({ accessor: c.accessor }));
      setOrderedColumns(tableId, { columns: onlyAcc });
      setColumnOrder(newColumnOrder);
    }
  };

  const setDefaultColumnsOrder = () => {
    setColumnOrder([...localColumns]);
    saveColumnOrder([...localColumns]);
  };

  return { columns: columnOrder, saveColumnOrder, setDefaultColumnsOrder };
};

export default useTableColumnOrder;

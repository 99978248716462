import cn from "classnames";
import './ContextMenu.scss';

export const ContextMenu = ({positionX, positionY, isToggled, contextMenuRef, children, className = ''}) => {
    return (
        <menu
            ref={contextMenuRef}
            className={cn('context-menu', className, {'context-menu--active': isToggled})}
            style={{
                top: `${positionY}px`,
                left: `${positionX}px`,
            }}
        >
            {children}
        </menu>
    );
};
import cn from "classnames";
import { MutableRefObject } from "react";
import { ChatMessageType, OnlineStatusesVerbose, UserTypeLabels, UserTypes } from "../../../../models/Chat";
import { ChatAvatar } from "../ChatAvatar/ChatAvatar";
import { ChatDate } from "../ChatDate/ChatDate";
import { ChatMessageBody } from "./ChatMessageBody";
import { ChatSystemMessage } from "./ChatSystemMessage";
import './ChatMessage.scss';

type ChatMessageProps = {
    message: ChatMessageType;
    userStatus?: OnlineStatusesVerbose;
    isReply?: boolean;
    isSupportChat?: boolean;
    onContextMenu?: (event: any, entity: ChatMessageType) => void;
    activeViaContextMenu?: boolean;
    foundMessageRef?: MutableRefObject<HTMLDivElement> | null;
};

export const ChatMessage = ({
                                foundMessageRef,
                                message,
                                userStatus,
                                isReply,
                                isSupportChat,
                                onContextMenu,
                                activeViaContextMenu
                            }: ChatMessageProps) => {
    const {
        id,
        temporary_id,
        owner,
        body,
        system_message,
        type_verbose,
        created_at,
        image,
        document,
        reply_to,
        edited,
        roles
    } = message;
    const pending = id === temporary_id;

    if (system_message) {
        return <ChatSystemMessage
          name={owner.name}
          message={body}
          date={created_at}
          type={type_verbose}
          foundMessageRef={foundMessageRef}
        />
    }

    const isClient = owner.base.type === UserTypes.CLIENTS;
    const managerRoles = (roles || []).map((role) => role.name).join(', ');

    return (
        <div
            className={cn('chat-message', { isReply, active: activeViaContextMenu, pending, found: !!foundMessageRef})}
            onContextMenu={(e) => !isReply && !pending && onContextMenu(e, message)}
            ref={foundMessageRef}
        >
            {!isReply ? (
                <ChatAvatar
                    className={UserTypeLabels[owner.base.type]}
                    name={owner.name}
                    status={userStatus === OnlineStatusesVerbose.ONLINE}
                />
            ) : null}
            <div className='chat-message-content'>
                <div className="chat-message-meta">
                    <div className="chat-message-name">{owner.name}</div>
                    {isSupportChat ? (
                      <div className={cn("user-type", {clients: isClient, managers: !isClient})}>
                          {isClient ? UserTypeLabels[owner.base.type] : managerRoles}
                      </div>
                    ) : null}
                    <ChatDate date={created_at} dateFormat='distance' />
                </div>
                {reply_to ? <ChatMessage message={reply_to} isReply={true} /> : null}
                <ChatMessageBody body={body} type={type_verbose} image={image} document={document} edited={edited}/>
            </div>
        </div>
    )
}
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Link, useParams } from 'react-router-dom';

import { copyToBuffer } from 'utils/copyToBuffer';
import { getStatusForCrypt } from 'utils/getStatusForCrypt';
import CryptoRequestForm from './CryptoRequestForm';
import { getCurrentRequestMethod } from 'api/customer-service/request';
import Button from 'components/ui/Button';
import { CryptoWithdrawWalletData } from 'models/CustomerService/CryptoWithdraw';
import { usePageTitle } from 'utils/usePageTitle';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import Page from 'pages/Page';
import { Icon, IconName } from 'components/ui/Icon';
import { CryptoRequestInfo } from './CryptoRequestInfo/CryptoRequestInfo';
import { CryptoRequestUserInfo } from './CryptoRequestInfo/CryptoRequestUserInfo';
import styles from './style.module.scss';

const cx = classNames.bind(styles);

const CryptoRequestPage = () => {
  const params = useParams();
  const componentMounted = useRef(true);

  const [walletData, setWalletData] = useState<CryptoWithdrawWalletData>();
  const [isLoading, setIsLoading] = useState(true);

  const title = 'Request - ' + walletData?.wallet_address;
  const statusRequest = getStatusForCrypt(walletData);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchWalletInfo = (params) => {
    setIsLoading(true);

    getCurrentRequestMethod(params.id)
      .then((res) => {
        setWalletData(res.data.data);
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  usePageTitle(title);

  useEffect(() => {
    fetchWalletInfo(params);
  }, [params]);

  const Title = () => {
    return (
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <Icon name={walletData?.crypto_type?.toLowerCase() as IconName} size={30} />
          <div className={styles.title}>
            <span
              className={styles.address}
              onClick={() => copyToBuffer(walletData?.wallet_address, true)}
            >
              {walletData?.wallet_address}
            </span>
            <span className={cx(styles[statusRequest], styles.status)}>
              {statusRequest.toUpperCase()}
            </span>
          </div>
        </div>
        <Link to={`/crypto-output`} className={styles.link}>
          <Button buttonText="To requests list" buttonType="outline" />
        </Link>
      </div>
    );
  };

  return (
    <DashboardLayout>
      {!isLoading && (
        <Page title={<Title />}>
          <>
            <div className={styles.info}>
              <div className={styles.block}>
                <CryptoRequestInfo wallet={walletData} status={statusRequest} />
              </div>
              <div className={styles.block}>
                <CryptoRequestUserInfo
                  address={walletData?.wallet_address}
                  transaction={walletData?.deposit_transaction}
                />
              </div>
            </div>
            {statusRequest === 'new' && (
              <CryptoRequestForm
                updateComponent={() => fetchWalletInfo(params)}
                formData={walletData}
              />
            )}
          </>
        </Page>
      )}
    </DashboardLayout>
  );
};

export default CryptoRequestPage;

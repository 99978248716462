import React, { useState, useEffect, useRef } from 'react';
import styles from './../../client-page.module.scss';

import TablePagination from 'components/ui/TablePagination';
import { useParams } from 'react-router-dom';
import OpenedPositionsFilter from './OpenedPositionsFilter';
import { findTradingHistoryPositions } from 'api/tradingHistory';
import { findOpenedPositions } from 'api/openedPositions';
import OpenedPositionsTable from 'components/ui/Table/OpenedPositionsTable';
import { ListValue } from 'models/ListValue';
import { OpenedPositionsFields } from './OpenedPositionsFields/OpenedPositionsFields';
import { useOpenedPositions } from './useOpenedPositions';
import { FormProvider } from 'react-hook-form';
import { TableLayout } from 'components/ui/Table/TableLayout';

const OpenedPositions = () => {
  const [openPositions, setOpenPositions] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [tickerOpt, setTickerOpt] = useState<ListValue[]>([]);
  const { id } = useParams();

  const componentMounted = useRef(false);

  useEffect(() => {
    getOpenedPositions();
    componentMounted.current = true;

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getOpenedPositions = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findOpenedPositions(null, filtersData, id)
      .then((res) => {
        if (res && res.status === 200) {
          if (componentMounted.current) {
            setOpenPositions(res.data.data);
          }
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsListLoading(false);
      });

    findTradingHistoryPositions(null, filtersData, id).then((res) => {
      const optTickerOpt = res.data.ticker.map((opt) => ({
        value: opt,
        label: opt,
      }));
      setTickerOpt(optTickerOpt);
    });
  };

  const clearFiltersSortList = () => {
    getOpenedPositions({ navigate: 'first' });
    setFilters(null);
  };

  const { form, table } = useOpenedPositions();

  return (
    <>
      <div className={styles.tabTitle}>Opened positions</div>
      <FormProvider {...form}>
        <OpenedPositionsFields userId={id} tickerOpt={tickerOpt} />
        <TableLayout
          header={
            <OpenedPositionsFilter
              onSubmit={(filtersData) => {
                if (!filters && !filtersData) {
                  return;
                }
                if (filters && !filtersData) {
                  clearFiltersSortList();
                } else {
                  setFilters(filtersData);
                  // setPerPageCount(filtersData?.filter?.limit);
                  getOpenedPositions({ navigate: 'first' }, filtersData);
                }
              }}
              reloadFilters={isListLoading}
              setDefaultColumnsOrder={table.setDefaultColumnsOrder}
            />
          }
        >
          <OpenedPositionsTable
            data={openPositions}
            perPage={200}
            showLoader={isListLoading}
            columns={table.columns}
            saveColumnOrder={table.saveColumnOrder}
            // modifyDataHandler={(callback) => console.log('a')}
          />
        </TableLayout>
      </FormProvider>
    </>
  );
};

export default OpenedPositions;

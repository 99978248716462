import Button from 'components/ui/Button';
import styles from './page-style.module.scss';

type Props = {
  total?: number;
  selectedCount: number;
  markAsReadHandle: () => void;
};

export const NotificationsPageTitle = ({ total, selectedCount, markAsReadHandle }: Props) => {
  return (
    <div className={styles.pageTitle}>
      <div>
        {total || 0} Notifications found & Selected {selectedCount}
      </div>
      <Button buttonText="Mark as read" onClick={markAsReadHandle} disabled={!selectedCount} />
    </div>
  );
};

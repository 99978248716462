import { CryptoWallet } from 'models/CustomerService/CryptoWallets';
import moment from 'moment';
import { copyToBuffer } from 'utils/copyToBuffer';

type Props = {
  wallet: CryptoWallet;
};

export const getWalletPageInfoTableData = ({ wallet }: Props) => [
  {
    title: 'Wallet:',
    value: wallet.wallet,
    onClick: () => copyToBuffer(wallet.wallet, true),
  },
  {
    title: 'Description:',
    value: wallet.description || '---',
  },
  {
    title: 'Created at:',
    value: moment.unix(wallet.created_at).format('DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Shared wallet:',
    value: wallet.general === 1 ? 'Yes' : 'No',
  },
];

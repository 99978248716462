import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import PasswordInput from 'components/ui/PasswordInput/PasswordInput';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import { PASSWORD_INPUT_VALIDATION } from 'constants/validators';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
import { notify } from 'utils/notify';
import { changePartnerPassword } from 'api/partners';

import styles from './partnerPopups.module.scss';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  userId: string;
};

const PartnerChangePasswordPopup = (props: Props) => {
  const { triggerBtn, updateComponent, userId } = props;

  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };
  const new_password = watch('password');

  const onSubmit = (close, data) => {
    setIsLoading(true);

    changePartnerPassword(userId, data)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Change password'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container className="d-flex flex-column gap-3">
                      <Row>
                        <Col md={12}>
                          <PasswordInput
                            name="password"
                            type="password"
                            label="New password"
                            control={control}
                            rules={{
                              required: 'Field is required',
                              validate: PASSWORD_INPUT_VALIDATION,
                            }}
                            errors={errors.password}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={12}>
                          <PasswordInput
                            name="password_confirmation"
                            type="password"
                            label="Confirm password"
                            control={control}
                            rules={{
                              required: 'Field is required',
                              validate: {
                                ...PASSWORD_INPUT_VALIDATION,
                                compare: (value) =>
                                  value === new_password ||
                                  'The password confirmation does not match.',
                              },
                            }}
                            errors={errors.password_confirmation}
                          />
                        </Col>
                      </Row>
                      <div className={cx('content-controls')}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="Cancel"
                            buttonType="outline"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />
                          <Button
                            isLoading={isLoading}
                            buttonText={'Change password'}
                            type="submit"
                            onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                          />
                        </div>
                      </div>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default PartnerChangePasswordPopup;

import moment from 'moment';
import { getTimeAgo } from 'utils/getTimeAgo';

type Props = {
  date?: number;
};

export const OperatorRegistered = ({ date }: Props) => {
  return (
    <div>
      <div className="mb-1">{date ? getTimeAgo(date) : '---'}</div>
      <div>on {date ? moment(date * 1000).format('DD.MM.YYYY HH:mm') : '---'}</div>
    </div>
  );
};

export const BROWN = '#462c1c';
export const PURPLE = '#722ed1';
export const GREEN = '#795c11';
export const ORANGE = '#8b471a';
export const BLUE = '#04145c';
export const LIGHT_BLUE = '#8bcbf5';

export const DEFAULT_COLORS = [BROWN, PURPLE, GREEN, ORANGE, BLUE, LIGHT_BLUE];
export const DEFAULT_COLORS_FOR_DYNAMIC_STATUSES = [
  PURPLE,
  '#A18594',
  BROWN,
  GREEN,
  '#BEBD7F',
  '#E4717A',
  '#FFBCAD',
  '#FFD1DC',
  '#3EB489',
  '#7FB5B5',
  '#A8E4A0',
  '#FF8C69',
  '#EFA94A',
  '#FFDB8B',
  '#52b0ff',
  LIGHT_BLUE,
];
export const COLORS = {
  MAIN_COLOR: process.env.REACT_APP_MAIN_COLOR || 'var(--main-color)',
  DISABLED_TEXT_COLOR: process.env.REACT_APP_DISABLED_TEXT_COLOR || '#8c8c8c',
  HEADER_BACKGROUND_COLOR: process.env.REACT_APP_HEADER_BACKGROUND_COLOR || 'var(--white)',
  MAIN_COLOR_HOVER: process.env.REACT_APP_MAIN_COLOR_HOVER || 'var(--main-color-hover)',
  MAIN_LIGHT_COLOR: process.env.REACT_APP_MAIN_LIGHT_COLOR || 'var(--main-light-color)',
  PRIMARY_TEXT_COLOR: process.env.REACT_APP_PRIMARY_TEXT_COLOR || 'var(--primary-text-color)',
  SECONDARY_TEXT_COLOR: process.env.REACT_APP_SECONDARY_TEXT_COLOR || 'var(--secondary-text-color)',
  SIDEBAR_BACKGROUND_COLOR: process.env.REACT_APP_SIDEBAR_BACKGROUND_COLOR || 'var(--white)',
  DEFAULT_COLOR: PURPLE,
  BODY_BACKGROUND_COLOR:
    process.env.REACT_APP_BODY_BACKGROUND_COLOR || 'var(--body-background-color)',
  BORDER_BACKGROUND_COLOR: process.env.REACT_APP_BORDER_BACKGROUND_COLOR || 'var(--main-color)',
  CURRENCY_COLOR: process.env.REACT_APP_CURRENCY_COLOR, //||'#fff',
};

import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { documentTypes } from '../../../api/documents';
import styles from './document-type.module.scss';
import Button from '../Button/Button';
import Select from 'react-select';
import { SELECT_DEFAULT_STYLES } from '../../../constants/select';
import PopupTemplate from '../PopupTemplate';
import './select-fix.scss';
import { ActiveStatuses, IOptions, ITypeOpt } from "../../../models/components/DocumentTypePopup/DocumentTypePopup";

const cx = classNames.bind(styles);

const DocumentTypePopup = ({ trigger, docName, onClose }) => {
  const [typesData, setTypesData] = useState<ITypeOpt[] | null>(null);

  const [categoryOptions, setCategoryOptions] = useState<IOptions[] | null>(null);
  const [typesOptions, setTypesOptions] = useState<IOptions[] | null>(null);

  const [selectedCategory, setSelectedCategory] = useState<IOptions | null>(null);
  const [selectedType, setSelectedType] = useState<IOptions | null>(null);

  const customStyles = {
    ...SELECT_DEFAULT_STYLES,
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: 150,
    }),
  };

  // const formatLabelText = (value) => {
  //   const firstLetterToUpperCase = value[0].toUpperCase() + value.substring(1);
  //   return firstLetterToUpperCase.replaceAll('_', ' ');
  // };

  const changeTypesDefaultValue = (value): void => {

    const typeOpt: ActiveStatuses[] = typesData.filter((el: ITypeOpt): boolean => el.key === value)[0].activeStatuses;

    const currentOpt: IOptions[] = typeOpt.map((el: ActiveStatuses): IOptions => ({ value: el.key, label: el.translate }))

    setTypesOptions(currentOpt);
    setSelectedType(null);
  };

  const closeModal = (closeModal): void => {
    setSelectedCategory(null);
    setSelectedType(null);

    closeModal();
    onClose();
  };

  useEffect((): void => {
    documentTypes()
      .then((res): void => {
        setTypesData(res.data.data);
        const categoryOptions = res.data.data.map((item): IOptions => ({
          value: item.key,
          label: item.translate,
        }));
        setCategoryOptions(categoryOptions);
      })
      .catch(console.log);
  }, []);

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle="Set document type"
            leftContent={
              <div className={cx('document-types__descr')}>
                <i></i>
              </div>
            }
            rightContent={
              <div className={cx('document-types__content')}>
                <div className={cx('document-types__name')}>
                  <span>Name:</span>
                  <span>{docName}</span>
                </div>
                <div className={cx('document-types__categories')}>
                  <div>
                    <span className={cx('document-types__label')}>Category:</span>
                    <Select
                      defaultValue={null}
                      options={categoryOptions}
                      styles={customStyles}
                      placeholder="Select category"
                      classNamePrefix="type-select"
                      components={{ IndicatorSeparator: (): null => null }}
                      onChange={(val): void => {
                        setSelectedCategory(val);
                        changeTypesDefaultValue(val.value);
                      }}
                    ></Select>
                  </div>
                  <div>
                    <span className={cx('document-types__label')}>Type:</span>
                    <Select
                      defaultValue={null}
                      options={typesOptions}
                      styles={customStyles}
                      value={selectedType}
                      isDisabled={!selectedCategory}
                      classNamePrefix="type-select"
                      components={{ IndicatorSeparator: (): null => null }}
                      onChange={(val: IOptions): void => {
                        setSelectedType(val);
                      }}
                      placeholder="Select type"
                    />
                  </div>
                </div>
                <div className={cx('document-types__btns')}>
                  <Button
                    type="button"
                    buttonText="Cancel"
                    buttonType="outline"
                    onClick={(): void => {
                      close();
                      onClose();
                      setSelectedCategory(null);
                      setSelectedType(null);
                    }}
                  />
                  <Button
                    type="submit"
                    buttonText="Send"
                    disabled={selectedCategory === null || selectedType === null}
                    onClick={(): void => {
                      close();
                      onClose({
                        category_type: selectedCategory.value,
                        type: selectedType.value,
                      });
                      setSelectedCategory(null);
                      setSelectedType(null);
                    }}
                  />
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default DocumentTypePopup;

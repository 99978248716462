import { getYear } from 'date-fns';

function range(start, end, step = 1) {
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }

  const result = [];
  if (step > 0) {
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
  } else if (step < 0) {
    for (let i = start; i > end; i += step) {
      result.push(i);
    }
  }

  return result;
}

export const yearsList: number[] = range(1990, getYear(new Date()) + 10, 1);
export const monthsList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const defaultFromTime = '00:00';

export const defaultToTime = '23:59';

export const dateRangeFormat = 'MMM dd, yyyy';
export const defaultDateFormat = 'yyyy-MM-dd';

export const defaultTimeFormat = 'HH:mm';

export const defaultDateTimeFormat = 'yyyy-MM-dd HH:mm';

export const fullDateTimeFormat = 'yyyy-MM-DD HH:mm:ss ZZ';

export const combineDateTime = (date: Date | null, time: string | undefined | null, defaultTime: string): Date | null => {
  if (!date) return null;

  if (!time) {
    time = defaultTime;
  }

  const [hoursStr, minutesStr] = time!.split(':');
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const combinedDateTime = new Date(date);

  combinedDateTime.setHours(hours);
  combinedDateTime.setMinutes(minutes);

  return combinedDateTime;
}

export const isValidDate = (date: any): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const isValidTime = (time: string): boolean => {
  const timeRegex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

  return timeRegex.test(time);
};

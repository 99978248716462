import { MultiSelect } from 'components/ui/MultiSelect';
import { ListValue } from 'models/ListValue';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

type Props = {
  taStatuses: ListValue[];
};

export const TrandingAccFields = ({ taStatuses }: Props) => {
  const { control } = useFormContext();

  return (
    <Row>
      <Col xs={12} md={12} lg={6} xl={3} className="mb-3">
        <MultiSelect
          label="Status"
          control={control}
          id="status"
          name="status"
          options={taStatuses}
        />
      </Col>
    </Row>
  );
};

import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { TABLE_NOTIFICATIONS_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const notificationsDefaultValues = {
  query: '',
  query_category: defaultQueryCategoryOption,
  created_at: '',
  desk_ids: [],
  team_ids: [],
  agents: [],
  type: [],
  details: [],
};

export const useNotificationsPage = () => {
  const table = useTableColumnOrder(
    'TABLE_NOTIFICATIONS_COLUMNS_NAMES',
    TABLE_NOTIFICATIONS_COLUMNS_NAMES,
  );

  const form = useForm({ reValidateMode: 'onChange', defaultValues: notificationsDefaultValues });

  return { form, table };
};

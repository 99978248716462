import Button from 'components/ui/Button';
import ChangeAcquisitionStatuses from '../SearchClientsPopups/ChangeAcquisitionStatuses/ChangeAcquisitionStatuses';
import MoveBulkActionsPopup from '../SearchClientsPopups/MoveBulkActionsPopup/MoveBulkActionsPopup';
import RetentionBulkActionsPopup from '../SearchClientsPopups/RetentionBulkActionsPopup/RetentionBulkActionsPopup';
import SalesBulkActionsPopup from '../SearchClientsPopups/SalesBulkActionsPopup/SalesBulkActionsPopup';
import { BULK_ACTIONS_TYPE } from 'constants/clients/bulk-actions.const';
import { usePermission } from 'utils/usePermission';

import styles from './search-clients-title.module.scss';

type Props = {
  selectedRows: number[];
  selectedClients: string[];
};

export const BulkActions = ({ selectedClients, selectedRows }: Props) => {
  const { permissionGiven: PermissionBulkMoveToSalesTeam } =
    usePermission('admin.client.bulk.sales');
  const { permissionGiven: PermissionBulkMoveToRetentionTeam } = usePermission(
    'admin.client.bulk.retention',
  );

  return (
    <div className={styles.bulkActions}>
      <span className={styles.bulkActions__title}>Bulk actions</span>
      <div className={styles.bulkActions__buttons}>
        {PermissionBulkMoveToSalesTeam ? (
          <ChangeAcquisitionStatuses
            trigger={<Button buttonText="Change Acquisition Status Sales" />}
            bulkActionsData={selectedRows}
            selectedClients={selectedClients}
            bulkActionsType={BULK_ACTIONS_TYPE.SALES}
          />
        ) : null}
        {PermissionBulkMoveToRetentionTeam ? (
          <ChangeAcquisitionStatuses
            trigger={<Button buttonText="Change Acquisition Status Retention" />}
            bulkActionsData={selectedRows}
            selectedClients={selectedClients}
            bulkActionsType={BULK_ACTIONS_TYPE.RETENTION}
          />
        ) : null}
        {PermissionBulkMoveToSalesTeam ? (
          <SalesBulkActionsPopup
            trigger={<Button buttonText="Sales" />}
            bulkActionsData={selectedRows}
            bulkActionsType={BULK_ACTIONS_TYPE.SALES}
            selectedClients={selectedRows}
          />
        ) : null}
        {PermissionBulkMoveToRetentionTeam ? (
          <RetentionBulkActionsPopup
            trigger={<Button buttonText="Retention" />}
            bulkActionsData={selectedRows}
            bulkActionsType={BULK_ACTIONS_TYPE.RETENTION}
            selectedClients={selectedRows}
          />
        ) : null}
        <MoveBulkActionsPopup
          trigger={<Button buttonText="Move" />}
          bulkActionsData={selectedRows}
          bulkActionsType={BULK_ACTIONS_TYPE.MOVE}
        />
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { useForm } from 'react-hook-form';
import { notify } from 'utils/notify';
import {
  approveRequestMethod,
  getCryptoExchangeRate,
  rejectRequestMethod,
} from 'api/customer-service/request';
import ApproveRequestPopup from './ApproveRequestPopup';
import { usePermission } from 'utils/usePermission';
import { CryptoWithdrawWalletData } from 'models/CustomerService/CryptoWithdraw';
import Button from 'components/ui/Button';
import styles from './style.module.scss';

const cx = classNames.bind(styles);

type Props = {
  formData: CryptoWithdrawWalletData;
  updateComponent: () => void;
};

const CryptoRequestForm = (props: Props) => {
  const { formData, updateComponent } = props;

  const { permissionGiven: PermissionApproveWithdrawalRequest } = usePermission(
    'admin.withdrawal.approve',
  );
  const { permissionGiven: PermissionRejectWithdrawalRequest } =
    usePermission('admin.withdrawal.reject');

  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, getValues, reset } = useForm({
    reValidateMode: 'onChange',
  });

  const onApprove = () => {
    const sendData = {
      accept_exchange_rate: getValues('accept_exchange_rate'),
      accept_amount: getValues('accept_amount'),
    };
    approveRequestMethod(formData.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Request approved successfully',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    onApprove();
  };

  const countAcceptAmount = () => {
    const val = getValues('accept_exchange_rate');
    const count = formData.amount_usd / val;
    reset({ accept_amount: count, accept_exchange_rate: val });
  };

  const onReject = () => {
    rejectRequestMethod(formData.id)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Request rejected successfully',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const crypto_type = formData.crypto_type_full.toLowerCase();
    getCryptoExchangeRate(crypto_type)
      .then((res) => {
        if (res.status === 200) {
          reset({
            accept_exchange_rate: res.data.data.rateUsd,
          });
          countAcceptAmount();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <div className={styles.buttons}>
          {PermissionRejectWithdrawalRequest ? (
            <ApproveRequestPopup
              currentMethod={onReject}
              data={formData}
              headerTitle={'Reject request'}
              textQuery={`Are you sure, you want to reject request ?`}
              btnText={'Reject'}
              triggerBtn={
                <Button
                  type={'button'}
                  buttonText="Reject request"
                  buttonType="outline"
                  onClick={() => {
                    // onReject();
                  }}
                />
              }
            />
          ) : null}
          {PermissionApproveWithdrawalRequest ? (
            <ApproveRequestPopup
              currentMethod={onApprove}
              data={formData}
              headerTitle={'Approve request'}
              textQuery={`Are you sure, you want to approve request ?`}
              btnText={'Approve request'}
              triggerBtn={<Button type={'button'} buttonText="Approve request" />}
            />
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default CryptoRequestForm;

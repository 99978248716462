import classNames from 'classnames/bind';
import React, { FC } from 'react';

import styles from './popup-template.module.scss';

const cx = classNames.bind(styles);

interface IPopupTemplateProps {
  dismissModal: () => void;
  headerTitle: string | JSX.Element;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  isBigPopup?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xs';
  style?: {
    wrapper?: React.CSSProperties;
    content?: React.CSSProperties;
    popup?: React.CSSProperties;
    title?: React.CSSProperties;
  };
  bigSizeMedia?: boolean;
  trigger?: JSX.Element;
}

const PopupTemplate: FC<IPopupTemplateProps> = (props) => {
  const {
    dismissModal,
    headerTitle,
    leftContent,
    rightContent,
    isBigPopup = true,
    size,
    style,
    bigSizeMedia = false,
  } = props;

  const closeModal = (): void => {
    dismissModal();
  };

  return (
    <div
      className={cx('wrapper', 'popup-wrapper', size ? size : 'md', bigSizeMedia && 'bigSizeMedia')}
      style={style?.wrapper}
    >
      <div className={cx('popup')} style={style?.popup}>
        <div className={cx('popup__title')} style={style?.title}>
          <span>{headerTitle}</span>
          <i className={cx('popup__close-btn')} onClick={closeModal}></i>
        </div>
        <div
          style={isBigPopup ? { overflowY: 'auto' } : {}}
          className={cx('popup__content')}
          // style={style?.content}
        >
          {leftContent ? <div className={cx('popup__content-left')}>{leftContent}</div> : ''}
          <div className={cx('popup__content-right', leftContent ? '' : 'full')}>
            {rightContent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupTemplate;

import { range } from 'lodash';

export const MAX_SHOW_PAGES = 5;

export const getPagesArray = (currentPage: number, pageCount: number): number[] => {
  // Если страниц <= 5, добавляем все
  if (pageCount <= MAX_SHOW_PAGES) {
    return range(1, pageCount + 1);
  }

  const pages = [1];

  // Определяем диапазон отображаемых страниц
  let startPage = Math.max(2, currentPage - 1);
  let endPage = Math.min(pageCount - 1, currentPage + 1);

  // Если текущая страница близка к первой странице, сдвигаем диапазон
  if (currentPage <= 3) {
    endPage = Math.min(4, pageCount - 2);
  }

  // Если текущая страница близка к последней странице, сдвигаем диапазон
  if (currentPage >= pageCount - 2) {
    startPage = Math.max(pageCount - 3, 2);
  }

  // Добавляем диапазон страниц
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  // Всегда добавляем последнюю страницу
  if (pageCount > 1) {
    pages.push(pageCount);
  }

  return pages;
};

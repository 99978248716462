import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import styles from '../operator-page.module.scss';
import { notify } from 'utils/notify';
import { changeBranchMethod } from 'api/operator';
import PaginateSelect from 'components/ui/PaginateSelect/PaginateSelect';
import { fetchBranchListWithParams } from 'utils/managment/fetchData';
import { useParams } from 'react-router-dom';
import { ManagementOperator } from 'models/Management/Operators/Operator';

const cx = classNames.bind(styles);

type Props = {
  operatorData: ManagementOperator;
  setOpenForm: (val: boolean) => void;
  updateComponent: () => void;
  oldBranchId: number;
};

const ChangeBranchForm = (props: Props) => {
  const { operatorData, setOpenForm, updateComponent, oldBranchId } = props;

  const setDefaultValues = () => {
    return {
      branch_id: '',
      role: '',
    };
  };

  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = {
      admin_user_id: operatorData.id,
      old_branch_id: oldBranchId,
      new_branch_id: data?.branch_id?.value,
    };

    changeBranchMethod(postData)
      .then((res) => {
        if (res.status === 202) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          reset(setDefaultValues());
          setOpenForm(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response || 'Error change branch',
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <form className={cx('form-department')} onSubmit={handleSubmit(onSubmit)}>
      <Row className={cx('form__line')}>
        <Col md={6} className="mb-4">
          <PaginateSelect
            isSearchable={false}
            isRequired={true}
            isMulti={false}
            menuPlacement="top"
            label={'Branch'}
            control={control}
            id="branch_id"
            name="branch_id"
            onChange={(page, search, branch_type) => {
              return fetchBranchListWithParams(branch_type.value, page, branch_type.params);
            }}
            mainVal={{
              value: 'Team',
              params: params.id,
            }}
            errors={errors.role}
            rules={{
              required: 'Role field is required',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className={cx('btn-wrapper')}>
            <Button
              buttonText="Cancel"
              buttonType="outline"
              type="button"
              onClick={() => {
                setOpenForm(false);
              }}
            />
            <Button isLoading={isLoading} disabled={!isDirty} buttonText={'Save'} type="submit" />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default ChangeBranchForm;

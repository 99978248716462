import { useFormContext } from 'react-hook-form';

import Button from 'components/ui/Button/Button';
import { ManagementTeamFilter } from 'models/Management/Teams/Filter';
import { ReloadButton } from 'components/ui/ReloadButton';
import { SearchBar } from 'components/ui/SearchBar';
import { teamsFilterDefaultValues, TeamsFilterType } from '../useTeamsPage';
import styles from './teams-filter.module.scss';

type Props = {
  onSubmit: (data: ManagementTeamFilter) => void;
  isLoading: boolean;
  setDefaultColumnsOrder: () => void;
};

const TeamsFilter = (props: Props) => {
  const { onSubmit, isLoading, setDefaultColumnsOrder } = props;

  const {
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { dirtyFields },
  } = useFormContext<TeamsFilterType>();

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        office_ids: getValue(data['office_ids']) ? [getValue(data['office_ids'])] : '',
        desk_ids: getValue(data['desk_ids']) ? [getValue(data['desk_ids'])] : '',
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const isFormChange = () => {
    if (getValues('office_ids')) {
      return true;
    }
    return Object.keys(dirtyFields).length;
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={styles.form}
    >
      <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
      <SearchBar
        className={styles.searchBar}
        inputProps={{
          id: 'query',
          name: 'query',
          control,
          placeholder: 'Search by Team Name',
          onSearch: () => submit(getValues()),
          onChange: (v) => v?.length === 0 && submit(getValues()),
        }}
      />
      <div className={styles.buttons}>
        <ReloadButton isLoading={isLoading} onClick={() => submit(getValues())} />
        <Button
          buttonText="Reset"
          buttonType="outline"
          isLoading={isLoading}
          type="reset"
          onClick={() => {
            reset(teamsFilterDefaultValues);
            onSubmit({ filter: {} });
          }}
          disabled={!isFormChange()}
        />
        <Button
          buttonText="Apply"
          isLoading={isLoading}
          type="submit"
          onClick={handleSubmit(submit)}
          disabled={!isFormChange()}
        />
      </div>
    </form>
  );
};

export default TeamsFilter;

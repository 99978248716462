import styles from './filter.module.scss';
import Button from 'components/ui/Button';
import { useFormContext } from 'react-hook-form';
import { getTimesTamp } from 'utils/getTimesTamp';
import { getNumberRange } from 'utils/getNumberRange';
import { ReloadButton } from 'components/ui/ReloadButton';
import { tradingActivityClientDefaultValues } from '../useTradingActivity';
import { SearchBar } from 'components/ui/SearchBar';

const TradingActivityFilter = ({ onSubmit, reloadFilters, setDefaultColumnsOrder }) => {
  const {
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { dirtyFields },
  } = useFormContext();

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        trading_account: getValue(data['trading_account']),
        trade_side: getValue(data['trade_side']),
        symbol: getValue(data['ticker']),
        volume: getNumberRange(data['volume']),
        open_at: getTimesTamp(data['open_at']),
        close_at: getTimesTamp(data['close_at']),
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(tradingActivityClientDefaultValues);
    onSubmit(null);
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={styles.form}
      >
        <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
        <SearchBar
          className={styles.searchBar}
          inputProps={{
            id: 'query',
            name: 'query',
            control,
            placeholder: 'Search by Trade ID',
            onSearch: () => submit(getValues()),
            onChange: (v) => !v?.length && submit(getValues()),
          }}
        />
        <div className={styles.buttons}>
          <ReloadButton isLoading={reloadFilters} onClick={() => submit(getValues())} />
          <Button
            buttonText="Reset"
            buttonType="outline"
            isLoading={reloadFilters}
            type="reset"
            onClick={resetForm}
            disabled={!getDirtyFieldsLength()}
          />
          <Button
            buttonText="Apply"
            isLoading={reloadFilters}
            type="submit"
            onClick={handleSubmit(submit)}
            disabled={!getDirtyFieldsLength()}
          />
        </div>
      </form>
    </>
  );
};

export default TradingActivityFilter;

import { disableGlobalDemoAccounts, enableGlobalDemoAccounts } from 'api/clients';
import Button from 'components/ui/Button';
import { SearchBar } from 'components/ui/SearchBar';
import { Switch } from 'components/ui/Switch';
import { getDemoAccountsAllowed, getEnableWebTrader } from 'constants/selectors';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { usePermission } from 'utils/usePermission';
import { useFormContext } from 'react-hook-form';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import styles from './search-clients.module.scss';
import { ReloadButton } from 'components/ui/ReloadButton';

type Props = {
  isLoading: boolean;
  onSubmit: (data: any) => void;
  setDefaultColumnsOrder: () => void;
  onReload: () => void;
};

export const SearchClientsTableLayoutHeader = ({
  isLoading,
  onSubmit,
  setDefaultColumnsOrder,
  onReload,
}: Props) => {
  const { permissionGiven: PERMISSION_DEMOACCOUNTS_EDIT } = usePermission(
    'admin.trading-account.edit-demo',
  );

  const wtEnabled = useAppSelector(getEnableWebTrader);
  const initialDemoAccountsAllowed = useAppSelector(getDemoAccountsAllowed);

  const [demoAccountsAllowed, setDemoAccountsAllowed] = useState(initialDemoAccountsAllowed);

  const { control, getValues, watch, handleSubmit } = useFormContext();

  const clientsSearchOptions = useSearchCategoriesOptions('client');
  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    watch,
    getValues,
    clientsSearchOptions,
  );

  useEffect(() => {
    setDemoAccountsAllowed(initialDemoAccountsAllowed);
  }, [initialDemoAccountsAllowed]);

  return (
    <div className={styles.tableLayoutHeader}>
      <Button buttonText="Reset column order" onClick={setDefaultColumnsOrder} />
      <SearchBar
        className={styles.searchBar}
        inputProps={{
          id: 'query',
          name: 'query',
          control,
          placeholder: dynamicSearchPlaceholder,
          onSearch: handleSubmit(onSubmit),
          onChange: (v) => !v && onSubmit(getValues()),
        }}
        selectProps={{
          id: 'query_category',
          name: 'query_category',
          control,
          options: clientsSearchOptions,
          onSelectChange: () => {
            const values = getValues();
            if (values?.query) {
              onSubmit(getValues());
            }
          },
        }}
      />
      <div className={styles.actions}>
        <ReloadButton isLoading={isLoading} onClick={onReload} />

        {PERMISSION_DEMOACCOUNTS_EDIT && wtEnabled && (
          <div className={styles.allowSwitch}>
            <Switch
              isChecked={demoAccountsAllowed}
              isDisabled={isLoading}
              onChange={(e) => {
                if (e.target.checked) {
                  enableGlobalDemoAccounts();
                } else {
                  disableGlobalDemoAccounts();
                }
                setDemoAccountsAllowed(e.target.checked);
              }}
            />
            <p className={styles.allowSwitch__text}>Allow all clients to create demo accounts</p>
          </div>
        )}
      </div>
    </div>
  );
};

import { Tooltip } from 'bootstrap';
import classNames from 'classnames/bind';
import { FC, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import Select, { components, OptionProps } from 'react-select';

import { IJokerSelect, IOptions } from 'models/components/JokerSelect/JokerSelect.ts';
import { Icon } from '../Icon';
import { simpleSelectStyles } from '../SimpleSelect';
import styles from './joker-select.module.scss';

const cx = classNames.bind(styles);

const JokerSelect: FC<IJokerSelect> = (props) => {
  const {
    onChange,
    label,
    options,
    defaultValues,
    placeholder,
    errors,
    isDisabled = false,
    isRequired = false,
    isClearable = false,
    isMulti = false,
    isSearchable = true,
    rules,
    components,
    ...other
  } = props;
  const defaultValue: IOptions | null = options && options.length ? options[0] : null;

  const [value, setValue] = useState<IOptions>(defaultValue);

  const { field } = useController(props);

  const onSelectChange = (value): void => {
    field.onChange(value);
    setValue(value);
    props.onSelectChange?.(value);
  };

  useEffect(() => setValue(field.value), [field.value]);

  return (
    <div>
      {props.label && (
        <div className={cx('label-wrap')}>
          <label htmlFor={props.id} className={cx('label')}>
            {props.label}
            {props.isRequired && <span className={cx('required')}>*</span>}
          </label>
          {props.tooltip && props.tooltip.show && (
            <div className={cx('icon-container')}>
              <Tooltip params={props.tooltip} />
            </div>
          )}
        </div>
      )}
      <Select
        {...field}
        styles={{ ...simpleSelectStyles({ isDisabled, errors }), ...props.customStyles }}
        autoFocus
        // controlShouldRenderValue={false}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator,
          Option,
          IndicatorSeparator: null,
          ...components,
        }}
        hideSelectedOptions={false}
        menuPlacement="auto"
        isClearable={isClearable}
        onChange={onSelectChange}
        options={options}
        placeholder={placeholder}
        tabSelectsValue={false}
        value={value || placeholder || 'Any'}
      />
      {errors && <p className={cx('errorName')}>{errors.message}</p>}
    </div>
  );
};

const DropdownIndicator = () => (
  <div style={{ padding: '0 12px', display: 'flex' }}>
    <Icon name="caretDown" size={16} />
  </div>
);

const Option = (props: OptionProps) => {
  return (
    <div
      className={cx(
        'option',
        props.isSelected ? 'selected' : props.isFocused ? 'focused' : undefined,
      )}
    >
      <components.Option {...props}>
        <div>
          <span className={cx('account__label')} ref={props.innerRef} {...props.innerProps}>
            {props.children}
          </span>
        </div>
      </components.Option>
    </div>
  );
};

export default JokerSelect;

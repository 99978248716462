import Preloader from "../../components/ui/Preloader";
import { useSelector } from "react-redux";
import { DashboardLayout } from '../UserView/DashboardLayout';
import {
    selectChatAuth,
    selectChatConnectionStatus,
    selectChatLoading,
    selectChatToken
} from "../../store/slice/chat/chatSlice";
import { ChatThreadsContainer } from "./components/ChatThreadsContainer/ChatThreadsContainer";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../store";
import { useChatOpen } from "./helpers/useChatOpen";
import { usePageTitle } from "../../utils/usePageTitle";
import './Chat.scss';

const ChatPage = () => {
    const token = useSelector(selectChatToken);
    const userLoaded = useSelector(selectChatAuth);
    const connectionEstablished = useSelector(selectChatConnectionStatus);
    const threadsLoading = useAppSelector(selectChatLoading);
    const isAuth = token && userLoaded;

    useChatOpen();
    usePageTitle('Joker CRM');

    return (
        <DashboardLayout>
            <div className="chat-page">
                {!connectionEstablished ? <div className='connection-status'>Connection issues...</div> : null}
                {isAuth ? (
                    <>
                        <ChatThreadsContainer/>
                        <div className="chat-content">{!threadsLoading ? <Outlet/> : null}</div>
                    </>
                ) : <Preloader />}
            </div>
        </DashboardLayout>
    )
};

export default ChatPage;
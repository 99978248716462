import { createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { notify } from "../../utils/notify";
import {
    createTickerMessage,
    deleteTickerMessage,
    getTickerActive,
    getTickerMessages,
    updateTickerMessage, updateTickerMessageStatus
} from "../../api/tiker";
import { Alert, SystemMessageFormSubmitData } from "../../models/Ticker";
import { getAlertMessageType } from "../../pages/SettingsLk/SystemMessages/helpers/getAlertMessageType";
import { format } from "date-fns";

type InitialState = {
    messages: Alert[],
    messagesPending: boolean;
};

type UpdateAlertProps = {
    message: SystemMessageFormSubmitData,
    id: string,
    isRepublish: boolean
}

const initialState: InitialState = {
    messages: [],
    messagesPending: true
};

export const selectTickerMessages = createSelector(
    (state) => state.ticker.messages,
    messages => messages.reduce((result, item) => {
        const key = getAlertMessageType(item);
        result[key].push(item);

        return result;
    }, { active: [], scheduled: [], past: [] })
);

export const selectTickerActive = createSelector(
    (state) => state.ticker.messages,
    messages => messages.find(item => getAlertMessageType(item) === 'active')
);

export const selectTickerMessagesPending = (state) => state.ticker.messagesPending;

export const getAlerts = createAsyncThunk('ticker/alerts', async (_, { rejectWithValue }) => {
    try {
        const response = await getTickerMessages();
        return response.data.data.map(formatResponseTimestamp);
    } catch (error) {
        notify({
            type: 'error',
            message: error.response.data.message,
            timeOut: 3000,
        });
        return rejectWithValue(error.response.data.message);
    }
});

export const getActiveAlert = createAsyncThunk('ticker/active', async (_, { rejectWithValue }) => {
    try {
        const currentTime = encodeURIComponent(format(new Date(), 'yyyy-MM-dd HH:mm:ss xx'));
        const response = await getTickerActive(currentTime);
        return Object.keys(response.data.data).length ? formatResponseTimestamp(response.data.data) : null;
    } catch (error) {
        notify({
            type: 'error',
            message: error.response.data.message,
            timeOut: 3000,
        });
        return rejectWithValue(error.response.data.message);
    }
});

export const createAlert = createAsyncThunk('ticker/create', async (data: SystemMessageFormSubmitData, { rejectWithValue }) => {
    try {
        const response = await createTickerMessage(data);
        notify({
            type: 'success',
            message: 'The message was created successfully.',
            timeOut: 3000,
        });
        return formatResponseTimestamp(response.data.data);
    } catch (error) {
        notify({
            type: 'error',
            message: getMessageFormError(error),
            timeOut: 3000,
        });
        return rejectWithValue(error.response.data.message);
    }
});

export const updateAlert = createAsyncThunk('ticker/update', async (data: UpdateAlertProps, { rejectWithValue }) => {
    try {
        if (data.isRepublish) {
            data.message.status = true;
        }
        const response = await updateTickerMessage(data.message, data.id);
        notify({
            type: 'success',
            message: `The message was ${data.isRepublish ? 'rescheduled' : 'updated'} successfully.`,
            timeOut: 3000,
        });
        return formatResponseTimestamp(response.data.data);
    } catch (error) {
        notify({
            type: 'error',
            message: getMessageFormError(error),
            timeOut: 3000,
        });
        return rejectWithValue(error.response.data.message);
    }
});

export const updateAlertStatus = createAsyncThunk('ticker/update/status',
    async ({ id, status }:{ id: string, status: boolean }, { rejectWithValue }) => {
    try {
        const response = await updateTickerMessageStatus(id, status);
        notify({
            type: 'success',
            message: status ? 'The message was rescheduled successfully.' : 'The message was archived.',
            timeOut: 3000,
        });
        return formatResponseTimestamp(response.data.data);
    } catch (error) {
        notify({
            type: 'error',
            message: error.response.data.message,
            timeOut: 3000,
        });
        return rejectWithValue(error.response.data.message);
    }
});

export const deleteAlert = createAsyncThunk('ticker/delete', async (message: Alert, { rejectWithValue }) => {
    try {
        await deleteTickerMessage(message.id);
        return message.id
    } catch (error) {
        notify({
            type: 'error',
            message: error.response.data.message,
            timeOut: 3000,
        });
        return rejectWithValue(error.response.data.message);
    }
});

const updateMessages = (messages, newMessage) => {
    return messages.map(item => {
        if (item.id === newMessage.id) {
            return newMessage
        }
        return item;
    });
};

const getMessageFormError = (error) => {
    return error.response.data.message || Object.values(error.response.data?.errors || []).reduce((messages, item) => {
        messages += item[0] + '\n';
        return messages;
    }, '');
};

const formatResponseTimestamp = (message) => ({
    ...message,
    start_date: message.start_date * 1000,
    end_date: message.end_date * 1000
});

const tickerSlice = createSlice({
    name: 'ticker',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(getActiveAlert.fulfilled, (state, action) => {
            if (action.payload) {
                state.messages = state.messages.length ? updateMessages(state.messages, action.payload) : [action.payload];
            }
        });
        builder.addCase(getAlerts.fulfilled, (state, action) => {
            state.messagesPending = false;
            state.messages = action.payload;
        });
        builder.addCase(createAlert.fulfilled, (state, action) => {
            state.messages.push(action.payload);
        });
        builder.addCase(deleteAlert.fulfilled, (state, action) => {
            state.messages = state.messages.filter(item => item.id !== action.payload);
        });
        builder.addMatcher(isAnyOf(updateAlert.fulfilled, updateAlertStatus.fulfilled), (state, action) => {
            state.messages = updateMessages(state.messages, action.payload);
        });
    }
});

export const tickerReducer = tickerSlice.reducer;
export const {  } = tickerSlice.actions;

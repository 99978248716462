import React, { useState } from 'react';
import classNames from 'classnames/bind';

import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import styles from '../operator-page.module.scss';
import { notify } from 'utils/notify';
import JokerSelect from 'components/ui/JokerSelect';
import { useParams } from 'react-router-dom';
import { addBranchMethod } from 'api/operator';
import PaginateSelect from 'components/ui/PaginateSelect/PaginateSelect';
import { fetchBranchListWithParams } from 'utils/managment/fetchData';
import { ManagementOperator } from 'models/Management/Operators/Operator';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  operatorData: ManagementOperator;
  branchTypeList: ListValue[];
  setOpenForm: (val: boolean) => void;
  updateComponent: () => void;
};

const AddBranchForm = ({ operatorData, branchTypeList, setOpenForm, updateComponent }: Props) => {
  const params = useParams();

  const setDefaultValues = () => {
    return {
      branch_type: undefined,
      branch_id: '',
      role: '',
    };
  };

  const setDefaultValuesBranch = () => {
    return {
      branch_type: getValues('branch_type'),
      branch_id: undefined,
    };
  };

  const [isLoading, setIsLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [branch_type, setBranchType] = useState(null);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const branchWatch = watch('branch_type');

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      admin_user_id: operatorData.id,
      branch_type: data?.branch_type.label,
      branch_id: data?.branch_id?.value,
    };
    addBranchMethod(sendData)
      .then((res) => {
        if (res.status === 201) {
          notify({
            type: 'success',
            message: 'Branch added successfully',
            timeOut: 3000,
          });
          updateComponent();
          reset(setDefaultValues());
          setBranchList([]);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const updateListBranch = (branch_type) => {
    setBranchType({
      ...branch_type,
      params: params.id,
    });
    reset(setDefaultValuesBranch());
  };

  return (
    <form className={cx('form-department')} onSubmit={handleSubmit(onSubmit)}>
      <Row className={cx('form__line')}>
        <Col md={4} className="mb-4">
          <JokerSelect
            isRequired={false}
            label={'Branch Type'}
            onSelectChange={() => {
              updateListBranch(getValues('branch_type'));
            }}
            control={control}
            id="branch_type"
            name={'branch_type'}
            placeholder={'--Select--'}
            rightaligned={true}
            options={branchTypeList}
          />
        </Col>
        <Col md={4} className="mb-4">
          <PaginateSelect
            isSearchable={false}
            isDisabled={getValues('branch_type') ? false : true}
            isRequired={true}
            isMulti={false}
            menuPlacement="top"
            label={'Branch'}
            control={control}
            id="branch_id"
            name="branch_id"
            onChange={(page, search, branch_type) => {
              return fetchBranchListWithParams(branch_type.value, page, branch_type.params);
            }}
            cacheUniqs={[branchWatch]}
            mainVal={branch_type}
            errors={errors.role}
            rules={{
              required: 'Role field is required',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className={cx('btn-wrapper')}>
            <Button
              buttonText="Cancel"
              buttonType="outline"
              type="button"
              onClick={() => {
                setOpenForm(false);
              }}
            />
            <Button
              isLoading={isLoading}
              disabled={!isDirty}
              buttonText={'Save'}
              type="submit"
              onClick={(data) => handleSubmit(onSubmit.bind(data))}
            />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default AddBranchForm;

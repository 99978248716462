import classNames from 'classnames/bind';
import React, { FC } from 'react';
import styles from './preloader.module.scss';

const cx = classNames.bind(styles);

const Preloader: FC = () => {
  return (
    <div className={cx('loader-wrap')}>
      <div className={cx('loader')}></div>
    </div>
  );
};

export default Preloader;

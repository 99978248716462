export const isFormFilled = (values) => {
    if (!values) return false;

    return [
        values?.message?.trim(),
        values?.color,
        values?.scrolling_speed,
        values?.date?.from,
        values?.date?.to
    ].every(value => !!value);
};
import { CheckCircle } from 'phosphor-react';

import styles from './forgot-password-success.module.scss';

export const ForgotPasswordSuccess = () => {
  return (
    <div className={styles.wrapper}>
      <CheckCircle size={132} color="var(--green)" />
      <div className={styles.textWrapper}>
        <p>The letter was successfully sent.</p>
        <p>Please check.</p>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';

import classNames from 'classnames/bind';
import styles from './../../client-page.module.scss';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import { getDocumentByUser, removeDocumentRequest, documentTypes } from 'api/documents';
import FilesTable from 'components/ui/Table/FilesTable';
import TablePagination from 'components/ui/TablePagination';
import AddFilePopup from './AddFilesPopup';
import Button from 'components/ui/Button/Button';
import { usePermission } from 'utils/usePermission';
import { getTablePageParams } from 'utils/getTablePageParams';
import { TypeData } from 'models/Clients/ClientFile';
import { TableLinks, TableMeta } from 'models/Table';
import { TableLayout } from 'components/ui/Table/TableLayout';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import { TABLE_FILES_COLUMNS_NAMES } from 'constants/table';

const cx = classNames.bind(styles);

const Files = () => {
  const { permissionGiven: PermissionList } = usePermission('admin.document.user-list');
  const { permissionGiven: PermissionUploadDocument } = usePermission('admin.document.upload');

  const [isListLoading, setIsListLoading] = useState(true);

  const [documentId, setDocumentId] = useState(null);
  const [documentsList, setDocumentsList] = useState([]);

  const [typesData, setTypesData] = useState<TypeData[]>([]);
  const [categoryOptions, setCategoryOptions] = useState();

  const [tableMeta, setTableMeta] = useState<TableMeta | undefined>(undefined);
  const [tableLinks, setTableLinks] = useState<TableLinks | undefined>(undefined);
  const [perPageCount, setPerPageCount] = useState(10);
  const { id } = useParams();

  const fetchUserDocuments = (options?) => {
    setIsListLoading(true);

    getDocumentByUser(getTablePageParams(options, perPageCount, tableLinks), id)
      .then((res) => {
        if (res) {
          setDocumentsList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsListLoading(false);
      });
  };

  const deleteDocumentItem = (docId, userId) => {
    removeDocumentRequest(docId, userId)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'File deleted successfully',
            timeOut: 3000,
          });
          fetchUserDocuments();
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      });
  };

  useEffect(() => {
    if (PermissionList) {
      fetchUserDocuments();
      fetchDocumentTypes();
    }
  }, [PermissionList]);

  const fetchDocumentTypes = () => {
    documentTypes()
      .then((res) => {
        setTypesData(res.data.data);
        const categoryOptions = res.data.data.map((item) => ({
          value: item.key,
          label: item.translate,
        }));
        setCategoryOptions(categoryOptions);
      })
      .catch((err) => console.log(err));
  };

  const table = useTableColumnOrder('TABLE_FILES_COLUMNS_NAMES', TABLE_FILES_COLUMNS_NAMES);

  return (
    <>
      <div className={styles.tabTitle}>
        Files{' '}
        {PermissionUploadDocument ? (
          <AddFilePopup
            updateComponent={fetchUserDocuments}
            userId={id}
            getDocumentId={setDocumentId}
            triggerBtn={<Button buttonText="+ Add file" buttonType="outline" onClick={null} />}
          />
        ) : null}
      </div>
      <TableLayout
        header={
          <Button
            buttonStyle={{ marginBottom: 26 }}
            buttonText="Reset column order"
            onClick={table.setDefaultColumnsOrder}
          />
        }
      >
        {PermissionList ? (
          <FilesTable
            data={documentsList}
            perPage={tableMeta?.per_page}
            showLoader={isListLoading}
            userId={id}
            deleteAction={deleteDocumentItem}
            updateComponent={fetchUserDocuments}
            typesOpt={typesData}
            columns={table.columns}
            saveColumnOrder={table.saveColumnOrder}
          />
        ) : null}
      </TableLayout>
      {documentsList?.length ? (
        <TablePagination
          hidePageSelect={true}
          currentPage={tableMeta?.current_page}
          pagesLength={tableMeta?.last_page}
          perPageChange={(value) => {
            setPerPageCount(value);
            fetchUserDocuments({ perPage: value });
          }}
          goToSelectedPage={(page) => {
            fetchUserDocuments({ page });
          }}
        />
      ) : null}
    </>
  );
};

export default Files;

import cn from "classnames";
import { format, isToday, isYesterday } from "date-fns";
import './ChatDate.scss';

type Props = {
    className?: string;
    date: string;
    dateFormat: 'time' | 'date' | 'distance';
};

const formatsMap = {
    dayMonth: (date) => format(new Date(date), 'MMM') + ' ' + format(new Date(date), 'd'),
    time: (date) => format(new Date(date), 'p').toLowerCase(),
    date: (date) => {
        const isTodayDate = isToday(Date.parse(date)) && 'Today';
        const isYesterdayDate = !isTodayDate && isYesterday(Date.parse(date)) && 'Yesterday';
        return isTodayDate || isYesterdayDate || formatsMap.dayMonth(date);
    },
    distance: (date) => {
        if (isToday(Date.parse(date))) {
            return formatsMap.time(date)
        }

        if (isYesterday(Date.parse(date))) {
            return 'Yesterday ' + formatsMap.time(date)
        }

        return formatsMap.dayMonth(date) + ', ' + formatsMap.time(date);
    },
}

export const ChatDate = ({ date, dateFormat, className }: Props) => {
    if (!date) {
        return null;
    }

    const dateOutput = formatsMap[dateFormat](date);
    return (
        <div className={cn('chat-date', className)}>{dateOutput}</div>
    )
}
import { Icon } from 'components/ui/Icon';
import { Lead } from 'models/Leads/Lead';
import moment from 'moment';
import CheckRole from 'utils/RoleComponent';
import { usePermission } from 'utils/usePermission';
import { getShortUuid } from 'utils/getShortUuid';
import { useState } from 'react';
import styles from './lead-personal-info.module.scss';

type Props = {
  lead: Lead;
  permissions: {
    showPersonalInfo: boolean;
    showContacts: boolean;
    showAddress: boolean;
  };
};

export const LeadPersonalInfo = ({ lead, permissions }: Props) => {
  const { permissionGiven: PermissionPhone } = usePermission('admin.lead.show-phone');
  const { permissionGiven: PermissionMobile } = usePermission('admin.lead.show-mobile');
  const { permissionGiven: PermissionEmail } = usePermission('admin.lead.show-email');

  const { showAddress, showContacts, showPersonalInfo } = permissions;

  const [show, setShow] = useState({ phone: false, mobile: false, email: false });

  return (
    <div className={styles.container}>
      {showPersonalInfo ? (
        <>
          <div className={styles.row}>
            <span>Date of birth: </span>
            <span>
              {lead && lead.birthDate ? moment(lead.birthDate * 1000).format('DD.MM.YYYY') : '---'}
            </span>
          </div>
          <div className={styles.row}>
            <span>Gender: </span>
            <span>{lead && lead.gender ? lead.gender : '---'}</span>
          </div>
        </>
      ) : null}
      {showContacts ? (
        <>
          {PermissionPhone ? (
            <div className={styles.row}>
              <span>Phone: </span>
              <span>{show.phone ? (lead && lead.phone ? lead.phone : '---') : '***********'}</span>
              <CheckRole permissionType="admin.client.view-phone" roleType={['Super Admin']}>
                <Icon
                  className={styles.icon}
                  name={!show.phone ? 'eye' : 'eyeClose'}
                  size={17}
                  onClick={() => setShow((p) => ({ ...p, phone: !p.phone }))}
                />
              </CheckRole>
            </div>
          ) : null}
          {PermissionMobile ? (
            <div className={styles.row}>
              <span>Mobile: </span>
              <span>
                {show.mobile ? (lead && lead.mobile ? lead.mobile : '---') : '***********'}
              </span>
              <CheckRole permissionType="admin.client.view-phone" roleType={['Super Admin']}>
                <Icon
                  className={styles.icon}
                  name={!show.mobile ? 'eye' : 'eyeClose'}
                  size={17}
                  onClick={() => setShow((p) => ({ ...p, mobile: !p.mobile }))}
                />
              </CheckRole>
            </div>
          ) : null}
          {PermissionEmail ? (
            <div className={styles.row}>
              <span>E-mail: </span>
              <span>{show.email ? (lead && lead.email ? lead.email : '---') : '***********'}</span>
              <CheckRole permissionType="admin.client.view-email" roleType={['Super Admin']}>
                <Icon
                  className={styles.icon}
                  name={!show.email ? 'eye' : 'eyeClose'}
                  size={17}
                  onClick={() => setShow((p) => ({ ...p, email: !p.email }))}
                />
              </CheckRole>
            </div>
          ) : null}
        </>
      ) : null}
      {showAddress ? (
        <>
          <div className={styles.row}>
            <span>Country: </span>
            <span>
              {lead.country ? (
                <i style={{ backgroundImage: `url(${lead.country?.flag})` }} />
              ) : null}
              {lead && lead.country?.name ? lead.country?.name : ' ---'}
            </span>
          </div>
          {lead && lead.city ? (
            <div className={styles.row}>
              <span>City: </span>
              <span>{lead.city}</span>
            </div>
          ) : null}
        </>
      ) : null}
      <div className={styles.row}>
        <span>Language: </span>
        <span>{lead && lead.language ? lead.language.name : '---'}</span>
      </div>
      {lead && lead.source ? (
        <div className={styles.row}>
          <span>Source: </span>
          <span>{lead.source}</span>
        </div>
      ) : null}
      {lead && lead.affiliate ? (
        <div className={styles.row}>
          <span>Affiliate: </span>
          <span>{getShortUuid(lead.affiliate)}</span>
        </div>
      ) : null}
    </div>
  );
};

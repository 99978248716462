import { FormattedMessage } from 'react-intl';
import { Icon } from 'components/ui/Icon';

const DotContainer = () => (
  <span style={{ display: 'block', height: '100%' }}>
    <Icon name="dot" size={12} />
  </span>
);

export const topMenuItems = [
  {
    title: <FormattedMessage id="menu.clients" defaultMessage="Clients" />,
    key: 'clients',
    itemId: '#1',
    elemBefore: () => <Icon name="client" />,
    subNav: [
      {
        title: <FormattedMessage id="menu.searchClients" defaultMessage="Search clients" />,
        key: 'search_clients',
        itemId: '/search-clients',
        elemBefore: DotContainer,
      },
      {
        title: <FormattedMessage id="menu.kycDocuments" defaultMessage="KYC documents" />,
        key: 'kyc_documents',
        itemId: '/documents',
        elemBefore: DotContainer,
      },
      {
        title: <FormattedMessage id="menu.tradingAccount" defaultMessage="Trading Accounts" />,
        key: 'trading_accounts',
        itemId: '/trading-accounts',
        elemBefore: DotContainer,
      },
    ],
  },
  {
    title: <FormattedMessage id="menu.leads" defaultMessage="Leads" />,
    key: 'leads',
    itemId: '/search-leads',
    elemBefore: () => <Icon name="lead" />,
  },
  {
    title: 'Management',
    itemId: '#2',
    key: 'management',
    elemBefore: () => <Icon name="management" />,
    subNav: [
      {
        title: 'Offices',
        key: 'offices',
        itemId: '/management-offices',
        elemBefore: DotContainer,
      },
      {
        title: 'Desks',
        key: 'desks',
        itemId: '/management-desks',
        elemBefore: DotContainer,
      },
      {
        title: 'Teams',
        key: 'teams',
        itemId: '/management-teams',
        elemBefore: DotContainer,
      },
      {
        title: 'Operators',
        key: 'operators',
        itemId: '/management-operators',
        elemBefore: DotContainer,
      },
      {
        title: 'Affiliates',
        key: 'partners',
        itemId: '/management-partners',
        elemBefore: DotContainer,
      },
      {
        title: 'Sales rules',
        key: 'sales_rules',
        itemId: '/sales-rules',
        elemBefore: DotContainer,
      },
    ],
  },
  {
    title: 'Notifications',
    key: 'notifications',
    itemId: '/notifications',
    elemBefore: () => <Icon name="notification" />,
  },
  {
    title: 'Payments',
    key: 'payments',
    itemId: '/payments',
    elemBefore: () => <Icon name="payment" />,
  },
  {
    title: 'Callbacks',
    key: 'callbacks',
    itemId: '/callbacks/list',
    elemBefore: () => <Icon name="callback" />,
  },
  {
    title: 'Customer Service',
    key: 'customer_service',
    itemId: '#3',
    elemBefore: () => <Icon name="customerService" />,
    subNav: [
      {
        title: 'Crypto Wallets',
        key: 'crypto_wallets',
        itemId: '/customer-crypto',
        elemBefore: DotContainer,
      },
      {
        title: 'Crypto Transactions',
        key: 'crypto_transactions',
        itemId: '/customer-transactions',
        elemBefore: DotContainer,
      },
      {
        title: 'Crypto Withdraw',
        key: 'crypto_requests',
        itemId: '/crypto-output',
        elemBefore: DotContainer,
      },
    ],
  },
  {
    title: 'Hierarchy',
    key: 'hierarchy',
    itemId: '/hierarchy',
    elemBefore: () => <Icon name="hierarchy" />,
  },
  {
    title: 'Roles and Permissions',
    key: 'roles_and_permissions_section',
    itemId: '#5',
    elemBefore: () => <Icon name="rolePermission" />,
    subNav: [
      {
        title: 'Roles and Permissions',
        key: 'roles_and_permissions',
        itemId: '/roles-end-permissions',
        elemBefore: DotContainer,
      },
      {
        title: 'Templates',
        key: 'templates',
        itemId: '/template-roles',
        elemBefore: DotContainer,
      },
    ],
  },
  {
    title: 'Settings PC',
    key: 'settings',
    itemId: '#4',
    elemBefore: () => <Icon name="settings" />,
    subNav: [
      {
        title: 'Styles',
        key: 'style_pc',
        itemId: '/settings-pc-style',
        elemBefore: DotContainer,
      },
      {
        title: 'Dynamic statuses',
        key: 'dynamic_statuses',
        itemId: '/settings-pc-dynamic-statuses',
        elemBefore: DotContainer,
      },
      {
        title: 'System messages',
        key: 'system_messages',
        itemId: '/settings-pc-system-messages',
        elemBefore: DotContainer,
      },
    ],
  },
];

export const bottomMenuitems = [];

export const MAIN_MENU_TYPE = {
  SIDE_MENU: 'side_menu',
  SIDE_MENU_SEMI_BIG: 'side_menu_semi_big',
  BURGER: 'burger_menu',
};

export const getCurrentCallPhone = (data) => {
  return data?.phone.replace(/[^0-9]/g, '');
};

export const getCurrentAltCallPhone = (data) => {
  return data?.user_profile.alt_phone.replace(/[^0-9]/g, '')
}

export const getFullName = (data) => {
  return data?.user_profile?.first_name + '_' + data?.user_profile?.last_name;
};
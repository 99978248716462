export type JokerPermissionObject = {
  id: number;
  name: string;
  action: string;
  type: string;
  description: string;
};

const PermissionArray = [
  'admin.notifications.view',
  'admin.permission.list',
  'admin.settings.edit',
  'admin.role.index',
  'admin.role.show',
  'admin.role.edit',
  'admin.role.delete',
  'admin.partner-schedule.create',
  'admin.partner-schedule.update',
  'admin.partner-schedule.index',
  'admin.partner-schedule.show',
  'admin.partner-schedule.delete',
  'admin.partner.index',
  'admin.partner.show',
  'admin.partner.create',
  'admin.partner.update',
  'admin.partner.delete',
  'admin.partner.active',
  'admin.partner.change-password',
  'admin.rule.create',
  'admin.rule.index',
  'admin.rule.show',
  'admin.rule.update',
  'admin.rule.delete',
  'admin.callback.create',
  'admin.callback.index',
  'admin.callback.update',
  'admin.callback.delete',
  'admin.document.user-list',
  'admin.document.index',
  'admin.document.upload',
  'admin.document.download',
  'admin.document.delete',
  'admin.document.update',
  'admin.notes.create',
  'admin.notes.update',
  'admin.notes.delete',
  'admin.notes.list',
  'admin.lead.index',
  'admin.lead.show',
  'admin.lead.promote',
  'admin.leads.upload',
  'admin.lead.show-personal-info',
  'admin.lead.update-personal-info',
  'admin.lead.show-address',
  'admin.lead.update-address',
  'admin.lead.show-contacts',
  'admin.lead.update-contacts',
  'admin.lead.show-phone',
  'admin.lead.show-email',
  'admin.lead.show-mobile',
  'admin.branch.index',
  'admin.branch.create',
  'admin.branch.delete',
  'admin.branch.show',
  'admin.team.index',
  'admin.team.create',
  'admin.team.update',
  'admin.team.delete',
  'admin.desk.index',
  'admin.desk.create',
  'admin.desk.update',
  'admin.desk.delete',
  'admin.office.index',
  'admin.office.create',
  'admin.office.update',
  'admin.office.delete',
  'admin.client.list',
  'admin.client.change-password',
  'admin.client.view-personal-info',
  'admin.client.view-address-info',
  'admin.client.edit-address-info',
  'admin.client.edit-personal-info',
  'admin.client.blocked',
  'admin.client.unblocked',
  'admin.client.view-email',
  'admin.client.view-phone',
  'admin.client.team',
  'admin.client.buffer-list',
  'admin.client.status',
  'admin.client.bulk.to-teams',
  'admin.client.bulk.move',
  'admin.client.bulk.sales',
  'admin.client.bulk.retention',
  'admin.client.buffer-sales',
  'admin.client.buffer-retention',
  'admin.client.move.one-sales',
  'admin.client.move.one-retention',
  'admin.client.to-team.one-sales',
  'admin.client.to-team.one-retention',
  'admin.client.get-autologin',
  'admin.client.change-affiliation-data',
  'admin.admin-user.activation',
  'admin.admin-user.index',
  'admin.admin-user.show',
  'admin.admin-user.create',
  'admin.admin-user.edit',
  'admin.admin-user.delete',
  'admin.admin-user.role-add',
  'admin.admin-user.role-edit',
  'admin.admin-user.role-delete',
  'admin.admin-user.change-password-for-other',
  'admin.admin-user.enable-autologin',
  'admin.email-template.create',
  'admin.email-template.update',
  'admin.email-template.delete',
  'admin.email-template.index',
  'admin.email-template.show',
  'admin.deposit.transaction.index',
  'admin.deposit.transaction.show',
  'admin.deposit.transaction.show-by-account',
  'admin.deposit.balance',
  'admin.deposit.balance_info',
  'admin.deposit.transaction.create',
  'admin.deposit.transaction.withdrawal-moderate',
  'admin.deposit.transfer-funds',
  'admin.deposit.approve.crypto',
  'admin.deposit.approve.wire',
  'admin.deposit.approve.internal',
  'admin.deposit.approve.bonus',
  'admin.deposit.approve.credit',
  'admin.deposit.approve.credit_card',
  'admin.withdraw.approve.crypto',
  'admin.withdraw.approve.wire',
  'admin.withdraw.approve.internal',
  'admin.withdraw.approve.bonus',
  'admin.withdraw.approve.credit',
  'admin.withdraw.approve.credit_card',
  'admin.withdraw.approve.credit_card_ca',
  'admin.transfer.approve.crypto',
  'admin.transfer.approve.wire',
  'admin.transfer.approve.internal',
  'admin.transfer.approve.bonus',
  'admin.transfer.approve.credit',
  'admin.transfer.approve.credit_card',
  'admin.trading-account.index',
  'admin.trading-account.create',
  'admin.trading-account.show',
  'admin.trading-account.change-password',
  'admin.trading-account.block-account',
  'admin.trading-account.history.view',
  'admin.trading-account.edit-demo',
  'admin.logs.user',
  'admin.logs.document',
  'admin.logs.partner',
  'admin.logs.admin-user',
  'admin.blocked-status.create',
  'admin.blocked-status.update',
  'admin.blocked-status.index',
  'admin.blocked-status.delete',
  'admin.withdrawal.index',
  'admin.withdrawal.show',
  'admin.withdrawal.approve',
  'admin.withdrawal.reject',
  'admin.block-cypher.token-limits',
  'admin.block-cypher.web-hook-list',
  'admin.block-cypher.web-hook-delete',
  'admin.crypto_wallets.index',
  'admin.crypto_wallets.create',
  'admin.crypto_wallets.delete',
  'admin.crypto_wallets.restore',
  'admin.crypto_wallets.set-wallet',
  'admin.crypto_transactions.index',
  'admin.crypto_transaction.view-details',
  'admin.crypto_transaction.edit',
  'admin.statuses.create',
  'admin.statuses.show',
  'admin.menu-clients.view',
  'admin.menu-search-clients.view',
  'admin.menu-kyc-documents.view',
  'admin.menu-trading-accounts.view',
  'admin.menu-leads.view',
  'admin.menu-management.view',
  'admin.menu-sales-rules.view',
  'admin.menu-offices.view',
  'admin.menu-desks.view',
  'admin.menu-teams.view',
  'admin.menu-operators.view',
  'admin.menu-partners.view',
  'admin.menu-notifications.view',
  'admin.menu-payments.view',
  'admin.menu-callbacks.view',
  'admin.menu-customer-service.view',
  'admin.menu-crypto-wallets.view',
  'admin.menu-crypto-transactions.view',
  'admin.menu-crypto-requests.view',
  'admin.menu-hierarchy.view',
  'admin.menu-permissions.view',
  'admin.menu-roles-and-permissions.view',
  'admin.menu-templates.view',
  'admin.menu-settings.view',
  'admin.menu-style-pc.view',
  'admin.menu-dynamic-statuses.view',
  'admin.menu-mt-check.view',
  'admin.menu-system-messages.view',
  'admin.department.view',
  'admin.role.create',
  'admin.dashboard.view',
  'admin.hierarchy.view',
  'admin.management.view',
  'admin.payments.view',
  'admin.clients-distribution.view',
  'admin.customer-service.view',
  'admin.team.show',
  'admin.deposit.change-operator',
  'admin.client.view-balance',
  'admin.branch.change-team',
  'admin.client.verified',
  'admin.trading-account.password-view',
  'admin.lead.delete',
  'admin.client.delete',
  'admin.trading-account.delete',
  'admin.crypto_transaction.delete-payment',
  'admin.client.crypto_wallets.index',
  'admin.deposit.transaction.sales-index',
  'admin.deposit.transaction.retention-index',
  'admin.statuses.color.update',
  'admin.deposit.transaction.create-deposit-crypto',
  'admin.deposit.transaction.create-deposit-wire',
  'admin.deposit.transaction.create-deposit-internal-transfer',
  'admin.deposit.transaction.create-deposit-credit',
  'admin.deposit.transaction.create-deposit-bonus',
  'admin.deposit.transaction.create-deposit-credit-card',
  'admin.deposit.transaction.create-withdraw-crypto',
  'admin.deposit.transaction.create-withdraw-wire',
  'admin.deposit.transaction.create-withdraw-internal-transfer',
  'admin.deposit.transaction.create-withdraw-credit',
  'admin.deposit.transaction.create-withdraw-bonus',
  'admin.deposit.transaction.create-withdraw-credit-card',
  'admin.permission.supervisor'
] as const;

export type JokerPermissionKey = (typeof PermissionArray)[number];

export const PERMISSION_DENIED_URL = '/no-access'

import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { XCircle } from 'phosphor-react';
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectMessages, selectThreadId, selectUserStatuses, setFoundMessageId } from "../../../../store/slice/chat/chatSlice";
import { OnlineStatusesVerbose } from "../../../../models/Chat";
import { SearchResultMessage } from "./SearchResultMessage";
import { ChatSearchInputField } from "../ChatSearchInput/ChatSearchInputField";
import { getFoundMessagesList } from "../../../../store/slice/chat/thunks";
import { useDialogSearch } from "./useDialogSearch";
import './ChatDialogSearch.scss';
import { Icon } from "components/ui/Icon";

export const ChatDialogSearch = () => {
	const [opened, setOpened] = useState(false);

	const threadId = useAppSelector(selectThreadId);
	const userStatuses = useAppSelector(selectUserStatuses);
	const messages = useAppSelector(selectMessages);

	const { loading, onSearch, results, resetSearch } = useDialogSearch(threadId);

	const inputRef = useRef(null);

	const dispatch = useAppDispatch();

	useEffect(() => {
		opened && inputRef?.current?.focus();
	}, [inputRef?.current, opened]);

	useEffect(() => {
		handleCloseSearch();
	}, [threadId]);

	const handleCloseSearch = () => {
		setOpened(false);
		resetSearch();
	};

	const handleClickResult = (id: string) => {
		const cached = messages.find(message => message.id === id);
		cached ?
			dispatch(setFoundMessageId(cached.id)) :
			dispatch(getFoundMessagesList({ threadId, id }));

		handleCloseSearch();
	};

	return (
		<div className={cn('dialog-search-wrap', {opened})}>
			<div className="dialog-search-button" onClick={() => setOpened(!opened)}>
				<Icon name="search" size={20} color={'var(--main-color)'}/>
			</div>
			<div className={'dialog-search'}>
				{opened ? (
					<div className="search-form-wrap">
						<div className='dialog-search-form'>
							<ChatSearchInputField isLoading={loading}>
								<input name='chat-search' placeholder='Search' onChange={onSearch} maxLength={120} ref={inputRef}/>
							</ChatSearchInputField>
							<div className="dialog-search-form-button" onClick={handleCloseSearch}>
								<XCircle size={20}/>
							</div>
						</div>
						<div className='dialog-search-results'>
							{results.map(item =>
								<SearchResultMessage
									message={item}
									isOnline={userStatuses[item.owner_id] === OnlineStatusesVerbose.ONLINE}
									onClick={handleClickResult}
									key={item.id}
								/>
							)}
						</div>
					</div>
				) : null}
			</div>
		</div>
	)
}
import API from '../api';
import data from 'bootstrap/js/src/dom/data';

export const getDepartmentsListMethod = () => API.get(`api/v1/departments`);
export const getDepartmentRolesMethod = (id) => API.get(`api/v1/departments/${id}`);
export const getPermissionsTempleRole = (id) => API.get(`api/v1/roles/${id}/template`);
export const changePermissionsMethod = (id, data) => API.put(`api/v1/roles/${id}/permission`, data);
export const resetToDefaultPermissions = (id) => API.put(`api/v1/roles/${id}/set-default`);
export const changeNameRole = (id, data) => API.put(`api/v1/roles/${id}`, data);
export const changeTemplate = (id, data) => API.put(`api/v1/roles/${id}/template`, data);

export const getDictionaryMenu = () => API.get(`/api/v1/dictionary/menu`);

// const StatusList = [
//   { value: 'new', label: 'New' },
//   { value: 'accepted', label: 'Accepted' },
//   { value: 'rejected', label: 'Rejected' },
// ];

const getStatusForCrypt = (item) => {
  if (item?.deleted_at !== null) {
    return 'rejected';
  }
  if (item?.deleted_at === null && item?.accept_time) {
    return 'accepted';
  }
  if (item?.deleted_at === null && item?.accept_time === null) {
    return 'new';
  }
};

export { getStatusForCrypt };

import React from 'react';
import classNames from 'classnames/bind';
import styles from './tableStyle.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import moment from 'moment';
import { getShortUuid } from 'utils/getShortUuid';
import { copyToBuffer } from 'utils/copyToBuffer';
import { Link } from 'react-router-dom';
import { mapNotificationType } from 'constants/notifications';
import { upperFirst } from 'lodash';

const cx = classNames.bind(styles);

const NotificationsTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  colSortCheck,
  columns,
  saveColumnOrder,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, type, uuid, details, agent, client, created_at, priority, is_read } = item;

    return {
      type: (
        <div className={cx('notifications-table_td')}>
          <span>
            {uuid && (
              <>
                <span className={cx('type')}>{mapNotificationType[type] || type}</span>
                <span className={cx('uuid')} onClick={() => copyToBuffer(uuid, true)}>
                  {getShortUuid(uuid)}
                </span>
              </>
            )}
          </span>
        </div>
      ),
      details: <div className={cx('notifications-table_td')}>{<span>{details}</span>}</div>,
      agent: (
        <div className={cx('notifications-table_td')}>
          <span>
            {agent && (
              <>
                <span className={cx('agent')}>{agent.full_name}</span>
                <span className={cx('uuid')} onClick={() => copyToBuffer(agent.uuid, true)}>
                  {getShortUuid(agent.uuid)}
                </span>
              </>
            )}
          </span>
        </div>
      ),
      client: (
        <div className={cx('notifications-table_td')}>
          <span>
            {client && (
              <>
                <Link
                  target={'_blank'}
                  to={`/client/${client?.id ?? 0}`}
                  className={cx('notifications-table_td-link')}
                >
                  <span className={cx('client')}>
                    {client?.user_profile?.first_name + ' ' + client?.user_profile?.last_name}
                  </span>
                </Link>
                <span className={cx('uuid')} onClick={() => copyToBuffer(client.uuid, true)}>
                  {getShortUuid(client.uuid)}
                </span>
              </>
            )}
          </span>
        </div>
      ),
      created_at: (
        <div className={cx('notifications-table_td')}>
          <span className={cx('date')}>
            <span>{created_at && moment(created_at * 1000).format('DD.MM.YYYY')}</span>
            <span>{created_at && moment(created_at * 1000).format('HH:mm:ss')}</span>
          </span>
        </div>
      ),
      priority: (
        <div className={cx('notifications-table_td')}>
          <span>
            <div>
              <span className={cx(priority)}>{upperFirst(priority)}</span>
            </div>
          </span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('notifications-table')}
      isSelected={true}
      getCellProps={(cellInfo, cx) => {
        const { row } = cellInfo;
        const isRead = tableData[row.index]?.is_read;
        return {
          style: {
            backgroundColor: `${isRead ? 'var(--white)' : 'var(--grey-600)'}`,
          },
        };
      }}
    />
  );
};

export default NotificationsTable;

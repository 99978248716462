import { PencilSimple } from "phosphor-react";
import { setProcessedMessage } from "../../../../store/slice/chat/chatSlice";
import { ChatMessageType, ProcessedMessageMode } from "../../../../models/Chat";
import { useAppDispatch } from "../../../../store";
import { MenuOption } from "./MenuOption";

type EditMessageOptionProps = { message: ChatMessageType };

export const EditMessageOption = ({ message }: EditMessageOptionProps) => {
    const dispatch = useAppDispatch();

    const handleEditMessage = () => {
        dispatch(setProcessedMessage({ message: message, mode: ProcessedMessageMode.IS_EDITING }));
    };

    return (
        <MenuOption title='Edit' onClick={handleEditMessage}>
            <PencilSimple size={32} color="var(--main-color)"/>
        </MenuOption>
    )
}
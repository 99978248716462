import React from "react";
import cn from "classnames";
import Button from "../../../../../components/ui/Button";
import { ChatAvatar } from "../../ChatAvatar/ChatAvatar";
import { CrownSimple, Gear, UserPlus } from "phosphor-react";
import { useAppSelector } from "../../../../../store";
import { selectChatUserId } from "../../../../../store/slice/chat/chatSlice";
import { PromoteAdminOption } from "../../menuOptions/PromoteAdminOption";
import { DeleteParticipantOption } from "../../menuOptions/DeleteParticipantOption";
import { JokerPortalDropdown } from "../../../../../components/ui/JokerDropdown/JokerDropdown";
import { useParticipantsDropdown } from "./useParticipantsDropdown";
import { UserTypeLabels, UserTypes } from "../../../../../models/Chat";


export const GroupParticipants = ({ thread, participants, closeModal, switchMode }) => {
    const userId = useAppSelector(selectChatUserId);
    const isAdmin = participants[userId]?.admin;
    const { selected, points, handleSelect, handleClickOutside } = useParticipantsDropdown();
    const selectedParticipant = participants[selected];

    return (
        <div className='chat-group-participants'>
            <div className='group-participants-list'>
                {(Object.values(participants)).map(({ id, owner, owner_id, admin }) => (
                    <div className={cn('chat-group-participant', { selected: selected === owner_id })} key={id}>
                        <ChatAvatar  className={UserTypeLabels[owner.base.type]} name={owner.name} status={false} size='sm' />
                        <div className="group-participant-name">{owner.name}</div>
                        {admin ? <CrownSimple size={24} color="var(--main-color)" /> : null}
                        {(userId !== owner_id) && isAdmin && owner.base.type === UserTypes.MANAGERS ? (
                            <Gear
                                className='participants-dropdown-button'
                                size={24}
                                onClick={(e) => handleSelect(e, owner_id)}
                            />
                        ) : null}
                    </div>
                ))}
            </div>
            {isAdmin ? (
                <div className='content-controls'>
                    <Button
                        buttonText="Cancel"
                        buttonType="outline"
                        type="button"
                        onClick={closeModal}
                    />
                    <Button
                        iconPrefix={true}
                        icon={<UserPlus size={32} color="var(--white)"/>}
                        buttonText="Add participants"
                        type="button"
                        onClick={switchMode}
                    />
                </div>
            ) : null}
            {selected ? (
                <JokerPortalDropdown
                    className='group-participant-dropdown'
                    positionX={points.x}
                    positionY={points.y}
                    onClickOutside={handleClickOutside}
                >
                    {!selectedParticipant.admin ? <PromoteAdminOption id={selectedParticipant.id} threadId={thread.id}/> : null}
                    <DeleteParticipantOption id={selectedParticipant.id} threadId={thread.id} ownerId={selectedParticipant.owner_id}/>
                </JokerPortalDropdown>
            ) : null}
        </div>
    )
}
import { defaultQueryCategoryOption } from 'components/ui/SearchBar';
import { TABLE_KYC_DOCUMENTS_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const kycDocumentsDefaultValues = {
  query: '',
  query_category: defaultQueryCategoryOption,
  created_at: '',
  kyc_category: [],
  kyc_type: [],
};

export const useKycDocumentsPage = () => {
  const table = useTableColumnOrder(
    'TABLE_KYC_DOCUMENTS_COLUMNS_NAMES',
    TABLE_KYC_DOCUMENTS_COLUMNS_NAMES,
  );

  const form = useForm({ reValidateMode: 'onChange', defaultValues: kycDocumentsDefaultValues });

  return { table, form };
};

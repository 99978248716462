import API from '../api';

export const findRules = (params, data) => API.post(`/api/v1/search/rules?${params}`, data);

export const addRule = (data) => API.post(`/api/v1/rules`, data);

export const updateRule = (id, data) => API.put(`/api/v1/rules/${id}`, data);

export const deleteRule = (id) => API.delete(`/api/v1/rules/${id}`);

export const toggleRule = (id, active: boolean) => API.post(`/api/v1/rule/${id}/change-status`, { active: active ? 1 : 0 });

import { TABLE_TRADING_ACTIVITY_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const tradingActivityClientDefaultValues = {
  query: '',
  trading_account: [],
  trade_side: [],
  ticker: [],
  open_at: '',
  close_at: '',
  volume: {
    from: '',
    to: '',
  },
};

export const useTradingActivity = () => {
  const form = useForm({
    reValidateMode: 'onChange',
    defaultValues: tradingActivityClientDefaultValues,
  });

  const table = useTableColumnOrder(
    'TABLE_TRADING_ACTIVITY_COLUMNS_NAMES',
    TABLE_TRADING_ACTIVITY_COLUMNS_NAMES,
  );

  return { form, table };
};

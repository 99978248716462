import classNames from 'classnames/bind';
import { XCircle, FolderSimplePlus } from 'phosphor-react';
import React, { FC, useEffect } from 'react';
import ImageUploading, { ImageType } from 'react-images-uploading';
import { systemImageTypes } from 'constants/systemStyles';
import styles from './style.module.scss';
import Button from '../Button';
import { Icon } from '../Icon';
import PopupYesOrNo from '../PopupYesOrNo/PopupYesOrNo';

const cx = classNames.bind(styles);

interface IDragDropUploadImage {
  src: string;
  onChange: (logo: string, key: string, setImages) => void;
  onRemove: (key: string) => void;
  name: string;
  maxFileSize?: number;
  acceptType?: string[];
  onError?: (error: any) => void;
}

const DragDropUploadImage: FC<IDragDropUploadImage> = ({
  onChange,
  onRemove,
  src,
  name,
  onError,
  maxFileSize = 1048576,
  acceptType = null,
}) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  useEffect(() => {
    src ? setImages([src]) : setImages([]);
  }, [src]);

  const handleChange = (imageList): void => {
    !images.length && setImages(imageList);
    onChange(imageList[0], name, setImages);
  };

  return (
    <div className={cx('drag-drop-upload-image')}>
      <ImageUploading
        multiple={false}
        value={images}
        onChange={handleChange}
        maxNumber={maxNumber}
        maxFileSize={maxFileSize}
        acceptType={acceptType || systemImageTypes}
        onError={onError}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <div className={cx('wrapper-uploaderImage')} {...dragProps}>
            <div className={cx('file-uploader')}>
              <div className={cx('file-uploader__files')}>
                <div className={cx('file-uploader__area')}>
                  {images.length === 0 ? (
                    <>
                      <Icon name="uploadCloud" size={33} color="var(--main-color)" />
                      <span className={cx('placeholder-title')}>Drag & Drop to Upload file</span>
                      <span className={cx('placeholder-separator')}>or</span>
                      <Button
                        icon={<Icon name="addFile" size={20} />}
                        buttonType="outline"
                        buttonText="Browse file"
                        onClick={onImageUpload}
                      />
                    </>
                  ) : (
                    imageList.map((image: ImageType, index) => (
                      <div key={index} className={cx('image-item')}>
                        <img src={image?.data_url ? image.data_url : image} alt="" />
                        <div className={cx('image-item__btn-wrapper')}>
                          <PopupYesOrNo headerTitle='Remove image' bodyText='Are you sure you want to remove this image?' onClick={() => {
                              onImageRemove(index);
                              onRemove(name);
                            }} triggerBtn={<Icon
                            name="close"
                            size={16}
                            color="var(--red)"
                          />} />
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            {errors?.maxFileSize && (
              <span className={cx('error__maxFileSize')}>
                The selected file is larger than {Math.floor(maxFileSize / 1024 ** 2)} MB.
              </span>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};
export default DragDropUploadImage;

import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getStatusForCrypt } from 'utils/getStatusForCrypt';
import { usePermission } from 'utils/usePermission';
import { copyToBuffer } from 'utils/copyToBuffer';
import { CRYPTO_WALLETS_TYPES } from 'constants/payments.const';
import { ICryptoRequestTable } from 'models/components/Table/CryptoRequestTable/CryptoRequestTable.ts';
import { Icon } from 'components/ui/Icon/Icon.tsx';
import { upperFirst } from 'lodash';
import styles from './crypto-wallet-table.module.scss';

const cx = classNames.bind(styles);

const CryptoRequestTable: FC<ICryptoRequestTable> = ({
  data,
  perPage,
  showLoader,
  columns,
  onSaveColumnOrder,
  onRowSelect,
}) => {
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);
  const { permissionGiven: PermissionShowWithdrawalRequest } = usePermission(
    'admin.trading-account.show',
  );

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, wallet_address, crypto_type, amount_usd, description, created_at, deleted_at } =
      item;
    const status = getStatusForCrypt(item);
    return {
      wallet_address: (
        <div className={cx('wallet__desc')}>
          <Icon name="wallet" size={18} />
          <span
            className={cx('wallet__name')}
            onClick={() => copyToBuffer(wallet_address, true)}
          >
            {wallet_address}
          </span>
          <span>{description}</span>
        </div>
      ),
      crypto_type: (
        <div className={cx('wallet__type')}>
          <Icon name={crypto_type?.toLowerCase()} size={18} />
          <span>{CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]}</span>
        </div>
      ),
      amount_usd: (
        <div className={cx('amount')}>
          <span>{amount_usd}</span>
        </div>
      ),
      created_at: (
        <div className={cx('transaction__created')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      status: (
        <div className={cx('transaction__pinned')}>
          <span className={cx(status)}>{upperFirst(status)}</span>
        </div>
      ),
      action: (
        <div className={cx('wallet__actions')}>
          {PermissionShowWithdrawalRequest ? (
            <Link to={`/crypto-request/${id}`} className={cx('view_link')}>
              read more
            </Link>
          ) : null}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
      <Table
        columns={columns}
        data={tableDataMeMo}
        perPage={perPage}
        showLoader={showLoader}
        onRowSelect={onRowSelect}
        className={cx('wallet-table')}
        isSelected={false}
        getCellProps={(cellInfo, cx) => {
          const { row } = cellInfo;
          const odd = row.index % 2 !== 0;
          return {
            style: {
              backgroundColor: `${odd ? 'var(--grey-600)' : 'var(--white)'}`,
            },
          };
        }}
        saveColumnOrder={onSaveColumnOrder}
      />
    </>
  );
};

export default CryptoRequestTable;

import { TABLE_ALL_CALLBACKS_COLUMNS_NAMES } from 'constants/table';
import { useForm } from 'react-hook-form';
import useTableColumnOrder from 'utils/useTableColumnOrder';

export const callbacksDefaultValues = {
  query: '',
  created_at: '',
  statuses: undefined,
};

export const useCallbacksPage = () => {
  const table = useTableColumnOrder(
    'TABLE_ALL_CALLBACKS_COLUMNS_NAMES',
    TABLE_ALL_CALLBACKS_COLUMNS_NAMES,
  );

  const form = useForm({ reValidateMode: 'onChange', defaultValues: callbacksDefaultValues });

  return { table, form };
};

import React, { useEffect, useState } from 'react';

import { getBrandMethod } from '../../api/hierarchy/index';
import { DashboardLayout } from '../UserView/DashboardLayout';
import { HierarchyRoot } from 'models/Hierarchy/HierarchyData';
import HierarchyComponentOffices from './HierarchyComponentOffices';
import Page from 'pages/Page';
import { usePageTitle } from 'utils/usePageTitle';

import 'react-simple-tree-menu/dist/main.css';

const title = 'Hierarchy';

const HierarchyPage = () => {
  const [data, setData] = useState<HierarchyRoot>();

  usePageTitle(title);

  useEffect(() => {
    getBrandMethod()
      .then((res) => {
        if (res.status === 200) {
          setData({ brand: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <DashboardLayout>
      <Page title={title}>
        <>
          {data?.brand?.map((item) => {
            return <HierarchyComponentOffices data={item} />;
          })}
        </>
      </Page>
    </DashboardLayout>
  );
};

export default HierarchyPage;

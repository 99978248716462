import { APISignInResponse } from 'models/API/Auth/Auth';
import API from '../api';
import { APIGetUserInfoResponse } from 'models/API/Auth/UserInfo';
import { APIGetMTResponse } from 'models/API/Auth/MT';

export const signIn = (data) => {
  return API.post<APISignInResponse>('/api/v1/login', data);
};
export const fetchUserInfo = () => API.get<APIGetUserInfoResponse>('/api/v1/admin-user/me');

// method unused
export const signUp = (data) => API.post('/api/v1/register', data);

// method unused
export const verificationEmail = (verificationData) =>
  API.post('/api/v1/verify/code', verificationData);

// response (message) unused
export const resetPassword = (data) => API.post('/api/v1/password/reset', data);

// method used in unused page
export const newPassword = (data) => API.post('/api/v1/new/password/email', data);

// response (message) unused
export const logOut = () => API.post('/api/v1/logout');

// method unused
export const authWS = (data) => API.post('/broadcasting/auth', data);

export const getSettings = () => API.get<APIGetMTResponse>('/api/v1/settings');

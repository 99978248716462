import moment from 'moment/moment';
import cn from 'classnames';
import styles from './client-last-activity.module.scss';
import { Client } from 'models/Clients/Clients';

type Props = {
  client: Client;
};

export const ClientLastActivity = ({ client }: Props) => {
  return (
    <div className={styles.container}>
      <div className={cn(styles.activity, styles[client?.activity])}>
        <span>{client?.activity ? client.activity : '---'}</span>
        <span>&#x2022;</span>
      </div>
      <div>
        <div className="mb-1">
          {client?.last_activity ? moment(client.last_activity * 1000).fromNow() : '---'}
        </div>
        <div>
          on{' '}
          {client?.last_activity ? moment(client.last_activity * 1000).format('DD.MM.YYYY') : '---'}
        </div>
      </div>
      <div>
        Location:{' '}
        {client?.visits?.length && client?.visits[0]?.country
          ? client?.visits[0]?.country.name
          : '---'}
      </div>
    </div>
  );
};

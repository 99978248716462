import React, { FC } from 'react';
import { TablePaginationButtons } from './components/TablePaginationButtons/TablePaginationButtons';
import { TablePaginationInput } from './components/TablePaginationInput/TablePaginationInput';
import { TablePaginationSelect } from './components/TablePaginationSelect/TablePaginationSelect';

import styles from './table-pagination.module.scss';

interface ITablePagination {
  perPage?: number;
  perPageChange: (perPage: number) => void;
  goToSelectedPage: (page: number) => void;
  currentPage: number;
  pagesLength: number;
  isRareCase?: boolean;
  hidePageSelect?: boolean;
}

const TablePagination: FC<ITablePagination> = (props) => {
  const {
    perPage = 10,
    perPageChange,
    goToSelectedPage,
    currentPage,
    pagesLength,
    isRareCase,
    hidePageSelect,
  } = props;

  return (
    <div className={styles.pagination}>
      <TablePaginationButtons
        currentPage={currentPage}
        pageCount={pagesLength}
        onChangePage={goToSelectedPage}
      />
      <TablePaginationInput
        defaultValue={currentPage}
        max={pagesLength}
        pageCount={pagesLength}
        onChangePage={(page) => goToSelectedPage(page)}
      />
      {!hidePageSelect && (
        <TablePaginationSelect isRareCase={isRareCase} perPage={perPage} onChange={perPageChange} />
      )}
    </div>
  );
};

export default TablePagination;
